import React from 'react';
import PropTypes from 'prop-types';

const StaticWave = ({ fill }) => (
  <path
    fill={fill}
    d="M0,160L40,154.7C80,149,160,139,240,154.7C320,171,400,213,480,234.7C560,256,640,256,720,224C800,192,880,128,960,101.3C1040,75,1120,85,1200,101.3C1280,117,1360,139,1400,149.3L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
  />
);

StaticWave.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default StaticWave;
