import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    backgroundColor: '#eaf0f3',
    borderRadius: 10,
    padding: 8,
  },
  icon: {
    fontSize: '1.2rem',
  },
}));
