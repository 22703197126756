import React from 'react';
import PropTypes from 'prop-types';
import AdminLayout from 'common/components/admin/Layout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Banner from 'common/components/admin/Banner';
import Button from 'common/components/admin/Button';
import IconButton from 'common/components/admin/IconButton';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import SelectInput from 'common/components/admin/SelectInput';
import SortIcon from 'assets/images/icons/sort';
import {
  assignedAssessmentsData,
  clientsValues,
  statusValues,
} from './assignedAssessmentsData';
import useStyles from './styles';

const PersonRemoveIcon = ({ style }) => (
  <Icon
    className="fas fa-user-minus"
    style={{ color: '#fff', fontSize: 14, width: 18, ...style }}
  />
);

const columnNames = [
  { label: 'Assessment name', isSorted: true, style: { width: '30%' } },
  { label: 'Client', isSorted: true, style: { width: '20%' } },
  { label: 'Status', isSorted: true, style: { width: '12%' } },
  { label: 'Assigned date', isSorted: true, style: { width: '18%' } },
  { label: 'Actions', style: { width: '20%' } },
];

const rows = assignedAssessmentsData.map((item) => {
  const { assignedDate, status } = item;
  const tempDate = new Date(assignedDate);
  const month = tempDate.getMonth() + 1;
  const day = tempDate.getDate();
  const parsedMonth = month < 10 ? `0${month}` : month;
  const parsedDay = day < 10 ? `0${day}` : day;
  const parsedDate = `${parsedMonth}/${parsedDay}/${tempDate.getFullYear()}`;
  return {
    ...item,
    date: parsedDate,
    status: (
      <Banner title={status.title.toUpperCase()} bgColor={status.color} />
    ),
  };
});

const Row = ({ row }) => {
  const classes = useStyles();
  return (
    <>
      <TableRow key={row.id}>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.assessmentName}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.client}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.status}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.date}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButtonsGroup>
            <IconButton
              IconComponent={VisibilityIcon}
              iconProps={{
                style: {
                  color: '#143A56',
                },
              }}
            />
            <IconButton
              IconComponent={PrintIcon}
              iconProps={{
                style: {
                  color: '#143A56',
                },
              }}
            />
            <IconButton
              IconComponent={PersonRemoveIcon}
              iconProps={{
                style: {
                  color: '#143A56',
                },
              }}
            />
          </IconButtonsGroup>
        </TableCell>
      </TableRow>
    </>
  );
};

const AssignedAssessments = () => {
  const classes = useStyles();

  return (
    <AdminLayout subTitle="Assessments" title="Assigned assessments">
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          component={Box}
          py={4}
          px={2}
        >
          <Grid
            item
            container
            component={Box}
            flex={1}
            className={classes.countLabel}
          >
            <Typography component="span">Showing</Typography>
            <Typography style={{ fontWeight: 700 }}>&nbsp;75&nbsp;</Typography>
            <Typography>assigned assessments</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            component={Box}
            flex={2}
            justifyContent="flex-end"
          >
            <Grid item component={Box}>
              <SelectInput
                placeholder="All clients"
                valueOptions={clientsValues}
              />
            </Grid>
            <Grid item component={Box}>
              <SelectInput placeholder="Status" valueOptions={statusValues} />
            </Grid>
            <Grid item component={Box}>
              <Button className={classes.resetButton} variant="outlined">
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" component={Box}>
          <TableContainer className={classes.table}>
            <Table size="small" style={{ tableLayout: 'fixed' }}>
              <TableHead style={{ backgroundColor: '#F5F8FA' }}>
                <TableRow>
                  {columnNames.map((col) => {
                    return (
                      <TableCell
                        key={col.label}
                        style={col.style}
                        className={classes.thGrid}
                      >
                        <Grid
                          component={Box}
                          display="flex"
                          container
                          alignItems="center"
                        >
                          <Tooltip title={col.label} arrow>
                            <Typography className={classes.th}>
                              {col.label}
                            </Typography>
                          </Tooltip>
                          {col.isDecrypted && (
                            <Icon
                              className={[
                                'fas fa-question-circle',
                                classes.queryIcon,
                              ].join(' ')}
                            />
                          )}
                          {col.isSorted && (
                            <SvgIcon
                              component={Box}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {SortIcon}
                            </SvgIcon>
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

PersonRemoveIcon.propTypes = {
  style: PropTypes.shape(),
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    assessmentName: PropTypes.string,
    client: PropTypes.string,
    status: PropTypes.shape(),
    date: PropTypes.string,
  }),
};

export default AssignedAssessments;
