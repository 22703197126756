import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Header from 'common/components/Header';
import Footer from 'common/components/Footer';

import useStyles from './styles';

const Terms = () => {
  const styles = useStyles();

  return (
    <>
      <Header height={150} />
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="justify"
          className={styles.root}
        >
          <Typography variant="h4" component="h1">
            TERMS OF USE (“Terms”)
          </Typography>
          <Typography variant="h4">For</Typography>
          <Typography variant="h4" component="h2">
            Text and Online Counseling
          </Typography>
          <Box mt={4}>
            <Typography variant="body2">
              DO NOT USE THIS SERVICE FOR EMERGENCY MEDICAL NEEDS. IF YOU
              EXPERIENCE A MEDICAL EMERGENCY, CALL 911 IMMEDIATELY.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography variant="body2">
              IF YOU ARE THINKING ABOUT SUICIDE OR IF YOU ARE CONSIDERING TAKING
              ACTIONS THAT MAY CAUSE HARM TO YOU OR TO OTHERS OR IF YOU FEEL
              THAT YOU OR ANY OTHER PERSON MAY BE IN ANY DANGER OR IF YOU HAVE
              ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL THE EMERGENCY
              SERVICE NUMBER AND NOTIFY THE RELEVANT AUTHORITIES. THE TEXT AND
              ONLINE COUNSELING SITE DISPLAYS THE SUICIDE HOTLINE IN SEVERAL
              PROMINENT LOCATIONS AND HAS AN INDEX OF NATIONAL, REGIONAL AND
              STATE RESOURCES FOR MENTAL HEALTH EMERGENCIES WHICH WE IMPLORE ALL
              USERS OF THIS SITE TO USE WHEN FACED WITH AN IMMEDIATE OR EMERGENT
              CRISIS.
            </Typography>
          </Box>

          <Typography variant="body2">
            Please read the following Terms carefully before using Text and
            Online Counseling[’s/LLC’s] website and its subdomains, including
            but not limited to the www.textandonlinecounseling.com website, as
            well as any online features and other services and/or programs
            offered by Text and Online Counseling[ LLC] (collectively, “TOC”,
            “our”, “us” or “we”).
          </Typography>

          <Typography variant="body1">
            By accessing or using our website and its subdomains (referred
            herein to as the “Website”), you indicate your unconditional
            acceptance of the following Terms on your own behalf and on behalf
            of any organization you represent (collectively, &ldquo;you&ldquo;
            or &ldquo;your&ldquo;). You should review these Terms regularly as
            they may change at any time at the sole discretion of TOC. If you do
            not agree to any portion of these Terms, you should not access or
            otherwise use the Website. “Content” refers to services available
            through the TOC network via the Website and any text, materials,
            documents, images, graphics, logos, design, audio, video and any
            other information provided from or on, uploaded to and/or downloaded
            from the Website.
          </Typography>

          <Box mt={4}>
            <Typography variant="body1">
              We will make an effort to update this page with any changes to
              these Terms and/or to the services described in these Terms and
              you are encouraged to review these Terms frequently (the date of
              the most recent revision to these Terms appear at the end of these
              Terms).
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="body1">
              You hereby certify that you are (i) over the age of eighteen (18)
              or have the legal ability to consent to therapy or have your
              parent/guardian consent to therapy, and (ii) physically located or
              are a resident of the State you have chosen as your current
              residency when creating your Account. You agree to provide
              “Contact Information” (your personal contact and/or a close family
              member/relation) to your Provider (as defined below) to act as an
              information source to be used in case of a mental health crisis or
              other emergency where knowledge of your location is crucial. You
              acknowledge that your ability to access and use the Website is
              conditioned upon the truthfulness of the information you provide
              regarding your age, residence and Contact Information and that the
              mental health service providers you access (“Provider(s)”) are
              relying upon this certification in order to interact with you and
              provide the mental health counseling services (the “Services”).
            </Typography>
          </Box>
          <Typography variant="body1" component="div">
            <ol>
              <li>
                <Typography variant="body2" component="span">
                  CONVENIENCE AND INFORMATION ONLY; SITE CONTENT.
                </Typography>
                &nbsp; By merely providing access to the Website, TOC does not
                warrant or represent that: (a) the Content is accurate,
                complete, up-to-date or current; (b) TOC has any obligation to
                update any Content; (c) the Content is free from technical
                inaccuracies or typographical errors; (d) that the Content does
                not infringe on the intellectual property rights of any third
                party; (e) that the Content is free from changes caused by a
                third party; (f) your access to the Website will be free from
                interruptions, errors, computer viruses or other harmful
                components; and/or (g) any information obtained in response to
                questions asked through, or postings made on, the Website is
                accurate or complete. Other than the guidance and advice you
                receive directly by you from your licensed Provider, the other
                educational, graphics, research sources and other incidental
                information on the Website, the Content, should not be
                considered medical advice. You should always talk to an
                appropriately qualified health care professional for diagnosis
                and treatment, including information regarding which medications
                or treatment may be appropriate for you. None of the Content
                represents or warrants that any particular medication or
                treatment is safe, appropriate, or effective for you. TOC does
                not endorse any specific tests, medications, products or
                procedures. TOC is not licensed to practice medicine and does
                not render medical care or medical advice. All services or
                recommendations made by a Provider are based solely on such
                Provider’s independent professional judgment.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  SITE USE AND CONTENT.
                </Typography>
                &nbsp;You may view, copy or print the Content solely for
                personal, non-commercial purposes. You may not otherwise use,
                modify, copy, print, display, reproduce, distribute or publish
                any information from the Content without the express, prior,
                written consent of TOC. At any time, we may, without further
                notice, make changes to the Content or the Website, to these
                Terms and/or to the services described in these Terms.
              </li>

              <li>
                <Typography variant="body2">
                  USER ACCOUNT, EAP, FEES AND INSURANCE BENEFITS.
                </Typography>

                <ol type="a">
                  <li>
                    <Typography variant="body2" component="span">
                      ONLINE ACCOUNT ACCESS.
                    </Typography>
                    &nbsp;To access the Services, you must first enroll to
                    establish an individual user account (“Account”), by
                    providing certain information. You agree that you will not
                    create more than one Account, or create an Account for
                    anyone other than yourself without first receiving
                    permission from the other person. In exchange for your use
                    of the Website and the Service and, if applicable, in order
                    for Providers to send notices to you, you agree to: (i)
                    provide true, accurate, current and complete information
                    about yourself as prompted by our Account enrollment form
                    (understanding you may use an anonymous user name or
                    nickname after you provide certain personal and emergency
                    contact information); and (ii) each time you log on,
                    maintain and promptly update such Account information to
                    keep it true, accurate, current and complete. If you provide
                    any information that is untrue, inaccurate, not current or
                    incomplete, or TOC has reasonable grounds to suspect that
                    such Account information is untrue, inaccurate, not current
                    or incomplete, TOC reserves the right to transition your
                    treatment to another provider outside of TOC and refuse any
                    future request(s) to use the TOC Website. You shall at all
                    times remain responsible for maintaining the confidentiality
                    of your Account password and username (if any) and any other
                    security information related to your Account. TOC will not
                    be liable for any loss that you incur as a result of someone
                    else accessing and using your Account, either with or
                    without your knowledge.
                  </li>

                  <li>
                    <Typography variant="body2" component="span">
                      EMPLOYER ASSISTANCE PROGRAMS (EAP).
                    </Typography>
                    &nbsp;In addition to the registration described above,
                    access to the Services through an EAP or an
                    employer-sponsored benefit plan or other insurance backed
                    plans may require you to provide additional registration
                    information. This information is collected to confirm your
                    eligibility with the EAP or network benefit administrator
                    and is not shared with your Provider (who will collect your
                    contact information separately). Further, your employer or
                    the employer of the subscriber of your benefits will not be
                    provided this information (you likely already gave them
                    similar information in your employee file unless you are a
                    family member of an eligible employee) and your employer
                    will not be told you or your family member are using the
                    Services unless such information is shared by you or part of
                    a pre-arranged special program. Information collected under
                    these circumstances will include but not be limited to:
                    First Name, Last Name, Email, Date of Birth, Phone number,
                    Who you are (employee or the spouse, domestic partner, or
                    dependent of an employee in certain programs), Employer’s
                    name. This information is required to confirm program or
                    benefit eligibility, your specific benefits, and to prevent
                    insurance and benefit fraud..
                  </li>

                  <li>
                    <Typography variant="body2" component="span">
                      CHARGES FOR SERVICES.
                    </Typography>
                    &nbsp;You will be informed of the fee to be charged when you
                    select a Provider. Fees may vary from Provider to Provider.
                    You will be asked to supply credit card information, which
                    will be verified prior to your online visit. You will not be
                    able to use Website to communicate with a health services
                    provider if the credit card information you provide is
                    inaccurate or if your credit card is declined. In order to
                    facilitate payment for your online visit, TOC will share
                    your credit card information and related personal
                    information to its designated credit card payment processor.
                    This information is shared solely for the purpose of
                    collecting the fee.
                  </li>

                  <li>
                    <Typography variant="body2" component="span">
                      RESPONSIBILITY FOR APPLICABLE FEES AND INSURANCE BENEFITS.
                    </Typography>
                    &nbsp;You are solely responsible for all fees associated
                    with your use of the Website, including any Services. You
                    may, at your sole discretion, elect to provide TOC with your
                    health insurance information. This information will be used
                    to electronically check your eligibility and submit a claim
                    on your behalf. Your copay and/or deductible will be
                    estimated based on the response from your health insurance
                    provider at the time of the visit and will be charged to
                    your credit card. The actual payment by your health plan may
                    be more or less than the estimated amount. If your health
                    plan reimburses more than the estimated amount, TOC will
                    refund the difference back to your credit card. If your
                    health plan reimburses less than the estimated amount, you
                    will be responsible for paying the difference. If your
                    health insurance carrier has entered into a contract with a
                    Provider to reimburse the Provider for services, the amount
                    your insurer has agreed to reimburse the Provider will be
                    reflected in the fee that you are charged. You are
                    encouraged to contact your insurer to see if the services
                    offered through TOC and its Website are covered prior to
                    using the Website or the Services. PLEASE NOTE that in order
                    to take advantage of any insurance benefits your insurer may
                    offer you must create an Account. Users who have not created
                    an Account will not have their insurance claim
                    electronically submitted to the user’s insurance, even if
                    such benefits are offered by the user’s insurer.
                  </li>
                </ol>
              </li>

              <li>
                <Typography variant="body2" component="span">
                  USER CONTENT
                </Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body2" component="span">
                      Submitted Content
                    </Typography>
                    &nbsp;You are solely responsible for any data, text, files,
                    information, usernames, images, graphics, photos, profiles,
                    audio and video clips, sounds, musical works, works of
                    authorship, applications, links and other content or
                    materials (collectively, &ldquo;User Content&ldquo;) that
                    you submit, use, display or share on or via the Website or
                    Services. You must not submit, upload, post, display or
                    share any User Content that you do not have the right to
                    copy, use or share for the purpose of your use of the
                    Website or Services. If you choose to provide information or
                    User Content to use, register for or participate in a
                    service, event, or promotion, you agree that you will
                    provide accurate, complete, and up to date information as
                    requested on the screens that collect information from you.
                  </li>

                  <li>
                    <Typography variant="body2" component="span">
                      Ownership and Use of User Content.
                    </Typography>
                    &nbsp;We do not claim ownership of any User Content. By
                    submitting such User Content, however, You hereby grant us
                    the right to (i) copy, store and use that User Content for
                    the purpose of providing you with our services and (ii)
                    modify the User Content to remove identifiers of you and any
                    other individuals or companies, aggregate such information
                    (&ldquo;Aggregated Data&ldquo;) and copy, store and use such
                    Aggregated Data for the purpose of improving the TOC
                    services generally.
                  </li>

                  <li>
                    <Typography variant="body2" component="span">
                      User Representations.
                    </Typography>
                    &nbsp;You represent and warrant that: (i) you own the User
                    Content submitted and used by you on or through the Website
                    or Services or otherwise have the right to grant the rights
                    and licenses set forth in these Terms; (ii) the submission
                    and use of your User Content on or through the Website or
                    Services does not violate, misappropriate or infringe on the
                    rights of any third party, including, without limitation,
                    privacy rights, publicity rights, copyrights, trademark
                    and/or other intellectual property rights; (iii) you agree
                    to pay for all royalties, fees, and any other monies owed by
                    reason of User Content you submit or use on or through the
                    Website or Services; and (iv) you have the legal right and
                    capacity to enter into these Terms in your jurisdiction.
                  </li>

                  <li>
                    <Typography variant="body2" component="span">
                      Required Disclosures.
                    </Typography>
                    &nbsp;You acknowledge and agree that we may store and/or
                    preserve User Content and may also disclose User Content
                    and/or other information provided by you if required to do
                    so by law or in the good faith belief that such preservation
                    or disclosure is reasonably necessary to: (a) comply with
                    legal process; (b) enforce the terms of these Terms; (c)
                    respond to claims that any User Content violates the rights
                    of third-parties in accordance with these Terms; and/or (d)
                    protect the rights, property, or personal safety of us, our
                    representatives and/or agents, our users or the public.
                  </li>
                </ol>
              </li>

              <li>
                <Typography variant="body2" component="span">
                  USER CONDUCT.
                </Typography>
                &nbsp;You understand and agree not to use TOC to:
                <ol type="a">
                  <li>
                    Post or upload images, content or initiate communications
                    which are unlawful, libelous, abusive, obscene,
                    discriminatory, or otherwise objectionable.
                  </li>
                  <li>To violate any laws or for any illegal purpose.</li>
                  <li>
                    Falsely state, impersonate, or otherwise misrepresent your
                    identity, including but not limited to the use of a
                    pseudonym, or misrepresenting your current or previous
                    positions and qualifications, or your affiliations with a
                    person or entity, past or present.
                  </li>
                  <li>
                    Upload, post, email, transmit or otherwise make available
                    any content, or initiate communications which include
                    information, that you do not have the right to disclose or
                    make available under any law or under contractual or
                    fiduciary relationships (such as insider information, or
                    proprietary and confidential information learned or
                    disclosed as part of employment relationships or under
                    nondisclosure agreements).
                  </li>
                  <li>
                    Upload, post, email, transmit or otherwise make available
                    any content, or initiate communication that infringes upon
                    patents, trademarks, trade secrets, copyrights or other
                    proprietary rights.
                  </li>
                  <li>
                    Upload, post, email, transmit or otherwise make available
                    any unsolicited or unauthorized advertising, promotional
                    materials, &ldquo;junk mail,&ldquo; &ldquo;spam,&ldquo;
                    &ldquo;chain letters,&ldquo; &ldquo;pyramid schemes,&ldquo;
                    or any other form of solicitation. This prohibition includes
                    but is not limited to: a) using TOC to send messages to
                    people who don&apos;t know you or who are unlikely to
                    recognize you as a known contact; b) using TOC to connect to
                    people who don&apos;t know you and then sending unsolicited
                    promotional messages to those direct connections without
                    their permission; and c) sending messages to distribution
                    lists, newsgroup aliases, or group aliases.
                  </li>
                  <li>
                    Upload, post, email, transmit or otherwise make available
                    any material that contains software viruses or any other
                    computer code, files or programs designed to interrupt,
                    destroy or limit the functionality of any computer software
                    or hardware or telecommunications equipment.
                  </li>
                  <li>Stalk or harass anyone.</li>
                  <li>
                    Forge headers or otherwise manipulate identifiers in order
                    to disguise the origin of any communication transmitted
                    through TOC.
                  </li>
                  <li>
                    Post content in fields that are not intended for that
                    content. Example: Putting an address in a name or title
                    field.
                  </li>
                  <li>
                    Interfere with or disrupt TOC or servers or networks
                    connected to TOC, or disobey any requirements, procedures,
                    policies or regulations of networks connected to TOC.
                  </li>
                </ol>
              </li>

              <li>
                <Typography variant="body2" component="span">
                  PROVIDERS.
                </Typography>
                &nbsp;TOC does not directly employ the Providers listed on its
                Website. TOC only works with independent, licensed and insured
                professional Providers. Your relationship with the Provider is
                strictly with the Provider. TOC is not involved in the
                Provider-patient relationship and does not interfere, validate
                or control the Provider’s treatment unless advocating on behalf
                of You when following up on a particular complaint. The
                information we make available about any particular Provider is
                supplied to us by that Provider, and TOC makes no warranty as to
                its accuracy. Users of the Website are ultimately responsible
                for choosing which Provider to communicate with. You are always
                advised to exercise a high level of care and caution in the use
                of Providers listed on the Website as you would making any
                mental health or medical decision. You should never disregard,
                avoid, or delay obtaining medical advice from your primary
                doctor or other qualified healthcare Provider, or using or
                combining TOC with traditional face-to-face appointments, solely
                because of information you saw on the TOC Website or advice you
                received through a Provider.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  PRIVACY.
                </Typography>
                &nbsp;You affirmatively consent to the fact that TOC’s personnel
                will, as needed, review your particular clinical information,
                including, without limitation information you provide on the TOC
                intake form and information provided during the course of the
                Services (collectively, the “Clinical Information”), in a
                HIPAA/HITECH approved de-identified form for the following
                purposes: (1) during your on-boarding and during the Provider
                selection process to provide relevant Clinical Information to
                the Provider;; (2) to review your complaint about a particular
                issue/instance that you report about your Provider or about a
                particular language intention with your Provider; (3) for your
                safety concerns or complaints of unethical Provider practices;
                (4) to transition you to a new Provider or if your account is
                non responsive or inactive for an extended period of time; or
                (5) to address raised quality assurance concern(s) that may
                apply to an individual Provider, a state-wide compliance issue
                or a national network issue. You consent to TOC using
                &ldquo;Meta Data&ldquo; and other search terms to scan only
                HIPAA ‘Safe Harbor” de-identified transcripts to search for
                trends and patterns that may affect the quality of the services
                provided to you or the practices of the Providers. If your
                particular complaint requires a review of session transcripts in
                the original form, you consent to TOC personnel accessing your
                account only to respond to your particular complaint or raised
                issue. You consent to TOC recording your calls to TOC Customer
                Service to assure quality assurance. You affirmatively grant TOC
                permission to have your Provider periodically provide
                non-content based clinical assessments of your progress to TOC.
                All de-identified data, meta-data and research data collected by
                TOC through your use of the Website or Services remains the sole
                property of TOC. TOC may share some or all of this research data
                with third parties in connection with joint or outside research,
                analytics, or similar purposes. Research data is used in non
                identifiable aggregate form, that is, as a statistical measure,
                but not in a manner that would identify you personally. You
                shall not request that TOC remove or delete any of such data.
                You agree that the email username you provide can be used by TOC
                to send you marketing offers from TOC. In addition to the
                foregoing, personal data that you provide regarding yourself
                will be handled in accordance with TOC’s Privacy Policy.
              </li>

              <li>
                <Typography variant="body2">DISCLAIMERS.</Typography>
                <ol type="a">
                  <li>
                    <Typography variant="body2" component="span">
                      NO WARRANTIES.
                    </Typography>
                    &nbsp;YOU EXPRESSLY AGREE THAT YOUR USE OF THE WEBSITE IS AT
                    YOUR SOLE RISK. THE WEBSITE AND THE CONTENT IS PROVIDED “AS
                    IS” AND “AS AVAILABLE” FOR YOUR USE, WITHOUT WARRANTIES OF
                    ANY KIND, EITHER EXPRESS OR IMPLIED, UNLESS SUCH WARRANTIES
                    ARE LEGALLY INCAPABLE OF EXCLUSION. TOC PROVIDES THE WEBSITE
                    AND THE CONTENT ON A COMMERCIALLY REASONABLE BASIS AND TOC
                    MAKES NO REPRESENTATIONS OR WARRANTIES THAT THE WEBSITE, THE
                    CONTENT OR ANY SERVICES OFFERED IN CONNECTION WITH THE
                    WEBSITE ARE OR SHALL REMAIN UNINTERRUPTED OR ERROR-FREE, THE
                    CONTENT SHALL BE NON-INFRINGING ON ANY THIRD PARTY’S
                    INTELLECTUAL PROPERTY RIGHTS, THAT DEFECTS SHALL BE
                    CORRECTED, THAT THE WEB PAGES ON THE WEBSITE, ANY ELECTRONIC
                    COMMUNICATION OR THE SERVERS USED IN CONNECTION WITH THE
                    WEBSITE ARE OR SHALL REMAIN FREE FROM ANY VIRUSES, WORMS,
                    TIME BOMBS, DROP DEAD DEVICES, TROJAN HORSES OR OTHER
                    HARMFUL COMPONENTS, OR THAT ANY PERSON USING THE WEBSITE
                    WILL BE THE PERSON THAT HE OR SHE REPRESENTS HIMSELF OR
                    HERSELF TO BE. TOC DOES NOT GUARANTEE THAT YOU WILL BE ABLE
                    TO ACCESS OR USE THE WEBSITE AND/OR THE CONTENT AT TIMES OR
                    LOCATIONS OF YOUR CHOOSING, OR THAT TOC SHALL HAVE ADEQUATE
                    CAPACITY FOR THE WEBSITE AND/OR THE CONTENT AS A WHOLE OR IN
                    ANY SPECIFIC GEOGRAPHIC AREA.
                  </li>
                  <li>
                    <Typography variant="body2" component="span">
                      INDEMNIFICATION.
                    </Typography>
                    &nbsp;You agree to defend, indemnify and hold TOC and its
                    affiliates, subsidiaries, owners, directors, members,
                    officers, employees and agents harmless from and against any
                    and all claims, demands, suits, proceedings, liabilities,
                    judgments, losses, damages, expenses and costs (including
                    without limitation reasonable attorneys’ fees) assessed or
                    incurred by TOC, directly or indirectly, with respect to or
                    arising out of: (i) your failure to comply with these Terms;
                    (ii) your breach of your obligations under these Terms;
                    (iii) your use of the rights granted hereunder, including
                    without limitation any claims made by any third parties;
                    and/or (iv) your violation of any third party right,
                    including without limitation any copyright, property, or
                    privacy right.
                  </li>
                  <li>
                    <Typography variant="body2" component="span">
                      ALERT DISCLAIMER.
                    </Typography>
                    &nbsp;You understand and agree that any alerts provided to
                    you through TOC may be delayed or prevented by a variety of
                    factors. We do our best to provide alerts in a timely manner
                    with accurate information. However, we neither guarantee the
                    delivery nor the accuracy of the content of any alert. You
                    also agree we shall not be liable for any delays, failure to
                    deliver, or misdirected delivery of any alert; for any
                    errors in the content of an alert; or for any actions taken
                    or not taken by you or any third party in reliance on an
                    alert.
                  </li>
                </ol>
              </li>

              <li>
                <Typography variant="body2" component="span">
                  LIMITATION OF LIABILITY.
                </Typography>
                &nbsp;TOC’S ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY WITH
                RESPECT TO THE USE OF THE WEBSITE, THE CONTENT AND/OR ANY
                SERVICE PROVIDED IN CONNECTION WITH THE WEBSITE SHALL BE THE
                CANCELLATION OF YOUR USER ACCOUNT WITH TOC. IN NO EVENT WILL TOC
                BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR
                USE OF THE WEBSITE, THE CONTENT AND/OR ANY SERVICE PROVIDED IN
                CONNECTION WITH THE WEBSITE, OR FOR ANY OTHER CLAIM RELATED IN
                ANY WAY TO YOUR USE OF THE WEBSITE, THE CONTENT AND/OR ANY
                SERVICE PROVIDED IN CONNECTION WITH THE WEBSITE, INCLUDING, BUT
                NOT LIMITED TO, (A) ERRORS, MISTAKES, OR INACCURACIES OF
                CONTENT, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                WEBSITE, THE CONTENT AND/OR ANY SERVICE PROVIDED IN CONNECTION
                WITH THE WEBSITE, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
                COMPUTER SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
                FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR
                CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE, THE CONTENT
                AND/OR ANY SERVICE PROVIDED IN CONNECTION WITH THE WEBSITE,
                AND/OR (E) ANY VIRUSES, WORMS, TIME BOMBS, DROP DEAD DEVICES,
                TROJAN HORSES OR OTHER HARMFUL COMPONENTS THAT MAY BE
                TRANSMITTED TO OR THROUGH THE WEBSITE, THE CONTENT AND/OR ANY
                SERVICE PROVIDED IN CONNECTION WITH THE WEBSITE BY ANY THIRD
                PARTY OR FOR ANY LOSS OR DAMAGE OF ANY KIND. BECAUSE SOME STATES
                OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
                STATES OR JURISDICTIONS TOC’S LIABILITY WILL BE LIMITED TO THE
                GREATEST EXTENT PERMITTED BY LAW.
                <Typography variant="body2">
                  TOC is not responsible for any claims you may have against any
                  medical professionals, suppliers of products or other persons,
                  institutions or entities identified in whole or in part
                  through this Website, including any Providers providing
                  Services to you.
                </Typography>
              </li>

              <li>
                <Typography variant="body2" component="span">
                  THIRD PARTY CONTENT.
                </Typography>
                &nbsp;TOC may provide hyperlinks to other web sites maintained
                by third parties, or TOC may provide third party content on the
                Website. THE LINKS TO THIRD PARTY WEB SITES ARE PROVIDED FOR
                YOUR CONVENIENCE AND INFORMATION ONLY. THE CONTENT ON ANY LINKED
                WEB SITE IS NOT UNDER TOC’S CONTROL AND TOC IS NOT RESPONSIBLE
                FOR THE CONTENT OF LINKED WEB SITES, INCLUDING ANY FURTHER LINKS
                CONTAINED IN A THIRD PARTY WEB SITE. IF YOU DECIDE TO ACCESS ANY
                OF THE THIRD PARTY WEB SITES LINKED TO THE WEB SITE, YOU DO SO
                ENTIRELY AT YOUR OWN RISK.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  COPYRIGHT AND TRADEMARKS.
                </Typography>
                &nbsp;The trademarks, service marks and logos used and displayed
                on the Website are TOC’s, or its subsidiaries’ or affiliates’,
                registered and unregistered trademarks. TOC is the copyright
                owner or authorized licensee of all text and all graphics
                contained on the Website. Other parties’ trademarks and service
                marks that may be referred to on the Website are the property of
                their respective owners. Nothing on the Website should be
                construed as granting, by implication, estoppel or otherwise,
                any license or right to use any of TOC’s trademarks or service
                marks without TOC’s prior written permission. MY REFINWELL
                aggressively enforces its intellectual property rights. Neither
                the name of TOC nor any of TOC’s other trademarks, service marks
                or copyrighted materials may be used in any way, including in
                any advertising, hyperlink, publicity or promotional materials
                of any kind, whether relating to the Website or otherwise,
                without TOC’s prior written permission. If you believe that any
                Content on the Website violates any intellectual property right
                of yours, please contact TOC at the address, email address or
                telephone number set forth at the bottom of these Terms.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  LOCAL LAWS.
                </Typography>
                &nbsp;TOC makes no representation that Content or materials in
                the Website are appropriate or available for use in
                jurisdictions outside the United States. Access to the Website
                from jurisdictions where such access is illegal is prohibited.
                If you choose to access the Website from other jurisdictions,
                you do so on your own initiative and are responsible for
                compliance with applicable local laws. TOC is not responsible
                for any violation of law. You may not use or export the Content
                or materials in the Website in violation of U.S. export laws and
                regulations. You agree that the Website, these Terms and the
                Content shall be interpreted and governed in accordance with
                federal law and, to the extent not preempted by federal law,
                with the laws of the Commonwealth of Pennsylvania. The Website
                and the Content shall be deemed a passive website and service
                that does not give rise to personal jurisdiction over TOC,
                either specific or general, in jurisdictions other than the
                Commonwealth of Pennsylvania. You agree and hereby submit to the
                exclusive personal jurisdiction of the state and federal courts
                located in the Commonwealth of Pennsylvania, Philadelphia
                County. You further agree to comply with all applicable laws
                regarding the transmission of technical data exported from the
                United States and the country in which you reside (if different
                from the United States).
              </li>

              <li>
                <Typography variant="body2" component="span">
                  AVAILABILITY.
                </Typography>
                &nbsp;Information that TOC publishes in the Website may contain
                references or cross-references to products, programs or services
                of TOC that are not necessarily announced or available in your
                area. Such references do not mean that TOC will announce any of
                those products, programs or services in your area at any time in
                the future. You should contact TOC for information regarding the
                products, programs and services that may be available to you, if
                any.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  NON-TRANSFERABILITY OF USER ACCOUNT.
                </Typography>
                &nbsp;User Accounts and UserIDs are non-transferable, and all
                users are obligated to take preventative measures to prohibit
                unauthorized users from accessing the Website with his or her
                UserID and password. You may not assign these Terms, in whole or
                in part, or delegate any of your responsibilities hereunder to
                any third party. Any such attempted assignment or delegation
                will not be recognized by TOC unless acknowledged by TOC in
                writing. TOC has no obligation to provide you with written
                acknowledgment. TOC may, at any time and in its sole discretion,
                assign these Terms, in whole or in part, or delegate any of our
                rights and responsibilities under these Terms to any third party
                or entity.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  TERMINATION OF SERVICE.
                </Typography>
                &nbsp;We may terminate your User Account or right to access
                secured portions of the Website at any time, without notice, for
                conduct that we believe violates these Terms and/or is harmful
                to other users of the Website, to TOC, to the business of the
                Website’s Internet service provider, or to other information
                providers.
              </li>

              <li>
                <Typography variant="body2" component="span">
                  CUSTOMER COMMENTS.
                </Typography>
                &nbsp;We welcome the submission of comments, information or
                feedback through the Website. By submitting information through
                the Website, you agree that the information submitted shall be
                subject to the TOC Website Privacy Policy.
              </li>

              <Typography variant="body2">
                <li>
                  <Typography variant="body2" component="span">
                    CLASS ACTION WAIVER and ARBITRATION.
                  </Typography>
                  &nbsp;You agree that any dispute arising out of the use of the
                  Website or the Content shall be brought only in your capacity
                  as an individual, and no claims may be brought as a class. All
                  disputes shall be submitted to binding arbitration in
                  Philadelphia, PA before a board of three neutral arbitrators.
                  The arbitration and rules shall be according to the American
                  Arbitration Association (AAA). In no event shall the
                  arbitrators award punitive damages.
                </li>
              </Typography>

              <li>
                <Typography variant="body2" component="span">
                  MISCELLANEOUS.
                </Typography>
                &nbsp;If any provision of these Terms is deemed invalid by a
                court of competent jurisdiction, the invalidity of such
                provision shall not affect the validity of the remaining
                provisions of these Terms, which shall remain in full force and
                effect. No waiver of any term of these Terms shall be deemed a
                further or continuing waiver of such term or any other term, and
                TOC’s failure to assert any right or provision under these Terms
                shall not constitute a waiver of such right or provision. These
                Terms and the TOC Website Privacy Policy are the entire
                agreement between you and TOC with respect to your use of the
                Website and the Content, and supersede any and all prior
                communications and prior agreements, whether written or oral,
                between you and TOC regarding the Website and the Content.
              </li>
            </ol>
          </Typography>
          <Typography variant="body2" component="h3">
            Your Consent To This Agreement
          </Typography>
          <Typography variant="body1">
            By accessing and using the Website, you consent to and agree to be
            bound by the terms of the foregoing Terms. If we decide to change
            these Terms, we will make an effort to post those changes on the web
            page so that you will always be able to understand the terms and
            conditions that apply to your use of the Website and/or the Content.
            Your use of the Website and/or the Content following any amendment
            of these Terms will signify your assent to and acceptance of its
            revised terms.
          </Typography>
          <Typography variant="body1">
            If you have additional questions or comments of any kind, or if you
            see anything on the Website that you think is inappropriate, please
            let us know by sending your comments to:
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <Typography variant="body1">
              Text and Online Counseling[ LLC]
            </Typography>
            <Typography variant="body1">
              Attn: Jessica Blanding MS, LPC
            </Typography>
            <Typography variant="body1">
              Jessica@&shy;textand&shy;online&shy;coun&shy;seling.com
            </Typography>
            <Typography variant="body1">
              EFFECTIVE AS OF: January 1, 2021
            </Typography>
            <Typography variant="body1">
              LAST UPDATED: January 25, 2021
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
