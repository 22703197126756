import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';

import {
  Circle,
  TOCButton,
  TOCIconButton,
  TOCSimpleDropdown,
} from 'common/components/simple';
import routes from 'common/constants/routes';
import { openSideMenu } from 'modules/sideMenu/ducks';
import { saveFilters } from 'ui/containers/Search/ducks';
import logo from 'assets/images/logo-min.png';
import useStyles from './styles';

const HomeHeader = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [state, setState] = useState(1);
  const states = useSelector((store) => store.home.states.list);

  const handleRedirect = () => {
    dispatch(saveFilters({ state }));
    dispatch(push(routes.search.url));
  };

  const closeMenu = () => dispatch(openSideMenu());

  return (
    <Grid className={styles.homeHeaderWrap}>
      <Circle size={37} left="5%" top="5%" color="secondary" />
      <Circle size={17} left="55%" top="12%" color="tertiary" />
      <Circle size={54} left="57%" top="21%" />
      <Circle size={14} left="4%" top="75%" color="secondary" />
      <Circle size={31} left={-10} top="81%" />
      <Circle size={50} left="90%" top="80%" color="secondary" />
      <Circle size={22} left="85%" top="100%" color="tertiary" />

      <Container>
        <Grid className={styles.homeHeaderContentWrap}>
          <Grid container className={styles.homeHeaderTopWrap}>
            <Grid item xs={6} className={styles.logoWrap}>
              <Typography variant="h5" className={styles.logoSubtitle}>
                Welcome to
              </Typography>
              <img src={logo} alt="header logo" className={styles.logo} />
            </Grid>

            <Grid item className={styles.burgerWrap}>
              <TOCIconButton
                size="small"
                color="white"
                bgColor="primary"
                icon={MenuIcon}
                onClick={closeMenu}
              />
            </Grid>
          </Grid>

          <Grid>
            <Typography variant="h1" className={styles.headerSlogan}>
              Let&apos;s TOC about it
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            md={12}
            container
            className={styles.searchBlockWrapper}
          >
            <Grid
              item
              xs={12}
              md={9}
              lg={8}
              container
              className={styles.headerSearch}
            >
              <Grid item xs={12} md={6}>
                <TOCSimpleDropdown
                  label="Select a state to start searching"
                  value={state}
                  options={states}
                  onChange={setState}
                />
              </Grid>

              <Hidden smDown>
                <TOCButton
                  shadow={false}
                  size="large"
                  color="secondary"
                  width={250}
                  onClick={handleRedirect}
                  className={styles.searchBtn}
                >
                  <Box fontWeight="700" component="span">
                    Search for a counselor
                  </Box>
                </TOCButton>
              </Hidden>
            </Grid>

            <Grid item xs={12} md={4} className={styles.orJoinBlockWrapper}>
              <Hidden mdUp>
                <TOCButton
                  shadow={false}
                  size="large"
                  color="secondary"
                  width={250}
                  onClick={handleRedirect}
                  className={styles.searchBtn}
                >
                  <Box fontWeight="700" component="span">
                    Search for a counselor
                  </Box>
                </TOCButton>
              </Hidden>

              <Typography
                variant="subtitle1"
                className={styles.headerSearchDivider}
                component="span"
              >
                <Box
                  fontWeight="700"
                  component="span"
                  className={styles.orWrapper}
                >
                  Or
                </Box>
              </Typography>

              <TOCButton
                shadow={false}
                size="large"
                color="primary"
                width={150}
                to={routes.joinUs.url}
              >
                <Box fontWeight="700" component="span">
                  Join group
                </Box>
              </TOCButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default HomeHeader;
