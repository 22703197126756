import {
  reducerGenerator,
  actionTypesGenerator,
  genderMapper,
  sortByAlphabet,
} from 'common/utils';

export const options = actionTypesGenerator(['get'], 'options');

const initialOptionsState = {
  options: null,
  loading: true,
  error: false,
};

export default reducerGenerator(
  options.get,
  initialOptionsState,
  (state, payload) => {
    const {
      genders,
      availableStates,
      availableInsurances,
      ...restOptions
    } = payload.data;

    return {
      ...state,
      options: {
        ...restOptions,
        genders: genderMapper(genders),
        availableStates,
        availableInsurances: sortByAlphabet(availableInsurances),
      },
    };
  }
);

export const fetchOptions = () => ({
  type: options.get.requested,
  payload: {
    pagination: {
      page: 1,
      perPage: 1,
    },
    sort: {
      field: '',
      order: '',
    },
  },
});
