import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '0.825rem',
    fontWeight: 500,
  },
  value: {
    fontSize: '0.9rem',
    lineHeight: 1.1,
  },
}));

const DataItem = ({ title, value, direction, ...restProps }) => {
  const classes = useStyles();
  let rootProps = {};
  if (direction === 'row') {
    rootProps = {
      ...rootProps,
      justify: 'space-between',
    };
  }
  return (
    <Grid
      container
      direction={direction}
      {...rootProps}
      component={Box}
      py={0.5}
      {...restProps}
    >
      <Grid item component={Box} pb={0.5}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.title}
        >
          {title}
        </Typography>
      </Grid>
      {value && (
        <Grid item>
          {typeof value === 'string' ? (
            <Typography className={classes.value}>{value}</Typography>
          ) : (
            value
          )}
        </Grid>
      )}
    </Grid>
  );
};

DataItem.defaultProps = {
  direction: 'column',
};

DataItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  direction: PropTypes.oneOf(['column', 'row']),
  restProps: PropTypes.shape(),
};

export default DataItem;
