import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
} from 'react-share';

import routes from 'common/constants/routes';
import { BlogDateReadTime, TOCLink } from 'common/components/simple';
import useStyles from './styles';

const ArticleContent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [tooltip, toggleTooltip] = useState(false);

  const shareLink = window.location.href;

  const copyLink = async () => {
    await navigator.clipboard.writeText(shareLink);
    toggleTooltip(true);
    setTimeout(() => toggleTooltip(false), 2000);
  };

  const redirectTo = (link) => () => dispatch(push(link));

  const { article, loading } = useSelector((store) => store.article);
  if (loading || !article) {
    return (
      <Box
        mt={50}
        minHeight={300}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const previousArticleLink = routes.blogArticle.url.replace(
    ':id',
    article.previousArticle?.id
  );

  const nextArticleLink = routes.blogArticle.url.replace(
    ':id',
    article.nextArticle?.id
  );

  return (
    <Container>
      <Grid container direction="column" className={styles.articleWrapper}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={styles.articleTitle}
        >
          <Typography variant="h2" className={styles.articleTitleName}>
            {article.name}
          </Typography>

          <Grid item>
            <BlogDateReadTime
              size="medium"
              date={article.createdAt}
              readTime={article.minutesToRead}
            />
          </Grid>
        </Grid>

        <img
          alt="article cover"
          src={article.coverImageUrl}
          className={styles.articleCoverImage}
        />

        <Grid
          item
          container
          direction="column"
          className={styles.articleContentWrapper}
        >
          <Grid item container className={styles.articleContent}>
            {ReactHtmlParser(article.content)}
          </Grid>

          <Grid item container className={styles.articleShare} spacing={2}>
            <Grid item>
              <FacebookShareButton
                url={shareLink}
                resetButtonStyle={false}
                className={styles.articleShareButton}
              >
                <Grid container alignItems="center">
                  <Icon
                    className="fab fa-facebook-square"
                    classes={{ root: styles.articleShareFacebookIcon }}
                  />
                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      Share
                    </Box>
                  </Typography>
                </Grid>
              </FacebookShareButton>
            </Grid>

            <Grid item>
              <TwitterShareButton
                url={shareLink}
                resetButtonStyle={false}
                className={styles.articleShareButton}
              >
                <Grid container alignItems="center">
                  <Icon
                    className="fab fa-twitter"
                    classes={{ root: styles.articleShareTwitterIcon }}
                  />
                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      Tweet
                    </Box>
                  </Typography>
                </Grid>
              </TwitterShareButton>
            </Grid>

            <Grid item>
              <LinkedinShareButton
                url={shareLink}
                resetButtonStyle={false}
                className={styles.articleShareButton}
              >
                <Grid container alignItems="center">
                  <Icon
                    className="fab fa-linkedin"
                    classes={{ root: styles.articleShareLinkedInIcon }}
                  />
                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      Share
                    </Box>
                  </Typography>
                </Grid>
              </LinkedinShareButton>
            </Grid>

            <Grid item>
              <PinterestShareButton
                url={shareLink}
                media={article.coverImageUrl}
                resetButtonStyle={false}
                className={styles.articleShareButton}
              >
                <Grid container alignItems="center">
                  <Icon
                    className="fab fa-pinterest"
                    classes={{ root: styles.articleSharePinterestIcon }}
                  />
                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      Pin
                    </Box>
                  </Typography>
                </Grid>
              </PinterestShareButton>
            </Grid>

            <Grid item>
              <Tooltip
                arrow
                open={tooltip}
                title="Link copied!"
                classes={{
                  tooltipPlacementBottom: styles.articleShareCopyTooltip,
                }}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  className={styles.articleShareButton}
                  onClick={copyLink}
                >
                  <Icon
                    className="fas fa-link"
                    classes={{ root: styles.articleShareCopyIcon }}
                  />
                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      Copy link
                    </Box>
                  </Typography>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>

          <Divider className={styles.articleDivider} />

          <Grid item container className={styles.articleNavigation} spacing={3}>
            <Grid item xs={12} sm={6} component={Box}>
              {article.nextArticle ? (
                <>
                  <Box className={styles.articleNavigationLinkWrapper}>
                    <TOCLink to={nextArticleLink}>
                      <Icon className="fas fa-angle-left" fontSize="inherit" />
                      <Typography
                        variant="body2"
                        className={styles.articleNavigationLink}
                      >
                        Previous article
                      </Typography>
                    </TOCLink>
                  </Box>

                  <Typography
                    variant="h4"
                    component="p"
                    onClick={redirectTo(nextArticleLink)}
                    className={styles.articleNavigationName}
                  >
                    {article.nextArticle.name}
                  </Typography>
                </>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={6} component={Box}>
              {article.previousArticle ? (
                <>
                  <Box className={styles.articleNavigationLinkWrapper}>
                    <TOCLink align="right" to={previousArticleLink}>
                      <Typography
                        variant="body2"
                        className={styles.articleNavigationLink}
                      >
                        Next article
                      </Typography>
                      <Icon className="fas fa-angle-right" fontSize="inherit" />
                    </TOCLink>
                  </Box>

                  <Typography
                    align="right"
                    variant="h4"
                    component="p"
                    onClick={redirectTo(previousArticleLink)}
                    className={styles.articleNavigationName}
                  >
                    {article.previousArticle.name}
                  </Typography>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArticleContent;
