import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { TOCAccordion } from 'common/components';

const OurCounselors = ({ homePage = false }) => (
  <Grid>
    <TOCAccordion
      title="What kind of counseling do Text and Online Counselors (TOCs) provide?"
      fontWeight="700"
    >
      <Typography variant="body1" component="div">
        TOCs draw on a range of specialties and evidence-based treatment
        modalities when providing counseling services. These may include, but
        are not limited to,
        <ul>
          <li>Cognitive Behavior Therapy (CBT)</li>
          <li>Solution-Focused Therapy</li>
          <li>Dialectical Behavior Therapy (DBT)</li>
          <li>Mindfulness-Based Cognitive Therapy (MBCT)</li>
        </ul>
      </Typography>
    </TOCAccordion>

    <TOCAccordion
      title="What credentials and experience do TOCs have?"
      fontWeight="700"
    >
      <Typography variant="body1" component="div">
        Our TOCs are
        <ul>
          <li>Licensed professional counselors (LPCs)</li>
          <li>Licensed clinical social workers (LCSWs)</li>
          <li>Licensed marriage and family therapists (LMFTs)</li>
        </ul>
        In addition, they have at least three years of experience providing
        individual and/or family counseling in a clinical setting.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What about background checks?" fontWeight="700">
      <Typography variant="body1" component="div">
        TOCs undergo extensive local and federal background checks, and practice
        in accordance with their state licensing boards.
      </Typography>
    </TOCAccordion>
    {!homePage ? (
      <>
        <TOCAccordion
          title="What if I don’t think my counselor is the right fit?"
          fontWeight="700"
        >
          <Typography variant="body1" component="div">
            Progress in counseling begins with establishing a therapeutic
            relationship. Building trust and rapport with a counselor may take
            several sessions. However, if you feel the counselor you are working
            with is not the best fit for your needs, selecting a new counselor
            is easy. Simply browse our counselors and book with another
            counselor whom you feel will be a better fit. However, if you are
            changing counselors, please be sure to delete any future sessions in
            the client portal to prevent any cancellation or no-show fees.
          </Typography>
        </TOCAccordion>

        <TOCAccordion
          title="Why is the cost different from one counselor to the next?"
          fontWeight="700"
        >
          <Typography variant="body1" component="div">
            Counseling fees are not standard. Each TOC sets their own session
            rates.
          </Typography>
        </TOCAccordion>

        <TOCAccordion
          title="How many counselors can I work with at a time?"
          fontWeight="700"
        >
          <Typography variant="body1" component="div">
            Working with one counselor consistently is most appropriate for goal
            attainment and continuity of care. However, this is a matter of
            personal preference.
          </Typography>
        </TOCAccordion>

        <TOCAccordion
          title="Can I get in touch with my counselor outside of the TOC software?"
          fontWeight="700"
        >
          <Typography variant="body1" component="div">
            Unfortunately, no. Texting and many other forms of communication may
            not be a confidential way to communicate. Please message your
            counselor through the client portal and hold voice and video
            sessions as scheduled.
          </Typography>
        </TOCAccordion>

        <TOCAccordion
          title="Can I meet my counselor in an office?"
          fontWeight="700"
        >
          <Typography variant="body1" component="div">
            No, we are strictly a provider of telehealth mental health services.
            Working with a TOC outside of this platform will violate the
            counselor agreement with Text and Online Counseling.
          </Typography>
        </TOCAccordion>
      </>
    ) : null}

    <TOCAccordion title="How do I remain anonymous?" fontWeight="700">
      <Typography variant="body1" component="div">
        Client confidentiality is our utmost priority. All sessions are
        confidential and HIPAA-compliant. In addition, our counselors strictly
        adhere to ethics and confidentiality according to state laws. If you
        wish to remain anonymous, you may do so. However, this option is
        available only to those paying out of pocket for services and not
        utilizing any health insurance benefits.
      </Typography>
    </TOCAccordion>
  </Grid>
);

OurCounselors.propTypes = {
  homePage: PropTypes.bool,
};

export default OurCounselors;
