import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'common/components/admin/Button';
import AdminLayout from 'common/components/admin/Layout';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import IconButton from 'common/components/admin/IconButton';
import TotalStatistics from 'common/components/admin/Statistics/TotalStatistics';
import SortIcon from 'assets/images/icons/sort';
import Icon from '@material-ui/core/Icon';
import Banner from 'common/components/admin/Banner';

import tableData from './tableData';
import TableHeader from './components/TableHeader';

const useStyles = makeStyles({
  topButton: {
    borderRadius: 20,
    boxShadow: '0px 5px 15px #143A5680',
    color: '#fff',
  },
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  clientsCountLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: 10,
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: '0.9rem',
    color: 'black',
  },
  actionsCol: {
    width: 122,
  },
});

const iconStyles = {
  fontSize: '0.8rem',
};

const rows = tableData.map((item) => {
  const {
    id,
    isNew,
    fullName,
    dateJoined,
    activeClients,
    credentialingStatus,
  } = item;
  const getCredentialingStatus = (status) => {
    const statusString = status ? `${status} of 6 completed` : 'completed';
    return statusString.toUpperCase();
  };
  return {
    id,
    fullName: (
      <Grid
        component={Box}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography style={iconStyles}> {fullName} </Typography>
        {isNew && <Banner bgColor="#DAEAF5" title="NEW" />}
      </Grid>
    ),
    contact: (
      <Grid
        component={Box}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <PhoneIcon style={iconStyles} />
        <Typography style={iconStyles}> &middot; </Typography>
        <EmailIcon style={iconStyles} />
        <Typography style={iconStyles}> &middot; </Typography>
        <PhoneIphoneIcon style={iconStyles} />
      </Grid>
    ),
    dateJoined,
    activeClients,
    active: <Switch />,
    locked: <Switch />,
    credentialingStatus: (
      <Banner
        bgColor={credentialingStatus ? '#FCF4E6' : '#E2F3E9'}
        title={getCredentialingStatus(credentialingStatus)}
      />
    ),
  };
});

const columnNames = [
  { label: 'Provider Name', style: { width: '12%' } },
  { label: 'Contact' },
  { label: 'Date Joined' },
  { label: 'Active Clients' },
  { label: 'Active' },
  { label: 'Locked' },
  { label: 'Credentialing Status', style: { width: '12%' } },
  { label: 'Actions', style: { width: '26%' } },
];

const ProvidersOverview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const goToProfile = useCallback(() => {
    dispatch(push('/manageProviders/:id/profile'));
  }, [dispatch]);
  const goToEdit = useCallback(() => {
    dispatch(push('/manageProviders/0/edit'));
  }, [dispatch]);
  return (
    <AdminLayout
      subTitle="Manage providers"
      title="Providers overview"
      actionButtons={[
        <Button
          key={0}
          startIcon={
            <Icon className="fas fa-envelope-open-text" style={iconStyles} />
          }
          bgColor="#143a56"
          className={classes.topButton}
        >
          Invite provider
        </Button>,
        <Button
          key={1}
          startIcon={<AddIcon />}
          bgColor="#40ceba"
          className={classes.topButton}
        >
          Add new provider
        </Button>,
      ]}
    >
      <Grid
        container
        spacing={4}
        justifyContent="space-between"
        component={Box}
        pb={3}
      >
        <Grid item component={Box} flex={1}>
          <TotalStatistics
            iconBgColor="#c2d3df"
            icon={
              <Icon
                className="fas fa-id-card-alt"
                style={{ color: '#256290', width: 27 }}
              />
            }
            title="active providers"
            value={75}
          />
        </Grid>
        <Grid item component={Box} flex={1}>
          <TotalStatistics
            iconBgColor="#edeef0"
            icon={
              <Box style={{ position: 'absolute' }}>
                <Icon
                  className="fas fa-id-card-alt"
                  style={{
                    color: '#A6AEB7',
                    width: 30,
                    position: 'relative',
                    left: 16,
                    top: 2,
                  }}
                />
                <Icon
                  className="fas fa-slash"
                  style={{
                    color: '#A6AEB7',
                    width: 30,
                    position: 'relative',
                    right: 15,
                    top: 2,
                  }}
                />
              </Box>
            }
            title="inactive providers"
            value={13}
          />
        </Grid>
        <Grid item component={Box} flex={1}>
          <TotalStatistics
            iconBgColor="#fdf3e3"
            icon={
              <Icon
                className="fas fa-id-card-alt"
                style={{ color: '#f7bf78', width: 27 }}
              />
            }
            link={{
              title: 'View inquiries list',
              url: '/',
              styles: { position: 'absolute' },
            }}
            linkPosition="top right"
            title="new providers"
            value={16}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <TableHeader />
        <Grid item>
          <TableContainer className={classes.table}>
            <Table size="small" style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {columnNames.map((col) => {
                    return (
                      <TableCell
                        key={col.label}
                        style={col.style}
                        className={[
                          classes.thGrid,
                          col === 'Actions' ? classes.actionsCol : '',
                        ].join(' ')}
                      >
                        <Grid
                          component={Box}
                          display="flex"
                          container
                          alignItems="center"
                        >
                          <Tooltip title={col.label} arrow>
                            <Typography className={classes.th}>
                              {col.label}
                            </Typography>
                          </Tooltip>
                          <SvgIcon
                            component={Box}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {SortIcon}
                          </SvgIcon>
                        </Grid>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.td}
                    >
                      {row.fullName}
                    </TableCell>
                    <TableCell className={classes.td}>{row.contact}</TableCell>
                    <TableCell className={classes.td}>
                      {row.dateJoined}
                    </TableCell>
                    <TableCell className={classes.td} align="right">
                      {row.activeClients}
                    </TableCell>
                    <TableCell className={classes.td}>{row.active}</TableCell>
                    <TableCell className={classes.td}>{row.locked}</TableCell>
                    <TableCell className={classes.td}>
                      {row.credentialingStatus}
                    </TableCell>
                    <TableCell
                      className={[classes.td, classes.actionsCol].join(' ')}
                    >
                      <IconButtonsGroup>
                        <Button
                          bgColor="#EAF0F4"
                          style={{
                            fontSize: 12,
                            color: '#143A56',
                            padding: 10,
                            paddingTop: 6,
                            paddingBottom: 7,
                          }}
                        >
                          Credentialing
                        </Button>
                        <IconButton
                          onClick={goToProfile}
                          IconComponent={VisibilityIcon}
                        />
                        <IconButton
                          onClick={goToEdit}
                          IconComponent={EditIcon}
                        />
                        <IconButton
                          IconComponent={DeleteIcon}
                          iconProps={{
                            style: {
                              color: '#ed7271',
                            },
                          }}
                        />
                      </IconButtonsGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default ProvidersOverview;
