import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Box } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import palette from 'theme/palette';
import useStyles from './styles';

import { useGetBreakpoint } from './useGetBreakpoint';

const siblingCount = {
  xs: 0,
  sm: 1,
  md: 3,
  lg: 3,
  xl: 3,
};

const TOCPagination = (props) => {
  const { current, count, onChange } = props;
  const [page, setPage] = useState(current || 1);
  const styles = useStyles();
  const getBreakpoint = useGetBreakpoint();

  const handlePageChange = useCallback(
    (e, newPage) => {
      setPage(newPage);
      if (onChange) onChange(newPage);
    },
    [onChange]
  );

  useEffect(() => {
    if (count < page) {
      handlePageChange({}, 1);
    }
  }, [count, page, handlePageChange]);

  const itemVariant = (isSelected) => (isSelected ? 'text' : 'outlined');

  return (
    <Pagination
      count={count}
      showLastButton={current < count - 3 && getBreakpoint !== 'xs'}
      hidePrevButton={getBreakpoint !== 'xs'}
      page={page}
      size="large"
      shape="rounded"
      color="secondary"
      variant="outlined"
      siblingCount={siblingCount[getBreakpoint]}
      classes={{ ul: styles.pagination }}
      onChange={handlePageChange}
      renderItem={(item) =>
        item.type === 'last' ? (
          <Button
            classes={{ root: styles.paginationLastButton }}
            onClick={item.onClick}
          >
            <Typography variant="subtitle2">
              <Box fontWeight="700" component="span">
                Last &gt;
              </Box>
            </Typography>
          </Button>
        ) : (
          <PaginationItem
            {...item}
            variant={itemVariant(item.selected)}
            classes={{ root: styles.paginationItem }}
            style={{ color: item.selected ? palette.white : 'inherit' }}
          />
        )
      }
    />
  );
};

TOCPagination.propTypes = {
  current: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func,
};

export default TOCPagination;
