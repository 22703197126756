import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Banner from 'common/components/admin/Banner';
import Button from 'common/components/admin/Button';
import monthNames from 'common/constants/monthNames';
import DateInput from 'common/components/admin/DateInput';
import SelectInput from 'common/components/admin/SelectInput';
import ResetButton from '../ResetButton';
import useStyles from './styles';
import { billedData, sortOptionValues } from '../../insuranceData';

const paymentStatusColors = {
  paid: '#E2F3E9',
  denied: '#FADDDC',
  deductible: '#E2F3E9',
  accepted: '#FCF4E6',
};

const preparedData = (data) => {
  const prepared = {};
  for (let i = 0; i < data.length; i += 1) {
    const element = data[i];
    if (prepared[element.dateBilled]) {
      prepared[element.dateBilled] = [...prepared[element.dateBilled], element];
    } else {
      prepared[element.dateBilled] = [element];
    }
  }
  const convertedData = Object.entries(prepared);
  return convertedData;
};

const ListBilledClaims = ({ item }) => {
  const classes = useStyles();
  const date = new Date(item[0]);
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  return (
    <>
      <Grid
        container
        direction="row"
        component={Box}
        style={{ paddingTop: 15 }}
        key={date.toString}
      >
        <Grid xs={1} item>
          <Typography className={classes.dateString}>
            {month}&nbsp;{day}
          </Typography>
        </Grid>
        <Grid item xs={11} container direction="row">
          {item[1].map((subRow) => {
            const cost = `$ ${subRow.cost}`;
            return (
              <Grid
                container
                direction="row"
                component={Box}
                justifyContent="space-between"
                key={subRow.id}
                style={{ paddingBottom: 10 }}
              >
                <Grid item xs={3}>
                  <Typography className={classes.nameString}>
                    {subRow.fullName}
                  </Typography>
                  <Typography className={classes.payerString}>
                    {subRow.insurancePayer}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Banner
                    bgColor={paymentStatusColors[subRow.paymentStatus]}
                    title={subRow.paymentStatus.toUpperCase()}
                  />
                  <Typography className={classes.payerString}>
                    {subRow.paymentDescription}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.payerString}>
                    Days Submitted:
                  </Typography>
                  <Typography className={classes.countDays}>
                    {subRow.daysSubmitted}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.coastString}>
                    {cost}
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    bgColor="#EAF0F4"
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: '#143A56',
                      padding: 10,
                      paddingTop: 7,
                      paddingBottom: 6,
                    }}
                  >
                    View claim
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

const BilledClaims = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const list = preparedData(billedData);

  return (
    <>
      <Grid
        item
        container
        display="flex"
        flexDirection="row"
        justify="space-between"
        alignItems="center"
        component={Box}
        py={2}
        px={2}
      >
        <Grid
          item
          container
          component={Box}
          flex={1}
          className={classes.countLabel}
        >
          <Typography component="span">Showing</Typography>
          <Typography style={{ fontWeight: 700 }}>&nbsp;30&nbsp;</Typography>
          <Typography>billed claims</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          component={Box}
          xs={10}
          justifyContent="flex-end"
        >
          <Grid item component={Box}>
            <TextField
              placeholder="Search billed claims"
              variant="outlined"
              value={search}
              className={classes.textField}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: { input: classes.input },
              }}
            />
          </Grid>
          <Grid item component={Box}>
            <DateInput />
          </Grid>
          <Grid item component={Box}>
            <SelectInput
              placeholder="Sort by"
              valueOptions={sortOptionValues}
            />
          </Grid>
          <ResetButton />
        </Grid>
      </Grid>
      <Grid container direction="column" component={Box} py={2} px={2}>
        {list.map((item) => (
          <ListBilledClaims item={item} key={item[0]} />
        ))}
      </Grid>
    </>
  );
};

ListBilledClaims.propTypes = {
  item: PropTypes.arrayOf([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        cost: PropTypes.number,
        dateBilled: PropTypes.string,
        daysSubmitted: PropTypes.number,
        fullName: PropTypes.string,
        id: PropTypes.number,
        insurancePayer: PropTypes.string,
        paymentDescription: PropTypes.string,
        paymentStatus: PropTypes.string,
      })
    ),
  ]),
};

export default BilledClaims;
