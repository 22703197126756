const appointment = {
  date: '07.02.2020',
  client: 'Jonathan Wright',
  provider: 'Paula Brown',
  cpt: '90834',
  billed: 100,
  clientOwes: 0,
  totalInsurancePaid: 40,
  totalWriteOff: 0,
  insurancePaid: 50,
  writeOff: 10,
};

export const paidData = {
  id: 'b1',
  transactionDate: '06.10.2020',
  insurancePayer: 'Horizon Blue Cross Blue Shield of New Jersey (22099)',
  paymentMethod: 'Wire 0016926245',
  appointments: new Array(4).fill(0).map((_, id) => ({ ...appointment, id })),
  amount: 300,
  unallocated: 0,
};
