import { combineReducers } from 'redux';

import postsReducer, { getPosts } from './reducers/posts';
import topicTagsReducer, { getBlogTags } from './reducers/blogTags';
import latestArticleReducer, {
  getLatestArticle,
} from './reducers/latestArticle';

export { getPosts, getBlogTags, getLatestArticle };

export default combineReducers({
  posts: postsReducer,
  tags: topicTagsReducer,
  latestArticle: latestArticleReducer,
});
