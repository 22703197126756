import makeStyles from '@material-ui/styles/makeStyles';
import relax from 'assets/images/relax-min.png';

export default makeStyles(({ palette, spacing, breakpoints }) => {
  return {
    homeHeaderWrap: {
      maxWidth: `${breakpoints.values.xl}px`,
      margin: '0 auto',
      backgroundImage: `url(${relax})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right bottom',
      position: 'relative',

      [breakpoints.down('md')]: {
        backgroundPosition: 'right 20%',
        backgroundSize: '60% auto',
      },
      [breakpoints.down('xs')]: {
        backgroundSize: '50% auto',
      },
    },
    homeHeaderContentWrap: {
      padding: spacing(12.5, 0),

      [breakpoints.down('md')]: {
        padding: spacing(6, 0),
      },
      [breakpoints.down('xs')]: {
        padding: spacing(2, 0),
      },
    },
    homeHeaderTopWrap: {
      justifyContent: 'space-between',
    },
    logo: {
      width: 250,

      [breakpoints.down('sm')]: {
        width: 200,
      },
      [breakpoints.down('xs')]: {
        width: 160,
      },
    },
    logoSubtitle: {
      marginBottom: spacing(2.25),
      fontWeight: 700,

      [breakpoints.down('sm')]: {
        marginBottom: spacing(1.75),
      },
      [breakpoints.down('xs')]: {
        marginBottom: spacing(1),
      },
    },
    logoWrap: {
      color: palette.darkText,
    },
    burgerWrap: {
      alignSelf: 'flex-start',
    },
    headerSlogan: {
      padding: spacing(16, 0, 8.75),
      color: palette.primary.main,
      width: '50%',

      [breakpoints.down('sm')]: {
        padding: spacing(10, 0, 6),
      },
      [breakpoints.down('xs')]: {
        padding: spacing(8, 0, 8),
      },
    },
    headerSearch: {
      borderRadius: 30,
      background: palette.white,
      boxShadow: `0 6px 27px 0 ${palette.black}15`,
      padding: spacing(2.75),
      justifyContent: 'space-between',
      alignItems: 'center',

      [breakpoints.down('sm')]: {
        justifyContent: 'center',
        padding: spacing(2),
        borderRadius: 15,
      },
    },
    headerSearchDivider: {
      padding: spacing(0, 3),
      color: palette.semiBlack,
    },
    searchBlockWrapper: {
      alignItems: 'center',

      [breakpoints.down('md')]: {
        margin: '0 auto',
        justifyContent: 'center',
      },
    },
    orJoinBlockWrapper: {
      [breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    orWrapper: {
      [breakpoints.down('md')]: {
        display: 'block',
        marginTop: spacing(1),
        marginBottom: spacing(1),
      },
    },
    searchBtn: {
      [breakpoints.down('sm')]: {
        marginTop: spacing(3),
      },
    },
  };
});
