import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    marginTop: spacing(10),

    [breakpoints.down('md')]: {
      marginTop: spacing(5),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(2),
    },
  },
  contentContainer: {
    backgroundColor: palette.waveSecondaryColor,
  },
  contentWrapper: {
    color: palette.primary.main,
  },
  plansWrapper: {
    marginTop: spacing(2.5),
    marginBottom: spacing(9),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(5),
    },

    [breakpoints.down('xs')]: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
  },
  plansItem: {
    margin: '0 auto',
  },
  helloSectionMain: {
    marginTop: spacing(17.5),
    marginBottom: spacing(4),
    position: 'relative',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      marginTop: spacing(6),
      justifyContent: 'center',
      flexDirection: 'column',
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(4),
    },
  },
  helloSectionIntroVideoGrid: {
    position: 'relative',
  },
  helloSectionCircleWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,

    [breakpoints.down('sm')]: {
      width: 300,
      height: 300,
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  helloSectionIntroVideo: {
    width: 1200,
    height: 600,

    [breakpoints.down('md')]: {
      width: 800,
      height: 400,
    },
    [breakpoints.down('sm')]: {
      width: 600,
      height: 300,
    },
  },
  helloSectionIntroVideoWrapper: {
    marginBottom: spacing(5),
    position: 'relative', // safari bug
    width: 600,
    height: 600,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    boxShadow: `7px 10px 15px 6px ${palette.black}15`,

    [breakpoints.down('md')]: {
      width: 400,
      height: 400,
    },
    [breakpoints.down('sm')]: {
      width: 300,
      height: 300,
    },
  },
  subtitle: {
    marginBottom: spacing(1),

    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    marginBottom: spacing(4),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
      textAlign: 'center',
    },
  },
  text: {
    marginBottom: spacing(3.8),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
      textAlign: 'center',
    },
  },
  button: {
    marginTop: spacing(1.2),

    [breakpoints.down('sm')]: {
      margin: spacing(2, 'auto', 0),
    },
  },
}));
