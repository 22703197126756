import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Chip, Grid, Typography, Box } from '@material-ui/core';

import { counselorType } from 'common/constants/propTypes';
import useStyles from './styles';

const DescriptionSection = ({ counselor }) => {
  const styles = useStyles();

  return (
    <Grid container direction="column">
      <Typography variant="h4">Counselor Statement</Typography>

      <Typography
        variant="body1"
        component="div"
        className={styles.infoSectionDescription}
      >
        {ReactHtmlParser(counselor.description)}
      </Typography>

      {counselor.specialities.length ? (
        <Typography variant="h4">Specializes</Typography>
      ) : null}

      <Grid
        container
        alignItems="center"
        className={styles.infoSectionSpecializes}
      >
        {counselor.specialities.map(({ id, name }) => (
          <Chip
            key={id}
            label={<Typography variant="subtitle1">{name}</Typography>}
            className={styles.infoSectionSpecialityChip}
          />
        ))}
      </Grid>

      {counselor.certifications.length ? (
        <Typography variant="h4">Awards & Certifications</Typography>
      ) : null}

      <Grid className={styles.infoSectionCertifications}>
        {counselor.certifications.map((cert) => (
          <Grid
            key={cert.id}
            container
            direction="column"
            className={styles.infoSectionCertification}
          >
            <Grid item container justify="space-between" alignItems="center">
              <Typography variant="body1">{cert.name}</Typography>
              <Typography variant="subtitle2">
                <Box fontWeight="700" component="span">
                  {cert.type} {cert.year}
                </Box>
              </Typography>
            </Grid>

            <Typography variant="h6">{cert.description}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

DescriptionSection.propTypes = {
  counselor: counselorType,
};

export default DescriptionSection;
