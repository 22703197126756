import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonIcon from '@material-ui/icons/Person';
import Button from 'common/components/admin/Button';
import AdminLayout from 'common/components/admin/Layout';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import IconButton from 'common/components/admin/IconButton';
import TotalStatistics from 'common/components/admin/Statistics/TotalStatistics';
import SortIcon from 'assets/images/icons/sort';

import tableData from './tableData';
import TableHeader from './components/TableHeader';

const useStyles = makeStyles({
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  clientsCountLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: 10,
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: '0.9rem',
    color: 'black',
  },
  actionsCol: {
    width: 122,
  },
});

const iconStyles = {
  fontSize: 14,
};

const rows = tableData.map((item) => {
  const { id, fullName, assignedProvider, dateJoined, totalApts } = item;
  return {
    id,
    fullName,
    assignedProvider,
    contact: (
      <Grid
        component={Box}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <PhoneIcon style={iconStyles} />
        <EmailIcon style={iconStyles} />
        <PhoneIphoneIcon style={iconStyles} />
      </Grid>
    ),
    dateJoined,
    totalApts,
    active: <Switch />,
    locked: <Switch />,
  };
});

const columnNames = [
  { label: 'Client Name' },
  { label: 'Assigned Providers' },
  { label: 'Contact' },
  { label: 'Date Joined' },
  { label: 'Total Appts' },
  { label: 'Active' },
  { label: 'Locked' },
  { label: 'Actions', style: { width: '17%' } },
];

const ManageClients = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const goToProfile = useCallback(() => {
    dispatch(push('/manageClients/0'));
  }, [dispatch]);
  const goToEdit = useCallback(() => {
    dispatch(push('/manageClients/0/edit'));
  }, [dispatch]);
  return (
    <AdminLayout
      title="Manage Clients"
      actionButtons={[
        <Button
          key={1}
          startIcon={<AddIcon />}
          bgColor="#40ceba"
          style={{ color: '#fff' }}
        >
          Add new item
        </Button>,
      ]}
    >
      <Grid
        container
        spacing={4}
        justifyContent="space-between"
        component={Box}
        pb={3}
      >
        <Grid item component={Box} flex={1}>
          <TotalStatistics
            iconBgColor="#d1f3ee"
            icon={<PeopleIcon style={{ color: '#40ceba' }} />}
            title="Total Clients"
            value={450}
          />
        </Grid>
        <Grid item component={Box} flex={1}>
          <TotalStatistics
            iconBgColor="#eeeff1"
            icon={<PersonAddDisabledIcon style={{ color: '#a5afb8' }} />}
            title="Total Clients"
            value={450}
          />
        </Grid>
        <Grid item component={Box} flex={1}>
          <TotalStatistics
            iconBgColor="#fdf3e3"
            icon={<PersonIcon style={{ color: '#f7bf78' }} />}
            title="Total Clients"
            value={450}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <TableHeader />
        <Grid item>
          <TableContainer className={classes.table}>
            <Table size="small" style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {columnNames.map((col) => {
                    return (
                      <TableCell
                        key={col.label}
                        style={col.style}
                        className={[
                          classes.thGrid,
                          col === 'Actions' ? classes.actionsCol : '',
                        ].join(' ')}
                      >
                        <Grid
                          component={Box}
                          display="flex"
                          container
                          alignItems="center"
                        >
                          <Tooltip title={col.label} arrow>
                            <Typography className={classes.th}>
                              {col.label}
                            </Typography>
                          </Tooltip>
                          <SvgIcon
                            component={Box}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {SortIcon}
                          </SvgIcon>
                        </Grid>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.td}
                    >
                      {row.fullName}
                    </TableCell>
                    <TableCell className={classes.td}>
                      {row.assignedProvider}
                    </TableCell>
                    <TableCell className={classes.td}>{row.contact}</TableCell>
                    <TableCell className={classes.td}>
                      {row.dateJoined}
                    </TableCell>
                    <TableCell className={classes.td}>
                      {row.totalApts}
                    </TableCell>
                    <TableCell className={classes.td}>{row.active}</TableCell>
                    <TableCell className={classes.td}>{row.locked}</TableCell>
                    <TableCell
                      className={[classes.td, classes.actionsCol].join(' ')}
                    >
                      <IconButtonsGroup>
                        <IconButton
                          onClick={goToProfile}
                          IconComponent={VisibilityIcon}
                        />
                        <IconButton
                          onClick={goToEdit}
                          IconComponent={EditIcon}
                        />
                        <IconButton
                          IconComponent={DeleteIcon}
                          iconProps={{
                            style: {
                              color: '#ed7271',
                            },
                          }}
                        />
                      </IconButtonsGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default ManageClients;
