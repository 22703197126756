import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, breakpoints, spacing }) => ({
  sizeMedium: {
    background: ({ bg, light }) =>
      palette[bg]?.[light ? 'lighter' : 'main'] || palette[bg],
    width: 65,
    height: 65,
    padding: spacing(1),
    '&:hover': {
      background: ({ bg, light }) =>
        palette[bg]?.[light ? 'light' : 'lighter'] || palette[bg],
    },
    [breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
    [breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  sizeSmall: {
    background: ({ bg, light }) =>
      palette[bg]?.[light ? 'lighter' : 'main'] || palette[bg],
    width: 55,
    height: 55,
    padding: spacing(0.375),
    '&:hover': {
      background: ({ bg, light }) =>
        palette[bg]?.[light ? 'light' : 'lighter'] || palette[bg],
    },
    [breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
    [breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  MuiIconStyle: {
    color: ({ color }) => palette[color]?.main || palette[color],
  },
  fontAwesomeIconStyle: {
    color: ({ color }) => palette[color]?.main || palette[color],
  },
}));
