import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  resetButton: {
    textTransform: 'capitalize',
    backgroundColor: '#093b54',
    color: '#ffff',
    padding: 7,
    fontSize: 14,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#093b54',
      opacity: 0.6,
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: 14,
    color: '#143A56',
  },
});
