import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  item: {
    margin: '10px 0px',
  },
  subMenuCollapsed: {
    // height: '1em',
  },
  itemIcon: {
    minWidth: 30,
  },
  title: {
    color: '#295168',
    fontWeight: 500,
    fontSize: '1rem',
  },
  hidden: {
    display: 'none',
  },
  active: {
    color: '#40ceba',
  },
}));
