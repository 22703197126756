import React from 'react';
import AssessmentIcon from '@material-ui/icons/AssignmentTurnedIn';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Icon from '@material-ui/core/Icon';
import ReportIcon from '@material-ui/icons/Description';
import routes from 'common/constants/routes';

export default [
  {
    key: 0,
    title: routes.admin.dashboard.title,
    icon: <HomeIcon fontSize="small" />,
    url: routes.admin.dashboard.url,
  },
  {
    key: 1,
    title: routes.admin.manageClients.title,
    icon: <PeopleIcon fontSize="small" />,
    url: routes.admin.manageClients.url,
    otherUrls: [
      routes.admin.manageClients.url,
      routes.admin.clientProfile.url,
      routes.admin.editClient.url,
    ],
  },
  {
    key: 2,
    title: 'Manage Providers',
    icon: (
      <Icon
        className="fas fa-id-card-alt"
        style={{ height: 18, width: 22, fontSize: 16, alignSelf: 'center' }}
      />
    ),
    nested: [
      {
        key: 0,
        title: routes.admin.manageProviders.overview.title,
        url: routes.admin.manageProviders.overview.url,
      },
    ],
  },
  {
    key: 3,
    title: routes.admin.appointments.title,
    icon: <PeopleIcon fontSize="small" />,
    url: routes.admin.appointments.url,
  },
  {
    key: 4,
    title: routes.admin.billing.title,
    icon: <ReceiptIcon fontSize="small" />,
    nested: [
      {
        key: 1,
        title: routes.admin.billing.insuranceTransactions.title,
        url: routes.admin.billing.insuranceTransactions.url,
      },
      {
        key: 2,
        title: routes.admin.billing.creditCardPayments.title,
        url: routes.admin.billing.creditCardPayments.url,
      },
    ],
  },
  {
    key: 5,
    title: routes.admin.assessments.title,
    icon: <AssessmentIcon fontSize="small" />,
    url: routes.admin.assessments.url,
    otherUrls: [routes.admin.assessments.manageAssessments.url],
    nested: [
      {
        key: 0,
        title: routes.admin.assessments.manageAssessments.title,
        url: routes.admin.assessments.manageAssessments.url,
      },
      {
        key: 1,
        title: routes.admin.assessments.assignedAssessments.title,
        url: routes.admin.assessments.assignedAssessments.url,
      },
    ],
  },
  {
    key: 6,
    title: routes.admin.reports.title,
    icon: <ReportIcon fontSize="small" />,
    url: routes.admin.reports.url,
  },
];
