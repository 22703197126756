import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconButton, Icon as FAIcon } from '@material-ui/core';

import { openLink } from 'common/utils';
import useStyles from './styles';

const TOCIconButton = (props) => {
  const {
    light = false,
    bgColor,
    iconName,
    color,
    size = 'medium',
    iconSize = 'large',
    icon: Icon,
    to,
    onClick = () => {},
  } = props;
  const styles = useStyles({ light, color, bg: bgColor });

  const handleClick = (ev) => {
    if (!to) {
      onClick();
      return;
    }

    if (to.includes('http')) {
      ev.preventDefault();
      openLink(to);
    }
  };

  return (
    <IconButton
      size={size}
      to={{ pathname: to }}
      component={to ? Link : FAIcon}
      onClick={handleClick}
      classes={{
        root: styles.sizeMedium,
        sizeSmall: styles.sizeSmall,
      }}
    >
      {Icon ? (
        <Icon fontSize={iconSize} classes={{ root: styles.MuiIconStyle }} />
      ) : (
        <FAIcon
          fontSize={iconSize}
          className={`fab fa-${iconName}`}
          classes={{ root: styles.fontAwesomeIconStyle }}
        />
      )}
    </IconButton>
  );
};

TOCIconButton.propTypes = {
  light: PropTypes.bool,
  bgColor: PropTypes.string,
  iconName: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  iconSize: PropTypes.oneOf(['small', 'large', 'default']),
  icon: PropTypes.elementType,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default TOCIconButton;
