import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FormContainer = ({ children }) => {
  return (
    <Grid container direction="column">
      <Grid item>{children}</Grid>

      <Grid component={Box} pt={3}>
        <Divider />
      </Grid>

      <Grid item container alignItems="center" component={Box} pt={2} pb={1}>
        <Grid item>
          <Typography
            component="span"
            style={{
              fontSize: '1rem',
              color: 'red',
              position: 'relative',
              top: '0.3rem',
              paddingRight: 5,
            }}
          >
            *
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            component="span"
            style={{ fontSize: '0.7rem', color: 'grey' }}
          >
            Indicates required field
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node,
};

export default FormContainer;
