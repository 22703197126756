import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '85%',
      backgroundColor: '#57CEBA',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    color: '#A6AEB7',
    fontWeight: '600',
    margin: theme.spacing(2),
    '&$selected': {
      color: '#143A56',
    },
    '&:focus': {
      color: '#143A56',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
