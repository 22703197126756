import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { home } from '../index';

function* getPostsSagaRequest() {
  yield requestSaga({
    actionType: home.posts,
    request: dataProvider.getList,
    resource: resources.articles,
  });
}

export default getPostsSagaRequest;
