import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    margin: spacing(17, 0, 12),

    [breakpoints.down('md')]: {
      margin: spacing(10, 0, 6),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(7, 0, 0),
    },
  },
  wrapper: {
    maxWidth: 900,
    margin: '0 auto',
    color: palette.primary.main,
  },
  title: {
    marginBottom: spacing(12.5),

    [breakpoints.down('md')]: {
      marginBottom: spacing(8),
    },
    [breakpoints.down('sm')]: {
      marginBottom: spacing(4),
    },
  },
  ifNotEnoughSection: {
    margin: spacing(11.25, 0, 5.625),
    textAlign: 'center',

    [breakpoints.down('md')]: {
      margin: spacing(8, 0, 4),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(4, 0, 4),
    },
  },
  buttonSection: {
    maxWidth: 500,
    width: '100%',
    justifyContent: 'space-between',

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiButton-root': {
        marginBottom: spacing(3),
      },
    },
  },
}));
