import { actionTypesGenerator } from 'common/utils';

export const menu = actionTypesGenerator(['open', 'close'], 'menu');

export const initialState = {
  opened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case menu.open:
      return { opened: true };

    case menu.close:
      return { opened: false };

    default:
      return state;
  }
};

export const openSideMenu = () => ({
  type: menu.open,
});

export const closeSideMenu = () => ({
  type: menu.close,
});
