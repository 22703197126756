import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  title: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  active: {
    color: '#47d0bb',
  },
  selectRoot: {
    position: 'relative',
    top: '1px',
    marginTop: 1,
    marginRight: 4,
  },
  select: {
    fontSize: '0.7rem',
    color: 'black',
    padding: '4px 5px',
  },
}));
