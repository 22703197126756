import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    backgroundColor: palette.waveSecondaryColor,
  },
  wrapper: {
    color: palette.primary.main,
    marginBottom: spacing(6),
  },
  blogSectionHeader: {
    marginBottom: spacing(9.25),
    justifyContent: 'space-between',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: spacing(2),
    },
  },
  blogSectionTitle: {
    margin: spacing(1.5, 2, 1.5, 0),

    [breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: 'center',
      margin: spacing(1.5, 0, 3),
    },
  },

  blogSectionButton: {
    margin: spacing(1.5, 0),

    [breakpoints.down('sm')]: {
      margin: spacing(1.5, 0, 4),
    },
  },
  // Grid container's spacing is out of border Container's paddings (16px) on xs screen
  // add appropriate padding to post grid
  postsGrid: {
    width: 'calc(100% + 40px)',
    margin: '-20px',

    [breakpoints.down('xs')]: {
      width: 'calc(100% + 32px)',
      margin: '-16px',
    },
  },
  postGrid: {
    padding: 20,

    [breakpoints.down('xs')]: {
      padding: 16,
    },
  },
}));
