import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import IconButton from 'common/components/admin/IconButton';
import SortIcon from 'assets/images/icons/sort';
import SvgIcon from '@material-ui/core/SvgIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import VisibilityIcon from '@material-ui/icons/Visibility';

import AdminLayout from 'common/components/admin/Layout';
import Button from 'common/components/admin/Button';

import monthNames from 'common/constants/monthNames';
import PaidSection from './components/PaidSection';
import useStyles from './styles';
import { paidData } from './transactionsData';

const viewDateSlash = (dateValue) => {
  const renderDate = new Date(dateValue);
  return `${renderDate.getMonth()}/${renderDate.getDate()}/${renderDate.getFullYear()}`;
};
const viewDateParsed = (dateValue) => {
  const tempDate = new Date(dateValue);
  return `${
    monthNames[tempDate.getMonth()]
  } ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
};

const columnNames = [
  { label: 'Date', isSorted: true, style: { width: '10%' } },
  { label: 'Client', isSorted: true, style: { width: '12%' } },
  { label: 'Provider', isSorted: true, style: { width: '15%' } },
  { label: 'CPT', isSorted: true, style: { width: '10%' } },
  { label: 'Billed', isSorted: true, style: { width: '8%' } },
  { label: 'Client owes', style: { width: '10%' } },
  { label: 'Insurance paid', style: { width: '15%' } },
  { label: 'Write-off', style: { width: '10%' } },
  { label: 'Actions', style: { width: '10%' } },
];

const preparedRows = ({ appointments, insurancePayer }) => {
  return appointments.map((item) => ({
    ...item,
    date: viewDateParsed(item.date),
    details: {
      paymentDate: viewDateSlash(item.date),
      insurancePayer,
      insurancePaid: item.insurancePaid,
      writeOff: item.writeOff,
    },
  }));
};

const Row = ({ row }) => {
  const {
    id,
    date,
    client,
    provider,
    cpt,
    billed,
    clientOwes,
    totalInsurancePaid,
    totalWriteOff,
    details,
  } = row;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handlePress = () => setOpen(!open);
  return (
    <>
      <TableRow key={id + date}>
        <TableCell padding="checkbox">
          <Typography className={classes.td}>{date}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.boldFont}>{client}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.boldFont}>{provider}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{cpt}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>${billed}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>${clientOwes}</Typography>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.selected}>
          <Typography className={classes.td}>${totalInsurancePaid}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>${totalWriteOff}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton
            onClick={handlePress}
            IconComponent={VisibilityIcon}
            iconProps={{
              style: {
                color: '#143A56',
              },
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow key={id}>
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" className={classes.subTable}>
              <TableBody>
                <TableRow>
                  <TableCell scope="row" style={{ width: '10%' }}>
                    <IconButton
                      onClick={handlePress}
                      IconComponent={SubdirectoryArrowRightIcon}
                      style={{ backgroundColor: '#fff' }}
                      iconProps={{
                        style: {
                          color: '#A6AEB7',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.boldFont}
                    align="left"
                    scope="row"
                    style={{ width: '55%' }}
                  >
                    Payment on {details.paymentDate} from{' '}
                    {details.insurancePayer}
                  </TableCell>
                  <TableCell
                    className={classes.selected}
                    style={{ width: '15%' }}
                    align="left"
                  >
                    <Typography className={classes.boldFont}>
                      ${details.insurancePaid}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={classes.boldFont}>
                      ${details.writeOff}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TransactionsDetails = () => {
  const classes = useStyles();
  const rows = preparedRows(paidData);

  const actionButtons = [
    <Button
      key={1}
      startIcon={<DeleteIcon />}
      bgColor="#E55852"
      className={classes.topButton}
    >
      Delete payment
    </Button>,
    <Button
      key={2}
      startIcon={<EditIcon />}
      bgColor="#143A55"
      className={classes.topButton}
    >
      Edit
    </Button>,
  ];
  return (
    <AdminLayout
      subTitle="Billing < Insurance Transactions"
      title="Paid claim details"
      actionButtons={actionButtons}
    >
      <PaidSection paidData={paidData} />
      <Grid container component={Box} py={4}>
        <Typography className={classes.tableTitle}>
          Appointments included in insurance payment:
        </Typography>
      </Grid>
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <TableContainer className={classes.table}>
          <Table size="small" style={{ tableLayout: 'fixed' }}>
            <TableHead className={classes.selected}>
              <TableRow>
                {columnNames.map((col) => {
                  return (
                    <TableCell
                      key={col.label}
                      style={col.style}
                      className={classes.thGrid}
                    >
                      <Grid
                        component={Box}
                        display="flex"
                        container
                        alignItems="center"
                      >
                        <Tooltip title={col.label} arrow>
                          <Typography className={classes.th}>
                            {col.label}
                          </Typography>
                        </Tooltip>
                        {col.isSorted && (
                          <SvgIcon
                            component={Box}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {SortIcon}
                          </SvgIcon>
                        )}
                      </Grid>
                    </TableCell>
                  );
                })}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        component={Box}
        py={4}
        style={{ justifyContent: 'flex-end' }}
      >
        <Button
          startIcon={<DeleteIcon />}
          bgColor="#E55852"
          className={classes.topButton}
        >
          Delete payment
        </Button>
        <Box px={1} />
        <Button
          startIcon={<EditIcon />}
          bgColor="#143A55"
          className={classes.topButton}
        >
          Edit
        </Button>
      </Grid>
    </AdminLayout>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    client: PropTypes.string,
    provider: PropTypes.string,
    cpt: PropTypes.string,
    billed: PropTypes.number,
    clientOwes: PropTypes.number,
    totalInsurancePaid: PropTypes.number,
    totalWriteOff: PropTypes.number,
    details: PropTypes.shape({
      paymentDate: PropTypes.string,
      insurancePayer: PropTypes.string,
      insurancePaid: PropTypes.number,
      writeOff: PropTypes.number,
    }),
  }),
};

export default TransactionsDetails;
