import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import IconButton from 'common/components/admin/IconButton';
import FormInput from 'common/components/admin/formControls/FormInput';
import RadioGroup from 'common/components/admin/formControls/RadioGroup';
import Checkbox from 'common/components/admin/formControls/Checkbox';
import DateInput from 'common/components/admin/formControls/DateInput';
import FormContainer from 'common/components/admin/formControls/FormContainer';

import CreditCards from './CreditCards';
import FileInput from '../FileInput';
import MultiInput from '../MultiInput';
import useStyles from './styles';

const controlWidth = 220;

const BillingClient = () => {
  const classes = useStyles();
  return (
    <FormContainer>
      <Typography className={classes.sectionTitle}>Billing type</Typography>
      <RadioGroup
        options={[
          { value: 'selfPay', label: 'Self Pay' },
          { value: 'insurance', label: 'Insurance' },
        ]}
      />
      <Divider className={classes.divider} />
      <Typography className={classes.sectionTitle}>
        Client & Debet cards
      </Typography>
      <Checkbox label="Autoplay enrolled" />
      <CreditCards />
      <Divider className={classes.divider} />
      <Typography className={classes.sectionTitle}>
        Insurance Information
      </Typography>
      <RadioGroup
        label="Insurance type"
        options={[
          { value: 'primary', label: 'Primary' },
          { value: 'secondary', label: 'Secondary' },
          { value: 'other', label: 'Other' },
        ]}
      />
      <Box py={1}>
        <FormInput label="Insurance provider" />
      </Box>

      <Grid container component={Box} py={1}>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Member ID" required />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Plan ID" />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Group ID" />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Copay/Consuriance" placeholder="$0" />
        </Grid>
      </Grid>
      <Box py={1}>
        <FormInput label="Deductible" placeholder="$0" />
      </Box>
      <Box py={1}>
        <RadioGroup
          label="Primary insured"
          options={[
            { value: 'client', label: 'Client' },
            { value: 'clientSpouse', label: 'Client Spouse' },
            { value: 'clientParent', label: 'Client Parent' },
          ]}
        />
      </Box>

      <Grid
        container
        direction="column"
        component={Box}
        p={2}
        style={{
          backgroundColor: '#f0f4f7',
          borderRadius: 10,
        }}
      >
        <Grid item>
          <Typography
            className={classes.subtitle}
            style={{
              color: '#afb8c1',
              paddingBottom: 8,
            }}
          >
            Enter primary insured&apos;s details below
          </Typography>
        </Grid>
        <Grid item container component={Box} py={1}>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured first name" required />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured middle name" />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured last name" required />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput
              label="Insured sex"
              options={[
                { value: 'male', label: 'Male' },
                { value: 'female', label: 'Female' },
              ]}
              required
            />
          </Grid>
        </Grid>

        <Grid item container component={Box} py={1}>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <DateInput
              label="Insured date of birth"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured phone" required />
          </Grid>
        </Grid>

        <Grid item container component={Box} py={1}>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured street" required />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured city" required />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured state" required />
          </Grid>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Insured zip" required />
          </Grid>
        </Grid>
      </Grid>
      <Box pt={1}>
        <Typography className={classes.subtitle}>
          Insurance card on free
        </Typography>
      </Box>
      <Grid container justify="space-between">
        <Grid item component={Box} width="48%">
          <FileInput title="Front of insurnce card" style={{ width: '100%' }} />
        </Grid>
        <Grid item component={Box} width="48%">
          <FileInput title="Back of insurance card" style={{ width: '100%' }} />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Typography className={classes.sectionTitle}>
        Client Default Services
      </Typography>
      <Grid container justify="space-between">
        <Grid item container component={Box} flex={1} width="48%" py={1}>
          <RadioGroup
            direction="column"
            interval={0.5}
            options={[
              { value: '98037', label: '98037 Psychotheraphy, 50 min' },
              { value: '98038', label: '98037 Psychotheraphy, 50 min' },
            ]}
          />
        </Grid>
        <Grid item component={Box} flex={1} width="48%">
          <Grid item container>
            <Grid item>
              <FormInput
                inputClassName={classes.rateInput}
                label="Rate per units"
                placeholder="$0"
                style={{ width: '7.1rem' }}
              />
            </Grid>
            <Grid item>
              <MultiInput label="Modifiers" />
            </Grid>
            <Grid item component={Box} py={2} pl={1}>
              <IconButtonsGroup>
                <IconButton
                  IconComponent={DeleteIcon}
                  iconProps={{
                    style: {
                      color: '#ed7271',
                    },
                  }}
                />
              </IconButtonsGroup>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item component={Box} pt={0.6}>
              <FormInput
                inputClassName={classes.rateInput}
                placeholder="$0"
                style={{ width: '7.1rem' }}
              />
            </Grid>
            <Grid item>
              <MultiInput />
            </Grid>
            <Grid item component={Box} py={0.5} pl={1}>
              <IconButtonsGroup>
                <IconButton
                  IconComponent={DeleteIcon}
                  iconProps={{
                    style: {
                      color: '#ed7271',
                    },
                  }}
                />
              </IconButtonsGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box py={1}>
        <FormInput label="Add new service" placeholder="Select service" />
      </Box>
      <Divider className={classes.divider} />
      <Typography className={classes.sectionTitle}>
        Insurance Authorization
      </Typography>
      <Grid item container component={Box} py={1}>
        <Grid item component={Box} pr={2} width={238}>
          <FormInput label="Prior Authorization Number" required />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Valid from" required />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Number of users" required />
        </Grid>
        <Grid item component={Box} pt={2.5}>
          <IconButtonsGroup>
            <IconButton
              IconComponent={AddIcon}
              style={{ backgroundColor: 'black' }}
              iconProps={{
                style: {
                  color: '#ffff',
                },
              }}
            />
            <IconButton
              IconComponent={DeleteIcon}
              iconProps={{
                style: {
                  color: '#ed7271',
                },
              }}
            />
          </IconButtonsGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Checkbox label="Remind me a month before expiring" />
        </Grid>
        <Grid item>
          <Checkbox label="Active" />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default BillingClient;
