import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Image from 'assets/images/client-image.jpg';

import useStyles from './styles';

const dataList = Array(50)
  .fill(0)
  .map((_, id) => ({
    id,
    fullName: 'Dr Firstname Lastname',
    image: Image,
  }));

const ClientsDropdown = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Grid
        container
        component={Box}
        style={{ cursor: 'pointer' }}
        border="1px solid #e0e2e5"
        borderRadius={10}
        justify="space-between"
        alignItems="center"
        py={1}
        px={1.5}
        onClick={handleClick}
      >
        <Grid item container component={Box} alignItems="center" flex={1}>
          <Grid
            item
            component={Box}
            width={30}
            height={30}
            className={classes.avatar}
            style={{ backgroundImage: `url("${Image}")` }}
          />
          <Grid item component={Box} alignItems="center" flex={1} pl={1}>
            <Typography style={{ fontSize: 14 }}>
              Dr Firstname Lastname
            </Typography>
          </Grid>
        </Grid>
        <Grid item>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ width: '100%' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container direction="column" component={Box} py={2} px={1}>
          <Grid item>
            <TextField
              className={classes.searchInputRoot}
              style={{ fontSize: 14, borderRadius: 10 }}
              placeholder="Search"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: { input: classes.searchInput },
              }}
            />
          </Grid>
          <Grid item component={Box} maxHeight={200} py={1} overflow="auto">
            {dataList.map(({ id, fullName, image }) => (
              <Grid key={id} container my={1} component={MenuItem}>
                <Grid
                  item
                  component={Box}
                  width={25}
                  height={25}
                  className={classes.avatar}
                  style={{ backgroundImage: `url("${image}")` }}
                />
                <Grid
                  item
                  component={Typography}
                  style={{ marginLeft: 10, fontSize: 14, fontWeight: 500 }}
                >
                  {fullName}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
};

export default ClientsDropdown;
