import { combineReducers } from 'redux';

import optionsReducer, { fetchOptions } from './reducers/filterOptions';
import searchReducer, { searchCounselors } from './reducers/searchResult';
import filtersReducer, {
  saveFilters,
  resetFilters,
} from './reducers/localFilters';

export { fetchOptions, searchCounselors, saveFilters, resetFilters };

export default combineReducers({
  options: optionsReducer,
  result: searchReducer,
  filters: filtersReducer,
});
