import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  reportsContainer: {
    marginTop: '0.9rem',
    padding: '1.8rem',
    maxWidth: '27%',
    borderRadius: 15,
    backgroundColor: '#fff',
  },
  reportCard: {
    border: '2px solid #E9EDF2',
    borderRadius: 15,
    padding: '1.8rem',
    marginTop: 15,
  },
  columnTitle: {
    fontSize: '1.1rem',
    fontWeight: 800,
    letterSpacing: -0.4,
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 600,
    letterSpacing: -0.4,
  },
  subTitle: {
    color: '#A6AEB7',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: -0.35,
  },
});
