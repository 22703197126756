const statusTitle = [
  { title: 'to do', color: '#E4F0F8' },
  { title: 'completed', color: '#E2F3E9' },
  { title: 'in progress', color: '#FCF4E6' },
];

export const clientsValues = [
  {
    value: 'client1',
    label: 'Client 1',
  },
  {
    value: 'client2',
    label: 'Client 2',
  },
];

export const statusValues = statusTitle.map(({ title }, index) => ({
  value: index,
  label: title,
}));

export const assignedAssessmentsData = new Array(20).fill(0).map((_, id) => ({
  id,
  assessmentName: 'Mental & Emotional Health Assessment',
  client: 'Jonathan Wright',
  status: statusTitle[id % 3],
  assignedDate: '06.21.2020',
}));
