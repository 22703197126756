import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import routes from 'common/constants/routes';
import { TOCLink } from 'common/components/simple';
import { Header, Footer } from 'common/components';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  IntroSection,
  AppointmentSection,
  DescriptionSection,
} from './components';
import { getCounselor } from './ducks';
import useStyles from './styles';

const Profile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const styles = useStyles();

  useEffect(() => {
    dispatch(getCounselor(id));
  }, [dispatch, id]);

  const { counselor, loading } = useSelector((store) => store.profile);

  return (
    <>
      <Header height={{ xs: '150px', md: '250px' }} />

      {loading || !counselor ? (
        <Box
          mt={50}
          minHeight={300}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : null}

      <Container>
        <Grid container direction="column" className={styles.profileContainer}>
          <Grid
            container
            justify="flex-end"
            className={styles.backLinkContainer}
          >
            <TOCLink to={routes.search.url}>
              <Icon className="fas fa-angle-left" fontSize="inherit" />
              <Typography variant="subtitle2">
                <Box fontWeight="700" component="span">
                  Back to search results
                </Box>
              </Typography>
            </TOCLink>
          </Grid>

          <Grid container justify="space-between">
            <Grid item className={styles.bookingSection}>
              {counselor ? <AppointmentSection counselor={counselor} /> : null}
            </Grid>

            <Grid
              item
              container
              direction="column"
              className={styles.profileSection}
            >
              {counselor ? <IntroSection counselor={counselor} /> : null}
              {counselor ? <DescriptionSection counselor={counselor} /> : null}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default Profile;
