import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { article } from '../index';

function* getArticleSagaRequest() {
  yield requestSaga({
    actionType: article.fetch,
    request: dataProvider.getOne,
    resource: resources.articles,
  });
}

export default getArticleSagaRequest;
