import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import TOCButton from 'common/components/simple/TOCButton';
import { sendMessage } from '../../ducks/index';
import useStyles from './styles';

const ContactForm = () => {
  const dispatch = useDispatch();
  const prevLoading = useRef(false);
  const { register, errors, handleSubmit, reset } = useForm();
  const [captcha, updateCaptcha] = useState(null);
  const [isMessageShown, toggleMessage] = useState(false);
  const styles = useStyles();

  const onChange = (value) => {
    updateCaptcha(value);
  };

  const onSubmit = (value) => dispatch(sendMessage(value));

  const { loading } = useSelector((store) => store.contact);

  useEffect(() => {
    if (prevLoading.current !== loading && !loading) {
      toggleMessage(true);
      reset();
      prevLoading.current = loading;
      return;
    }

    prevLoading.current = loading;
  }, [loading, reset]);

  return (
    <Container className={styles.container}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Box className={styles.textWrapper}>
            <Typography variant="body2">
              <Box fontWeight="700" component="span">
                Toll free contact number
              </Box>
            </Typography>
            <Typography variant="body1">856-889-8434</Typography>
            <Box mt={5}>
              <Typography variant="body2">
                <Box fontWeight="700" component="span">
                  For insurance questions:
                </Box>
              </Typography>
              <a
                href="mailto:insurances@textandonlinecounseling.com"
                className={styles.emailLink}
              >
                <Typography variant="body1">
                  <Box color="secondary.main" component="span">
                    insurances@text&shy;and&shy;online&shy;counseling.com
                  </Box>
                </Typography>
              </a>
            </Box>

            <Box mt={5}>
              <Typography variant="body2">
                <Box fontWeight="700" component="span">
                  For account support:
                </Box>
              </Typography>
              <a
                href="mailto:support@textandonlinecounseling.com"
                className={styles.emailLink}
              >
                <Typography variant="body1">
                  <Box color="secondary.main" component="span">
                    support@text&shy;and&shy;online&shy;counseling.com
                  </Box>
                </Typography>
              </a>
            </Box>

            <Box mt={6}>
              <Typography variant="body2">
                <Box fontWeight="700" component="span">
                  For general enquiries:
                </Box>
              </Typography>
              <a
                href="mailto:info@textandonlinecounseling.com"
                className={styles.emailLink}
              >
                <Typography variant="body1">
                  <Box color="secondary.main" component="span">
                    info@text&shy;and&shy;online&shy;counseling.com
                  </Box>
                </Typography>
              </a>

              <Typography variant="body1">
                or you can use the contact form to get in touch.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column">
              <Grid container justify="space-between">
                <Box className={styles.inputWrapper}>
                  <Typography variant="subtitle2">
                    <Box fontWeight="700" component="span">
                      First name
                    </Box>
                  </Typography>

                  <input
                    name="firstName"
                    ref={register({ required: true })}
                    className={styles.textField}
                  />

                  <Typography variant="subtitle2">
                    <Box mt={0.5} color="alert.warning">
                      {errors.firstName && 'This field is required'}
                    </Box>
                  </Typography>
                </Box>

                <Box className={styles.inputWrapper}>
                  <Typography variant="subtitle2">
                    <Box fontWeight="700" component="span">
                      Last name
                    </Box>
                  </Typography>

                  <input
                    name="lastName"
                    ref={register({ required: true })}
                    className={styles.textField}
                  />

                  <Typography variant="subtitle2">
                    <Box mt={0.5} color="alert.warning">
                      {errors.lastName && 'This field is required'}
                    </Box>
                  </Typography>
                </Box>
              </Grid>

              <Grid container justify="space-between">
                <Box mt={3} className={styles.inputWrapper}>
                  <Typography variant="subtitle2">
                    <Box fontWeight="700" component="span">
                      Email address
                    </Box>
                  </Typography>

                  <input
                    name="eMail"
                    type="email"
                    ref={register({
                      required: true,
                      pattern: /^\S+@\S+\.[\S]{2,}$/,
                    })}
                    className={styles.textField}
                  />

                  <Typography variant="subtitle2">
                    <Box mt={0.5} color="alert.warning">
                      {errors.eMail?.type === 'required' &&
                        'This field is required'}

                      {errors.eMail?.type === 'pattern' &&
                        'Email address is not correct'}
                    </Box>
                  </Typography>
                </Box>

                <Box className={styles.inputWrapper} mt={3}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2">
                      <Box fontWeight="700" component="span">
                        Phone number
                      </Box>
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary">
                      <Box component="span" fontSize="0.75rem">
                        Optional
                      </Box>
                    </Typography>
                  </Box>

                  <input
                    name="phone"
                    ref={register({ pattern: /^[0-9-]{5,12}$/ })}
                    className={styles.textField}
                  />

                  <Typography variant="subtitle2">
                    <Box mt={0.5} color="alert.warning">
                      {errors.phone?.type === 'pattern' &&
                        'Phone is not correct'}
                    </Box>
                  </Typography>
                </Box>

                <Box mt={3} width="100%">
                  <Typography variant="subtitle2">
                    <Box fontWeight="700" component="span">
                      Your message or query
                    </Box>
                  </Typography>

                  <textarea
                    rows={3}
                    name="message"
                    className={styles.textArea}
                    ref={register({ required: true })}
                  />

                  <Typography variant="subtitle2">
                    <Box mt={0.5} color="alert.warning">
                      {errors.message && 'This field is required'}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={4}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                onChange={onChange}
              />
            </Box>

            <Box mt={4}>
              <TOCButton
                width={228}
                height={79}
                size="large"
                color="secondary"
                type="submit"
                disabled={!captcha || loading}
              >
                <Box fontWeight="700" component="span">
                  Send your message
                </Box>
              </TOCButton>
            </Box>

            <Box mt={4}>
              {isMessageShown ? (
                <Alert variant="outlined" severity="success">
                  Your message has been sent
                </Alert>
              ) : null}
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactForm;
