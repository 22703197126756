import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';

import { Circle } from 'common/components/simple';
import useStyles from './styles';

const SmartphoneAppAdvertisement = () => {
  const styles = useStyles();

  return (
    <Grid className={styles.section}>
      <Circle size={37} left="19%" top="5%" color="secondary" />
      <Circle size={17} left="78%" top="20%" color="tertiary" />
      <Circle size={54} left="54%" top="22%" />
      <Circle size={50} left="86%" top="36%" color="secondary" />
      <Circle size={17} left="14%" top="47%" color="tertiary" />
      <Circle size={31} left="-0.7%" top="50%" />
      <Circle size={22} left="53%" top="66%" color="tertiary" />

      <Circle size={12} left="86%" top="76%" color="tertiary" />

      <Circle size={14} left="4.5%" top="87%" color="secondary" />

      <Container className={styles.container}>
        <Grid container alignItems="center" className={styles.wrapper}>
          <Grid item xs={12} md={7} className={styles.image} />
          <Grid item xs={12} sm={10} md={4} container direction="column">
            <Typography variant="h5" component="h2" className={styles.subTitle}>
              Available on IOS & Android
            </Typography>

            <Typography variant="h2">COMING SOON!</Typography>
            <Typography variant="h2">TOC Mobile App</Typography>

            <Typography variant="body1" className={styles.description}>
              Manage your schedule, view and rate your counselors, and update
              payment and insurance information right from your smartphone or
              another device.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default SmartphoneAppAdvertisement;
