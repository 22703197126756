import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Header, Footer } from 'common/components';
import {
  TOCButton,
  TOCPagination,
  TOCSimpleDropdown,
} from 'common/components/simple';
import { Counselor, FilterList } from './components';

import { fetchOptions, saveFilters, searchCounselors } from './ducks';
import useStyles from './styles';

const pagination = { page: 1, count: 8 };

const Search = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { options } = useSelector((store) => store.search.options);
  const { filters } = useSelector((store) => store.search.filters);
  const { counselors, total, loading } = useSelector(
    (store) => store.search.result
  );

  const pagesCount = Math.ceil(total / pagination.count);

  const triggerSearch = useCallback(() => {
    const {
      page,
      state,
      availability,
      specialities,
      insurance,
      genders,
      languages,
      ethnicity,
      age,
    } = filters;

    dispatch(
      searchCounselors({
        page,
        stateIds: state,
        IsAvailable: availability === 1 || undefined,
        SpecialityIds: specialities,
        InsuranceIds: insurance || undefined,
        Genders: genders,
        LanguageIds: languages,
        EthnicityIds: ethnicity,
        MinAge: age[0],
        MaxAge: age[1],
      })
    );
  }, [dispatch, filters]);

  useEffect(() => {
    dispatch(fetchOptions());
  }, [dispatch]);

  useEffect(() => {
    triggerSearch();
  }, [triggerSearch]);

  const handleChange = (name, value) => {
    dispatch(saveFilters({ [name]: value }));
  };

  return (
    <>
      <Header height={{ xs: '420px', sm: '480px', lg: '370px' }} />

      {/* todo move this markup and logic to separated component */}
      <Grid container direction="column" className={styles.searchContainer}>
        <Container>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={styles.searchStateDropdownContainer}
          >
            <Typography variant="h4" className={styles.searchResultSubtitle}>
              <Box fontWeight="600">Search results</Box>
            </Typography>

            <Typography variant="h1" align="center">
              {total} counselors found in your area
            </Typography>

            <Grid
              item
              xs={12}
              sm={8}
              container
              className={styles.searchStateDropdown}
            >
              <Grid item xs={12} md={9}>
                <TOCSimpleDropdown
                  label="Select a state to start searching"
                  value={filters.state}
                  options={options?.availableStates || []}
                  onChange={(value) => handleChange('state', value)}
                />
              </Grid>

              <TOCButton
                shadow={false}
                size="large"
                color="secondary"
                width={140}
                onClick={triggerSearch}
                className={styles.searchButton}
              >
                <Box fontWeight="700" component="span">
                  Search
                </Box>
              </TOCButton>
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Typography variant="subtitle1" className={styles.searchTitle}>
            <Box fontWeight="700" component="span">
              Refine your search
            </Box>
          </Typography>

          <Grid item container direction="row" justify="space-between">
            <Grid
              item
              xs={12}
              lg={3}
              container
              direction="column"
              className={styles.filters}
            >
              <FilterList />
            </Grid>

            <Grid
              item
              xs
              container
              direction="column"
              className={styles.counselorList}
            >
              {!counselors?.length && !loading ? (
                <Box ml={15}>
                  <Typography variant="h2">
                    <Box fontSize={44}>No results found</Box>
                  </Typography>
                </Box>
              ) : null}

              {counselors.map((counselor) => (
                <Counselor
                  key={counselor.id}
                  counselor={counselor}
                  stateId={filters.state}
                />
              ))}

              {total > 0 ? (
                <TOCPagination
                  current={filters.page}
                  count={pagesCount}
                  onChange={(value) => handleChange('page', value)}
                />
              ) : null}
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Footer />
    </>
  );
};

Search.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedState: PropTypes.string,
    }),
  }),
};

export default Search;
