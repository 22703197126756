import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { CheckCircleRounded } from '@material-ui/icons';

import ClientsGraphPreview from 'assets/images/clients_preview-min.png';
import EarningsGraphPreview from 'assets/images/earnings_preivew-min.png';
import CalendarPreview from 'assets/images/calendar-min.png';
import { Wave } from 'common/components/simple';
import useStyles from './styles';

const AboutSoftware = () => {
  const styles = useStyles();

  return (
    <>
      <Wave animated color="waveSecondaryColor" />
      <Grid className={styles.aboutSoftwareContainer}>
        <Container>
          <Box pt={12} pb={{ xs: 4, sm: 6, md: 10 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                className={styles.aboutSoftwarePreviewSet}
              >
                <img
                  src={ClientsGraphPreview}
                  alt="clients preview"
                  className={styles.softwareClientsPreview}
                />
                <img
                  src={EarningsGraphPreview}
                  alt="earnings preview"
                  className={styles.softwareEarningsPreview}
                />
                <img
                  src={CalendarPreview}
                  alt="calendar preview"
                  className={styles.softwareCalendarPreview}
                />
              </Grid>

              <Grid
                item
                container
                direction="column"
                xs={12}
                md={6}
                className={styles.aboutSoftwareDescription}
              >
                <Typography
                  variant="subtitle1"
                  className={styles.aboutSoftwareDescriptionHint}
                >
                  The right tools for the job
                </Typography>

                <Typography
                  variant="h2"
                  className={styles.aboutSoftwareDescriptionTitle}
                >
                  Our software is designed to make your job easier
                </Typography>

                <Box mt={4} mb={3}>
                  <Typography variant="body1">
                    Our intuitive software is easy to use and comes loaded with
                    smart features.
                  </Typography>
                </Box>

                <Box
                  textAlign="left"
                  maxWidth={700}
                  mx={{ sm: 'auto', md: 'unset' }}
                >
                  <Box my={1}>
                    <Grid container wrap="nowrap">
                      <Box mr={2.5}>
                        <CheckCircleRounded color="secondary" />
                      </Box>

                      <Typography variant="subtitle1">
                        <Box fontWeight="700" component="span">
                          Detailed billing and payment reports
                        </Box>
                      </Typography>
                    </Grid>
                  </Box>

                  <Box my={1}>
                    <Grid container wrap="nowrap">
                      <Box mr={2.5}>
                        <CheckCircleRounded color="secondary" />
                      </Box>

                      <Typography variant="subtitle1">
                        <Box fontWeight="700" component="span">
                          Calendar functions
                        </Box>
                      </Typography>
                    </Grid>
                  </Box>

                  <Box my={1}>
                    <Grid container wrap="nowrap">
                      <Box mr={2.5}>
                        <CheckCircleRounded color="secondary" />
                      </Box>

                      <Typography variant="subtitle1">
                        <Box fontWeight="700" component="span">
                          Set your own rates, hours, and availability
                        </Box>
                      </Typography>
                    </Grid>
                  </Box>
                </Box>

                <Box mt={3} mb={{ xs: 3, sm: 4 }}>
                  <Typography variant="body1">
                    Setup your profile today, and begin accepting appointments
                    tomorrow! What&apos;s more, registration is free - there are
                    no monthly fees and no hidden costs.
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body1">
                    If you are ready to take your career into your own hands,
                    sign up and become a TOC today!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>
      <Wave animated invert color="waveSecondaryColor" />
    </>
  );
};

export default AboutSoftware;
