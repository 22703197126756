import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    background: palette.secondary.lighter,
    borderRadius: 30,
    marginBottom: spacing(3.75),
    padding: spacing(3.625, 5),
    color: palette.primary.main,
    boxShadow: `10px 15px 28px -15px ${palette.black}25`,
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: spacing(0, 0, 3.75),
    },
    '&.Mui-expanded:last-child': {
      margin: spacing(0, 0, 3.75),
    },

    [breakpoints.down('sm')]: {
      padding: spacing(3, 4),
      marginBottom: spacing(3),
      '&.Mui-expanded:last-child': {
        marginBottom: spacing(3),
      },
    },
    [breakpoints.down('xs')]: {
      padding: spacing(2, 3),
    },
  },
  summary: {
    // 117 is required height
    minHeight: `calc(117px - ${spacing(3.625 * 2)}px)`,
    padding: 0,
    '&.Mui-expanded': {
      margin: spacing(0),
      minHeight: 48,
    },
  },
  summaryContent: {
    '&.Mui-expanded': {
      margin: spacing(1.5, 0),
    },
  },
  summaryIcon: {
    color: palette.grey,
    '&.Mui-expanded': {
      color: palette.primary.main,
    },
  },
  details: {
    padding: 0,

    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
    },
  },
}));
