import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    backgroundColor: '#256290',
  },
  markedContainer: {
    backgroundColor: '#5381a1',
    minWidth: '10%',
  },
  title: {
    color: '#FFF',
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: -0.33,
  },
  subTitle: {
    color: '#FFF',
    fontSize: 14,
    letterSpacing: -0.33,
  },
}));
