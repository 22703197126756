import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    width: 70,
    transition: 'width 0.3s linear, padding 0.3s linear',
    padding: 10,
  },
  menuExpanded: {
    width: '20%',
    padding: '2em 1.2% 2em 2%',
  },
  logo: {
    width: '50%',
    minWidth: 45,
    maxWidth: 110,
  },
  expandIcon: {
    color: 'red',
  },
});
