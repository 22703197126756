import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Drawer, Grid, Typography, Box } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import ClearIcon from '@material-ui/icons/Clear';

import { TOCIconButton } from 'common/components/simple';
import {
  twitterLink,
  facebookLink,
  instagramLink,
} from 'common/constants/common';
import routes from 'common/constants/routes';

import { closeSideMenu } from 'modules/sideMenu/ducks';

import useStyles from './styles';

const SideMenu = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const opened = useSelector((store) => store.sideMenu.opened);
  const pathname = useSelector((store) => store.router.location.pathname);

  const closeMenu = useCallback(() => dispatch(closeSideMenu()), [dispatch]);

  useEffect(() => {
    closeMenu();
  }, [pathname, closeMenu]);

  return (
    <Drawer
      anchor="right"
      open={opened}
      onClose={closeMenu}
      classes={{
        root: styles.overlay,
        paperAnchorRight: styles.sideMenuWrap,
      }}
    >
      <Grid container className={styles.menuWrapper}>
        <Grid
          container
          justify="space-between"
          className={styles.menuButtonsSection}
        >
          <Grid item xs={5} />

          <Grid item>
            <TOCIconButton
              light
              color="white"
              size="medium"
              icon={ClearIcon}
              bgColor="primary"
              onClick={closeMenu}
            />
          </Grid>
        </Grid>

        <Grid container className={styles.contentWrapper}>
          <NavLink
            to={routes.home.url}
            className={styles.sideMenuLink}
            activeClassName={styles.sideMenuActiveLink}
          >
            <Typography color="textSecondary" variant="h4">
              {routes.home.title}
            </Typography>
          </NavLink>

          <NavLink
            to={routes.about.url}
            className={styles.sideMenuLink}
            activeClassName={styles.sideMenuActiveLink}
          >
            <Typography color="textSecondary" variant="h4">
              {routes.about.title}
            </Typography>
          </NavLink>

          <NavLink
            to={routes.faq.url}
            className={styles.sideMenuLink}
            activeClassName={styles.sideMenuActiveLink}
          >
            <Typography color="textSecondary" variant="h4">
              {routes.faq.title}
            </Typography>
          </NavLink>

          <NavLink
            to={routes.joinUs.url}
            className={styles.sideMenuLink}
            activeClassName={styles.sideMenuActiveLink}
          >
            <Typography color="textSecondary" variant="h4">
              {routes.joinUs.title}
            </Typography>
          </NavLink>

          <NavLink
            to={routes.contact.url}
            className={styles.sideMenuLink}
            activeClassName={styles.sideMenuActiveLink}
          >
            <Typography color="textSecondary" variant="h4">
              {routes.contact.title}
            </Typography>
          </NavLink>

          <NavLink
            to={routes.blog.url}
            className={styles.sideMenuLink}
            activeClassName={styles.sideMenuActiveLink}
          >
            <Typography color="textSecondary" variant="h4">
              {routes.blog.title}
            </Typography>
          </NavLink>

          <Box
            width={200}
            display="flex"
            justifyContent="space-between"
            className={styles.socialLinksWrapper}
          >
            <TOCIconButton
              light
              size="small"
              iconSize="small"
              color="white"
              bgColor="primary"
              iconName="facebook-f"
              to={facebookLink}
            />

            <TOCIconButton
              light
              size="small"
              iconSize="small"
              color="white"
              bgColor="primary"
              icon={TwitterIcon}
              to={twitterLink}
            />

            <TOCIconButton
              light
              size="small"
              iconSize="small"
              color="white"
              bgColor="primary"
              iconName="instagram"
              to={instagramLink}
            />
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideMenu;
