import React from 'react';

import { Header, Footer, SmartphoneAppAdvertisement } from 'common/components';
import AboutHeaderSection from './components/AboutHeaderSection';
import InsurancesSection from './components/InsurancesSection';
import OurSpecialities from './components/OurSpecialities';
import AboutOurMission from './components/AboutOurMission';
import Founder from './components/Founder';

const AboutUs = () => {
  return (
    <>
      <Header height={{ xs: '280px', sm: '450px', md: '500px', lg: '550px' }} />
      <AboutHeaderSection />
      <InsurancesSection />
      <OurSpecialities />
      <AboutOurMission />
      <Founder />
      <SmartphoneAppAdvertisement />
      <Footer />
    </>
  );
};

export default AboutUs;
