import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReplayIcon from '@material-ui/icons/Replay';
import EditIcon from '@material-ui/icons/Edit';
import Rating from '@material-ui/lab/Rating';
import AdminLayout from 'common/components/admin/Layout';
import TotalStatistics from 'common/components/admin/Statistics/TotalStatistics';
import GraphStatistics from 'common/components/admin/Statistics/GraphStatistics';
import ResponsiveBar from 'common/components/admin/Statistics/graphs/ResponsiveBar';
import InfoCard from 'common/components/admin/InfoCard';
import FormDocuments from 'ui/containers/admin/ClientProfile/components/FormsDocuments';

import Overview from './components/Overview';
import Credentialing from './components/Credentialing';
import CalendarSection from './components/CalendarSection';
import TotalRevenue from './components/TotalRevenue';

import { statistics } from './data';
import useStyles from './styles';

const ClientProfile = () => {
  const classes = useStyles();
  const bar = useRef();
  const handleMenuExpand = () => {
    bar.current?.refresh();
  };
  return (
    <AdminLayout
      title="Paula Brown"
      onWindowResize={handleMenuExpand}
      onMenuExpand={handleMenuExpand}
    >
      <Grid container component={Box} spacing={2}>
        <Grid item container direction="column" xs={4}>
          <Grid item component={Overview} />
          <Grid item component={Box} pt={2}>
            <Credentialing />
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <CalendarSection />

          <Grid
            container
            spacing={1}
            component={Box}
            justify="space-between"
            pt={2}
          >
            <Grid item component={Box} width="48%">
              <TotalStatistics
                iconBgColor="#d1f3ee"
                icon={<PeopleIcon style={{ color: '#40ceba' }} />}
                title="Total active clients"
                value={450}
                link={{
                  title: 'View clients',
                }}
                linkPosition="top right"
                style={{ height: '100%' }}
              />
            </Grid>

            <Grid item component={Box} width="48%">
              <TotalStatistics
                iconBgColor="#fff3d7"
                icon={<ThumbUpAltIcon style={{ color: '#ffd060' }} />}
                title="Total Clients"
                value={450}
                style={{ height: '100%' }}
              >
                <Rating />
                <Typography
                  style={{
                    fontSize: '1.24rem',
                    fontWeight: 600,
                  }}
                >
                  4.5 client rating
                </Typography>

                <Typography
                  style={{
                    color: '#bdc5ca',
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    position: 'relative',
                    bottom: 7,
                  }}
                >
                  Based on 95 rating
                </Typography>
              </TotalStatistics>
            </Grid>
          </Grid>

          <Grid item component={Box} py={2}>
            <GraphStatistics headerTitle="Profile Statistics">
              <Box height={203} style={{ width: '100%' }}>
                <ResponsiveBar
                  ref={bar}
                  data={statistics}
                  keys={[
                    'New enrollments',
                    'Profile Views',
                    'Avarage Weekly Sessions',
                  ]}
                />
              </Box>
            </GraphStatistics>
          </Grid>

          <Grid item container component={Box} justify="space-between" py={2}>
            <Grid item component={Box} width="48%">
              <TotalRevenue />
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              component={Box}
              width="48%"
            >
              <TotalStatistics
                iconBgColor="#d1f3ee"
                icon={<MonetizationOnIcon style={{ color: '#40ceba' }} />}
                title="Total Clients"
                value={450}
              >
                <Typography className={classes.statisticsTitle}>
                  Self-pay rate
                </Typography>
                <Box>
                  <Typography
                    component="span"
                    style={{
                      fontSize: 34,
                      fontWeight: 500,
                      lineHeight: 1,
                    }}
                  >
                    $45.00
                  </Typography>

                  <Typography
                    component="span"
                    style={{
                      fontSize: 20,
                      position: 'relative',
                      bottom: 5,
                    }}
                  >
                    /hour
                  </Typography>
                </Box>
              </TotalStatistics>
              <Box pt={2}>
                <TotalStatistics
                  iconBgColor="#d1f3ee"
                  icon={<ReplayIcon style={{ color: '#40ceba' }} />}
                  title="Total Clients"
                  value={450}
                >
                  <Typography className={classes.statisticsTitle}>
                    Client retention rate
                  </Typography>
                  <Box>
                    <Typography
                      style={{
                        fontSize: 34,
                        fontWeight: 500,
                        lineHeight: 1,
                      }}
                    >
                      86%
                    </Typography>
                    <Typography
                      style={{
                        color: '#3d6175',
                        fontSize: 10,
                      }}
                    >
                      12 out of 14 new clients have had more than 4 sessions
                    </Typography>
                  </Box>
                </TotalStatistics>
              </Box>
            </Grid>
          </Grid>

          <Grid item component={Box} width="100%" py={2}>
            <InfoCard
              title="Forms & Documents"
              link={{
                title: 'Upload new',
              }}
              LinkIcon={EditIcon}
            >
              <FormDocuments />
            </InfoCard>
          </Grid>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default ClientProfile;
