export default {
  white: '#FFFFFF',
  black: '#000000',
  semiWhite: '#FFFFFF50',
  semiBlack: '#00000050',
  transparent: '#00000000',
  primary: {
    light: '#1E567E',
    lighter: '#1E4A68',
    main: '#143A56',
    dark: '#113148',
  },
  secondary: {
    light: '#F2FAF5',
    lighter: '#D7EEE0',
    dark: '#3dc1a7',
    main: '#5CD0B9',
    shadow: '#c0d0b9',
  },
  alert: {
    info: '#1D4A68',
    warning: '#D94343',
  },
  text: {
    primary: '#A6AEB7',
    secondary: '#FFFFFF',
    disabled: '#babcbe',
  },
  lightText: '#D5ECFC',
  darkText: '#212827',
  waveSecondaryColor: '#F2FAF5',
  waveDefaultBackgroundColor: 'transparent',
  logoColors: {
    primary: '#4897ce',
    secondary: '#5CD0B9',
    tertiary: '#6fc491',
  },
  shareButton: {
    facebook: '#3b5a98',
    twitter: '#1da1f1',
    linkedIn: '#0077b5',
    pinterest: '#cd2d26',
  },
};
