import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    margin: spacing(20, 'auto', 10),
    maxWidth: 1100,

    '& h1, & h2': { margin: spacing(2, 0) },
    '& h3': { margin: spacing(1, 0) },
    '& div, & p': { margin: spacing(1, 0) },
    '& div ul': { margin: spacing(2) },
    '& div > ul > li': { marginBottom: spacing(1) },
    '& table > tr > td:last-child': {
      borderRight: 'none',
    },
    '& table > tr:last-child > td': {
      borderBottom: 'none',
    },
    '& table > tr > td': {
      borderBottom: '1px solid #ccc',
      borderRight: '1px solid #ccc',
    },
    '& table > tr > th:nth-child(1)': {
      width: '20%',
    },
    '& table > tr > th:nth-child(2)': {
      width: '40%',
    },
    '& table > tr > th, & table > tr > td': {
      width: '15%',
      textAlign: 'left',
      padding: spacing(1),
    },

    [breakpoints.down('md')]: {
      margin: spacing(10, 'auto', 6),
    },
    [breakpoints.down('sm')]: {
      margin: spacing(6, 'auto', 6),
      '& div ul, & div ol': {
        margin: spacing(1, 0),
        paddingLeft: 30,
      },
    },
  },
  tableWrapper: {
    [breakpoints.down('xs')]: {
      overflow: 'scroll',
      '& table': {
        width: 600,
      },
    },
  },
}));
