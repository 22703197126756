import React from 'react';
import Typography from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import InfoCard from 'common/components/admin/InfoCard';

import useStyles from './styles';

const Credentialing = () => {
  const classes = useStyles();

  return (
    <InfoCard
      title="Credentialing"
      link={{
        title: 'View & Update',
      }}
      LinkIcon={EditIcon}
    >
      <TableContainer className={classes.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>
                <Typography className={classes.th}>
                  Insurance company
                </Typography>
              </TableCell>

              <TableCell>
                <Typography
                  className={[classes.th, classes.alignRight].join(' ')}
                >
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.td}>Aetna</TableCell>
              <TableCell className={[classes.td, classes.alignRight].join(' ')}>
                <Typography
                  className={classes.tdRightContent}
                  component="span"
                  style={{
                    backgroundColor: '#fdf4e7',
                  }}
                >
                  PROCESSING
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.td}>BlueCross BlueShield</TableCell>
              <TableCell className={[classes.td, classes.alignRight].join(' ')}>
                <Typography
                  className={classes.tdRightContent}
                  component="span"
                  style={{
                    backgroundColor: '#e1f3e9',
                  }}
                >
                  FULLY CREDENTIALED
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.td}>Cigna</TableCell>
              <TableCell className={[classes.td, classes.alignRight].join(' ')}>
                <Typography
                  className={classes.tdRightContent}
                  component="span"
                  style={{
                    backgroundColor: '#e5e7ea',
                  }}
                >
                  NOT STARTED
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.td}>
                Magellan Health Services
              </TableCell>
              <TableCell className={[classes.td, classes.alignRight].join(' ')}>
                <Typography
                  className={classes.tdRightContent}
                  component="span"
                  style={{
                    backgroundColor: '#d7eaf4',
                  }}
                >
                  APPLICATION SENT
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.td}>United Healthcare</TableCell>
              <TableCell className={[classes.td, classes.alignRight].join(' ')}>
                <Typography
                  className={classes.tdRightContent}
                  component="span"
                  style={{
                    backgroundColor: '#e9d9fa',
                  }}
                >
                  APPLICATION RECEIVED
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </InfoCard>
  );
};

export default Credentialing;
