import React from 'react';

import { Header, Footer } from 'common/components';
import BlogList from './components/BlogList';
import LatestArticle from './components/LatestArticle';

const Blog = () => (
  <>
    <Header height={{ xs: '520px', sm: '240px', md: '350px' }} />
    <LatestArticle />
    <BlogList />
    <Footer />
  </>
);

export default Blog;
