import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import AdminLayout from 'common/components/admin/Layout';
import Button from 'common/components/admin/Button';
import SelectInput from 'common/components/admin/SelectInput';
import useStyles from './styles';
import { assessmentsCard, assessmentSortValues } from './assessmentsData';
import AssessmentCard from './components/AssessmentCard';

const ManageAssessments = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const actionButton = [
    <Button
      key={0}
      startIcon={<AddIcon />}
      bgColor="#40ceba"
      className={classes.topButton}
    >
      Add assessment
    </Button>,
  ];
  return (
    <AdminLayout
      title="Manage assessments"
      subTitle="Assessments"
      actionButtons={actionButton}
    >
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          component={Box}
          py={4}
        >
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            component={Box}
            px={3}
          >
            <Grid item component={Box}>
              <TextField
                placeholder="Search assessments"
                variant="outlined"
                value={search}
                className={classes.textField}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { input: classes.input },
                }}
              />
            </Grid>
            <Grid item component={Box}>
              <SelectInput
                placeholder="Sort by"
                valueOptions={assessmentSortValues}
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.contentContainer}>
            {assessmentsCard.map((card) => (
              <AssessmentCard key={card.id} data={card} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default ManageAssessments;
