import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  topButton: {
    borderRadius: 15,
    padding: '7px 15px',
    boxShadow: '0px 5px 15px #143A5680',
    color: '#fff',
  },
  countLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
  },
  tableTitle: {
    fontSize: 18,
    letterSpacing: -0.45,
    color: '#143A56',
  },
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderRadius: 15,
  },
  table: {
    backgroundColor: '#ffff',
    borderRadius: 15,
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: 14,
    color: '#143A56',
  },
  boldFont: {
    fontWeight: 600,
    fontSize: 14,
    color: '#143A56',
  },
  iconButton: {
    height: 32,
    width: 32,
    borderRadius: 10,
  },
  selected: {
    backgroundColor: '#F5F8FA',
  },
}));
