import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const contact = actionTypesGenerator(['send'], 'contact');

const initialState = {
  result: null,
  loading: false,
  error: false,
};

export default reducerGenerator(
  contact.send,
  initialState,
  (state, payload) => ({ ...state, result: payload.data })
);

export const sendMessage = (data) => ({
  type: contact.send.requested,
  payload: { data },
});
