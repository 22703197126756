import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    margin: spacing(20, 'auto', 10),
    maxWidth: 1100,

    '& li': { marginBottom: spacing(2) },

    [breakpoints.down('md')]: {
      margin: spacing(10, 'auto', 6),
    },
    [breakpoints.down('sm')]: {
      margin: spacing(6, 'auto', 6),
      '& div ul, & div ol': {
        margin: spacing(1, 0),
        paddingLeft: 30,
      },
    },
  },
}));
