import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import routes from 'common/constants/routes';
import { TOCButton } from 'common/components/simple';
import OurCounselors from 'ui/containers/FAQ/components/OurCounselors';

import useStyles from './styles';

const FAQSection = () => {
  const styles = useStyles();

  return (
    <Grid container className={styles.container}>
      <Container>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={styles.wrapper}
        >
          <Typography variant="h2" align="center" className={styles.title}>
            Read our FAQs to learn more
          </Typography>

          <OurCounselors homePage />

          <Typography variant="body2" className={styles.ifNotEnoughSection}>
            <Box component="span" fontWeight="700">
              If you&lsquo;d like to learn more, view our full FAQs or get in
              touch.
            </Box>
          </Typography>

          <Grid item container className={styles.buttonSection}>
            <TOCButton
              width={235}
              size="large"
              color="secondary"
              to={routes.faq.url}
            >
              <Box component="span" fontWeight="700">
                Check out our FAQs
              </Box>
            </TOCButton>

            <TOCButton width={235} size="large" to={routes.contact.url}>
              <Box component="span" fontWeight="700">
                Get in touch
              </Box>
            </TOCButton>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default FAQSection;
