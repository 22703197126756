import React, { memo } from 'react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';

import routes from 'common/constants/routes';
import { TOCIconButton, Circle, Wave } from 'common/components/simple';
import { openSideMenu } from 'modules/sideMenu/ducks';
import logo from 'assets/images/logo-min.png';
import useStyles from './styles';

const Header = memo(({ height = 100 }) => {
  const styles = useStyles({ height });
  const dispatch = useDispatch();

  const closeMenu = () => dispatch(openSideMenu());
  const openHomePage = () => dispatch(push(routes.home.url));

  return (
    <Grid className={styles.container}>
      <Grid className={styles.logoMenuContainer}>
        <Circle size={54} left="60%" top="55" />
        <Circle size={37} left="6%" top="120" color="secondary" />
        <Circle size={14} left="9%" top="240" color="secondary" />
        <Circle size={17} left="77%" top="250" color="tertiary" />
        <Circle size={50} left="90%" top="360" color="secondary" />
        <Circle size={31} left="4%" top="500" />
        <Circle size={22} left="84%" top="520" color="tertiary" />

        <Container className={styles.wrapper}>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid onClick={openHomePage}>
              <img src={logo} alt="header logo" className={styles.logo} />
            </Grid>

            <TOCIconButton
              size="small"
              color="white"
              bgColor="primary"
              icon={MenuIcon}
              onClick={closeMenu}
            />
          </Grid>
        </Container>

        <Grid className={styles.headerEmptySpace}>
          <Box height={height} />
          <Wave invert animated height={height} color="waveSecondaryColor" />
        </Grid>
      </Grid>
    </Grid>
  );
});

Header.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default Header;
