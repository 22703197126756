import React, { useReducer } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from 'common/components/admin/Button';

import useStyles from './styles';

const initialState = {
  radio: 'All',
  searchByClient: '',
  searchByProvider: '',
};

const actionTypes = {
  setRadio: 'SET_RADIO',
  setSearchByClient: 'SET_SEARCH_BY_CLIENT',
  setSearchByProvider: 'SET_SEARCH_BY_PROVIDER',
  clear: 'CLEAR',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setRadio:
      return {
        ...state,
        radio: action.value,
      };
    case actionTypes.setSearchByClient:
      return {
        ...state,
        searchByClient: action.value,
      };
    case actionTypes.setSearchByProvider:
      return {
        ...state,
        searchByProvider: action.value,
      };
    case actionTypes.clear:
      return {
        ...initialState,
      };
    default:
      throw new Error();
  }
};

const TableHeader = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Grid
      item
      container
      display="flex"
      flexDirection="row"
      justify="space-between"
      alignItems="center"
      component={Box}
      py={2}
      px={2}
    >
      <Grid
        item
        container
        component={Box}
        flex={1}
        className={classes.clientsCountLabel}
      >
        <Typography component="span">Showing</Typography>
        <Typography style={{ fontWeight: 700 }}>&nbsp;4&nbsp;</Typography>
        <Typography>Clients</Typography>
      </Grid>
      <Grid item container spacing={1} component={Box} display="flex" flex={3}>
        <Grid item component={Box} flex={3}>
          <RadioGroup
            row
            value={state.radio}
            onChange={(e) =>
              dispatch({ type: actionTypes.setRadio, value: e.target.value })
            }
          >
            <FormControlLabel
              value="all"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="All"
            />
            <FormControlLabel
              value="waiting"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="Waiting"
            />
            <FormControlLabel
              value="active"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="Active"
            />
            <FormControlLabel
              value="inactive"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="Inactive"
            />
          </RadioGroup>
        </Grid>
        <Grid item component={Box} flex={2}>
          <TextField
            placeholder="Search by client"
            variant="outlined"
            value={state.searchByClient}
            className={classes.textField}
            onChange={(e) =>
              dispatch({
                type: actionTypes.setSearchByClient,
                value: e.target.value,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.input },
            }}
          />
        </Grid>
        <Grid item component={Box} flex={2}>
          <TextField
            placeholder="Search by provider"
            variant="outlined"
            value={state.searchByProvider}
            className={classes.textField}
            onChange={(e) =>
              dispatch({
                type: actionTypes.setSearchByProvider,
                value: e.target.value,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.input },
            }}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.resetButton}
            variant="outlined"
            onClick={() => dispatch({ type: actionTypes.clear })}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableHeader;
