import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const menuItemStyles = {
  paddingRight: 0,
  right: 0,
  flexFlow: 'row wrap',
};

const BOTTOM_RIGHT = 'bottom right';
const TOP_RIGHT = 'top right';

const StatisticsLayout = ({
  header,
  children,
  link = {},
  linkPosition = BOTTOM_RIGHT,
  style: propStyle = {},
}) => {
  const { title: linkTitle, url: linkUrl = '/' } = link;
  const renderLink = () => {
    const style = {};
    if (linkPosition === TOP_RIGHT) {
      style.height = 17;
      style.position = 'absolute';
      style.right = 5;
      style.width = linkTitle.length < 14 ? '40%' : '55%';
    }
    return (
      <Grid
        item
        component={Box}
        container
        pb={1}
        display="flex"
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={style}
      >
        <MenuItem url={linkUrl} style={menuItemStyles}>
          <Typography
            variant="h5"
            style={{ fontSize: '0.9rem', color: '#40ceba' }}
          >
            {linkTitle}
          </Typography>
          <ArrowForwardIcon style={{ height: '1rem', color: '#d2dae2' }} />
        </MenuItem>
      </Grid>
    );
  };
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      component={Box}
      style={{ backgroundColor: '#ffff', ...propStyle, position: 'relative' }}
      pt={3}
      px={2.5}
      borderRadius={5}
    >
      {header && <Grid item>{header}</Grid>}
      {linkTitle && linkPosition === TOP_RIGHT && renderLink()}
      {children}
      {linkTitle && linkPosition === BOTTOM_RIGHT && renderLink()}
    </Grid>
  );
};

StatisticsLayout.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  linkPosition: PropTypes.oneOf([TOP_RIGHT, BOTTOM_RIGHT]),
  style: PropTypes.shape(),
};

export default StatisticsLayout;
