import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  tab: {
    color: '#b5bcc4',
    textTransform: 'capitalize',
    fontSize: '1rem',
  },
  selected: {
    color: '#244e65',
  },
});
