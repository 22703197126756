import { all, fork } from 'redux-saga/effects';
import { adminSaga } from 'react-admin';

import dataProvider from 'store/dataProvider';
import home from 'ui/containers/Home/ducks/sagas';
import search from 'ui/containers/Search/ducks/sagas';
import profile from 'ui/containers/Profile/ducks/sagas';
import blog from 'ui/containers/Blog/ducks/sagas';
import blogArticle from 'ui/containers/BlogArticle/ducks/sagas';
import contact from 'ui/containers/Contact/ducks/sagas';

const sagas = [
  adminSaga(dataProvider, {}),
  home,
  search,
  profile,
  blog,
  blogArticle,
  contact,
];

export default function* rootSaga() {
  yield all(sagas.flat().map((saga) => fork(saga)));
}
