import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  middleSectionLabel: {
    fontSize: 11,
  },
  statisticsTitle: {
    fontSize: 16,
    color: '#a9b3ba',
    fontWeight: 600,
  },
});
