import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Slider,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './styles';

const Range = (props) => {
  const { title, subtitle, min, max, value, step, valueType, onChange } = props;
  const [range, setRange] = useState(value);
  const styles = useStyles();

  const handleChange = (event, newValue) => setRange(newValue);

  const handleChangeCommitted = (event, newValue) => onChange(newValue);

  return (
    <Accordion square defaultExpanded classes={{ root: styles.listContainer }}>
      <AccordionSummary
        id="range-accordion"
        aria-controls="range-accordion"
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: styles.listSummary,
          content: styles.listSummaryContent,
          expandIcon: styles.listSummaryIcon,
        }}
      >
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="subtitle2" className={styles.subTitle}>
          {subtitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: styles.listDetails }}>
        <Typography variant="overline" className={styles.rangeMinPrice}>
          {valueType === 'number' ? range[0] : `${(range[0] / 100).toFixed(2)}`}
        </Typography>

        <Slider
          step={step}
          min={min}
          max={max}
          value={range}
          color="secondary"
          valueLabelDisplay="off"
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          classes={{ rail: styles.rangeRail }}
          className={styles.rangeSlider}
        />

        <Typography variant="overline" className={styles.rangeMaxPrice}>
          {valueType === 'number' ? range[1] : `${(range[1] / 100).toFixed(2)}`}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

Range.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.number),
  step: PropTypes.number,
  valueType: PropTypes.string,
  onChange: PropTypes.func,
};

export default Range;
