import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  popoverContainer: {
    marginRight: 20,
    borderRadius: 10,
    boxShadow: '0px 5px 15px #00000027',
    '& > *:not(:last-child)': {
      marginBottom: '0.7rem',
    },
  },
  itemRow: {
    '&:hover': {
      backgroundColor: '#F8FAFA',
      color: '#000',
      opacity: 0.6,
    },
  },
  itemRowTitle: {
    paddingLeft: 5,
    paddingRight: 15,
    fontSize: '0.9rem',
    color: '#143A56',
    letterSpacing: -0.35,
  },
}));
