import React from 'react';
import { TOCAccordion } from 'common/components';
import { Typography } from '@material-ui/core';

const CounselingFAQs = () => (
  <>
    <TOCAccordion title="How do I know if I need counseling?">
      <Typography variant="body1" component="div">
        Counseling can benefit anyone looking to feel their best. However, when
        emotions and behaviors impede life functioning in one or more areas such
        as school, work, relationships, and/or social activities, it is
        important to seek professional intervention.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What is counseling, and how does it work?">
      <Typography variant="body1" component="div">
        Oftentimes, we seek counseling to get through a difficult experience,
        manage overwhelming emotions, or improve relationships. While counseling
        is effective in helping one get through isolated challenges, the goal of
        counseling is to help identify patterns of ideas, emotions, and
        behaviors that may contribute to repetitive maladaptive experiences.
        <p />
        Our current experiences are often shaped by past experiences. It is
        common to get stuck in ways that make it difficult to move past a
        particular state of being. It is important to recognize that counseling
        is a collaborative effort. The true work of counseling often occurs
        between sessions in self-reflection, self-awareness, and a commitment to
        self-improvement.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What are the differences between online counseling and counseling in an office?">
      <Typography variant="body1" component="div">
        There is no difference in the efficacy of care. While online counseling
        is safer and more convenient, some prefer the experience of being in an
        office environment. It is a matter of personal preference.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="Can I have more than one session per week?">
      <Typography variant="body1" component="div">
        Yes. This depends on your needs and insurance plan (if applicable). A
        discussion with your TOC will help you determine what is best for you
        and your situation.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What if I am not interested in a long-term counselor?">
      <Typography variant="body1" component="div">
        Ongoing counseling can provide lasting improvement in many areas in
        life. However, if you are seeking support for an isolated event or
        challenge, you may schedule on an as-needed basis. Please discuss your
        needs and goals with your TOC. They will be able to provide the best
        advice in terms of goal attainment.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="Are services for both adults and children?">
      <Typography variant="body1" component="div">
        Yes. However, online counseling treatment modalities are most
        appropriate and effective in children over 12 years of age.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What if I am in crisis?">
      <Typography variant="body1" component="div">
        Text and Online Counseling does not provide crisis intervention
        services. Should you have a mental health-related or substance
        use-related emergency, please call 911 or visit your nearest hospital.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What if I need to cancel a session?">
      <Typography variant="body1" component="div">
        Appointment reminders are sent 48 hours and 1 hour in advance of a
        scheduled appointment. If you need to cancel an appointment, please do
        in the client portal at least 24 hours in advance. Appointments canceled
        less than 24 hours in advance will incur a cancellation or no-show fee,
        which is the counselor’s full session cost.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="How long is each session?">
      <Typography variant="body1" component="div">
        <ul>
          <li>The initial assessment session is 60 minutes.</li>
          <li>Follow-up sessions are 50 to 60 minutes.</li>
        </ul>
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="How frequently should I attend counseling sessions?">
      <Typography variant="body1" component="div">
        Attending sessions consistently is important to reaching goals. However,
        each individual has different needs. Weekly sessions are usually
        recommended for the first six to eight weeks. Following this period, a
        discussion with your TOC about the frequency of sessions can determine
        your particular needs.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What can I expect after I sign up?">
      <Typography variant="body1" component="div">
        After creating an account, you will get an email with information on how
        to complete your account. This will include intake forms, consent for
        treatment, credit card authorization, and insurance information (if
        applicable). Prior to the each session, we verify eligibility (if using
        insurance) and charge the card on file for session fees or copayment
        amounts. When your profile is completed, you may search for and book an
        appointment with a TOC online.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What can I expect the first session?">
      <Typography variant="body1" component="div">
        After reviewing the intake questionnaire, the counselor will meet with
        you online to discuss your needs, goals, treatment history, and reasons
        for seeking counseling. You will also get a feel for the counselor and
        their counseling style. Before the end of the session, you and your
        counselor will discuss scheduling of follow-up appointments to best meet
        your needs.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="How many sessions will I need before I feel better?">
      <Typography variant="body1" component="div">
        Treatment length varies for each individual and depends on your current
        circumstance, treatment goals, and history in counseling, among other
        factors. You may choose to work with a counselor for a limited number of
        sessions or seek longer-term care. To ensure your needs are being met,
        your TOC will discuss your progress often and mutually decide when your
        goals are attained and counseling should end.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="Can we do family or group counseling online?">
      <Typography variant="body1" component="div">
        Yes, group sessions are available. You may text or email the unique
        session link to the participant(s) you wish to join the session.
        However, large groups are not appropriate for online counseling. For
        this reason, sessions should be limited to three participants.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="Can I get a prescription refill?">
      <Typography variant="body1" component="div">
        Text and Online Counseling does not offer medication management and/or
        prescription refills. Please continue to work with your medical provider
        and/or psychiatrist for the management of psychotropic medications.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="Do I need a diagnosis to receive counseling services from TOC?">
      <Typography variant="body1" component="div">
        If you are using health insurance, a diagnosis is needed to submit
        claims. Please speak with your counselor if you have questions about
        diagnostic impressions. Those who pay out of pocket for services do not
        have to be diagnosed.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="I have a court order to seek counseling. Will TOC count?">
      <Typography variant="body1" component="div">
        Counseling through Text and Online Counseling will satisfy requirements
        for court-ordered counseling. However, we are unable to provide reports
        on and verification of such services. You may print documents,
        statements, and session history from the client portal to verify your
        counseling attendance.
      </Typography>
    </TOCAccordion>
  </>
);

export default CounselingFAQs;
