import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Banner = ({ bgColor, styles = {}, title }) => {
  const defaultStyles = {
    backgroundColor: '#ffff',
    borderRadius: 3,
    padding: 2,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: -0.25,
    color: '#143A56',
    opacity: 1,
  };
  if (bgColor) defaultStyles.backgroundColor = bgColor;
  return (
    <Typography
      component="span"
      style={{
        ...defaultStyles,
        ...styles,
      }}
    >
      {title}
    </Typography>
  );
};

Banner.propTypes = {
  bgColor: PropTypes.string,
  styles: PropTypes.shape(),
  title: PropTypes.string,
};

export default Banner;
