import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from 'common/components/admin/IconButton';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { actions } from '../../appointmentsData';
import useStyles from './styles';

const ActionsPopover = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        IconComponent={MoreHorizIcon}
        onClick={handleClick}
        style={{ backgroundColor: '#fff' }}
        iconProps={{
          style: {
            color: '#143A56',
          },
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popoverContainer}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container component={Box} direction="column" py={2} px={1}>
          <Grid item container direction="column">
            {actions.map((item) => (
              <Grid
                key={item.id}
                container
                direction="row"
                className={classes.itemRow}
                onClick={handleClose}
              >
                <Icon
                  className={item.icon}
                  style={{ fontSize: 14, alignSelf: 'flex-end' }}
                />
                <Typography key={item.id} className={classes.itemRowTitle}>
                  {item.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default ActionsPopover;
