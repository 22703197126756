import { combineReducers } from 'redux';
import { adminReducer } from 'react-admin';
import { connectRouter } from 'connected-react-router';

import sideMenu from 'modules/sideMenu/ducks';
import spWidgetService from 'modules/spWidget/ducks';
import homeReducer from 'ui/containers/Home/ducks';
import searchReducer from 'ui/containers/Search/ducks';
import profileReducer from 'ui/containers/Profile/ducks';
import blogReducer from 'ui/containers/Blog/ducks';
import blogArticleReducer from 'ui/containers/BlogArticle/ducks';
import contactReducer from 'ui/containers/Contact/ducks';

const rootReducer = (history) =>
  combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    sideMenu,
    widget: spWidgetService,
    home: homeReducer,
    search: searchReducer,
    profile: profileReducer,
    blog: blogReducer,
    article: blogArticleReducer,
    contact: contactReducer,
  });

export default rootReducer;
