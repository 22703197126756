import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  CardContent,
} from '@material-ui/core';

import routes from 'common/constants/routes';
import { BlogDateReadTime } from 'common/components/simple';
import useStyles from './styles';

const TOCBlogCard = ({ post }) => {
  const { id, name, coverImageUrl, minutesToRead, createdAt } = post;
  const dispatch = useDispatch();
  const styles = useStyles();

  const postLink = routes.blogArticle.url.replace(':id', String(id));
  const openPost = () => dispatch(push(postLink));

  return (
    <Card onClick={openPost} classes={{ root: styles.blogContainer }}>
      <CardContent classes={{ root: styles.blogTextContainer }}>
        <Grid
          container
          direction="column"
          justify="space-between"
          className={styles.blogContentContainer}
        >
          <BlogDateReadTime date={createdAt} readTime={minutesToRead} />

          <Grid item className={styles.blogTitleWrapper}>
            <Typography
              color="primary"
              variant="h4"
              component="h3"
              className={styles.blogTitle}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardMedia
        component="img"
        image={coverImageUrl}
        classes={{ root: styles.blogMedia }}
      />
    </Card>
  );
};

TOCBlogCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    coverImageUrl: PropTypes.string,
    createdAt: PropTypes.string,
    minutesToRead: PropTypes.number,
  }),
};

export default TOCBlogCard;
