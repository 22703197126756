import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { contact } from '../index';

function* sendMessageSagaRequest() {
  yield requestSaga({
    actionType: contact.send,
    request: dataProvider.create,
    resource: resources.contact,
  });
}

export default sendMessageSagaRequest;
