export default {
  imageUrl:
    'https://avatars.mds.yandex.net/get-zen_doc/3517023/pub_5ef83cfdef03f030a698757d_5ef83d86b761c26bbc17d3e8/scale_1200',
  name: 'Jonatahn Write',
  username: 'jonatahnwrite',
  dateJoined: new Date().toLocaleDateString(),
  sex: 'Male',
  phone: '(123) 456-7890',
  email: 'jonatahnwrite@gmail.com',
  address: '123 Street Name, Philadelphia',
};

const enrollmentColor = '#4acfbc';
const profileViewsColor = '#0d648e';
const weeklySessionsColor = '#b2ce56';

export const statistics = [
  {
    country: 'Jan',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Feb',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Mar',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Apr',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'May',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Jun',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Jul',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Aug',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Sep',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Oct',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Nov',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
  {
    country: 'Dec',
    'New enrollments': 180,
    'New enrollmentsColor': enrollmentColor,
    'Profile Views': 62,
    'Profile ViewsColor': profileViewsColor,
    'Avarage Weekly Sessions': 62,
    'Avarage Weekly SessionsColor': weeklySessionsColor,
  },
];
