import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pure } from 'recompose';

import {
  WIDGET_CDN,
  WIDGET_HREF,
  WIDGET_SCOPE_URI,
  WIDGET_SCOPE_ID,
  WIDGET_APPLICATION_ID,
} from 'common/constants/spWidget';
import { loadScript } from 'common/utils/common';
import { mountSPWidget } from '../../ducks';
import useStyles from './styles';

const SPWidget = pure(({ onInit }) => {
  const [ref, setRef] = useState(null);
  const dispatch = useDispatch();
  const styles = useStyles();

  const { mounted } = useSelector((store) => store.widget);

  useEffect(() => {
    if (!ref || mounted) return;
    loadScript(WIDGET_CDN, { async: true });
    if (onInit) onInit(ref);
    dispatch(mountSPWidget());
  }, [dispatch, onInit, ref, mounted]);

  return (
    <div className={styles.spWidgetButtonWrapper}>
      <a
        ref={setRef}
        href={WIDGET_HREF}
        data-spwidget-autobind
        data-spwidget-scope-global
        data-spwidget-scope-uri={WIDGET_SCOPE_URI}
        data-spwidget-scope-id={WIDGET_SCOPE_ID}
        data-spwidget-application-id={WIDGET_APPLICATION_ID}
      >
        Text
      </a>
    </div>
  );
});

export default SPWidget;
