import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  markButton: {
    height: 20,
    width: 20,
    '&:not(:last-child)': {
      marginRight: 10,
    },
  },
  markIcon: {
    color: '#9b9b9b',
    fontSize: 18,
  },
}));
