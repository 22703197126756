import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  clientsCountLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: 10,
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.73rem',
    color: '#8B94AC',
  },
  td: {
    fontWeight: 400,
    fontSize: '0.85rem',
    color: 'black',
    padding: '12px 0px 12px 0px',
  },
  alignRight: {
    textAlign: 'right',
  },
  tdRightContent: {
    fontSize: '0.6rem',
    fontWeight: 500,
    padding: '2px 5px',
    borderRadius: 3,
  },
});
