import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import routes from 'common/constants/routes';
import { TOCBlogCard } from 'common/components';
import { Wave, TOCButton } from 'common/components/simple';

import useStyles from './styles';

const BlogSection = () => {
  const styles = useStyles();

  const posts = useSelector((store) => store.home.posts.list);

  return (
    <>
      <Wave animated color="waveSecondaryColor" />

      <Grid container className={styles.container}>
        <Container>
          <Grid className={styles.wrapper}>
            <Grid container className={styles.blogSectionHeader}>
              <Typography
                variant="h3"
                component="h2"
                className={styles.blogSectionTitle}
              >
                Articles and helpful tips from our blog
              </Typography>
              <TOCButton
                size="large"
                width={165}
                height={54}
                to={routes.blog.url}
                className={styles.blogSectionButton}
              >
                Visit the blog
              </TOCButton>
            </Grid>

            <Grid container className={styles.postsGrid}>
              {posts.map((post) => (
                <Grid
                  key={post.id}
                  item
                  xs={12}
                  md={4}
                  container
                  className={styles.postGrid}
                >
                  <TOCBlogCard post={post} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Wave invert animated color="waveSecondaryColor" />
    </>
  );
};

export default BlogSection;
