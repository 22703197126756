import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';

import AdminLayout from 'common/components/admin/Layout';
import Button from 'common/components/admin/Button';
import DateInput from 'common/components/admin/DateInput';
import IconButton from 'common/components/admin/IconButton';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import SelectInput from 'common/components/admin/SelectInput';
import SortIcon from 'assets/images/icons/sort';
import CreditCardModal from '../CreditCardModal';

import useStyles from './styles';

import { clientsValues, paymentsData, typesValues } from './cardPaymentsData';

const columnNames = [
  { label: 'Date', isSorted: true, style: { width: '10%' } },
  { label: 'Client', isSorted: true, style: { width: '20%' } },
  { label: 'Type', isSorted: true, style: { width: '12%' } },
  { label: 'Net', isSorted: true, isDecrypted: true, style: { width: '12%' } },
  {
    label: 'Amount',
    isSorted: true,
    isDecrypted: true,
    style: { width: '17%' },
  },
  { label: 'Fee', isSorted: true, style: { width: '10%' } },
  { label: 'Actions', style: { width: '18%' } },
];

const rows = paymentsData.map((item) => {
  const { paymentDate } = item;
  const tempDate = new Date(paymentDate);
  const month = tempDate.getMonth() + 1;
  const day = tempDate.getDate();
  const parsedMonth = month < 10 ? `0${month}` : month;
  const parsedDay = day < 10 ? `0${day}` : day;
  const parsedDate = `${parsedMonth}/${parsedDay}/${tempDate.getFullYear()}`;
  return {
    ...item,
    date: parsedDate,
  };
});

const Row = (props) => {
  const { row, handleOpenModal } = props;
  const classes = useStyles();
  const rowStyle = row.type === 'Payout' ? classes.boldFont : classes.td;
  return (
    <>
      <TableRow key={row.id}>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.date}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.boldFont}>{row.client}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={rowStyle}>{row.type}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={rowStyle}>${row.net}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={rowStyle}>${row.amount}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={[classes.td, classes.lightGrey].join(' ')}>
            ${row.fee}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButtonsGroup>
            <IconButton
              onClick={handleOpenModal}
              IconComponent={VisibilityIcon}
              iconProps={{
                style: {
                  color: '#143A56',
                },
              }}
            />
            <Button
              bgColor="#EAF0F4"
              className={classes.actionButton}
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                letterSpacing: -0.3,
              }}
            >
              Refund
            </Button>
          </IconButtonsGroup>
        </TableCell>
      </TableRow>
    </>
  );
};

const CreditCardPayments = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const actionButtonsRow = [
    <Button
      key={0}
      startIcon={<Icon className="fas fa-file-export" />}
      bgColor="#143A56"
      className={[classes.topButton, classes.topButtonIcon].join(' ')}
    >
      Export
    </Button>,
    <Button
      key={1}
      startIcon={<AddIcon />}
      bgColor="#40ceba"
      className={classes.topButton}
    >
      Apply payment
    </Button>,
  ];
  return (
    <AdminLayout
      subTitle="Billing"
      title="Credit card payments"
      actionButtons={actionButtonsRow}
    >
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          component={Box}
          py={4}
          px={2}
        >
          <Grid
            item
            container
            component={Box}
            flex={1}
            className={classes.countLabel}
          >
            <Typography component="span">Showing</Typography>
            <Typography style={{ fontWeight: 700 }}>&nbsp;75&nbsp;</Typography>
            <Typography>payments</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            component={Box}
            flex={4}
            justifyContent="flex-end"
          >
            <Grid item component={Box}>
              <DateInput />
            </Grid>
            <Grid item component={Box}>
              <SelectInput
                placeholder="All clients"
                valueOptions={clientsValues}
              />
            </Grid>
            <Grid item component={Box}>
              <SelectInput placeholder="All types" valueOptions={typesValues} />
            </Grid>
            <Grid item component={Box}>
              <Button className={classes.resetButton} variant="outlined">
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" component={Box}>
          <TableContainer className={classes.table}>
            <Table size="small" style={{ tableLayout: 'fixed' }}>
              <TableHead style={{ backgroundColor: '#F5F8FA' }}>
                <TableRow>
                  {columnNames.map((col) => {
                    return (
                      <TableCell
                        key={col.label}
                        style={col.style}
                        className={classes.thGrid}
                      >
                        <Grid
                          component={Box}
                          display="flex"
                          container
                          alignItems="center"
                        >
                          <Tooltip title={col.label} arrow>
                            <Typography className={classes.th}>
                              {col.label}
                            </Typography>
                          </Tooltip>
                          {col.isDecrypted && (
                            <Icon
                              className={[
                                'fas fa-question-circle',
                                classes.queryIcon,
                              ].join(' ')}
                            />
                          )}
                          {col.isSorted && (
                            <SvgIcon
                              component={Box}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {SortIcon}
                            </SvgIcon>
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Row key={row.id} row={row} handleOpenModal={handleOpen} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <CreditCardModal handleCloseModal={handleClose} data={rows[1]} />
      </Modal>
    </AdminLayout>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    amount: PropTypes.number,
    cardExpires: PropTypes.string,
    cardNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    client: PropTypes.string,
    date: PropTypes.string,
    fee: PropTypes.number,
    id: PropTypes.number,
    net: PropTypes.number,
    paymentDate: PropTypes.string,
    paymentId: PropTypes.string,
    statementDescriptor: PropTypes.string,
    type: PropTypes.string,
  }),
  handleOpenModal: PropTypes.func,
};

export default CreditCardPayments;
