import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '0.86rem',
    color: '#6f8999',
    fontWeight: 600,
  },
}));

const AdminHeader = () => {
  const classes = useStyles();
  return (
    <header>
      <Grid
        container
        component={Box}
        py={1}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.text}>
            Administrator account
          </Typography>
        </Grid>
        <Grid item component={Box} display="flex" alignItems="center">
          <Grid item>
            <IconButton>
              <NotificationsIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <EmailIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>Jane Jones</Typography>
          </Grid>
          <Grid item component={Box} px={1}>
            <FaceIcon fontSize="large" />
          </Grid>
        </Grid>
      </Grid>
    </header>
  );
};

export default AdminHeader;
