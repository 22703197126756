import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  blogContainer: {
    color: palette.primary.main,
    marginBottom: spacing(12),

    [breakpoints.down('md')]: {
      marginBottom: spacing(10),
    },
    [breakpoints.down('sm')]: {
      marginBottom: spacing(6),
    },
  },
  filterTopicSection: {
    flexWrap: 'nowrap',
    marginTop: spacing(12),

    [breakpoints.down('md')]: {
      marginTop: spacing(10),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginTop: spacing(8),
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: spacing(4.75),
    },
  },
  filterTitleGrid: {
    flexShrink: 0,
  },
  filterTopicChipsGrid: {
    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  filterDivider: {
    margin: spacing(10, 0),

    [breakpoints.down('md')]: {
      margin: spacing(8, 0),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(5, 0),
    },
  },
  filterTopicTitle: {
    color: `${palette.primary.main}50`,

    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  filterTopicChip: {
    margin: spacing(0, 0.625, 1, 0.625),
  },
  blogPaginationContainer: {
    marginTop: spacing(7.5),

    [breakpoints.down('sm')]: {
      marginTop: spacing(6),
    },
  },
  // Grid container's spacing is out of border Container's paddings (16px) on xs screen
  // add appropriate padding to post grid
  postsGrid: {
    width: 'calc(100% + 40px)',
    margin: '-20px',

    [breakpoints.down('xs')]: {
      width: 'calc(100% + 32px)',
      margin: '-16px',
    },
  },
  postGrid: {
    padding: 20,

    [breakpoints.down('xs')]: {
      padding: 16,
    },
  },
}));
