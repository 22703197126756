import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import routes from 'common/constants/routes';
import { TOCAccordion } from 'common/components';
import useStyles from './styles';

const Privacy = () => (
  <>
    <TOCAccordion title="What about confidentiality between counselors if I choose another one?">
      <Typography variant="body1">
        Information is not shared between counselors without consent. When you
        book an appointment with a new counselor, you will be asked if you wish
        to share the initial intake questionnaire or if you wish to complete a
        new one. Otherwise, all session information will remain confidential.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What is your privacy policy?">
      <Typography variant="body1">
        To learn how we use information, please view our privacy policy
        <Link
          to={routes.privacyPolicy.url}
          className={useStyles().faqPrivacyLink}
        >
          here
        </Link>
        .
      </Typography>
    </TOCAccordion>
  </>
);

export default Privacy;
