import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import StatisticsLayout from '../components/StatisticsLayout';
import ToggleButtonGroup from './components/ToggleButtonGroup';

const CustomSelect = () => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(1);
  return (
    <Select
      classes={{
        select: classes.select,
      }}
      className={classes.selectRoot}
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      value={selectValue}
      onChange={(e) => {
        setSelectValue(e.target.value);
      }}
      variant="outlined"
    >
      <MenuItem className={classes.select} value={1}>
        Custom
      </MenuItem>
      <MenuItem className={classes.select} value={2}>
        Default
      </MenuItem>
    </Select>
  );
};

const GraphStatistics = ({ headerTitle, children, link, ...rest }) => {
  const classes = useStyles();

  const header = (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Typography className={classes.title}>{headerTitle}</Typography>
      </Grid>
      <Grid item>
        <CustomSelect />
        <ToggleButtonGroup />
      </Grid>
    </Grid>
  );
  return (
    <StatisticsLayout header={header} link={link} {...rest}>
      {children}
    </StatisticsLayout>
  );
};

GraphStatistics.propTypes = {
  headerTitle: PropTypes.string,
  children: PropTypes.node,
  link: PropTypes.string,
};

export { CustomSelect, ToggleButtonGroup };

export default GraphStatistics;
