import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

const StatusRow = ({ mainColor, title = 'No title', onClick }) => {
  const classes = useStyles();
  const defaultMainStyles = {
    backgroundColor: '#eaf0f3',
  };
  if (mainColor) defaultMainStyles.backgroundColor = `${mainColor}55`;
  return (
    <Box
      direction="row"
      alignItems="center"
      className={classes.container}
      style={{ ...defaultMainStyles }}
      onClick={onClick}
    >
      <Icon
        className="fas fa-circle"
        style={{ fontSize: 10, color: mainColor }}
      />
      <Typography component="span" className={classes.title}>
        {title}
      </Typography>
      <Icon
        className="fas fa-chevron-down"
        style={{ fontSize: 10, color: '#143A56' }}
      />
    </Box>
  );
};

StatusRow.propTypes = {
  mainColor: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default StatusRow;
