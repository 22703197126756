import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    backgroundColor: '#ffff',
    borderRadius: 5,
  },
  footerTitle: {
    fontSize: 9,
  },
  footerValue: {
    fontSize: 14,
    fontWeight: 600,
  },
});
