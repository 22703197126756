import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useStyles from './styles';

const Placeholder = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const SelectInput = ({ valueOptions, placeholder }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Select
      className={classes.formControl}
      displayEmpty
      value={value}
      size="small"
      variant="outlined"
      onChange={handleChange}
      InputProps={{
        classes: { outline: classes.input },
      }}
      renderValue={
        value !== ''
          ? undefined
          : () => (
              // eslint-disable-next-line react/jsx-indent
              <Placeholder className={classes.placeholder}>
                {placeholder}
              </Placeholder>
            )
      }
    >
      {valueOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

Placeholder.propTypes = {
  children: PropTypes.node,
};

SelectInput.propTypes = {
  valueOptions: PropTypes.shape(),
  placeholder: PropTypes.string,
};

export default SelectInput;
