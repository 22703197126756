import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import IconButton from 'common/components/admin/IconButton';
import FormInput from 'common/components/admin/formControls/FormInput';
import DateInput from 'common/components/admin/formControls/DateInput';
import Checkbox from 'common/components/admin/formControls/Checkbox';
import RadioGroup from 'common/components/admin/formControls/RadioGroup';
import FormContainer from 'common/components/admin/formControls/FormContainer';

import states from './statesList';

import useStyles from './styles';

const emailTypes = [
  { label: 'Work', value: 'Work' },
  { label: 'Home', value: 'Home' },
];

const controlWidth = 220;

const ClientInfo = () => {
  const classes = useStyles();
  return (
    <FormContainer>
      <Grid container>
        <Grid item component={Box} width={controlWidth - 16}>
          <FormInput label="MRN" disabled />
        </Grid>
      </Grid>

      <Grid container component={Box} py={1}>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="First Name" required />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Middle Name" />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Last Name" required />
        </Grid>
        <Grid item component={Box} width={controlWidth}>
          <FormInput
            label="Suffix"
            options={[
              { value: 'mr', label: 'Mr' },
              { value: 'ms', label: 'Ms' },
              { value: 'mrs', label: 'Mrs' },
            ]}
          />
        </Grid>
      </Grid>

      <Box py={1}>
        <FormInput label="Preferred name" />
        <Typography
          style={{ fontSize: '0.8rem', color: '#bec5cb', fontWeight: 500 }}
        >
          Used in place of first name, as well as in client communication
          (reminders, billing documents etc.)
        </Typography>
      </Box>

      <Box py={1}>
        <DateInput label="Date of birth" />
      </Box>

      <Checkbox label="Client a minor" />

      <Box py={3}>
        <Divider />
      </Box>

      <Box>
        <Typography className={classes.sectionTitle}>Address</Typography>
        <Grid container>
          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="Street" defaultValue="Main Street" />
          </Grid>

          <Grid item component={Box} pr={2} width={controlWidth}>
            <FormInput label="City" defaultValue="Washington" />
          </Grid>

          <Grid item component={Box} pr={2} className={classes.shortItem}>
            <FormInput label="State" options={states} defaultValue="Alaska" />
          </Grid>

          <Grid item component={Box} pr={2} className={classes.shortItem}>
            <FormInput label="Zip" />
          </Grid>

          <Grid item component={Box} pt={2.8}>
            <IconButtonsGroup>
              <IconButton
                IconComponent={DeleteIcon}
                iconProps={{
                  style: {
                    color: '#ed7271',
                  },
                }}
              />

              <IconButton
                IconComponent={AddIcon}
                style={{ backgroundColor: 'black' }}
                iconProps={{
                  style: {
                    color: '#ffff',
                  },
                }}
              />
            </IconButtonsGroup>
          </Grid>
        </Grid>
      </Box>

      <Box py={3}>
        <Divider />
      </Box>

      <Box>
        <Typography className={classes.sectionTitle}>
          Contact Details
        </Typography>
        <Grid container>
          <Grid item component={Box} pr={2} className={classes.longItem}>
            <FormInput label="Email Address" style={{ maxWidth: 'unset' }} />
          </Grid>

          <Grid item component={Box} pr={2} className={classes.shortItem}>
            <FormInput
              label="Email Type"
              options={emailTypes}
              style={{ width: '150px' }}
            />
          </Grid>

          <Grid item component={Box} pt={2.8}>
            <IconButtonsGroup>
              <IconButton
                IconComponent={AddIcon}
                style={{ backgroundColor: 'black' }}
                iconProps={{
                  style: {
                    color: '#ffff',
                  },
                }}
              />
            </IconButtonsGroup>
          </Grid>
        </Grid>

        <Grid container component={Box} py={2}>
          <Grid item component={Box} pr={2} className={classes.longItem}>
            <FormInput label="Phone Number" style={{ maxWidth: 'unset' }} />
          </Grid>

          <Grid item component={Box} pr={2} className={classes.shortItem}>
            <FormInput
              label="Phone Type"
              options={emailTypes}
              style={{ width: '150px' }}
            />
          </Grid>

          <Grid item component={Box} pt={2.8}>
            <IconButtonsGroup>
              <IconButton
                IconComponent={AddIcon}
                style={{ backgroundColor: 'black' }}
                iconProps={{
                  style: {
                    color: '#ffff',
                  },
                }}
              />
            </IconButtonsGroup>
          </Grid>
        </Grid>
      </Box>

      <Grid container>
        <Grid item component={Box} style={{ marginRight: 24 }}>
          <RadioGroup
            label="Appointment Reminders"
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'yes', label: 'Yes' },
            ]}
          />
        </Grid>

        <Grid item>
          <Typography style={{ fontSize: '0.85rem', fontWeight: 600 }}>
            Remind Via
          </Typography>
          <Grid container>
            <Grid item>
              <Checkbox label="Text" />
            </Grid>
            <Grid item>
              <Checkbox label="Email" />
            </Grid>
            <Grid item>
              <Checkbox label="Voice" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default ClientInfo;
