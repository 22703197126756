import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  listContainer: {
    borderRadius: 15,
    padding: spacing(1, 3.75),
    border: `1px solid ${palette.text.primary}30`,
    marginBottom: spacing(2.5),
    boxShadow: 'none',
    transition: 'padding 200ms',
    '&.Mui-expanded': {
      marginTop: 0,
      padding: spacing(1, 3.75, 2),
      marginBottom: spacing(2.5),
      borderRadius: 15,
    },
    '&:before': {
      display: 'none',
    },
  },
  listSummary: {
    padding: 0,
    '&.Mui-expanded': {
      margin: spacing(0),
      minHeight: 48,
    },
  },
  listSummaryContent: {
    '&.Mui-expanded': {
      margin: spacing(1.5, 0),
    },
  },
  listSummaryIcon: {
    color: palette.primary.main,
  },
  subTitle: {
    marginLeft: spacing(1),
    color: palette.text.primary,
  },
  listDetails: {
    padding: spacing(2, 0, 0),
    position: 'relative',
  },
  rangeRail: {
    color: palette.text.primary,
  },
  rangeMinPrice: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: '0.95rem',
  },
  rangeMaxPrice: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '0.95rem',
  },
  rangeSlider: {
    marginTop: spacing(2),
  },
}));
