import React from 'react';
import AdminLayout from 'common/components/admin/Layout';
import Tabs from 'common/components/admin/Tabs';

import { ClientInfo, AboutClient, BillingClients } from './components/index';

const EditClient = () => {
  return (
    <AdminLayout title="Update Client">
      <Tabs
        pages={{
          clientInfo: {
            title: 'Client Info',
            page: ClientInfo,
          },
          about: {
            title: 'About Client',
            page: AboutClient,
          },
          billing: {
            title: 'Billing & Clients',
            page: BillingClients,
          },
        }}
      />
    </AdminLayout>
  );
};

export default EditClient;
