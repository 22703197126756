import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './styles';

const monthOptions = [
  { value: 'Jan', label: 'January' },
  { value: 'Feb', label: 'February' },
  { value: 'Mar', label: 'March' },
  { value: 'Apr', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'Jun', label: 'June' },
  { value: 'Jul', label: 'July' },
  { value: 'Aug', label: 'August' },
  { value: 'Sep', label: 'September' },
  { value: 'Oct', label: 'October' },
  { value: 'Nov', label: 'November' },
  { value: 'Dec', label: 'December' },
];

const getNumberOfDays = (month, year) => {
  switch (month) {
    case 'Apr':
    case 'Jun':
    case 'Sep':
    case 'Nov':
      return 30;
    case 'Feb':
      return year % 4 === 0 ? 29 : 28;
    default:
      return 31;
  }
};

const years = Array(120)
  .fill(0)
  .map((_, idx) => new Date().getFullYear() - idx);

const DateInput = ({ label, style = {} }) => {
  const classes = useStyles();
  const [month, setMonth] = useState(monthOptions[new Date().getMonth()].value);
  const [year, setYear] = useState(new Date().getFullYear());

  const dates = new Array(getNumberOfDays(month, year))
    .fill(0)
    .map((_, idx) => idx);

  const [date, setDate] = useState(new Date().getDate());

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const handleMonth = (e) => {
    setMonth(e.target.value);
  };

  const handleYear = (e) => {
    setYear(e.target.year);
  };

  return (
    <FormControl style={style}>
      <Grid container direction="column">
        <Grid item component={InputLabel} className={classes.label} shrink>
          {label}
        </Grid>

        <Grid item component={Box} display="flex">
          <Select
            className={classes.select}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={month}
            onChange={handleMonth}
            input={
              <OutlinedInput
                classes={{
                  root: [classes.inputRoot, classes.monthInput].join(' '),
                  disabled: classes.inputDisabled,
                  input: classes.input,
                }}
              />
            }
          >
            {monthOptions.map(({ value, label: optLabel }) => (
              <MenuItem key={value} className={classes.menuItem} value={value}>
                {optLabel}
              </MenuItem>
            ))}
          </Select>

          <Select
            className={classes.select}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={date}
            onChange={handleDate}
            input={
              <OutlinedInput
                classes={{
                  root: [classes.inputRoot].join(' '),
                  disabled: classes.inputDisabled,
                  input: classes.input,
                }}
              />
            }
          >
            {dates.map((_date) => (
              <MenuItem key={_date} className={classes.menuItem} value={_date}>
                {_date}
              </MenuItem>
            ))}
          </Select>

          <Select
            className={classes.select}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={year}
            onChange={handleYear}
            input={
              <OutlinedInput
                classes={{
                  root: [classes.inputRoot, classes.yearInput].join(' '),
                  disabled: classes.inputDisabled,
                  input: classes.input,
                }}
              />
            }
          >
            {years.map((_year) => (
              <MenuItem key={_year} className={classes.menuItem} value={_year}>
                {_year}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
};

export default DateInput;
