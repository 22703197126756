import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    color: '#b0b8c1',
    backgroundColor: '#e4eaf0',
    fontWeight: 600,
    minWidth: 'unset',
    paddingLeft: 10,
    paddingRight: 10,
    textTransform: 'capitalize',
    fontSize: '0.7rem',
  },
  selected: {
    color: '#ffff',
    backgroundColor: '#093b54',
    boxShadow: '0px 2px 9px #113148',
  },
}));
