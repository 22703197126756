import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

const styles = {
  root: {
    fontFamily: 'consolas, sans-serif',
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
};

const MyResponsiveBar = forwardRef(({ data, keys }, ref) => {
  const rootRef = useRef();
  useImperativeHandle(ref, () => ({
    refresh(delay) {
      rootRef.current.style.display = 'none';
      setTimeout(() => {
        rootRef.current.style.display = 'block';
      }, delay || 1);
    },
  }));
  return (
    <div style={styles.root} ref={rootRef}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="country"
        margin={{ top: 10, right: 0, bottom: 60, left: 30 }}
        padding={0.75}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'paired' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: { id: 'fries' },
            id: 'dots',
          },
          {
            match: { id: 'sandwich' },
            id: 'lines',
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        borderRadius={2}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={19}
        labelSkipHeight={10}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateY: 60,
            itemsSpacing: 10,
            itemWidth: 200,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 5,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
        colors={({ id, data: _data }) => _data[`${id}Color`]}
      />
    </div>
  );
});

MyResponsiveBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  keys: PropTypes.arrayOf(PropTypes.string),
};

export default MyResponsiveBar;
