import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { Header, Footer } from 'common/components';
import ArticleContent from './components/ArticleContent';
import { getArticle } from './ducks';

const BlogArticle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticle(id));
  }, [dispatch, id]);

  return (
    <>
      <Header height={{ xs: '100px', lg: '350px' }} />
      <ArticleContent />
      <Footer />
    </>
  );
};

export default BlogArticle;
