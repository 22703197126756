import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  input: {
    color: '#143A56',
    fontSize: '1.1rem',
    minWidth: 'unset',
  },
  dateRow: {
    minWidth: '40%',
  },
  container: {
    backgroundColor: '#E4EAF0',
    borderRadius: 10,
  },
});
