import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TwitterIcon from '@material-ui/icons/Twitter';

import routes from 'common/constants/routes';
import { TOCIconButton } from 'common/components/simple';
import {
  twitterLink,
  facebookLink,
  instagramLink,
} from 'common/constants/common';
import Logo from 'assets/images/logo&slogan-min.png';
import wave from 'assets/images/wave-min.png';
import useStyles from './styles';

const Footer = () => {
  const styles = useStyles();

  return (
    <>
      <img
        src={wave}
        width="100%"
        style={{ marginBottom: '-2px' }}
        alt="bottom wave"
      />
      <Grid className={styles.footerWrap}>
        <Container>
          <Grid container className={styles.footerContentWrap}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={styles.logoWrap}
            >
              <Grid item xs={12} sm={4} className={styles.logoWrapper}>
                <img src={Logo} alt="logo" className={styles.logo} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                container
                className={styles.socialLinks}
              >
                <Box display="flex" justifyContent="space-between" width={200}>
                  <TOCIconButton
                    light
                    size="small"
                    iconSize="small"
                    color="white"
                    bgColor="primary"
                    iconName="facebook-f"
                    to={facebookLink}
                  />

                  <TOCIconButton
                    light
                    size="small"
                    iconSize="small"
                    color="white"
                    bgColor="primary"
                    icon={TwitterIcon}
                    to={twitterLink}
                  />

                  <TOCIconButton
                    light
                    size="small"
                    iconSize="small"
                    color="white"
                    bgColor="primary"
                    iconName="instagram"
                    to={instagramLink}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={styles.footerBottom}
            >
              <Grid item xs={12} md={6} container className={styles.copyright}>
                <Typography variant="subtitle2" className={styles.rightsText}>
                  © 2020 Text and Online Counseling. All rights reserved.
                </Typography>

                <Grid item container className={styles.policy}>
                  <Link
                    to={routes.privacyPolicy.url}
                    className={styles.footerLink}
                  >
                    <Typography variant="subtitle2">Privacy Policy</Typography>
                  </Link>

                  <Grid className={styles.dot} />

                  <Link
                    to={routes.termsConditions.url}
                    className={styles.footerLink}
                  >
                    <Typography variant="subtitle2">
                      Terms & Conditions
                    </Typography>
                  </Link>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} container className={styles.menu}>
                <Grid
                  component={Link}
                  to={routes.home.url}
                  className={styles.link}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    component="span"
                  >
                    <Box fontWeight="700" component="span">
                      Home
                    </Box>
                  </Typography>
                </Grid>

                <Link to={routes.about.url} className={styles.link}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    component="span"
                  >
                    <Box fontWeight="700" component="span">
                      About
                    </Box>
                  </Typography>
                </Link>

                <Link to={routes.faq.url} className={styles.link}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    component="span"
                  >
                    <Box fontWeight="700" component="span">
                      FAQ
                    </Box>
                  </Typography>
                </Link>

                <Link to={routes.joinUs.url} className={styles.link}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    component="span"
                  >
                    <Box fontWeight="700" component="span">
                      Join Our Group
                    </Box>
                  </Typography>
                </Link>

                <Link to={routes.contact.url} className={styles.link}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    component="span"
                  >
                    <Box fontWeight="700" component="span">
                      Contact
                    </Box>
                  </Typography>
                </Link>

                <Link to={routes.blog.url} className={styles.link}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    component="span"
                  >
                    <Box fontWeight="700" component="span">
                      Blog
                    </Box>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Footer;
