import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    backgroundColor: '#ffff',
    borderRadius: 30,
    padding: '0.5rem',
    paddingTop: 0,
    paddingBottom: 3,
    display: 'inline',
  },
  title: {
    color: '143A56',
    letterSpacing: -0.35,
    fontSize: '0.8rem',
    paddingRight: '0.3rem',
    paddingLeft: '0.3rem',
  },
}));
