export const getTwoDigitsValue = (value) => (value < 10 ? `0${value}` : value);

export const convertTime = ({ hours, minutes }) => {
  let timePrefix = 'am';
  let parsedHours = hours;
  if (hours >= 12) {
    parsedHours = hours - 12;
    timePrefix = 'pm';
  }
  if (hours === 0) {
    parsedHours = 12;
  }
  const viewMinutes = getTwoDigitsValue(minutes);
  const viewHours = getTwoDigitsValue(parsedHours);
  return `${viewHours}:${viewMinutes}${timePrefix}`;
};
