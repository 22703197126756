import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  profileContainer: {
    marginTop: spacing(20),
    marginBottom: spacing(10),
    color: palette.primary.main,

    [breakpoints.down('sm')]: {
      marginTop: spacing(6),
      marginBottom: spacing(6),
    },
  },
  backLinkContainer: {
    marginBottom: spacing(2.5),
  },

  bookingSection: {
    width: '25%',

    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  profileSection: {
    width: '71%',

    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
