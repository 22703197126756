const insurancePayers = [
  'Blue Cross of New Jersey Horizon',
  'AETNA',
  'Horizon Blue Cross Blue Shield of New Jersey',
];
const paymentStatuses = ['paid', 'denied', 'accepted', 'deductible'];
const paymentDescriptions = [
  'Accepted for processing.',
  'Claim has been forwarded to entity.',
  'Pending/Patient Requested Information - The claim or encounter is waiting for information that has already been requested from the patient. (Note: A status code identifying the type of information requested must be sent) (P4) Investigating existence of other insurance coverage.PENDING, PATIENT REQUESTED INFORMATION:INVESTIGATING EXISTENCE OF OTHER INSURANCE COVERAGE. (52)',
];
const dates = ['05.07.2021', '03.07.2021', '07.07.2021'];

const appointments = [
  {
    id: 'a1',
    date: '01.07.2021',
    name: '90837 Psychotherapy, 60 min',
    provider: 'Paula Brown',
    charged: 150,
  },
  {
    id: 'a2',
    date: '28.06.2021',
    name: '90847 Family or couples therapy',
    provider: 'Paula Brown',
    charged: 120,
  },
];

export const billedData = new Array(20).fill(0).map((_, id) => ({
  id,
  fullName: 'Jonathan Wright',
  insurancePayer: insurancePayers[id % 3],
  paymentStatus: paymentStatuses[id % 4],
  paymentDescription: paymentDescriptions[id % 3],
  daysSubmitted: 7,
  cost: 150,
  dateBilled: new Date(dates[id % 3]).toLocaleDateString(),
}));

export const unbilledData = new Array(20).fill(0).map((_, id) => ({
  id,
  fullName: 'Jonathan Wright',
  insurancePayer: insurancePayers[id % 3],
  appointments,
}));

export const paidData = new Array(20).fill(0).map((_, id) => ({
  id,
  transactionDate: dates[id % 3],
  insurancePayer: insurancePayers[id % 3],
  paymentMethod: 'Wire 0016926245',
  paid: 100,
  unallocated: 100,
}));

export const clientsOptionValues = [
  {
    value: 'client1',
    label: 'Client 1',
  },
  {
    value: 'client2',
    label: 'Client 2',
  },
];

export const insuranceOptionValues = [
  {
    value: 'insuranceType1',
    label: 'InsuranceType 1',
  },
  {
    value: 'insuranceType2',
    label: 'InsuranceType 2',
  },
];

export const payersOptionValues = [
  {
    value: 'payer1',
    label: 'Payer 1',
  },
  {
    value: 'payer2',
    label: 'Payer 2',
  },
];

export const sortOptionValues = [
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'status',
    label: 'Status',
  },
];
