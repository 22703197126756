import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import overrides from './overrides';
import typography from './typography';
import breakpoints from './breakpoints';

export default createMuiTheme({
  breakpoints,
  palette,
  overrides,
  typography,
});
