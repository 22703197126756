import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AdminLayout from 'common/components/admin/Layout';
import useStyles from './styles';
import { data } from './reportData';

const Reports = () => {
  const classes = useStyles();

  return (
    <AdminLayout title="Reports">
      <Grid container className={classes.root}>
        {data.map(({ columnName, reports }) => (
          <Grid key={columnName} xs={4} className={classes.reportsContainer}>
            <Typography className={classes.columnTitle}>
              {columnName}
            </Typography>
            {reports.map(({ title, subTitle }) => (
              <Grid key={title} className={classes.reportCard}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subTitle}>{subTitle}</Typography>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </AdminLayout>
  );
};

export default Reports;
