import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { blog } from '../reducers/blogTags';

function* getBlogTagsSagaRequest() {
  yield requestSaga({
    actionType: blog.tags,
    request: dataProvider.getList,
    resource: resources.topic,
  });
}

export default getBlogTagsSagaRequest;
