import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';

import { openLink } from 'common/utils/common';
import useStyles from './styles';

const TOCButton = (props) => {
  const {
    shadow = true,
    size = 'medium',
    color = 'primary',
    width,
    height,
    children,
    disabled = false,
    to,
    linkParams,
    type = 'button',
    onClick = () => {},
    className,
  } = props;
  const styles = useStyles({ width, height, shadow, color });
  const isMediumSize = size === 'medium';

  const handleClick = (ev) => {
    if (!to) {
      onClick();
      return;
    }

    if (to.includes('http')) {
      ev.preventDefault();
      openLink(to);
    }
  };

  return (
    <Button
      size={size}
      type={type}
      color={color}
      variant="contained"
      disabled={disabled}
      onClick={handleClick}
      component={to ? Link : 'button'}
      to={{
        pathname: to,
        state: {
          ...linkParams,
        },
      }}
      classes={{
        root: styles.sizeMedium,
        sizeLarge: styles.sizeLarge,
        containedSecondary: styles.containedSecondary,
      }}
      className={className}
    >
      <Typography
        variant={isMediumSize ? 'subtitle2' : 'subtitle1'}
        className={styles.buttonText}
        component="span"
      >
        {children}
      </Typography>
    </Button>
  );
};

TOCButton.propTypes = {
  shadow: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['reset', 'submit', 'button']),
  to: PropTypes.string,
  linkParams: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
};

export default TOCButton;
