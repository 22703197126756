import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './styles';

const InfoCard = ({ title, link, LinkIcon, children, ...restProps }) => {
  const classes = useStyles();
  const { title: linkTitle, url: linkUrl } = link || {};
  return (
    <Grid
      container
      direction="column"
      component={Box}
      borderRadius={10}
      py={2}
      px={3}
      className={classes.root}
      {...restProps}
    >
      <Grid
        item
        container
        component={Box}
        justify="space-between"
        alignItems="center"
        pb={3}
      >
        <Grid
          item
          component={Typography}
          variant="subtitle2"
          style={{ fontSize: '1rem' }}
        >
          {title}
        </Grid>
        {linkTitle && (
          <Grid item>
            <MenuItem url={linkUrl} style={{ paddingLeft: 4, paddingRight: 4 }}>
              <Typography
                variant="h5"
                style={{ fontSize: '0.8rem', color: '#40ceba' }}
              >
                {linkTitle}
              </Typography>
              <LinkIcon style={{ height: 12, width: 18, color: '#6f8999' }} />
            </MenuItem>
          </Grid>
        )}
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string,
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  LinkIcon: PropTypes.node,
  children: PropTypes.node,
};

export default InfoCard;
