import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography }) => ({
  listContainer: {
    borderRadius: 15,
    padding: spacing(1, 3.75),
    border: `1px solid ${palette.text.primary}30`,
    marginBottom: spacing(2.5),
    boxShadow: 'none',
    transition: 'padding 200ms',
    '&.Mui-expanded': {
      marginTop: 0,
      padding: spacing(1, 3.75, 2),
      marginBottom: spacing(2.5),
      borderRadius: 15,
    },
    '&:before': {
      display: 'none',
    },
  },
  listSummary: {
    padding: 0,
    '&.Mui-expanded': {
      margin: spacing(0),
      minHeight: 48,
    },
  },
  listSummaryContent: {
    '&.Mui-expanded': {
      margin: spacing(1.5, 0),
    },
  },
  listSummaryIcon: {
    color: palette.primary.main,
  },
  listDetails: {
    padding: 0,
  },
  radioGroupRow: {
    marginTop: spacing(1.875),
    flexDirection: 'row',
  },
  checkLabel: {
    margin: spacing(0.6, 0),
  },
  checkBase: {
    padding: spacing(0),
    margin: spacing(0, 1.25, 0, 0),
    color: palette.text.primary,
    '& .MuiSvgIcon-root': {
      fontSize: typography.pxToRem(16),
    },
  },
  checked: {
    color: palette.secondary.main,
  },
}));
