import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const search = actionTypesGenerator(['counselors'], 'search');

const initialResultState = {
  counselors: [],
  total: 0,
  loading: false,
  error: false,
};

export default reducerGenerator(
  search.counselors,
  initialResultState,
  (state, payload) => ({
    ...state,
    counselors: payload.data,
    total: payload.total,
  })
);

// note: it's data provider. it requires pagination and sorting for GET request
//  it relates to all GET requests for lists
export const searchCounselors = ({ page = 1, count = 8, ...filters }) => ({
  type: search.counselors.requested,
  payload: {
    pagination: { page, perPage: count },
    sort: {
      field: 'id',
      order: 'ASC',
    },
    filter: {
      ...filters,
    },
  },
});
