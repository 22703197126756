import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import LotusPosition from 'assets/images/lotus_position-min.png';
import OurCounselors from '../OurCounselors';
import CounselingFAQs from '../CounselingFAQs';
import PaymentAndInsurance from '../PaymentAndInsurance';
import Privacy from '../Privacy';
import useStyles from './styles';

const FAQList = () => {
  const styles = useStyles();

  const sections = {
    'Our Counselors': OurCounselors,
    'Counseling FAQs': CounselingFAQs,
    'Payment & Insurance': PaymentAndInsurance,
    Privacy,
  };

  return (
    <Grid className={styles.faqWrapper}>
      <Container>
        <Grid container justify="space-between">
          <Grid item xs={7} container direction="column">
            <Typography variant="h4" className={styles.faqHeaderSubtitle}>
              Frequently asked questions
            </Typography>

            <Typography variant="h1" className={styles.faqHeadertitle}>
              Answers to all the common questions to help you get started
            </Typography>
          </Grid>

          <Grid item xs={5} container alignItems="flex-end">
            <img
              alt="lotus position woman"
              src={LotusPosition}
              className={styles.imgFluide}
            />
          </Grid>
        </Grid>
      </Container>

      <Grid container direction="column">
        <Container>
          <Grid
            item
            container
            justify="space-between"
            className={styles.faqTopicSection}
            spacing={4}
          >
            <Grid item className={styles.faqTopicTitleWrapper}>
              <Typography variant="body2" className={styles.faqTopicTitle}>
                Jump to a topic:
              </Typography>
            </Grid>

            <Grid item className={styles.faqTopicAnchorWrapper}>
              {Object.keys(sections).map((name) => (
                <AnchorLink
                  key={name}
                  offset="50"
                  href={`#${name.toLowerCase()}`}
                  className={styles.faqTopicAnchor}
                >
                  <Chip
                    size="medium"
                    className={styles.faqTopicChip}
                    label={<Typography variant="subtitle1">{name}</Typography>}
                  />
                </AnchorLink>
              ))}
            </Grid>
          </Grid>
        </Container>

        {Object.entries(sections).map(([name, Component]) => (
          <Grid key={name}>
            <Container>
              <Divider className={styles.faqDivider} />

              <Grid
                item
                container
                justify="space-between"
                id={name.toLowerCase()}
                className={styles.faqTopicWrapper}
              >
                <Grid item xs={12} md={4} className={styles.faqTopicHeader}>
                  <Typography variant="h3">{name}</Typography>
                </Grid>

                <Grid item xs={12} md={8} container direction="column">
                  <Component />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FAQList;
