export default [
  {
    id: 'Copay',
    label: 'Copay',
    value: 38,
    color: '#afcd50',
  },
  {
    id: 'No show fees',
    label: 'No show fees',
    value: 26,
    color: '#0d648e',
  },
  {
    id: 'Deductible',
    label: 'Deductible',
    value: 10,
    color: '#40ceba',
  },
  {
    id: 'Insurance Payments',
    label: 'Insurance Payments',
    value: 26,
    color: '#349bcc',
  },
];
