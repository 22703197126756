import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import IconButton from 'common/components/admin/IconButton';

import tableData from './tableData';

const useStyles = makeStyles({
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  clientsCountLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: 10,
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.73rem',
    color: '#8B94AC',
  },
  td: {
    fontWeight: 400,
    fontSize: '0.85rem',
    color: 'black',
  },
  statuses: {
    '& > *:not(:last-child)': {
      marginRight: '0.3rem',
    },
  },
  status: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#e1f3e9',
    padding: 5,
    borderRadius: 5,
  },
  nameCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  actionsCol: {
    width: '20%',
  },
});

const columnNames = ['Name', 'Date addded', 'Status'];

const rows = tableData.map((item) => {
  const { id, name, dateAdded, status } = item;
  return {
    id,
    name,
    dateAdded,
    status,
  };
});

const FormDocumetns = () => {
  const classes = useStyles();
  const renderStatus = (name) => (
    <Grid item component={Box} className={classes.status}>
      <CheckIcon
        style={{
          color: 'green',
          fontSize: '0.9rem',
        }}
      />
      <Typography
        style={{
          fontSize: '0.5rem',
          textTransform: 'uppercase',
          fontWeight: 600,
          marginLeft: 6,
        }}
      >
        {name}
      </Typography>
    </Grid>
  );
  return (
    <TableContainer className={classes.table}>
      <Table size="small" style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columnNames.map((col) => {
              const style = col === 'Name' ? { width: '37%' } : {};
              return (
                <TableCell style={style} key={col} className={classes.thGrid}>
                  <Typography className={classes.th}>{col}</Typography>
                </TableCell>
              );
            })}
            <TableCell
              className={[classes.thGrid, classes.actionsCol].join(' ')}
            >
              <Typography className={classes.th} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell
                component="th"
                scope="row"
                className={classes.td}
                style={{ paddingLeft: 10 }}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    <DescriptionIcon style={{ color: 'grey', width: 33 }} />
                  </Grid>
                  <Grid
                    item
                    component={Typography}
                    style={{ width: 'calc(100% - 33px)' }}
                  >
                    <Tooltip title={row.name} arrow>
                      <Typography
                        className={[classes.td, classes.nameCell].join(' ')}
                      >
                        {row.name}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.td}>{row.dateAdded}</TableCell>
              <TableCell className={classes.td}>
                <Grid container className={classes.statuses}>
                  {row.status.map((statusItem, idx) =>
                    renderStatus(statusItem, idx)
                  )}
                </Grid>
              </TableCell>
              <TableCell className={[classes.td, classes.actionsCol].join(' ')}>
                <IconButtonsGroup justify="flex-end">
                  <IconButton IconComponent={VisibilityIcon} />
                  <IconButton
                    IconComponent={DeleteIcon}
                    iconProps={{
                      style: {
                        color: '#ed7271',
                      },
                    }}
                  />
                </IconButtonsGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormDocumetns;
