import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WIDGET_CDN } from 'common/constants/spWidget';
import SPWidget from './components/SPWidget';
import { destroySPWidget, initSPWidget, closeSPWidget } from './ducks';

const SPWidgetService = () => {
  const dispatch = useDispatch();
  const [ref, setRef] = useState(null);
  const { init, opened } = useSelector((store) => store.widget);

  const clearTrash = () => {
    const parent = document.body;
    const scriptTag = document.querySelector(`script[src='${WIDGET_CDN}']`);
    const preloader = document.querySelector('.spwidget--preload');

    parent?.removeChild(scriptTag);
    parent?.removeChild(preloader);

    dispatch(destroySPWidget());

    setTimeout(() => dispatch(initSPWidget()), 100);
  };

  useEffect(() => {
    dispatch(initSPWidget());
  }, [dispatch]);

  useEffect(() => {
    if (!opened) return;

    if (ref) ref.click();

    // saving native console log method
    const oldConsoleLogMethod = window.console.log.bind(window.console.log);

    const iFrameWrapper = document.querySelector('.spwidget--scroller');
    const handleClose = () => {
      dispatch(closeSPWidget());
      iFrameWrapper.removeEventListener('click', handleClose);

      // returning to native console log method version
      window.console.log = oldConsoleLogMethod;
      setTimeout(() => clearTrash(), 100);
    };

    // method that tracks console outputs to catch widget close event
    const newConsoleLogMethod = (message) => {
      oldConsoleLogMethod(message);
      const isCloseMessage = message.includes(`"action":"close"`);
      if (isCloseMessage) {
        handleClose();
      }
    };

    iFrameWrapper.addEventListener('click', handleClose);
    window.console.log = newConsoleLogMethod;
    /* eslint react-hooks/exhaustive-deps: 0 */
  }, [dispatch, ref, opened]);

  return init ? <SPWidget onInit={setRef} /> : <div />;
};

export default SPWidgetService;
