import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ManSmile from 'assets/images/man_smile-min.png';
import { Circle } from 'common/components/simple';
import useStyles from './styles';

const OurSpecialities = () => {
  const styles = useStyles();

  return (
    <Box className={styles.rootSection}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4} container className={styles.mainTitleGrid}>
            <Box mb={4}>
              <Typography variant="h2">Our specialities</Typography>
            </Box>

            <Typography variant="body1">
              To best meet your needs, TOC&apos;s have a variety of specialties
              and are skilled and experienced in treating many mental health and
              emotional challenges. However&nbsp;
              <Box fontWeight="700" component="span">
                if you are in a crisis or if you or another is in danger, please
                seek immediate help by call 911 or going to your nearest crisis
                center.
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} lg={8} container className={styles.tilesGrid}>
            <Grid item xs={12} container>
              <Box className={styles.prosSectionTile}>
                <Box mb={1.5}>
                  <Typography variant="h4">Anxiety</Typography>
                </Box>

                <Typography variant="body1" className={styles.tileText}>
                  If your feelings of anxiety are extreme, it is difficult to
                  control your worries or your symptoms are interfering with
                  your daily life over a long period, you may have an anxiety
                  disorder.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} container>
              <Box className={styles.prosSectionTile}>
                <Box mb={1.5}>
                  <Typography variant="h4">Depression</Typography>
                </Box>

                <Typography variant="body1" className={styles.tileText}>
                  While depression is difficult to live with, help is available
                  and with the right treatment you can take steps towards
                  recovering.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} container>
              <Box className={styles.prosSectionTile}>
                <Box mb={1.5}>
                  <Typography variant="h4">Grief</Typography>
                </Box>

                <Typography variant="body1" className={styles.tileText}>
                  Grief is a natural reaction to losing someone we care about.
                  However, if left unprocessed, loss can cause deep emotional
                  scars and mental health problems. Bereavement counselling can
                  help with managing grief and loss.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} container>
              <Box className={styles.prosSectionTile}>
                <Box mb={1.5}>
                  <Typography variant="h4">Anger</Typography>
                </Box>

                <Typography variant="body1" className={styles.tileText}>
                  Like any emotion, anger can be dealt with poorly or allowed to
                  swing out of control. This can include disruptions to your
                  relationships, career, mental and physical health, and ability
                  to achieve goals.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Box className={styles.boxPersonWrapper}>
        <img src={ManSmile} alt="man smiling" />
        <Circle size={12} left="60%" top="1%" color="tertiary" />
        <Circle size={8} left="87%" top="12%" />
        <Circle size={13} left="82%" top="36%" color="secondary" />
        <Circle size={6} left="97%" top="66%" />
        <Circle size={8} left="53%" top="89%" />
      </Box>
    </Box>
  );
};

export default OurSpecialities;
