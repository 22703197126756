import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography, breakpoints }) => ({
  infoSectionDescription: {
    margin: spacing(1.5, 0, 4.5),
    lineHeight: typography.pxToRem(30),
  },
  infoSectionSpecializes: {
    margin: spacing(3, 0, 6),

    [breakpoints.down('sm')]: {
      margin: spacing(3, 0, 4),
    },
  },
  infoSectionSpecialityChip: {
    minHeight: 38,
    borderRadius: 18.5,
    padding: spacing(0, 2),
    marginRight: spacing(1.25),
    marginBottom: spacing(1.25),
    color: palette.primary.main,
    background: `${palette.black}10`,

    [breakpoints.down('xs')]: {
      minHeight: 30,
      padding: spacing(0, 1),
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
  },
  infoSectionCertifications: {
    marginTop: spacing(3.5),
  },
  infoSectionCertification: {
    marginBottom: spacing(3.5),
  },
}));
