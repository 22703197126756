import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  joinUsContainer: {
    position: 'relative',
  },
  prosSectionHeader: {
    margin: spacing(2, 0, 49),

    [breakpoints.down('sm')]: {
      margin: spacing(2, 0, 40),
    },
  },
  prosSectionHint: {
    color: palette.text.primary,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: spacing(0.4),
  },
  prosSectionHeaderName: {
    marginBottom: spacing(1.2),
    lineHeight: 1.2,
    maxWidth: '70%',
  },
  personImage: {
    zIndex: 1,
    position: 'absolute',
    '&:nth-child(1)': {
      top: 130,
      right: '18%',
    },
    '&:nth-child(2)': {
      top: 270,
      left: -20,
    },
    '&:nth-child(3)': {
      top: 350,
      left: '20%',
    },
    '&:nth-child(4)': {
      top: 390,
      right: '27%',
    },
    '&:nth-child(5)': {
      top: 270,
      right: 8,
    },

    '@media (min-width:1925px)': {
      '&:nth-child(1)': {
        top: 130,
        right: 'calc(50% - 690px)',
      },
      '&:nth-child(2)': {
        top: 270,
        left: 'calc(50% - 890px)',
      },
      '&:nth-child(3)': {
        top: 370,
        left: 'calc(50% - 540px)',
      },
      '&:nth-child(4)': {
        top: 390,
        right: 'calc(50% - 570px)',
      },
      '&:nth-child(5)': {
        top: 270,
        right: 'calc(50% - 930px)',
      },
    },
    '@media (max-width:1400px)': {
      '&:nth-child(1)': {
        width: 200,
        top: 80,
        right: '10%',
      },
      '&:nth-child(2)': {
        width: 180,
        top: 310,
      },
      '&:nth-child(5)': {
        width: 240,
        top: 265,
        right: 8,
      },
    },
    [breakpoints.down('md')]: {
      '&:nth-child(1)': {
        width: 150,
      },
      '&:nth-child(3)': {
        width: 130,
        left: 370,
      },
      '&:nth-child(5)': {
        width: 180,
        top: 290,
      },
    },
    [breakpoints.down('sm')]: {
      '&:nth-child(1)': {
        width: 120,
        top: -70,
      },
      '&:nth-child(2)': {
        width: 150,
        top: 250,
      },
      '&:nth-child(3)': {
        width: 110,
        left: '25%',
        top: 240,
      },
      '&:nth-child(4)': {
        width: 80,
        top: 205,
        right: '27%',
      },
      '&:nth-child(5)': {
        width: 150,
        top: 160,
      },
    },
    [breakpoints.down('xs')]: {
      '&:nth-child(1)': {
        width: 90,
        top: -60,
        right: '5%',
      },
      '&:nth-child(2)': {
        width: 100,
        top: 170,
      },
      '&:nth-child(3)': {
        width: 80,
        left: '28%',
        top: 250,
      },
      '&:nth-child(4)': {
        width: 60,
        top: 140,
        right: '35%',
      },
      '&:nth-child(5)': {
        width: 100,
        top: 180,
        right: '5%',
      },
    },
  },
  prosSectionMainContainer: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  prosSectionIntro: {
    marginBottom: spacing(29),
  },
  prosSectionIntroTitle: {
    lineHeight: 1.33,
  },
  prosSectionTilesTitle: {
    position: 'relative',
    marginTop: spacing(29),
    marginBottom: spacing(15),

    [breakpoints.down('md')]: {
      marginTop: spacing(25),
      marginBottom: spacing(8),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(10),
      marginBottom: spacing(6),
    },
  },
  prosSectionTileWrapper: {
    maxWidth: 560,
    width: '100%',

    [breakpoints.down('md')]: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  prosSectionTile: {
    boxSizing: 'border-box',
    minHeight: 165,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.secondary.lighter,
    borderRadius: '0 60px',
    marginBottom: spacing(5),
    padding: spacing(4.5),
    boxShadow: `0 6px 10px 5px ${palette.black}10`,

    [breakpoints.down('xs')]: {
      marginBottom: spacing(3),
      padding: spacing(3),
    },
  },
  prosSectionTilesIcon: {},
  prosSectionTileTitle: {
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: spacing(1),
  },
  prosSectionTileText: {
    lineHeight: 1.55,
  },
}));
