import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormInput from 'common/components/admin/formControls/FormInput';
import FormContainer from 'common/components/admin/formControls/FormContainer';

const controlWidth = 220;

const ClientInfo = () => {
  return (
    <FormContainer>
      <Grid container component={Box} py={1}>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput
            label="Sex"
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
            ]}
            helperText="Required for insurance claims"
          />
        </Grid>
        <Grid item component={Box} width={controlWidth}>
          <FormInput label="Gender Identity" helperText="Include pronouns" />
        </Grid>
      </Grid>

      <Grid container component={Box} py={1}>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput
            label="Employment status"
            options={[
              { value: 'full', label: 'Full-time' },
              { value: 'part', label: 'Part-time' },
            ]}
          />
        </Grid>
        <Grid item component={Box} width={controlWidth}>
          <FormInput
            label="Relationship status"
            options={[
              { value: 'single', label: 'Single' },
              { value: 'multiple', label: 'Multiple' },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container component={Box} py={1}>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Race" options={[]} />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Ethnicity" options={[]} />
        </Grid>
        <Grid item component={Box} pr={2} width={controlWidth}>
          <FormInput label="Preffered language" options={[]} />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default ClientInfo;
