import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FileIcon from 'assets/images/icons/file-icon.svg';

import useStyles from './styles';

const FileInput = ({ title, ...rest }) => {
  const classes = useStyles();
  return (
    <Box
      component={ButtonBase}
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      className={classes.root}
      py={2}
      px={3}
      {...rest}
    >
      <Typography className={classes.title}>{title}</Typography>
      <img className={classes.fileIcon} src={FileIcon} alt="file" />
      <Typography className={classes.footer}>
        Drag a photo or your insurance card here or browse for a file to upload
      </Typography>
    </Box>
  );
};

FileInput.propTypes = {
  title: PropTypes.string,
};

export default FileInput;
