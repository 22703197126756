import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from 'common/components/admin/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from 'common/components/admin/IconButton';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import DateFnsUtils from '@date-io/date-fns';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import monthNames from 'common/constants/monthNames';
import weekDayNames from 'common/constants/weekDayNames';

import useStyles from './styles';

const buttonStyle = {
  border: '1px solid #D2D6DB',
  backgroundColor: '#fff',
  color: '#143A56',
};

const DatePickerExtended = (props) => {
  const classes = useStyles();
  const { mode } = props;
  const [pickedDate, setPickedDate] = useState(new Date());
  const setCurrentDate = () => setPickedDate(new Date());
  const setPrevDate = () => {
    if (mode === 'day') {
      setPickedDate(addDays(pickedDate, -1));
    }
    if (mode === 'week') {
      setPickedDate(addWeeks(pickedDate, -1));
    }
    if (mode === 'month') {
      setPickedDate(addMonths(pickedDate, -1));
    }
  };
  const setNextDate = () => {
    if (mode === 'day') {
      setPickedDate(addDays(pickedDate, 1));
    }
    if (mode === 'week') {
      setPickedDate(addWeeks(pickedDate, 1));
    }
    if (mode === 'month') {
      setPickedDate(addMonths(pickedDate, 1));
    }
  };
  const handleDateChange = (date) => {
    setPickedDate(date);
  };

  const formatSelectLabel = (date) => {
    const month = date.getMonth();
    const monthName = monthNames[month];
    const year = date.getFullYear();
    if (mode === 'day') {
      const day = date.getDay();
      const dateDay = date.getDate();
      const dayName = weekDayNames[day].short;
      return `${dayName}, ${monthName}, ${dateDay}, ${year}`;
    }
    if (mode === 'week') {
      const start = startOfWeek(date);
      const end = endOfWeek(date);
      const startDay = start.getDate();
      const endDay = end.getDate();
      return `${monthName}, ${startDay} - ${endDay}, ${year}`;
    }
    return `${monthName}, ${year}`;
  };

  return (
    <Grid container direction="row">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          variant="inline"
          className={classes.dateRow}
          margin="normal"
          value={pickedDate}
          onChange={handleDateChange}
          labelFunc={formatSelectLabel}
          InputProps={{
            classes: { input: classes.input },
          }}
        />
        <Grid item component={Box} pl={1} pt={2}>
          <IconButtonsGroup>
            <IconButton
              IconComponent={ArrowBackIcon}
              style={buttonStyle}
              iconProps={{
                style: {
                  color: buttonStyle.color,
                },
              }}
              onClick={setPrevDate}
            />
            <IconButton
              IconComponent={ArrowForwardIcon}
              style={buttonStyle}
              iconProps={{
                style: {
                  color: buttonStyle.color,
                },
              }}
              onClick={setNextDate}
            />
            <Button bgColor="#fff" style={buttonStyle} onClick={setCurrentDate}>
              Today
            </Button>
          </IconButtonsGroup>
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

DatePickerExtended.propTypes = {
  mode: PropTypes.string,
};

export default DatePickerExtended;
