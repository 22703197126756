import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

const CustomconButtonsGroup = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container alignItems="center" {...rest}>
      {children}
    </Grid>
  );
};

CustomconButtonsGroup.propTypes = {
  children: PropTypes.node,
};

export default CustomconButtonsGroup;
