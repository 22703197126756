import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  avatar: {
    height: 120,
    width: 120,
    borderRadius: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  name: {
    fontSize: '1.4rem',
  },
  middleSectionLabel: {
    fontSize: '0.825rem',
  },
  middleSectionMenuItem: {
    fontSize: '0.7rem',
    fontWeight: 600,
    color: '#40ceba',
    paddingLeft: 2,
    paddingRight: 2,
  },
  lowerLabelBlock: {
    width: 'auto',
    position: 'relative',
    top: '1.6rem',
    fontSize: 10,
  },
}));
