import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';

const CalendarSection = () => {
  const renderItem = ({ title, value }) => (
    <Box display="flex" pl={3} alignItems="center">
      <Typography style={{ fontSize: '0.86rem', color: '#ffff' }}>
        {`${title}:`}
      </Typography>
      <Typography
        style={{
          fontSize: '0.86rem',
          color: '#ffff',
          fontWeight: 600,
          paddingLeft: 10,
        }}
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <Grid
      container
      component={Box}
      py={2}
      px={2}
      borderRadius={10}
      alignItems="center"
      style={{ backgroundColor: '#40ceba' }}
    >
      <Grid
        item
        component={Box}
        p={1}
        height={50}
        width={50}
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: '#76dacc' }}
        borderRadius={15}
      >
        <EventIcon style={{ color: '#ffff' }} />
      </Grid>
      <Grid item container direction="column" component={Box} flex={1}>
        <Grid item style={{ paddingLeft: 24 }}>
          <Typography
            style={{
              color: '#ffff',
              fontSize: '1.3rem',
              fontWeight: 500,
              paddingBottom: 5,
            }}
          >
            3 appointments today
          </Typography>
        </Grid>
        <Grid item container component={Box} flex={1}>
          {renderItem({ title: 'New appointment', value: 'June 24, 2020' })}
          {renderItem({ title: 'Client', value: 'Dr. Paula Braun' })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalendarSection;
