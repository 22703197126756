import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

const StatusBanner = ({ mainColor, title = 'No title', count = 0 }) => {
  const classes = useStyles();
  const defaultMainStyles = {
    backgroundColor: '#fff',
  };
  if (mainColor) defaultMainStyles.backgroundColor = `${mainColor}55`;
  return (
    <Box
      direction="row"
      alignItems="center"
      className={classes.container}
      style={{ ...defaultMainStyles }}
    >
      <Icon
        className="fas fa-circle"
        style={{ fontSize: 10, color: mainColor }}
      />
      <Typography component="span" className={classes.title}>
        {count} {title}
      </Typography>
    </Box>
  );
};

StatusBanner.propTypes = {
  mainColor: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
};

export default StatusBanner;
