import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Header from 'common/components/Header';
import Footer from 'common/components/Footer';

import useStyles from './styles';

const Index = () => {
  const styles = useStyles();

  return (
    <>
      <Header height={150} />

      <Container>
        <Box
          display="flex"
          textAlign="justify"
          flexDirection="column"
          className={styles.root}
        >
          <Typography variant="body2" component="h1" align="center">
            <Box fontStyle="italic" mb={2} component="span">
              Privacy and Information Security Policy
            </Box>
          </Typography>

          <Typography variant="body1">
            Text and Online Counseling[LLC] (“TOC”) is committed to safeguarding
            online privacy with respect to the personally identifiable
            information that we may obtain from users of the TOC website
            (“Website”). Our Privacy and Information Security Policy (“Privacy
            Policy”) answers frequently asked questions about your online
            privacy, including what personally identifiable information we may
            obtain from you and how it will be used and protected. My REfinwell
            may from time to time change this Privacy Policy, so please check
            back periodically.
          </Typography>

          <Typography variant="body1" component="h2">
            <Box fontStyle="italic" component="span">
              WHAT PERSONALLY IDENTIFIABLE INFORMATION DO WE OBTAIN FROM YOU?
              WHEN DO WE OBTAIN SUCH INFORMATION?
            </Box>
          </Typography>

          <Typography variant="body1">
            If, upon visiting our Website, your use is limited to browsing our
            informational content, we will not require that you provide us with
            any personally identifiable information. However, we may request
            personal information from you at other times. For example:
          </Typography>

          <Typography variant="body1" component="div">
            <ul>
              <li>
                Other features of our website or our service may require
                registration and establishment of a user account, which involves
                provision of your name, email address, and a password.
              </li>
              <li>
                When you use certain features of our service, we collect a
                variety of other personal information from you. Although this
                information varies depending on how you use our service, it may
                include such information as your name, address, phone number,
                email address, social security number, credit card information,
                employment information or health insurance account information.
              </li>
              <li>
                If you choose to participate in a customer survey conducted by
                us or by one of our business partners, we may ask for your name,
                e-mail address and other information as may be required by the
                particular survey.
              </li>
              <li>
                If you report a problem or submit a customer review, we will ask
                that you provide your name, e-mail address, address, phone
                number and/or account number. Should you contact us for any
                reason other than to report a problem and/or submit a review, we
                may also keep a record and/or copy of your correspondence with
                us.
              </li>
            </ul>
          </Typography>

          <Typography variant="body1" component="h2">
            <Box fontStyle="italic" component="span">
              HOW DO WE PROTECT THE SECURITY AND QUALITY OF YOUR PERSONAL
              INFORMATION?
            </Box>
          </Typography>

          <Typography variant="body1">
            To protect the security and quality of your personal information, we
            have implemented technical and managerial procedures to maintain
            accurate, current and complete information as well as to protect
            your personal information against loss, misuse or alteration when it
            is under our control. Your personally identifiable information will
            be encrypted and stored on our secured servers. Your personal
            information is also password protected so that access is limited to
            yourself and those with whom you share your password, TOC, as well
            as third party access facilitated by TOC with your prior permission.
          </Typography>

          <Typography variant="body1" component="h2">
            HOW DO WE USE YOUR INFORMATION?
          </Typography>

          <Typography variant="body1">
            Information you share with us will be used in a variety of ways that
            let us serve you better. We may also use this information to perform
            administrative, technical, hosting or other functions that help us
            manage our Website and deliver new functionality to you.
          </Typography>

          <Typography variant="body1">
            To the extent your health insurance provides coverage for online
            services, TOC may obtain personal data from your insurance provider
            and other third parties that provide data interchange services for
            your insurance provider. That data will be subject to the same
            privacy and security policies as the personal information that you
            submit directly to us.
          </Typography>

          <Typography variant="body1">
            Your personal information will be stored in a secure manner. We have
            implemented a variety of commercially standard encryption and
            security technologies and procedures to protect your personal
            information stored in our computer systems from unauthorized access.
            We also maintain standard physical and electronic procedural
            safeguards that limit access to your personal information to those
            of our associates (or people working on our behalf and under
            confidentiality agreements) who, through the course of standard
            business activities, need to access your personal information.
          </Typography>

          <Typography variant="body1">
            TOC may combine, in a non-identifiable format, the personal
            information you provide with information from other users to create
            aggregate data that may be disclosed to third parties for general
            public health or commercial purposes. Aggregate data does not
            contain any information that could be used to contact you or
            identify you.
          </Typography>

          <Typography variant="body1" component="h2">
            HOW AND WHY WE SHARE YOUR PERSONAL AND OTHER INFORMATION
          </Typography>

          <Typography variant="body1">
            We will not make your personal information available to health
            service provider (such as a therapist) (“Provider”), unless you
            direct us to do so. While it is fully within your rights to not
            share personal information, you may not be able to utilize some
            features of the Website or related services if you choose not to
            supply personal information to a Provider. All Providers have
            represented to us that they will comply with all federal and state
            privacy and security laws applicable to your interactions with them.
            However, TOC is not responsible for any violations of those laws by
            Providers. Each time you choose a new Provider, you will be given a
            copy of that provider’s Notice of Privacy Practice, in accordance
            with the federal Health Insurance Portability and Accountability
            Act, privacy of information provisions.
          </Typography>

          <Typography variant="body1">
            We will not share your personal information with any other third
            parties without your express permission, except as otherwise
            permitted by this Privacy Policy, or as required by law.
          </Typography>

          <Typography variant="body1">
            TOC and its affiliated companies use third-party service providers
            to help us manage and improve the Website and related services.
            These service providers may collect and/or use your personal or
            other information to assist us in achieving the purposes discussed
            above in the section entitled &ldquo;How Do We Use Your
            Information?&ldquo;
          </Typography>

          <Typography variant="body1">
            We may also share your personal information with other third parties
            when necessary to fulfill your requests for services; to complete a
            transaction that you initiate; to meet the terms of any agreement
            that you have with us or our partners; or to manage our business.
          </Typography>

          <Typography variant="body1">
            The TOC Website or related services may enable you to share personal
            information with websites or online services operated by third
            parties. For example, the Website may contain links and widgets for
            third-party websites that incorporate comment and social media
            features. If you choose to use these features, you may disclose your
            personal information not just to those third-party websites and
            services, but also to their users and the public more generally.
            Because these third-party websites and services are not operated by
            TOC, we are not responsible for the content or practices of those
            websites or services. The collection, use, and disclosure of your
            personal and non-personal information will be subject to the privacy
            policies of the third party websites or services, and not this
            Privacy Policy.
          </Typography>

          <Typography variant="body1">
            The TOC Website or related services may also integrate certain
            third-party plug-ins (such as a Facebook &ldquo;like&ldquo; button).
            Even if you do not click on these plug-ins, they may collect
            information about you, such as your IP address and the pages that
            you view. They also may set and/or access a cookie. These plugins
            are governed by the privacy policy of the company providing them.
            Our practices with respect to cookies and other tracking mechanisms
            is discussed below.
          </Typography>

          <Typography variant="body1">
            TOC reserves the right (and you authorize TOC) to share or disclose
            your personal information when TOC determines, in its sole
            discretion, that the disclosure of such information is necessary or
            appropriate.
          </Typography>

          <Typography variant="body1">
            Legal purposes. We also may use or share your personal or
            non-personal information with third parties when we believe, in our
            sole discretion, that doing so is necessary:
          </Typography>

          <Typography variant="body1">
            (i) To comply with applicable law or a court order, subpoena, or
            other legal process;
          </Typography>

          <Typography variant="body1">
            (ii) To investigate, prevent, or take action regarding illegal
            activities, suspected fraud, violations of our terms and conditions,
            or situations involving threats to our property or the property or
            physical safety of any person or third party;
          </Typography>

          <Typography variant="body1">
            (iii) To establish, protect, or exercise our legal rights or defend
            against legal claims; or
          </Typography>

          <Typography variant="body1">
            (iv) To facilitate the financing, securitization, insuring, sale,
            assignment, bankruptcy, or other disposal of all or part of our
            business or assets.
          </Typography>

          <Typography variant="body1">
            Finally, your personal information may be transferred to a third
            party as a result of a sale, acquisition, merger, reorganization or
            other change of control, reorganization, bankruptcy, or asset sale.
            If we sell, merge or transfer any part of our business, part of the
            sale may include your personal information.
          </Typography>

          <Typography variant="body1" component="h2">
            <Box my={2} component="span">
              DO WE SELL OR RENT YOUR PERSONALLY IDENTIFIABLE INFORMATION?
            </Box>
          </Typography>

          <Typography variant="body1">
            No, as a general rule, we do not sell or rent your personally
            identifiable information to anyone. If and whenever we intend to
            share your personally identifiable information with a third party
            (other than as provided herein), you will be notified at the time of
            data collection or transfer, and you will have the option of not
            permitting the transfer. However, we may from time to time rent or
            sell demographic information in the aggregate that does not contain
            your personally identifiable information.
          </Typography>

          <Typography variant="body1" component="h2">
            HOW CAN YOU UPDATE, CORRECT OR DELETE YOUR PERSONALLY IDENTIFIABLE
            INFORMATION?
          </Typography>

          <Typography variant="body1">
            You may edit your personally identifiable information and your
            password at any time by sending an e-mail request to
            tocservice@&shy;textand&shy;online&shy;counseling.com.
          </Typography>

          <Typography variant="body1" component="h2">
            WHAT ARE COOKIES? HOW DO WE USE COOKIES?
          </Typography>

          <Typography variant="body1">
            Cookies enable us to customize and personalize your experience on
            our Website, including the products and promotions that are offered
            to you. Essentially, a cookie is a small amount of data that is sent
            to your browser from a web server and is stored on your computer’s
            hard drive. We use cookies for several purposes in connection with
            the operation of our Website
          </Typography>

          <Typography variant="body1" component="div">
            <ul>
              <li>
                We may use cookies to identify you and access your information
                stored on our computers in order to deliver you a better and
                more personalized experience. For example, we may use cookies to
                tell you about products and services specific to your interests.
              </li>
              <li>
                Upon request, we will save your “user name” so that you do not
                have to re-enter it every time you visit our Web Site. In
                providing you with this service, we use cookies.
              </li>
              <li>
                We may use cookies to estimate our customer base and customer
                usage patterns. Each browser accessing our Web Site may use
                given a unique cookie that is then used to determine the extent
                of repeat visits and the customer activity during those visits.
                We may use the historical information to help target promotions
                based on customer interests and behavior, both specifically to
                individual customers and on an aggregate basis with respect to
                all customers.
              </li>
              <li>
                We also may use cookies to track your progress and number of
                entries in some promotions, sweepstakes and contests, or through
                a meeting registration process. For example, when a promotion
                uses cookies, the information coded to the cookie indicates your
                progress through the promotion, and may be used to track
                entries, submissions and status of prize drawings.
              </li>
            </ul>
          </Typography>

          <Typography variant="body1">
            Business partners that offer co-branded services and
            jointly-sponsored sweepstakes, contests and promotions on our Web
            Site, may use their own cookies. We have no control over those
            cookies, nor does this Privacy Policy cover how your personal
            information contained in those cookies may be used or protected. If
            you have any questions about the cookies of such third parties, or
            about the use of your personal information by such third parties,
            you should contact the site administrator or web-master of the third
            party site.
          </Typography>

          <Typography variant="body1" component="h2">
            DO YOU HAVE CHOICES ABOUT COOKIES?
          </Typography>

          <Typography variant="body1">
            Yes, you have several choices with respect to cookies. You can
            modify your browser preferences to accept all cookies, to notify you
            when a cookie is set, or to reject all cookies. However, our Web
            Site uses cookie-based authentication. Accordingly, if you choose to
            reject all cookies, you may not be able to log onto our Web Site
            and/or use our services or participate in our sweepstakes, contests
            or promotions.
          </Typography>

          <Typography variant="body1" component="h2">
            WHAT ELSE SHOULD YOU KNOW ABOUT YOUR ONLINE PRIVACY?
          </Typography>

          <Typography variant="body1">
            It is important to remember that whenever you voluntarily disclose
            personal information on-line, your information can be collected and
            used by others. If you transmit or post personal information on-line
            that is accessible to others, you will not be able to control how
            that information is used by others. When we receive the transmitted
            information, we will use the procedures summarized in this Privacy
            Policy to ensure the integrity and security of that information in
            our systems. Unfortunately, notwithstanding any of the steps taken
            by us, it is not possible to guarantee the security and integrity of
            data transmitted over the Internet. Consequently, while we take the
            above-described reasonable steps to protect your personal
            information, we cannot and do not warrant the security or integrity
            of any information you transmit to us when registering for our
            Website or otherwise. All such transmission of information is at
            your own risk. Moreover, though we are committed to having our
            Website comply with this Privacy Policy, you are ultimately
            responsible for maintaining the secrecy of your password and your
            personally identifiable information. If you are careless with your
            password, or you decide to share your password with third parties,
            you must be aware of the risk that such third parties will have
            access to all your personally identifiable information.
          </Typography>

          <Typography variant="body1" component="h2">
            PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
          </Typography>

          <Typography variant="body1">
            This Privacy Notice for California Residents (this “Notice”)
            supplements and is expressly made part of the information contained
            in the TOC Privacy Policy and applies solely to all visitors, users,
            and others who reside in the State of California (“consumers” or
            “you”). We adopt this Notice to comply with the California Consumer
            Privacy Act of 2018 (“CCPA”). Any terms defined in the CCPA have the
            same meaning when used in this Notice.
          </Typography>

          <Typography variant="body1" component="div">
            <ol>
              <li>
                <Typography variant="body2" component="h3">
                  Information We Collect
                </Typography>
              </li>
              Through your use of our website, we collect information that
              identifies, relates to, describes, references, is capable of being
              associated with, or could reasonably be linked, directly or
              indirectly, with a particular consumer or device (“personal
              information”). In particular, our Websites has collected the
              following categories of personal information from its consumers
              within the last twelve (12) months. Additionally, we have
              disclosed the following categories of information for a business
              purpose in the past twelve (12) months:
              <Typography
                variant="body1"
                component="div"
                className={styles.tableWrapper}
              >
                <table>
                  <tr>
                    <th>
                      <Typography variant="body2">Category</Typography>
                    </th>
                    <th>
                      <Typography variant="body2">Examples</Typography>
                    </th>
                    <th>
                      <Typography variant="body2">Collected</Typography>
                    </th>
                    <th>
                      <Typography variant="body2">
                        Disclosed for a business purpose
                      </Typography>
                    </th>
                  </tr>

                  <tr>
                    <td>A. Identifiers.</td>
                    <td>
                      A real name, alias, postal address, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, account name, Social Security number,
                      driver&apos;s license number, passport number, or other
                      similar identifiers.
                    </td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>
                      B. Personal information categories listed in the
                      California Customer Records statute (Cal. Civ. Code §
                      1798.80(e)).
                    </td>
                    <td>
                      A name, signature, Social Security number, physical
                      characteristics or description, address, telephone number,
                      passport number, driver&apos;s license or state
                      identification card number, insurance policy number,
                      education, employment, employment history, bank account
                      number, credit card number, debit card number, or any
                      other financial information, medical information, or
                      health insurance information. Some personal information
                      included in this category may overlap with other
                      categories.
                    </td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>
                      C. Protected classification characteristics under
                      California or federal law.
                    </td>
                    <td>
                      Age (40 years or older), race, color, ancestry, national
                      origin, citizenship, religion or creed, marital status,
                      medical condition, physical or mental disability, sex
                      (including gender, gender identity, gender expression,
                      pregnancy or childbirth and related medical conditions),
                      sexual orientation, veteran or military status, genetic
                      information (including familial genetic information).
                    </td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>D. Commercial information.</td>
                    <td>
                      Records of personal property, products or services
                      purchased, obtained, or considered, or other purchasing or
                      consuming histories or tendencies.
                    </td>
                    <td>No</td>
                    <td>No</td>
                  </tr>

                  <tr>
                    <td>E. Biometric information.</td>
                    <td>
                      Genetic, physiological, behavioral, and biological
                      characteristics, or activity patterns used to extract a
                      template or other identifier or identifying information,
                      such as, fingerprints, faceprints, and voiceprints, iris
                      or retina scans, keystroke, gait, or other physical
                      patterns, and sleep, health, or exercise data.
                    </td>
                    <td>No</td>
                    <td>No</td>
                  </tr>

                  <tr>
                    <td>F. Internet or other similar network activity.</td>
                    <td>
                      Browsing history, search history, information on a
                      consumer&apos;s interaction with a website, application,
                      or advertisement.
                    </td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>G. Geolocation data.</td>
                    <td>Physical location or movements.</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>H. Sensory data.</td>
                    <td>
                      Audio, electronic, visual, thermal, olfactory, or similar
                      information.
                    </td>
                    <td>No</td>
                    <td>No</td>
                  </tr>

                  <tr>
                    <td>I. Professional or employment-related information.</td>
                    <td>
                      Current or past job history or performance evaluations
                    </td>
                    <td>No</td>
                    <td>No</td>
                  </tr>

                  <tr>
                    <td>
                      J. Non-public education information (per the Family
                      Educational Rights and Privacy Act (20 U.S.C. Section
                      1232g, 34 C.F.R. Part 99)).
                    </td>
                    <td>
                      Education records directly related to a student maintained
                      by an educational institution or party acting on its
                      behalf, such as grades, transcripts, class lists, student
                      schedules, student identification codes, student financial
                      information, or student disciplinary records
                    </td>
                    <td>No</td>
                    <td>No</td>
                  </tr>

                  <tr>
                    <td>
                      K. Inferences drawn from other personal information.
                    </td>
                    <td>
                      Profile reflecting a person&apos;s preferences,
                      characteristics, psychological trends, predispositions,
                      behavior, attitudes, intelligence, abilities, and
                      aptitudes
                    </td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                </table>
              </Typography>
              Personal information does not include:
              <ul style={{ listStyleType: 'circle' }}>
                <li>Publicly available information from government records.</li>
                <li>Deidentified or aggregated consumer information.</li>
                <li>
                  Information excluded from the CCPA&apos;s scope, like:
                  <ul style={{ listStyleType: 'square' }}>
                    <li>
                      health or medical information covered by the Health
                      Insurance Portability and Accountability Act of 1996
                      (HIPAA) and the California Confidentiality of Medical
                      Information Act (CMIA) or clinical trial data;
                    </li>
                    <li>
                      personal information covered by certain sector-specific
                      privacy laws, including the Fair Credit Reporting Act
                      (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                      Financial Information Privacy Act (FIPA), and the
                      Driver&apos;s Privacy Protection Act of 1994.
                    </li>
                  </ul>
                </li>
              </ul>
              TOC obtains the categories of personal information listed above
              from the following categories of sources:
              <ul style={{ listStyleType: 'circle' }}>
                <li>
                  Directly from you. For example, from information you submit on
                  the Website during the process of using and paying for
                  services.
                </li>
                <li>
                  Indirectly from you. For example, from observing your actions
                  on our Website.
                </li>
                <li>
                  From third-party business partners such as social media sites,
                  ad networks, and analytics providers.
                </li>
              </ul>
              <li>
                <Typography variant="body2" component="h3">
                  Use of Personal Information
                </Typography>
              </li>
              <Typography variant="body2">
                First and foremost, TOC does not sell and has not sold any
                Personal Information in the preceding twelve (12) months.
              </Typography>
              We may use or disclose the personal information we collect for one
              or more of the following business or marketing purposes:
              <ul style={{ listStyleType: 'circle' }}>
                <li>
                  To create your account on our Website and let you log in to
                  your account and use the Website services.
                </li>
                <li>
                  To manage your account, provide you with customer support, and
                  ensure you are receiving quality service.
                </li>
                <li>
                  To contact you or provide you with information, alerts and
                  suggestions that are related to our services.
                </li>
                <li>For billing-related purposes.</li>
                <li>
                  To reach out to you, either ourselves or using the appropriate
                  authorities, if either we or a Provider have a good reason to
                  believe that you or any other person may be in danger or may
                  be either the cause or the victim of a criminal act.
                </li>
                <li>To facilitate your finding a Provider.</li>
                <li>To enable and facilitate the Provider Services.</li>
                <li>To supervise, administer and monitor the service.</li>
                <li>
                  To measure and improve the quality, the effectiveness and the
                  delivery of our services.
                </li>
                <li>Market the Website and our services to you.</li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations.
                </li>
                <li>
                  To provide, support, personalize, and develop our Website and
                  our services.
                </li>
                <li>
                  To personalize your Website experience and to deliver content
                  and product and service offerings relevant to your interests,
                  including targeted offers and ads through our Websites,
                  third-party sites, and via email or text message (with your
                  consent, where required by law).
                </li>
              </ul>
              TOC will not collect additional categories of personal information
              or use the personal information we collected for materially
              different, unrelated, or incompatible purposes without providing
              you notice.
              <Typography variant="body2">
                <li>Disclosure of Personal Information</li>
              </Typography>
              TOC may disclose your personal information to a third party for a
              business purpose. When we disclose personal information for a
              business purpose, we enter a contract that describes the purpose
              and requires the recipient to both keep that personal information
              confidential and not use it for any purpose except performing the
              contract.
              <Typography variant="body1">
                We share the minimum necessary personal information with the
                following categories of third parties:
              </Typography>
              <ul style={{ listStyleType: 'circle' }}>
                <li>
                  Service providers that provide audit, legal, operational,
                  technical or other services for us, such as:
                  <ul style={{ listStyleType: 'square' }}>
                    <li>Customer service</li>
                    <li>Technical maintenance</li>
                    <li>Monitoring website activity</li>
                    <li>Email management and communication</li>
                    <li>Database management</li>
                    <li>Billing and payment processing</li>
                    <li>Reporting and analytics</li>
                    <li>Marketing and advertising</li>
                  </ul>
                </li>
                <li>Providers who provide the Services</li>
              </ul>
              <li>
                <Typography variant="body2" component="h3">
                  Your Rights and Choices
                </Typography>
              </li>
              The CCPA provides consumers (California residents) with specific
              rights regarding their personal information. This section
              describes your CCPA rights and explains how to exercise those
              rights.
              <Box fontStyle="italic">
                Right to Request Access to Information
              </Box>
              You have the right to request that TOC notifies you of the
              personal information about you that we have collected and used.
              Once we receive and confirm your verifiable consumer request
              (see&nbsp;
              <Box fontStyle="italic" component="span">
                Exercising Access, Data Portability, and Deletion Rights
              </Box>
              ), we will disclose to you:
              <ul style={{ listStyleType: 'circle' }}>
                <li>
                  The categories of personal information we collected about you.
                </li>
                <li>
                  The categories of sources for the personal information we
                  collected about you.
                </li>
                <li>
                  Our business or commercial purpose for collecting that
                  personal information.
                </li>
                <li>
                  The categories of third parties with whom we shared that
                  personal information.
                </li>
                <li>
                  The specific pieces of personal information we collected about
                  you.
                </li>
                <li>
                  If we disclosed your personal information for a business
                  purpose and identifying the personal information categories
                  that each category of recipient obtained.
                </li>
              </ul>
              <Box fontStyle="italic">
                Right to Request Deletion of Information
              </Box>
              You have the right to request that TOC deletes any of your
              personal information that we collected about you and retained.
              Once we receive your request and verify who you are, we will
              delete (and direct our service providers to delete) your personal
              information from our records, unless an exception applies.
              <Typography variant="body1">
                You have the right to request that TOC deletes any of your
                personal information that we collected about you and retained.
                Once we receive your request and verify who you are, we will
                delete (and direct our service providers to delete) your
                personal information from our records, unless an exception
                applies.
              </Typography>
              <Typography variant="body1">
                We may deny your deletion request if retaining the information
                is necessary for us or our service provider(s) to:
              </Typography>
              <ul style={{ listStyleType: 'circle' }}>
                <li>
                  Complete the transaction for which we collected the personal
                  information, provide a good or service that you requested,
                  take actions reasonably anticipated within the context of our
                  ongoing business relationship with you, or otherwise perform
                  our contract with you.
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities.
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality.
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise their free speech rights, or exercise another right
                  provided for by law.
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act (Cal. Penal Code § 1546&nbsp;seq.).
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when the
                  information&apos;s deletion may likely render impossible or
                  seriously impair the research&apos;s achievement, if you
                  previously provided informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on your relationship with us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                  Make other internal and lawful uses of that information that
                  are compatible with the context in which you provided it.
                </li>
              </ul>
              <Box fontStyle="italic">Exercising Your Rights</Box>
              <Typography variant="body1">
                To exercise the rights listed above, please submit a request in
                writing to TOC via the contact information listed in the Contact
                Information section. Only you, or a person registered with the
                California Secretary of State that you authorize to act on your
                behalf, may make a request related to your personal information.
                You may also make a request on behalf of your minor child.
              </Typography>
              <Typography variant="body1">
                You may only make a request for access twice within a 12-month
                period. Your request must:
              </Typography>
              <ul style={{ listStyleType: 'circle' }}>
                <li>
                  Provide sufficient information that allows us to reasonably
                  verify you are the person about whom we collected personal
                  information or an authorized representative.
                </li>
                <li>
                  Describe your request with sufficient detail that allows us to
                  properly understand, evaluate, and respond to it.
                </li>
              </ul>
              <Typography variant="body1">
                We cannot respond to your request or provide you with personal
                information if we cannot verify your identity or authority to
                make the request and confirm the personal information relates to
                you. Making a request does not require you to create an account
                with us. We will only use personal information provided in a
                request to verify your identity or authority to make the
                request.
              </Typography>
              <Box fontStyle="italic">Response Timing and Format</Box>
              <Typography variant="body1">
                We endeavor to respond to a request within forty-five (45) days
                of its receipt. If we require more time (up to 90 days), we will
                inform you of the reason and extension period in writing.
              </Typography>
              <Typography variant="body1">
                For Requests to Access, our response will only cover the
                12-month period preceding the request. The response we provide
                will also explain the reasons we cannot comply with a request,
                if applicable. We will select a format to provide your personal
                information that is readily useable and should allow you to
                transmit the information from one entity to another entity
                without hindrance.
              </Typography>
              <Typography variant="body1">
                We do not charge a fee to process or respond to your request
                unless it is excessive, repetitive, or manifestly unfounded. If
                we determine that the request warrants a fee, we will tell you
                why we made that decision and provide you with a cost estimate
                before completing your request.
              </Typography>
              <li>
                <Typography variant="body2" component="h3">
                  Non-Discrimination
                </Typography>
              </li>
              We will not discriminate against you for exercising any of your
              CCPA rights. We will not:
              <ul style={{ listStyleType: 'circle' }}>
                <li>Deny you goods or services.</li>
                <li>
                  Charge you different prices or rates for goods or services,
                  including through granting discounts or other benefits, or
                  imposing penalties.
                </li>
                <li>
                  Provide you a different level or quality of goods or services.
                </li>
                <li>
                  Suggest that you may receive a different price or rate for
                  goods or services or a different level or quality of goods or
                  services.
                </li>
              </ul>
              <li>
                <Typography variant="body2" component="h3">
                  Other California Privacy Rights
                </Typography>
              </li>
              California&apos;s “Shine the Light” law (Civil Code Section §
              1798.83) permits users of our Website that are California
              residents to request certain information regarding our disclosure
              of personal information to third parties for their direct
              marketing purposes. To make such a request, please send an email
              to tocservice@&shy;textand&shy;online&shy;coun&shy;seling.com.
              <li>
                <Typography variant="body2" component="h3">
                  Changes to Our Privacy Notice
                </Typography>
              </li>
              TOC reserves the right to amend this privacy Notice at our
              discretion and at any time. When we make changes to this privacy
              Notice, we will post the updated Notice on the Website and update
              the Notice&apos;s effective date. Regardless of changes to our
              Privacy Policy, we will never use the information you submit under
              our current privacy notice in a new way without first notifying
              you and giving you the option to opt out. Your continued use of
              our Website following the posting of changes constitutes your
              acceptance of such changes.
              <li>
                <Typography variant="body2" component="h3">
                  Contact Information
                </Typography>
              </li>
              If you have any questions or comments about this Notice, the ways
              in which TOC collects and uses your information described above,
              your choices and rights regarding such use, or wish to exercise
              your rights under California law, please do not hesitate to
              contact us at: &nbsp;
              <Typography variant="body1" component="span">
                tocservice@&shy;textand&shy;online&shy;coun&shy;seling.com
              </Typography>
            </ol>

            <Typography variant="body1" component="h2">
              CONTACT US.
            </Typography>
            <Typography variant="body1">
              If you have any questions or comments about this Privacy Policy or
              the practices of our Website, please feel free to e-mail us at
              tocservice@&shy;textand&shy;online&shy;coun&shy;seling.com or
              visit our contact page.
            </Typography>
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Index;
