import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';

import useStyles from './styles';

const CustomIconButton = ({
  IconComponent,
  iconProps,
  style,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={onClick}
      style={style}
      className={classes.root}
      {...props}
    >
      <IconComponent className={classes.icon} {...iconProps} />
    </ButtonBase>
  );
};

CustomIconButton.defaultProps = {
  iconProps: {},
  style: {},
  onClick: () => {},
};

CustomIconButton.propTypes = {
  IconComponent: PropTypes.node,
  style: PropTypes.shape(),
  iconProps: PropTypes.shape(),
  onClick: PropTypes.func,
};

export default CustomIconButton;
