import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import WomanWateringPlant from 'assets/images/woman_watering_plant-min.png';

import useStyles from './styles';

const AboutHeaderSection = () => {
  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.heroGrid}
        spacing={4}
      >
        <Grid item xs={6}>
          <Typography
            color="textPrimary"
            variant="h4"
            className={styles.subheaderMain}
          >
            <Box fontWeight={600} component="span">
              About us
            </Box>
          </Typography>

          <Typography variant="h1">
            The fastest growing telehealth practice in the US
          </Typography>
        </Grid>
        <Grid item xs={6} className={styles.imageGrid}>
          <img src={WomanWateringPlant} alt="woman watering plant" />
        </Grid>
      </Grid>

      <Typography
        color="textPrimary"
        variant="body2"
        component="h4"
        className={styles.subheaderSecondary}
      >
        What we do
      </Typography>

      <Grid container spacing={4} className={styles.secondaryGrid}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" className={styles.headerSecondary}>
            We provide high-quality, evidence-based therapeutic services to
            individuals (both adults and teens) and couples.
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography variant="body1" className={styles.textSecondary}>
            Partnering with clinically sound and professionally licensed mental
            health counselors, we deliver telehealth services with high
            standards of ethics, dedication to privacy, and attention to the
            consumer experience.
          </Typography>

          <Typography variant="body1" className={styles.textSecondary}>
            Through our intuitive and practical search features, paired with
            client testimonials and ratings, we are transparent with the quality
            of care. In addition, streamlined scheduling and real-time
            communication puts individuals and families in control of their
            counseling experience.
          </Typography>

          <Typography variant="body1" className={styles.textSecondary}>
            Through these initiatives, TOC has earned a position as a top-choice
            provider of telehealth counseling services.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutHeaderSection;
