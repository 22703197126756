import {
  dataProvider as createEmpeekDataProvider,
  saveTokens,
  clearTokens,
} from 'empeek.utils.react-admin';
import { API_URL, API_AUTHORIZATION_URL } from 'common/constants/api';

const empeekDataProvider = createEmpeekDataProvider({
  fakeData: {},
  apiBaseUrl: API_URL,
  apiAuthUrl: API_AUTHORIZATION_URL,
  saveTokens,
  clearTokens,
});

const dataProvider = {
  getList: async (resource, params) => {
    const { getList } = await empeekDataProvider;
    return getList(resource, params);
  },

  getOne: async (resource, params) => {
    const { getOne } = await empeekDataProvider;
    return getOne(resource, params);
  },

  getMany: async (resource, params) => {
    const { getMany } = await empeekDataProvider;
    return getMany(resource, params);
  },

  getManyReference: async (resource, params) => {
    const { getManyReference } = await empeekDataProvider;
    return getManyReference(resource, params);
  },

  create: async (resource, params) => {
    const { create } = await empeekDataProvider;
    return create(resource, params);
  },

  update: async (resource, params) => {
    const { update } = await empeekDataProvider;
    return update(resource, params);
  },

  updateMany: async (resource, params) => {
    const { updateMany } = await empeekDataProvider;
    return updateMany(resource, params);
  },

  delete: async (resource, params) => {
    const { delete: deleteMethod } = await empeekDataProvider;
    return deleteMethod(resource, params);
  },

  deleteMany: async (resource, params) => {
    const { deleteMany } = await empeekDataProvider;
    return deleteMany(resource, params);
  },
};

export default dataProvider;
