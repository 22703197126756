import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  Grid,
  Icon,
  Chip,
  Avatar,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';

import routes from 'common/constants/routes';
import { TOCButton } from 'common/components/simple';
import { counselorType } from 'common/constants/propTypes';
import { joinNameTitles } from 'common/utils';
import { openSPWidget } from 'modules/spWidget/ducks';
import useStyles from './styles';

const Counselor = ({ counselor, stateId }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const titles = joinNameTitles(counselor.titles);
  const profileRoute = routes.profile.url.replace(':id', String(counselor.id));
  const stateCode = counselor.states.find((item) => item.id === stateId)?.code;

  const openWidgetModal = () => dispatch(openSPWidget());

  return (
    <Grid container className={styles.counselorContainer}>
      <Grid
        item
        xs={12}
        sm={3}
        container
        justify="space-between"
        className={styles.avatarSection}
      >
        <Avatar
          alt="Counselor profile avatar"
          src={counselor.photoUrl}
          className={styles.counselorAvatar}
          component={Link}
          to={profileRoute}
        />

        {counselor.introVideoUrl ? (
          <Grid
            item
            container
            component={Link}
            alignItems="center"
            wrap="nowrap"
            className={styles.counselorVideoLink}
            to={{
              pathname: profileRoute,
              state: { autoscroll: true },
            }}
          >
            <IconButton classes={{ root: styles.counselorVideoIcon }}>
              <Icon fontSize="inherit" className="fas fa-video" />
            </IconButton>

            <Typography variant="caption">
              <Box fontWeight="700" component="span">
                Watch intro video
              </Box>
            </Typography>
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs container direction="column" className={styles.infoSection}>
        <Grid container>
          <Grid item xs={12} md={7} className={styles.infoSectionName}>
            <Typography
              component={Link}
              variant="h4"
              to={profileRoute}
              className={styles.counselorTitle}
            >
              {counselor.firstName} {counselor.lastName}
              {titles}
            </Typography>

            <Grid container className={styles.infoSectionLicence}>
              <Icon
                fontSize="inherit"
                color="secondary"
                className="fas fa-user-shield"
                classes={{ root: styles.infoSectionLicenceIcon }}
              />

              <Typography variant="subtitle2">
                <Box fontWeight="700" component="span">
                  {counselor.subTitle}
                </Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} container className={styles.licenseCityRow}>
            <Grid item xs container className={styles.licenseCity}>
              <Icon
                fontSize="inherit"
                color="secondary"
                className="fas fa-map-marker-alt"
              />
              <Typography variant="h6">{stateCode}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Typography
          variant="subtitle1"
          component="div"
          className={styles.infoSectionDescription}
        >
          {ReactHtmlParser(counselor.description)}
        </Typography>

        <Grid container component={Box} flexDirection="row">
          <Grid item xs container direction="column" component={Box} pr={2}>
            <Grid
              container
              alignItems="flex-start"
              className={styles.infoSectionSpecializes}
            >
              <Grid item>
                <Typography variant="subtitle2">
                  <Box fontWeight="700" component="span" mr={1}>
                    Specializes in:
                  </Box>
                </Typography>
              </Grid>

              {(counselor.specialities || []).map(({ id, name }) => (
                <Chip
                  size="small"
                  key={id}
                  label={name}
                  className={styles.infoSectionSpecialityChip}
                />
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={2}
            container
            component={Box}
            flexDirection={{ xs: 'row', md: 'column' }}
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            alignItems="flex-end"
            minWidth={{ xs: '100%', md: 113 }}
            pt={{ xs: 4, md: 0 }}
          >
            <Grid component={Box} mb={{ xs: 0, md: 2 }} mr={{ xs: 2, md: 0 }}>
              <TOCButton
                width={113}
                height={41.5}
                color="secondary"
                disabled={!(counselor.availability || []).length}
                onClick={openWidgetModal}
              >
                <Box className={styles.profileButtons} component="span">
                  Book now
                </Box>
              </TOCButton>
            </Grid>

            <TOCButton width={113} height={41.5} to={profileRoute}>
              <Box className={styles.profileButtons} component="span">
                View profile
              </Box>
            </TOCButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Counselor.propTypes = {
  counselor: counselorType,
  stateId: PropTypes.number,
};

export default Counselor;
