import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  title: {
    lineHeight: 1.33,
  },
  feedbackNoticeWrap: {
    maxWidth: 890,
    width: '100%',
    marginBottom: spacing(8),
    padding: spacing(3.75, 5.625),
    boxShadow: `0 24px 40px 0 ${palette.black}08`,
    boxSizing: 'border-box',

    [breakpoints.down('sm')]: {
      padding: spacing(3.75, 3),
    },
    [breakpoints.down('xs')]: {
      padding: spacing(3, 3),
      marginBottom: spacing(4),
    },
  },
  feedbackNoticeBackground: {
    borderRadius: '0 30px',
    backgroundColor: palette.primary.main,
    color: palette.white,
  },
  feedbackNoticeMessage: {
    lineHeight: 1.66,
    padding: 0,
    '& > a': {
      color: palette.secondary.main,
    },
  },
  plansWrapper: {
    marginTop: spacing(9),
    marginBottom: spacing(11),

    [breakpoints.down('sm')]: {
      marginTop: spacing(6),
      marginBottom: spacing(8),
    },

    [breakpoints.down('xs')]: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
    },
  },
  plansItem: {
    margin: '0 auto',
  },
  boxFirstPerson: {
    position: 'absolute',
    top: -350,
    left: -100,
    '& img': {
      maxWidth: '100%',
    },

    '@media (min-width:1925px)': {
      left: 'calc(50% - 965px)',
    },
    '@media (max-width:1400px)': {
      maxWidth: 380,
      width: '100%',
      top: -300,
      left: -80,
    },
    [breakpoints.down('md')]: {
      maxWidth: 300,
      top: -250,
      left: -50,
    },
    [breakpoints.down('sm')]: {
      maxWidth: 200,
      top: -150,
      left: -50,
    },
    [breakpoints.down('xs')]: {
      maxWidth: 150,
      top: -120,
      left: -40,
    },
  },
  boxSecondPersonWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: 1000,
    right: 0,
    bottom: -530,
    position: 'absolute',
    backgroundColor: 'transparent',

    '@media (min-width:2000px)': {
      right: 'calc(50% - 995px)',
    },
  },
  boxSecondPerson: {
    position: 'absolute',
    zIndex: 1,
    top: 350,
    right: -115,
    '& img': {
      maxWidth: '100%',
    },

    '@media (min-width:1925px)': {
      right: 0,
    },
    '@media (max-width:1400px)': {
      width: 380,
      height: 380,
      top: 400,
      right: -100,
    },
    [breakpoints.down('md')]: {
      width: 300,
      height: 300,
      top: 430,
      right: -80,
    },
    [breakpoints.down('sm')]: {
      width: 200,
      height: 200,
      top: 430,
      right: -60,
    },
    [breakpoints.down('xs')]: {
      width: 150,
      height: 150,
      top: 470,
      right: -40,
    },
  },
}));
