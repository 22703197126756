import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, breakpoints }) => ({
  pagination: {
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  paginationItem: {
    borderRadius: '30%',
    width: 50,
    height: 50,
    color: palette.primary.main,
    fontWeight: 700,
    '&.MuiPaginationItem-ellipsis': {
      display: 'inline',
    },

    [breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
    '@media (max-width:370px)': {
      '& .MuiButtonBase-root': {
        width: 30,
        height: 30,
        minWidth: 30,
      },
    },
  },
  paginationLastButton: {
    textTransform: 'none',
  },
}));
