import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Person from 'assets/images/people/joinUs/person1-min.png';
import Person2 from 'assets/images/people/joinUs/person2-min.png';
import Person3 from 'assets/images/people/joinUs/person3-min.png';
import Person4 from 'assets/images/people/joinUs/person4-min.png';
import Person5 from 'assets/images/people/joinUs/person5-min.png';

import shout from 'assets/images/icons/shout-min.png';
import order from 'assets/images/icons/order-min.png';
import card from 'assets/images/icons/card-min.png';
import support from 'assets/images/icons/support-min.png';

import { Circle } from 'common/components/simple';
import useStyles from './styles';

const ProsSection = () => {
  const styles = useStyles();

  return (
    <Grid className={styles.joinUsContainer}>
      <img src={Person} alt="person" className={styles.personImage} />
      <img src={Person2} alt="person two" className={styles.personImage} />
      <img src={Person3} alt="person three" className={styles.personImage} />
      <img src={Person4} alt="person four" className={styles.personImage} />
      <img src={Person5} alt="person five" className={styles.personImage} />

      <Box mt={17} mb={{ xs: 5, sm: 10, md: 18 }}>
        <Container>
          <Grid className={styles.prosSectionHeader}>
            <Typography variant="h4" className={styles.prosSectionHint}>
              Join our group
            </Typography>

            <Typography variant="h1" className={styles.prosSectionHeaderName}>
              Become a TOC and work amongst the best telehealth counselors in
              the US
            </Typography>
          </Grid>
        </Container>

        <Container className={styles.prosSectionMainContainer}>
          <Typography variant="h5" className={styles.prosSectionHint}>
            The new face of counseling
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h2" className={styles.prosSectionIntroTitle}>
                Text and Online Counseling is a mental health provider like none
                other.
              </Typography>
            </Grid>

            <Grid item xs={12} md={7} container direction="column">
              <Box mb={{ xs: 3, sm: 4 }}>
                <Typography variant="body1">
                  We combine the support of a traditional mental health
                  practice, the marketing of a robust professional listing
                  service, and the claims processing and collections of an
                  exceptional billing service.
                </Typography>
              </Box>

              <Typography variant="body1">
                We allow you to run your own independent telehealth practice
                while benefiting from the support and infrastructure of a larger
                organization! In short, we make it easy to do what you do best:
                counseling!
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={styles.prosSectionTilesTitle}
          >
            <Circle size={37} left="0" top="-100" color="secondary" />
            <Circle size={27} left="74%" top="-100" />
            <Circle size={22} left="35%" top="-60" color="tertiary" />
            <Circle size={31} left="17%" top="40" />

            <Circle size={50} left="90%" top="50" color="secondary" />
            <Circle size={14} left="-70" top="150" color="secondary" />
            <Circle size={17} left="58%" top="180" color="tertiary" />

            <Typography variant="h5" className={styles.prosSectionHint}>
              What you&apos;ll get
            </Typography>

            <Grid item xs={12} sm={7} md={6}>
              <Typography
                variant="h2"
                align="center"
                className={styles.prosSectionIntroTitle}
              >
                For one low, per-claim administrative fee, we provide:
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between">
            <Grid item className={styles.prosSectionTileWrapper}>
              <Box className={styles.prosSectionTile}>
                <Box mr={{ xs: 2, sm: 4 }}>
                  <img
                    src={shout}
                    alt="shout icon"
                    className={styles.prosSectionTilesIcon}
                  />
                </Box>

                <Grid item xs={9} container direction="column">
                  <Typography
                    variant="h4"
                    className={styles.prosSectionTileTitle}
                  >
                    Innovative Professional Marketing
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.prosSectionTileText}
                  >
                    to ensure your calendar is full
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item className={styles.prosSectionTileWrapper}>
              <Box className={styles.prosSectionTile}>
                <Box mr={{ xs: 2, sm: 4 }} minWidth={61}>
                  <img
                    src={order}
                    alt="order icon"
                    className={styles.prosSectionTilesIcon}
                  />
                </Box>

                <Grid item xs={10} container direction="column">
                  <Typography
                    variant="h4"
                    className={styles.prosSectionTileTitle}
                  >
                    Preparation, Submission and collection of health insurance
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.prosSectionTileText}
                  >
                    claims on your behalf
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item className={styles.prosSectionTileWrapper}>
              <Box className={styles.prosSectionTile}>
                <Box mr={{ xs: 2, sm: 4 }} minWidth={61}>
                  <img
                    src={card}
                    alt="card icon"
                    className={styles.prosSectionTilesIcon}
                  />
                </Box>

                <Grid item xs={9} container direction="column">
                  <Typography
                    variant="h4"
                    className={styles.prosSectionTileTitle}
                  >
                    Credentialing services
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.prosSectionTileText}
                  >
                    with commercial health plans
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item className={styles.prosSectionTileWrapper}>
              <Box className={styles.prosSectionTile}>
                <Box mr={{ xs: 2, sm: 4 }} minWidth={61}>
                  <img
                    src={support}
                    alt="support icon"
                    className={styles.prosSectionTilesIcon}
                  />
                </Box>

                <Grid item xs={9} container direction="column">
                  <Typography
                    variant="h4"
                    className={styles.prosSectionTileTitle}
                  >
                    24/7 Support
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.prosSectionTileText}
                  >
                    providing technical advice whenever you need it
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Grid>
  );
};

export default ProsSection;
