import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from 'common/components/admin/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SvgIcon from '@material-ui/core/SvgIcon';

import monthNames from 'common/constants/monthNames';

import SortIcon from 'assets/images/icons/sort';
import DateInput from 'common/components/admin/DateInput';
import SelectInput from 'common/components/admin/SelectInput';
import ResetButton from '../ResetButton';
import useStyles from './styles';
import { insuranceOptionValues, paidData } from '../../insuranceData';

const columnNames = [
  { label: 'Date', isSorted: true, style: { width: '12%' } },
  { label: 'Insurance payer', isSorted: true, style: { width: '30%' } },
  { label: 'Payment Method', isSorted: true, style: { width: '15%' } },
  { label: 'Paid', isSorted: true, style: { width: '15%' } },
  { label: 'Unallocated', isSorted: true, style: { width: '15%' } },
  { label: 'Actions', style: { width: '15%' } },
];

const rows = paidData.map((item) => {
  const {
    id,
    transactionDate,
    insurancePayer,
    paymentMethod,
    paid,
    unallocated,
  } = item;
  const tempDate = new Date(transactionDate);
  const parsedDate = `${
    monthNames[tempDate.getMonth()]
  } ${tempDate.getDate()}, ${tempDate.getFullYear()}`;

  return {
    id,
    date: parsedDate,
    insurancePayer,
    paymentMethod,
    paid,
    unallocated,
  };
});

const Row = (props) => {
  const { row } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const goToDetails = useCallback(() => {
    dispatch(push('/billing/transactionDetails/:id'));
  }, [dispatch]);
  return (
    <>
      <TableRow key={row.id}>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.date}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.insurancePayer}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.paymentMethod}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>${row.paid}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>${row.unallocated}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton
            onClick={goToDetails}
            IconComponent={VisibilityIcon}
            iconProps={{
              style: {
                color: '#143A56',
              },
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

const PaidClaims = () => {
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        container
        display="flex"
        flexDirection="row"
        justify="space-between"
        alignItems="center"
        component={Box}
        py={2}
        px={2}
      >
        <Grid
          item
          container
          component={Box}
          flex={1}
          className={classes.countLabel}
        >
          <Typography component="span">Showing</Typography>
          <Typography style={{ fontWeight: 700 }}>&nbsp;75&nbsp;</Typography>
          <Typography>paid claims</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          component={Box}
          xs={7}
          flex={3}
          justifyContent="flex-end"
        >
          <Grid item component={Box}>
            <DateInput />
          </Grid>
          <Grid item component={Box}>
            <SelectInput
              placeholder="All insurance"
              valueOptions={insuranceOptionValues}
            />
          </Grid>
          <ResetButton />
        </Grid>
      </Grid>
      <Grid container direction="column" component={Box}>
        <TableContainer className={classes.table}>
          <Table size="small" style={{ tableLayout: 'fixed' }}>
            <TableHead style={{ backgroundColor: '#F5F8FA' }}>
              <TableRow>
                {columnNames.map((col) => {
                  return (
                    <TableCell
                      key={col.label}
                      style={col.style}
                      className={classes.thGrid}
                    >
                      <Grid
                        component={Box}
                        display="flex"
                        container
                        alignItems="center"
                      >
                        <Tooltip title={col.label} arrow>
                          <Typography className={classes.th}>
                            {col.label}
                          </Typography>
                        </Tooltip>
                        {col.isSorted && (
                          <SvgIcon
                            component={Box}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {SortIcon}
                          </SvgIcon>
                        )}
                      </Grid>
                    </TableCell>
                  );
                })}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.number,
    insurancePayer: PropTypes.string,
    paid: PropTypes.number,
    paymentMethod: PropTypes.string,
    unallocated: PropTypes.number,
  }),
};

export default PaidClaims;
