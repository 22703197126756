import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

const PaidSection = ({ paidData }) => {
  const classes = useStyles();
  const {
    transactionDate,
    insurancePayer,
    paymentMethod,
    amount,
    unallocated,
  } = paidData;
  return (
    <Grid
      container
      component={Box}
      px={2}
      borderRadius={10}
      alignItems="center"
      justifyContent="space-between"
      className={classes.container}
    >
      <Grid item component={Box} p={1} alignItems="center">
        <Typography className={classes.title}>Payment date</Typography>
        <Typography className={classes.subTitle}>{transactionDate}</Typography>
      </Grid>
      <Grid item component={Box} p={1} alignItems="center">
        <Typography className={classes.title}>Insurance payer</Typography>
        <Typography className={classes.subTitle}>{insurancePayer}</Typography>
      </Grid>
      <Grid item component={Box} p={1} alignItems="center">
        <Typography className={classes.title}>Payment method</Typography>
        <Typography className={classes.subTitle}>{paymentMethod}</Typography>
      </Grid>
      <Grid
        item
        component={Box}
        p={2}
        py={5}
        alignItems="center"
        className={classes.markedContainer}
      >
        <Typography className={classes.title}>Amount</Typography>
        <Typography className={classes.subTitle}>$ {amount}</Typography>
      </Grid>
      <Grid
        item
        component={Box}
        p={1}
        alignItems="center"
        style={{ minWidth: '17%' }}
      >
        <Typography className={classes.title}>Unallocated</Typography>
        <Typography className={classes.subTitle}>${unallocated}</Typography>
      </Grid>
    </Grid>
  );
};

PaidSection.propTypes = {
  paidData: PropTypes.shape({
    amount: PropTypes.number,
    appointments: PropTypes.arrayOf(
      PropTypes.shape({
        billed: PropTypes.number,
        client: PropTypes.string,
        clientOwes: PropTypes.number,
        cpt: PropTypes.string,
        date: PropTypes.string,
        id: PropTypes.number,
        insurancePaid: PropTypes.number,
        provider: PropTypes.string,
        totalInsurancePaid: PropTypes.number,
        totalWriteOff: PropTypes.number,
        writeOff: PropTypes.number,
      })
    ),
    id: PropTypes.string,
    insurancePayer: PropTypes.string,
    paymentMethod: PropTypes.string,
    transactionDate: PropTypes.string,
    unallocated: PropTypes.number,
  }),
};

export default PaidSection;
