import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleButton from './ToggleButton';
import useStyles from './styles';

const ButtonTripleSwitch = ({ data, switchItem }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(data[0]?.value);
  const setSelectedItem = (value) => {
    setSelected(value);
    switchItem(value);
  };
  return (
    <ButtonGroup
      disableElevation
      variant="contained"
      className={classes.container}
    >
      {data.map((item) => (
        <ToggleButton
          key={item.value}
          {...item}
          isSelected={item.value === selected}
          onClick={() => setSelectedItem(item.value)}
        />
      ))}
    </ButtonGroup>
  );
};

ButtonTripleSwitch.defaultProps = {
  data: [
    {
      value: 'day',
      label: 'Day',
    },
    {
      value: 'week',
      label: 'Week',
    },
    {
      value: 'month',
      label: 'Month',
    },
  ],
};

ButtonTripleSwitch.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  switchItem: PropTypes.func,
};

export default ButtonTripleSwitch;
