import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './styles';

const RadioList = (props) => {
  const {
    title,
    value,
    onChange,
    row = false,
    options = [],
    allowEmpty = false,
  } = props;
  const styles = useStyles({ row });

  const ledToNumber = typeof options?.[0]?.id === 'number';

  const handleChange = (event, nValue) => {
    const newValue = ledToNumber ? Number(nValue) : nValue;
    if (onChange) onChange(newValue);
  };

  if (row) {
    return (
      <Grid className={styles.container}>
        <Typography variant="subtitle2">
          <Box fontWeight="700" component="span">
            {title}
          </Box>
        </Typography>
        <RadioGroup
          value={value}
          className={styles.radioGroupRow}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              label={
                <Typography variant="subtitle1">
                  <Box fontWeight="400" component="span">
                    {option.name}
                  </Box>
                </Typography>
              }
              className={styles.radioLabel}
              control={
                <Radio
                  classes={{
                    root: styles.radioBase,
                    checked: styles.radioChecked,
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
      </Grid>
    );
  }

  return (
    <Accordion square defaultExpanded classes={{ root: styles.listContainer }}>
      <AccordionSummary
        id="toc-accordion"
        aria-controls="toc-accordion"
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: styles.listSummary,
          content: styles.listSummaryContent,
          expandIcon: styles.listSummaryIcon,
        }}
      >
        <Typography variant="subtitle2">
          <Box fontWeight="700" component="span">
            {title}
          </Box>
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: styles.listDetails }}>
        <RadioGroup value={value} onChange={handleChange}>
          <>
            {allowEmpty ? (
              <FormControlLabel
                value={0}
                label={
                  <Typography variant="subtitle1">
                    <Box fontWeight="400" component="span">
                      Any
                    </Box>
                  </Typography>
                }
                className={styles.radioLabel}
                control={
                  <Radio
                    classes={{
                      root: styles.radioBase,
                      checked: styles.radioChecked,
                    }}
                  />
                }
              />
            ) : null}

            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                label={
                  <Typography variant="subtitle1">
                    <Box fontWeight="400" component="span">
                      {option.name}
                    </Box>
                  </Typography>
                }
                className={styles.radioLabel}
                control={
                  <Radio
                    classes={{
                      root: styles.radioBase,
                      checked: styles.radioChecked,
                    }}
                  />
                }
              />
            ))}
          </>
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
};

RadioList.propTypes = {
  row: PropTypes.bool,
  title: PropTypes.string,
  onChange: PropTypes.func,
  allowEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default RadioList;
