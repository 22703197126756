import React, { useState, useEffect } from 'react';
import { useCountUp } from 'react-countup';
// eslint-disable-next-line import/no-extraneous-dependencies
import throttle from 'lodash/throttle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Container from '@material-ui/core/Container';

import { Wave } from 'common/components/simple';
import Person from 'assets/images/people/home/person0-min.png';
import Person1 from 'assets/images/people/home/person1-min.png';
import Person2 from 'assets/images/people/home/person2-min.png';
import Person3 from 'assets/images/people/home/person3-min.png';
import Person4 from 'assets/images/people/home/person4-min.png';
import Person5 from 'assets/images/people/about/person5_small-min.png';
import Person6 from 'assets/images/people/home/person6-min.png';
import Line from 'assets/images/dashed_line-min.png';

import { Check } from '@material-ui/icons';
import useStyles from './styles';

const AboutOurMission = () => {
  const [, setCounter] = useState(false);
  const styles = useStyles();

  const { countUp: sessionsCount, start: sessionsStart } = useCountUp({
    start: 0,
    end: 450,
    duration: 5,
  });

  const { countUp: clientsCount, start: clientsStart } = useCountUp({
    start: 0,
    end: 2000,
    duration: 5,
  });

  const { countUp: teaCupsCount, start: teaCupsStart } = useCountUp({
    start: 0,
    end: 5400,
    duration: 5,
  });

  const { countUp: storiesCount, start: storiesStart } = useCountUp({
    start: 0,
    end: 500,
    duration: 5,
  });

  const handleScroll = () => {
    const element = document.querySelector('.jsCounter');
    const bounds = element?.getBoundingClientRect();

    if (bounds?.top <= window.innerHeight) {
      setCounter((v) => {
        if (bounds?.top <= window.innerHeight && !v) {
          sessionsStart();
          clientsStart();
          teaCupsStart();
          storiesStart();
          return true;
        }
        return v;
      });
    }
  };

  useEffect(() => {
    const scrollWithTrottle = throttle(handleScroll, 500);

    document.addEventListener('scroll', scrollWithTrottle);

    return () => window.removeEventListener('scroll', scrollWithTrottle);

    /* eslint react-hooks/exhaustive-deps: 0 */
  }, []);

  return (
    <>
      <Wave animated />

      <Box bgcolor="primary.main" className={styles.sectionWrapper}>
        <Box position="relative">
          <img src={Person} alt="person" className={styles.personImageTop} />
          <img
            src={Person2}
            alt="person two"
            className={styles.personImageTop}
          />
          <img
            src={Person6}
            alt="person six"
            className={styles.personImageTop}
          />
          <Container>
            <Grid container className={styles.missionGrid}>
              <Grid item xs={12} md={5} className={styles.quoteGrid}>
                <Box position="relative">
                  <img
                    src={Person3}
                    alt="person three"
                    className={styles.personImageQuoteBottom}
                  />
                  <Box className={styles.reviewsTile}>
                    <Box className={styles.personImageQuoteWrapper}>
                      <img
                        src={Person1}
                        alt="person one"
                        className={styles.personImageQuote}
                      />
                      <img
                        src={Person4}
                        alt="person four"
                        className={styles.personImageQuote}
                      />
                      <img
                        src={Person5}
                        alt="person five"
                        className={styles.personImageQuote}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      component="p"
                      color="textSecondary"
                      className={styles.quote}
                    >
                      This has probably been the most helpful counseling of my
                      life. The therapist made me feel so comfortable. Very
                      considerate yet still professional. I felt like I was
                      talking with an old friend.”
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      component="p"
                      color="textSecondary"
                      className={styles.quoteAuth}
                    >
                      William Connor, TOC customer
                    </Typography>
                    <Grid container justify="flex-end">
                      <Box className={styles.reviewButton}>
                        <Icon fontSize="small" className="fas fa-angle-left" />
                      </Box>

                      <Box ml={1} className={styles.reviewButton}>
                        <Icon fontSize="small" className="fas fa-angle-right" />
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className={styles.descriptionTile}>
                <Typography variant="subtitle1" className={styles.subtitle}>
                  <Box color="logoColors.primary" fontWeight="700">
                    Our mission?
                  </Box>
                </Typography>

                <Box mb={4}>
                  <Typography variant="h2" color="textSecondary">
                    Our mission is to change the narrative and remove the stigma
                    around mental health care.
                  </Typography>
                </Box>

                <Typography variant="body1" className={styles.descriptionText}>
                  <Box color="lightText" component="span">
                    We utilize cutting-edge technology and deliver convenient,
                    affordable service to those looking to feel their best. We
                    aim to improve lives ... one telehealth session at a time.
                  </Box>
                </Typography>

                <Typography variant="body1" className={styles.descriptionText}>
                  <Box color="lightText" component="span">
                    Partnering with clinically sound and professionally licensed
                    mental health counselors, we deliver telehealth service with
                    high standards of ethics, dedication to privacy, and
                    attention to the consumer experience.
                  </Box>
                </Typography>

                <Box textAlign="left">
                  <Grid className={styles.descriptionList}>
                    <Box
                      mr={2.5}
                      bgcolor="secondary.main"
                      color="white"
                      borderRadius="100%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width={35}
                      height={35}
                      flexShrink={0}
                    >
                      <Check />
                    </Box>

                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={styles.listItem}
                    >
                      <Box color="lightText" component="span" textAlign="left">
                        Our practice is 100% paperless.
                      </Box>
                    </Typography>
                  </Grid>

                  <Grid className={styles.descriptionList}>
                    <Box
                      mr={2.5}
                      bgcolor="secondary.main"
                      color="white"
                      borderRadius="100%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width={35}
                      height={35}
                      flexShrink={0}
                    >
                      <Check />
                    </Box>

                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={styles.listItem}
                    >
                      <Box color="lightText" component="span">
                        Our video chat sessions are 100% online.
                      </Box>
                    </Typography>
                  </Grid>

                  <Grid className={styles.descriptionList}>
                    <Box
                      mr={2.5}
                      bgcolor="secondary.main"
                      color="white"
                      borderRadius="100%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width={35}
                      height={35}
                      flexShrink={0}
                    >
                      <Check />
                    </Box>

                    <Grid item xs={10}>
                      <Typography
                        variant="subtitle1"
                        component="span"
                        className={styles.listItem}
                      >
                        <Box color="lightText" component="span">
                          Our telehealth software is secure, confidential and
                          100% HIPPA compliant.
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Container>
          <Box className={styles.figuresTitleWrapper}>
            <Typography color="textSecondary" variant="h3">
              So how are we doing so far?
            </Typography>
          </Box>

          <Grid container className={styles.figuresContentWrapper}>
            <Grid item xs={12} sm={6} md={3} className="jsCounter">
              <Box className={styles.figureWrapper1}>
                <Box position="relative" mb={3}>
                  <Typography color="secondary">
                    <Box fontSize={48} lineHeight={1} component="span">
                      {sessionsCount}
                    </Box>
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    sessions today
                  </Typography>

                  <Box className={styles.figureLink1}>
                    <img src={Line} alt="line" />
                  </Box>
                </Box>

                <Typography variant="overline">
                  <Box color="lightText" lineHeight="24px">
                    Our system enables counseling sessions across the country
                    that suit your schedule.
                  </Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Box className={styles.figureWrapper2}>
                <Box position="relative" mb={3}>
                  <Typography color="secondary">
                    <Box fontSize={48} lineHeight={1} component="span">
                      {clientsCount}
                    </Box>
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    happy clients
                  </Typography>

                  <Box className={styles.figureLink2}>
                    <img src={Line} alt="line" />
                  </Box>
                </Box>

                <Typography variant="overline">
                  <Box color="lightText" lineHeight="24px">
                    We’re proud to be helping so many people with the issues
                    that are most important to them.
                  </Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Box className={styles.figureWrapper3}>
                <Box position="relative" mb={3}>
                  <Typography color="secondary">
                    <Box fontSize={48} lineHeight={1} component="span">
                      {teaCupsCount}
                    </Box>
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    cups of tea
                  </Typography>

                  <Box className={styles.figureLink3}>
                    <img src={Line} alt="line" />
                  </Box>
                </Box>

                <Typography variant="overline">
                  <Box color="lightText" lineHeight="24px">
                    Both our counselors and our customers work hard on their
                    mental health - hard work deserves a tea break!
                  </Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Box className={styles.figureWrapper4}>
                <Box position="relative" mb={3}>
                  <Typography color="secondary">
                    <Box fontSize={48} lineHeight={1} component="span">
                      {storiesCount}
                    </Box>
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    success stories
                  </Typography>
                </Box>

                <Typography variant="overline">
                  <Box color="lightText" lineHeight="24px">
                    We are so proud to have provided a service that’s helped
                    over 500 people become healthy and happy.
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Wave animated invert bgcolor="waveSecondaryColor" />
    </>
  );
};

export default AboutOurMission;
