import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import routes from 'common/constants/routes';
import SideMenu from 'common/components/SideMenu';
import SPWidgetService from 'modules/spWidget';

import Home from './containers/Home';
import Search from './containers/Search';
import Profile from './containers/Profile';
import AboutUs from './containers/AboutUs';
import JoinUs from './containers/JoinUs';
import FAQ from './containers/FAQ';
import Contact from './containers/Contact';
import Blog from './containers/Blog';
import BlogArticle from './containers/BlogArticle';
import Terms from './containers/Terms';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Dashboard from './containers/admin/Dashboard';
import ManageClients from './containers/admin/ManageClients';
import EditClient from './containers/admin/EditClient';
import ClientProfile from './containers/admin/ClientProfile';
import ProviderProfile from './containers/admin/Provider/Profile';
import ProvidersOverview from './containers/admin/Provider/ProvidersOverview';
import InsuranceTransactions from './containers/admin/Billing/InsuranceTransactions';
import TransactionDetails from './containers/admin/Billing/TransactionDetails';
import CreditCardPayments from './containers/admin/Billing/CreditCardPayments';
import Reports from './containers/admin/Reports';
import ManageAssessments from './containers/admin/Assessments/ManageAssessments';
import AssignedAssessments from './containers/admin/Assessments/AssignedAssesments';
import Appointments from './containers/admin/Appointments';

const Routes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Switch>
        <Route exact path={routes.home.url} component={Home} />
        <Route exact path={routes.search.url} component={Search} />
        <Route exact path={routes.profile.url} component={Profile} />
        <Route exact path={routes.about.url} component={AboutUs} />
        <Route exact path={routes.joinUs.url} component={JoinUs} />
        <Route exact path={routes.faq.url} component={FAQ} />
        <Route exact path={routes.contact.url} component={Contact} />
        <Route exact path={routes.blog.url} component={Blog} />
        <Route exact path={routes.blogArticle.url} component={BlogArticle} />
        <Route exact path={routes.termsConditions.url} component={Terms} />
        <Route
          exact
          path={routes.privacyPolicy.url}
          component={PrivacyPolicy}
        />
        <Route exact path={routes.admin.dashboard.url} component={Dashboard} />

        <Route
          exact
          path={routes.admin.manageClients.url}
          component={ManageClients}
        />

        <Route
          exact
          path={routes.admin.editClient.url}
          component={EditClient}
        />

        <Route
          exact
          path={routes.admin.clientProfile.url}
          component={ClientProfile}
        />

        <Route
          exact
          path={routes.admin.manageProviders.overview.url}
          component={ProvidersOverview}
        />

        <Route
          exact
          path={routes.admin.appointments.url}
          component={Appointments}
        />

        <Route
          exact
          path={routes.admin.manageProviders.profile.url}
          component={ProviderProfile}
        />

        <Route
          exact
          path={routes.admin.billing.insuranceTransactions.url}
          component={InsuranceTransactions}
        />
        <Route
          path={routes.admin.billing.transactionDetails.url}
          component={TransactionDetails}
        />
        <Route
          exact
          path={routes.admin.billing.creditCardPayments.url}
          component={CreditCardPayments}
        />
        <Route
          exact
          path={routes.admin.assessments.manageAssessments.url}
          component={ManageAssessments}
        />
        <Route
          exact
          path={routes.admin.assessments.assignedAssessments.url}
          component={AssignedAssessments}
        />
        <Route exact path={routes.admin.reports.url} component={Reports} />
        <Route path="*">
          <Redirect to={routes.home.url} />
        </Route>
      </Switch>

      <SideMenu />
      <SPWidgetService />
    </>
  );
};

export default Routes;
