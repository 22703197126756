import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ContactsIcon from '@material-ui/icons/Contacts';
import Button from 'common/components/admin/Button';
import ClientsDropdown from 'common/components/admin/ClientsDropdown';

const AssignedProviders = () => {
  return (
    <Grid
      container
      component={Box}
      py={2}
      px={2}
      borderRadius={10}
      alignItems="center"
      style={{ backgroundColor: '#ffff' }}
    >
      <Grid
        item
        component={Box}
        p={1}
        height={50}
        width={50}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: '#c8d8e5' }}
        borderRadius={15}
      >
        <ContactsIcon style={{ color: '#0d648e' }} />
      </Grid>
      <Grid item container component={Box} pl={3} flex={1}>
        <Grid item container direction="row" component={Box} pb={1}>
          <Grid item container direction="column" component={Box} flex={1}>
            <Typography
              variant="subtitle1"
              style={{
                fontSize: '1rem',
                lineHeight: 1.4,
              }}
            >
              Assigned Provider(s)
            </Typography>
            <Typography style={{ fontSize: '0.9rem', color: '#A6AEB7' }}>
              Here you can manualy change current assigned providers, or add new
              ones
            </Typography>
          </Grid>
          <Button
            bgColor="#093b54"
            color="#ffff"
            fontSize={10}
            style={{ height: 30 }}
          >
            Add new provider
          </Button>
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <Grid item component={Box} width="48%">
            <ClientsDropdown />
          </Grid>
          <Grid item component={Box} width="48%">
            <ClientsDropdown />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignedProviders;
