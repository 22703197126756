import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import WomanWateringPlant from 'assets/images/woman_watering_plant-min.png';

import useStyles from './styles';

const HeaderSection = () => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Container>
        <Grid container justify="space-between" alignItems="center" spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} sm={12}>
                <Typography color="textPrimary" variant="h4">
                  <Box fontWeight="600" component="span">
                    Need to talk?
                  </Box>
                </Typography>

                <Typography variant="h1">Contact us</Typography>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.imageGridSm}>
                <img src={WomanWateringPlant} alt="woman watering plant" />
              </Grid>
            </Grid>

            <Box mt={3}>
              <Typography variant="h4" component="div">
                <Box fontWeight="400" lineHeight={1.5}>
                  We&apos;re here to help.
                </Box>
              </Typography>
              <Typography variant="h4" component="div">
                <Box fontWeight="400" lineHeight={1.5}>
                  Get in touch if you have any technical questions about using
                  TOC or if you&apos;d just like to speak with us.
                </Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} className={styles.imageGrid}>
            <img src={WomanWateringPlant} alt="woman watering plant" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeaderSection;
