import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  dropdown: {
    color: palette.black,
    height: '100%',
    width: '100%',
  },
  dropdownLabel: {
    color: palette.text.disabled,
    fontWeight: 500,
    fontSize: '13px',
    marginTop: spacing(0.4),
    marginBottom: spacing(1),
  },
  select: {
    color: palette.primary.main,
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& > .MuiSelect-select:focus': {
      backgroundColor: palette.transparent,
    },
  },
  selectMenuItem: {
    color: palette.primary.main,
  },
}));
