import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => {
  return {
    container: {
      marginBottom: spacing(15),
    },
    wrapper: {
      color: palette.primary.main,
    },
    stepItemGrid: {
      alignItems: 'center',
      flexDirection: 'column',

      [breakpoints.down('sm')]: {
        marginBottom: spacing(4),
      },
    },
    title: {
      maxWidth: 610,
      width: '100%',
      marginBottom: spacing(15),

      [breakpoints.down('md')]: {
        marginBottom: spacing(8),
      },
      [breakpoints.down('sm')]: {
        marginBottom: spacing(2),
      },

      [breakpoints.down('sm')]: {
        marginBottom: spacing(4),
      },
    },
    subtitle: {
      marginBottom: spacing(1.2),
    },
    firstStepImage: {
      maxWidth: 370,
      width: '100%',

      [breakpoints.down('sm')]: {
        maxWidth: 'unset',
        width: 250,
      },
    },
    secondStepImage: {
      maxWidth: 370,
      width: '100%',
      marginTop: spacing(6.25),

      [breakpoints.down('sm')]: {
        maxWidth: 'unset',
        width: 250,
        marginTop: 0,
        order: -1,
        marginBottom: -10,
      },
    },
    thirdStepImage: {
      maxWidth: 370,
      width: '100%',
      marginTop: spacing(6.25),

      [breakpoints.down('sm')]: {
        marginTop: 0,
        maxWidth: 'unset',
        width: 250,
      },
    },
    stepTitle: {
      lineHeight: 1.4,
      marginBottom: spacing(1.5),
      maxWidth: '80%',
      textAlign: 'center',
    },
    stepDescriptionBlock: {
      width: '78%',
      lineHeight: 1.6,
    },
    feedbackNoticeWrap: {
      boxSizing: 'border-box',
      maxWidth: 870,
      width: '100%',
      marginTop: spacing(13.75),
      padding: spacing(3.75, 5.625),
      boxShadow: `0 24px 40px 0 ${palette.black}08`,

      [breakpoints.down('md')]: {
        marginTop: spacing(10),
      },
      [breakpoints.down('sm')]: {
        padding: spacing(3.75, 3),
        marginTop: spacing(2),
      },
      [breakpoints.down('xs')]: {
        padding: spacing(3, 3),
        marginTop: spacing(2),
      },
    },
    feedbackNoticeBackground: {
      borderRadius: '0 30px',
      backgroundColor: palette.secondary.lighter,
      color: palette.alert.info,
    },
    feedbackNoticeMessage: {
      padding: 0,
    },
    feedbackNoticeMessageText: {
      lineHeight: 1.7,
    },
    circles: {
      height: 100,
      width: 100,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: spacing(2),
      marginBottom: spacing(4),
      '& .MuiTypography-root': {
        fontSize: '2rem',
        color: 'white',
      },
    },

    firstCircle: {
      backgroundColor: palette.logoColors.secondary,
      boxShadow: `${palette.logoColors.secondary}90 0px 10px 20px 0px`,
    },
    secondCircle: {
      order: -1,
      backgroundColor: palette.logoColors.tertiary,
      boxShadow: `${palette.logoColors.tertiary}90 0px 10px 20px 0px`,
    },
    thirdCircle: {
      backgroundColor: palette.logoColors.primary,
      boxShadow: `${palette.logoColors.primary}90 0px 10px 20px 0px`,
    },
  };
});
