import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Footer,
  HomeHeader,
  SmartphoneAppAdvertisement,
} from 'common/components';

import HelloSection from './components/HelloSection';
import HowItWorksSection from './components/HowDoesItWorkSection';
import OurMissionSection from './components/OurMissionSection';
import FAQSection from './components/FAQSection';
import BlogSection from './components/BlogSection';

import { fetchPosts, fetchOptions } from './ducks';

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPosts());
    dispatch(fetchOptions());
  }, [dispatch]);

  return (
    <>
      <HomeHeader />

      <HelloSection />
      <HowItWorksSection />
      <OurMissionSection />
      <FAQSection />
      <BlogSection />
      <SmartphoneAppAdvertisement />

      <Footer />
    </>
  );
};

export default HomePage;
