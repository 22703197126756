import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import StatisticsLayout from '../components/StatisticsLayout';

const TotalStatistics = ({
  title,
  value,
  icon,
  iconBgColor,
  link,
  children,
  linkPosition,
  ...rest
}) => {
  return (
    <StatisticsLayout link={link} linkPosition={linkPosition} {...rest}>
      <Grid
        container
        direction="row"
        alignItems="center"
        component={Box}
        pb={3}
      >
        <Grid
          item
          component={Box}
          p={1}
          height={50}
          width={50}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: iconBgColor }}
          borderRadius={15}
        >
          {icon}
        </Grid>

        <Grid
          item
          container
          direction="column"
          component={Box}
          flex={1}
          style={{ marginLeft: 20 }}
        >
          {children || (
            <>
              <Grid item>
                <Typography style={{ fontSize: '2.8rem', lineHeight: '3rem' }}>
                  {value}
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    color: '#6f8999',
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </StatisticsLayout>
  );
};

TotalStatistics.propTypes = {
  icon: PropTypes.node,
  iconBgColor: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  linkPosition: PropTypes.string,
};

export default TotalStatistics;
