import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  footerWrap: {
    marginTop: spacing(-0.5),
    paddingTop: spacing(6),
    backgroundColor: palette.primary.main,
  },
  footerContentWrap: {
    paddingBottom: spacing(15),
    margin: spacing(0, 'auto'),

    [breakpoints.down('md')]: {
      paddingBottom: spacing(10),
    },
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(5),
    },
  },
  logoWrap: {
    paddingBottom: spacing(7),

    [breakpoints.down('xs')]: {
      paddingBottom: spacing(5),
    },
  },
  logo: {
    width: 180,

    [breakpoints.down('xs')]: {
      marginBottom: spacing(4),
    },
  },
  logoWrapper: {
    textAlign: 'left',

    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  rightsText: {
    color: palette.semiWhite,
  },
  link: {
    textDecoration: 'none',
    marginRight: spacing(2.875),
    '&:last-child': {
      marginRight: 0,
    },
    [breakpoints.down('md')]: {
      marginRight: spacing(2),
    },
    [breakpoints.down('sm')]: {
      marginRight: 0,
      margin: spacing(2),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(0.5),
    },
  },
  footerLink: {
    color: palette.semiWhite,
    textDecoration: 'none',
    borderBottom: `1px solid ${palette.semiWhite}`,
    '& > *': {
      color: palette.semiWhite,
    },
  },
  socialLinks: {
    justifyContent: 'flex-end',

    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  dot: {
    '&:before': {
      content: '""',
      margin: spacing(1),
      display: 'block',
      width: 4,
      height: 4,
      borderRadius: '100%',
      backgroundColor: palette.semiWhite,
    },
  },
  menu: {
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: spacing(4),
      order: -1,
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  copyright: {
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  policy: {
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  menuWrapper: {
    justifyContent: 'flex-end',
  },
}));
