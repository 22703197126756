import makeStyles from '@material-ui/styles/makeStyles';

// excess indent at the top and bottom of svg element.
// it's a bug of react-wavify and wavify libraries
const negativeMargin = -2;

export default makeStyles(({ breakpoints }) => ({
  static: {
    marginTop: -110,
    marginBottom: negativeMargin,
  },
  dynamic: ({ invert, height }) => {
    if (invert && height) {
      return {
        zIndex: -1,
        position: 'absolute',
        top: `calc(100% + ${negativeMargin}px)`,
        transform: 'rotate(180deg)',
        display: 'flex !important',
      };
    }

    if (invert) {
      return {
        display: 'flex !important',
        transform: 'rotate(180deg)',
        marginTop: negativeMargin,
        height: 150,

        [breakpoints.down('sm')]: {
          height: 100,
        },
      };
    }

    return {
      display: 'flex !important',
      marginBottom: negativeMargin,
      height: 150,

      [breakpoints.down('sm')]: {
        height: 100,
      },
    };
  },
}));
