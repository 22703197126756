import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography, breakpoints }) => ({
  infoSectionContainer: {
    borderRadius: 15,
    marginBottom: spacing(7),
    border: `1px solid ${palette.text.primary}30`,
    backgroundColor: palette.white,

    [breakpoints.down('sm')]: {
      marginBottom: spacing(4),
    },
  },
  avatarSection: {
    padding: spacing(3.125),
    width: '27%',
    minWidth: 230,

    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  infoSectionAvatar: {
    width: 180,
    height: 180,
  },
  infoSection: {
    padding: spacing(5.5, 3.125, 3.125, 0),

    [breakpoints.down('sm')]: {
      padding: spacing(3.125, 2),
    },
    [breakpoints.down('xs')]: {
      padding: spacing(2, 2),
    },
  },
  acceptingChip: {
    backgroundColor: palette.secondary.lighter,
    color: palette.primary.main,
    marginBottom: spacing(1.75),
  },
  infoSectionProfileName: {
    fontWeight: 300,
    marginBottom: spacing(1),
  },
  price: {
    fontSize: typography.pxToRem(30),
  },
  priceDegree: {
    fontSize: typography.pxToRem(14),
    paddingTop: spacing(0.8),
    display: 'flex',
    alignItems: 'flex-start',
    '& > .MuiSvgIcon-root': {
      fontSize: typography.pxToRem(16),
      margin: spacing(0.6, 0, 0, 0.6),
    },
    '&:before': {
      content: '"."',
    },
  },
  infoSectionAvailabilityChip: {
    height: 20,
    borderRadius: 3,
    marginRight: spacing(0.625),
    background: palette.secondary.lighter,
  },
  infoSectionLicenceIcon: {
    width: 24,
    marginRight: spacing(0.5),
  },
  licenseRow: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: spacing(0.5),
    '& .MuiTypography-root': {
      lineHeight: 1.4,
    },
  },
  experienceRow: {
    marginBottom: spacing(5.65),
    '& .MuiTypography-root': {
      lineHeight: 1.4,
    },

    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
    },
  },
  infoSectionExperienceIcon: {
    width: 24,
    marginRight: spacing(0.5),
  },
  insurance: {
    width: 'auto',
    display: 'flex',
    marginRight: spacing(1),
  },
  insuranceText: {
    marginLeft: spacing(0.2),
    marginTop: spacing(0.4),
  },
  introVideoContainer: {
    paddingTop: '56.25%',
    position: 'relative',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& > div > div > iframe': {
      borderRadius: '0 0 15px 15px',
    },
  },
}));
