const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1248,
  xl: 1920,
};

export default {
  values,
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
};
