import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  radio: {
    color: palette.secondary.main,
    paddingRight: 4,
    '& svg': {
      fontSize: '1rem',
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
  },
}));
