import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import PageTitle from 'common/components/admin/PageTitle';

import logo from 'assets/images/logo-min.png';

import useStyles from './styles';
import SideMenu from '../SideMenu';
import Header from '../Header';

const AdminLayout = ({
  subTitle,
  title,
  children,
  onWindowResize,
  onMenuExpand,
  actionButtons,
}) => {
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();
  const contentRef = useRef();
  const menuContainerRef = useRef();
  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
  }, [onWindowResize]);
  useEffect(() => {
    onMenuExpand();
    if (expanded) {
      setTimeout(() => {
        menuContainerRef.current.setAttribute('style', 'min-width: 281px');
      }, 300);
    }
  }, [expanded, onMenuExpand]);
  const handleExpandClick = () => {
    menuContainerRef.current.setAttribute('style', 'min-width: unset');
    setExpanded((prevState) => !prevState);
  };
  return (
    <Grid container component={Box} height="100vh">
      <Grid
        ref={menuContainerRef}
        item
        component={Box}
        container
        direction="column"
        className={[classes.root, expanded ? classes.menuExpanded : ''].join(
          ' '
        )}
      >
        <Grid
          item
          container
          component={Box}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <img className={classes.logo} src={logo} alt="logo" />
          <IconButton onClick={handleExpandClick}>
            <MenuOpenIcon />
          </IconButton>
        </Grid>
        <Grid item component={Box} pt={6} flex={1} display="flex">
          <SideMenu expanded={expanded} />
        </Grid>
      </Grid>

      <Grid
        item
        container
        component={Box}
        direction="column"
        flex={1}
        py={5}
        px={3}
        style={{ backgroundColor: '#edf0f5' }}
      >
        <Header />
        <Grid
          container
          display="flex"
          flexDirection="column"
          flex={1}
          component={Box}
        >
          <PageTitle
            subTitle={subTitle}
            title={title}
            actionButtons={actionButtons}
          />
          <Grid
            container
            display="flex"
            flexDirection="column"
            flex={1}
            component={Box}
            py={2}
            ref={contentRef}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AdminLayout.defaultProps = {
  onWindowResize: () => {},
  onMenuExpand: () => {},
};

AdminLayout.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  onWindowResize: PropTypes.func,
  onMenuExpand: PropTypes.func,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
};

export default AdminLayout;
