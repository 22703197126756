import React from 'react';
import { TOCAccordion } from 'common/components';
import { Typography } from '@material-ui/core';

const PaymentAndInsurance = () => (
  <>
    <TOCAccordion title="What kinds of insurance do you take?">
      <Typography variant="body1">
        We accept many commercial health plans. Please see our Insurances page.
        If we are not credentialed in your plan and you would like us to be,
        please send an email to{' '}
        <b>insurances&shy;@textandonline&shy;counseling.com</b>.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="Can I pay with cash or check?">
      <Typography variant="body1">
        Unfortunately, no. Services and copay amounts must be charged to a
        credit card on file. These fees are deducted 24 hours prior to the
        session. If the card is declined, the session cannot take place until
        the card is updated and fees are paid.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What if I have health insurance but no mental health benefits?">
      <Typography variant="body1">
        If your health insurance plan does not provide mental health coverage,
        you must pay the counselor’s full session rate for each session.
      </Typography>
    </TOCAccordion>

    <TOCAccordion title="What if I have mental health benefits but I do not have telehealth coverage?">
      <Typography variant="body1">
        If your health insurance plan does not provide telehealth mental health
        coverage, you must pay the counselor’s full session rate for each
        session.
      </Typography>
    </TOCAccordion>
  </>
);

export default PaymentAndInsurance;
