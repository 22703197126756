import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  shortItem: {
    maxWidth: '15%',
  },
  longItem: {
    width: '35%',
    maxWidth: '35%',
    flexBasis: '35%',
    paddingRight: '2%',
  },
  radio: {
    color: palette.secondary.main,
    paddingRight: 4,
    '& svg': {
      fontSize: 15,
    },
  },
  radioLabel: {
    fontSize: 12,
  },
  sectionTitle: {
    paddingBottom: 6,
    fontSize: '1.2rem',
    fontWeight: 500,
  },
}));
