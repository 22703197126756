import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  button: {
    color: '#b0b8c1',
    backgroundColor: '#e4eaf0',
    fontWeight: 800,
    minWidth: 'unset',
    padding: '20 12 20 12',
    textTransform: 'capitalize',
    fontSize: 14,
    border: 'none',
    borderRadius: 10,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#e4eaf0',
      boxShadow: 'none',
    },
  },
  activeButton: {
    color: '#ffff',
    backgroundColor: '#093b54',
    borderRadius: 10,
    boxShadow: '0px 2px 9px #113148',
    zIndex: 2,
    '&:hover': {
      backgroundColor: '#093b54',
      boxShadow: '0px 2px 9px #113148',
    },
  },
});
