import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import InfoCard from 'common/components/admin/InfoCard';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DataItem from 'common/components/admin/profiles/DataItem';
// import OverviewLayout from 'common/components/admin/profiles/ProfileOverview';

import useStyles from './styles';
import defaultData from './data';

const PersonalInfo = ({ data }) => {
  const classes = useStyles();
  const {
    imageUrl,
    name,
    username,
    dateJoined,
    sex,
    phone,
    email,
    address,
  } = data;
  return (
    <InfoCard
      title="Overview"
      link={{
        title: 'View & Update',
      }}
      LinkIcon={EditIcon}
    >
      <Grid container direction="column">
        <Grid item container component={Box} pb={2}>
          <Grid
            item
            component={Box}
            className={classes.avatar}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
          <Grid
            item
            container
            component={Box}
            flex={1}
            px={3}
            pb={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Grid item component={Typography} className={classes.name}>
              {name}
            </Grid>
            <Grid item component={Box} py={1}>
              <DataItem title="Username" value={username} />
            </Grid>
            <Grid item component={Box} py={1}>
              <DataItem title="Active since" value={dateJoined} />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="column" component={Box} py={2}>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            component={Box}
            py={0.2}
          >
            <Grid
              item
              component={Typography}
              className={classes.middleSectionLabel}
              variant="subtitle2"
              color="textPrimary"
            >
              Active Profile
            </Grid>
            <Grid item component={Switch} />
          </Grid>

          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            component={Box}
            py={0.2}
          >
            <Grid
              item
              component={Typography}
              className={classes.middleSectionLabel}
              variant="subtitle2"
              color="textPrimary"
            >
              Portal Access
            </Grid>
            <Grid item component={Box} display="flex" alignItems="center">
              <Grid item component={Typography} style={{ fontSize: '1rem' }}>
                No
              </Grid>
              <Grid
                item
                component={MenuItem}
                className={classes.middleSectionMenuItem}
              >
                Resend invitation
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            component={Box}
            py={0.4}
          >
            <Grid
              item
              component={Typography}
              className={classes.middleSectionLabel}
              variant="subtitle2"
              color="textPrimary"
            >
              Insurance Benefits
            </Grid>
            <Grid
              item
              component={MenuItem}
              className={classes.middleSectionMenuItem}
            >
              Check eligibility
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="column" component={Box} py={2}>
          <Grid item container component={Box} py={1}>
            <Grid item component={Box} width={150}>
              <DataItem title="Date Joined" value={dateJoined} />
            </Grid>
            <Grid item component={Box} px={3}>
              <DataItem title="Sex" value={sex} />
            </Grid>
          </Grid>

          <Grid item container component={Box} py={1}>
            <Grid container>
              <Grid item>
                <DataItem title="Phone" value={phone} />
              </Grid>
              <Grid
                item
                container
                component={Box}
                className={classes.lowerLabelBlock}
              >
                <Typography
                  color="textPrimary"
                  style={{ fontSize: '0.74rem', paddingLeft: 5 }}
                >
                  Mobile
                </Typography>
                <Tooltip title="Do not call" placement="top" arrow>
                  <PhoneDisabledIcon
                    style={{
                      fontSize: '0.74rem',
                      color: 'red',
                      paddingLeft: 5,
                    }}
                  />
                </Tooltip>
                <PhoneIphoneIcon
                  style={{
                    fontSize: '0.74rem',
                    color: 'green',
                    paddingLeft: 5,
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container display="flex" component={Box} py={1}>
              <Grid container>
                <Grid item>
                  <DataItem title="Email" value={email} />
                </Grid>

                <Grid
                  item
                  container
                  component={Box}
                  className={classes.lowerLabelBlock}
                >
                  <Typography
                    color="textPrimary"
                    style={{ fontSize: '0.74rem', paddingLeft: 5 }}
                  >
                    Home
                  </Typography>
                  <MailOutlineIcon
                    style={{
                      fontSize: '0.74rem',
                      color: 'green',
                      paddingLeft: 5,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item component={Box} py={1}>
            <DataItem title="Address" value={address} />
          </Grid>
        </Grid>

        <Divider />

        <Grid
          item
          container
          justify="space-between"
          component={Box}
          pt={3}
          pb={1}
        >
          <Grid
            item
            component={Typography}
            className={classes.middleSectionLabel}
            variant="subtitle2"
            color="textPrimary"
          >
            Password reset required?
          </Grid>
          <Grid item component={MenuItem} style={{ padding: 0 }}>
            <Typography
              className={classes.middleSectionLabel}
              style={{ color: '#ee6563' }}
              variant="subtitle2"
            >
              Reset password
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
};

PersonalInfo.defaultProps = {
  data: defaultData,
};

PersonalInfo.propTypes = {
  data: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    dateJoined: PropTypes.string,
    sex: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
  }),
};

export default PersonalInfo;
