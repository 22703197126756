import React from 'react';

import { Header, Footer } from 'common/components';
import ProsSection from './components/ProsSection';
import AboutSoftware from './components/AboutSoftware';
import CounselorFAQSection from './components/CounselorFAQSection';

const JoinUs = () => {
  return (
    <>
      <Header height={{ xs: '400px', sm: '500px' }} />
      <ProsSection />
      <AboutSoftware />
      <CounselorFAQSection />
      <Footer />
    </>
  );
};

export default JoinUs;
