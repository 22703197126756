import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  sizeMedium: {
    borderRadius: 15,
    padding: spacing(1),
    minHeight: ({ height }) => height || 53,
    width: ({ width }) => width || 100,
    boxShadow: ({ shadow, color }) =>
      shadow ? `0 6px 20px 0 ${palette[color]?.main}80` : 'none',
    '& .MuiTypography-root': {
      color: palette.white,
    },
  },
  sizeLarge: ({ height }) => ({
    minHeight: height || 79,

    [breakpoints.down('sm')]: {
      minHeight: 50,
    },
    [breakpoints.down('xs')]: {
      borderRadius: 10,
      minHeight: 40,
    },
  }),
  buttonText: {
    textTransform: 'none',
  },
  containedSecondary: {
    '&:hover': {
      boxShadow: `0px 2px 4px -1px ${palette.secondary.shadow}20, 0px 4px 5px 0px ${palette.secondary.shadow}14, 0px 1px 10px 0px ${palette.secondary.shadow}12`,
    },
  },
}));
