import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

const ToggleButton = ({ value, label, isSelected, onClick }) => {
  const classes = useStyles();
  const handleClick = () => {
    onClick(value);
  };
  const rootClassName = [classes.root, isSelected ? classes.selected : ''].join(
    ' '
  );
  return (
    <Button
      className={rootClassName}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

ToggleButton.defaultProps = {
  onClick: () => {},
};

ToggleButton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ToggleButton;
