import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing, breakpoints }) => ({
  personImage: {
    zIndex: 1,
    position: 'absolute',
    '&:nth-child(1)': {
      top: -240,
      left: '11%',
    },
    '&:nth-child(2)': {
      top: -160,
      right: '20%',
    },
    '&:nth-child(3)': {
      top: 100,
      left: '-1.4%',
    },
    '&:nth-child(4)': {
      bottom: -30,
      left: '8%',
    },
    '&:nth-child(5)': {
      bottom: 130,
      left: '28%',
    },
    '&:nth-child(6)': {
      bottom: -190,
      right: '25%',
    },
    '&:nth-child(7)': {
      bottom: 35,
      right: '9%',
    },

    '@media (min-width:1925px)': {
      '&:nth-child(1)': {
        top: -240,
        left: 'calc(50% - 750px)',
      },
      '&:nth-child(2)': {
        top: -160,
        right: 'calc(50% - 580px)',
      },
      '&:nth-child(3)': {
        top: 100,
        left: 'calc(50% - 820px)',
      },
      '&:nth-child(4)': {
        bottom: 60,
        left: 'calc(50% - 800px)',
      },
      '&:nth-child(5)': {
        bottom: 230,
        left: 'calc(50% - 420px)',
      },
      '&:nth-child(6)': {
        bottom: -160,
        right: 'calc(50% - 480px)',
      },
      '&:nth-child(7)': {
        bottom: 70,
        right: 'calc(50% - 790px)',
      },
    },

    '@media (max-width:1400px)': {
      '&:nth-child(1)': {
        top: -200,
        left: '30%',
      },
      '&:nth-child(2)': {
        top: -100,
      },
      '&:nth-child(3)': {
        top: -100,
        left: '5%',
      },
      '&:nth-child(4)': {
        left: '3%',
      },
      '&:nth-child(6)': {
        bottom: -100,
        right: '55%',
      },
      '&:nth-child(7)': {
        bottom: -30,
      },
    },

    [breakpoints.down('sm')]: {
      '&:nth-child(1)': {
        width: 100,
      },
      '&:nth-child(2)': {
        width: 80,
        right: '4%',
      },
      '&:nth-child(3)': {
        width: 80,
        top: -70,
      },
      '&:nth-child(4)': {
        width: 100,
        bottom: -110,
        right: '50%',
        left: 'unset',
      },
      '&:nth-child(5)': {
        bottom: -50,
        right: '80%',
        left: 'unset',
      },
      '&:nth-child(6)': {
        width: 80,
        bottom: -80,
        right: '25%',
      },
      '&:nth-child(7)': {
        width: 60,
        bottom: -50,
        right: '5%',
      },
    },

    '@media (max-width:500px)': {
      '&:nth-child(4)': {
        width: 80,
      },
      '&:nth-child(5)': {
        width: 50,
      },
      '&:nth-child(6)': {
        width: 60,
        bottom: -70,
      },
      '&:nth-child(7)': {
        width: 40,
        bottom: -30,
      },
    },
  },
  subtitle: {
    paddingTop: spacing(4),
    marginBottom: spacing(1.2),

    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    width: '90%',

    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
      width: '100%',
      textAlign: 'center',
    },
  },
  text: {
    marginBottom: spacing(3.75),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
      textAlign: 'center',
    },
  },
  button: {
    marginTop: spacing(1.4),

    [breakpoints.down('sm')]: {
      margin: spacing(2, 'auto', 0),
      textAlign: 'center',
    },
  },
  contentGrid: {
    paddingBottom: spacing(4),
  },
}));
