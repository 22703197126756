import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Box } from '@material-ui/core';

import { counselorType } from 'common/constants/propTypes';
import { TOCButton } from 'common/components/simple';
import { openSPWidget } from 'modules/spWidget/ducks';
import useStyles from './styles';

const AppointmentSection = ({ counselor }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  return (
    <Grid className={styles.container}>
      <TOCButton
        width={230}
        height={60}
        size="large"
        color="secondary"
        disabled={!counselor.availability.length}
        onClick={() => dispatch(openSPWidget())}
      >
        <Box fontWeight="700" component="span">
          Request appointment
        </Box>
      </TOCButton>
    </Grid>
  );
};

AppointmentSection.propTypes = {
  counselor: counselorType,
};

export default AppointmentSection;
