import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { search } from '../reducers/searchResult';

function* filterCounselorsSagaRequest() {
  yield requestSaga({
    actionType: search.counselors,
    request: dataProvider.getList,
    resource: resources.counselors,
  });
}

export default filterCounselorsSagaRequest;
