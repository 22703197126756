import { combineReducers } from 'redux';
import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const home = actionTypesGenerator(['posts', 'states'], 'home');

const initialState = {
  list: [],
  loading: true,
  error: false,
};

const postsReducer = reducerGenerator(
  home.posts,
  initialState,
  (state, payload) => ({
    ...state,
    list: payload.data,
  })
);

const statesReducer = reducerGenerator(
  home.states,
  initialState,
  (state, payload) => ({
    ...state,
    list: payload.data.availableStates,
  })
);

export default combineReducers({
  posts: postsReducer,
  states: statesReducer,
});

export const fetchPosts = () => ({
  type: home.posts.requested,
  payload: {
    pagination: {
      page: 1,
      perPage: 3,
    },
    sort: {
      field: 'createdAt',
      order: 'DESC',
    },
  },
});

export const fetchOptions = () => ({
  type: home.states.requested,
  payload: {
    pagination: {
      page: 1,
      perPage: 1,
    },
    sort: {
      field: '',
      order: '',
    },
  },
});
