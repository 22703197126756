import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

const styles = {
  root: {
    fontFamily: 'consolas, sans-serif',
    textAlign: 'center',
    position: 'relative',
    height: '100%',
  },
};

const MyResponsivePie = forwardRef(
  ({ data, innerRadius, totalTitle, totalValue }, ref) => {
    const rootRef = useRef();
    useImperativeHandle(ref, () => ({
      refresh(delay) {
        rootRef.current.style.display = 'none';
        setTimeout(() => {
          rootRef.current.style.display = 'block';
        }, delay || 350);
      },
    }));

    const CenteredMetric = ({ centerX, centerY }) => {
      return (
        <g x={centerX} y={centerY}>
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            fill="#32596f"
            style={{
              fontSize: 30,
              fontWeight: '500',
            }}
          >
            {totalValue}
          </text>
          <text
            x={centerX}
            y={centerY + 23}
            textAnchor="middle"
            dominantBaseline="central"
            fill="#b1b9c1"
            style={{
              display: 'block',
              fontSize: 11,
              fontWeight: '600',
            }}
          >
            {totalTitle}
          </text>
        </g>
      );
    };

    CenteredMetric.propTypes = {
      centerX: PropTypes.number,
      centerY: PropTypes.number,
    };

    return (
      <div style={styles.root} ref={rootRef}>
        <ResponsivePie
          data={data}
          margin={{ right: 110, left: 10, top: 10, bottom: 10 }}
          innerRadius={innerRadius}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#ffffff"
          arcLabel={(e) => `${e.value}%`}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              itemsSpacing: 20,
              itemWidth: 10,
              itemHeight: 10,
              translateX: 15,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 5,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
          layers={[
            'arcs',
            'arcLabels',
            'arcLinkLabels',
            'legends',
            CenteredMetric,
          ]}
          colors={{ datum: 'data.color' }}
        />
      </div>
    );
  }
);

MyResponsivePie.defaultProps = {
  innerRadius: 0.7,
};

MyResponsivePie.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  innerRadius: PropTypes.number,
  totalTitle: PropTypes.string,
  totalValue: PropTypes.string,
};

export default MyResponsivePie;
