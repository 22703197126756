import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import AdminLayout from 'common/components/admin/Layout';
import InfoCard from 'common/components/admin/InfoCard';
import MenuLink from 'common/components/admin/Link';
import EditableTextarea from 'common/components/admin/EditableTextarea';
import GraphStatistics from 'common/components/admin/Statistics/GraphStatistics';
import ResponsivePie from 'common/components/admin/Statistics/graphs/ResponsivePie';
import DataItem from 'common/components/admin/profiles/DataItem';
import Button from 'common/components/admin/Button';

import PersonalInfo from './components/PersonalInfo';
import CalendarSection from './components/CalendarSection';
import AssignedProviders from './components/AssignedProviders';
import FormDocumetns from './components/FormsDocuments';
import payments from './data/payments';

const ClientProfile = () => {
  const pie1 = useRef();
  const pie2 = useRef();
  const handleMenuExpand = () => {
    pie1.current?.refresh();
    pie2.current?.refresh();
  };
  return (
    <AdminLayout
      title="Client Profile"
      onWindowResize={handleMenuExpand}
      onMenuExpand={handleMenuExpand}
      actionButtons={[
        <Button
          key={1}
          bgColor="#093b53"
          color="#ffff"
          startIcon={<EditIcon style={{ fontSize: 14 }} />}
          style={{ marginRight: '1em' }}
        >
          Update client info
        </Button>,
        <Button
          key={1}
          bgColor="#40ceba"
          color="#ffff"
          startIcon={<AddIcon style={{ fontSize: 14 }} />}
          style={{ marginRight: 34 }}
        >
          Create
        </Button>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item container direction="column" xs={12} lg={4} spacing={2}>
          <Grid item>
            <PersonalInfo />
          </Grid>

          <Grid item>
            <InfoCard
              title="Diagnosis & Treatment plan"
              link={{
                title: 'View & Update',
              }}
              LinkIcon={EditIcon}
            >
              <Grid
                container
                component={Box}
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <DataItem
                    title="Diagnosis"
                    value="F43. 23 Adjustment Disorder"
                  />
                </Grid>
                <Grid item>
                  <MenuLink
                    style={{ paddingTop: 1, paddingBottom: 1 }}
                    title="View & Edit Tx & Dx"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                component={Box}
                py={2}
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <DataItem
                    title="Progress notes"
                    value="Last Updated: 06/12/2020"
                  />
                </Grid>
                <Grid item>
                  <MenuLink
                    style={{ paddingTop: 1, paddingBottom: 1 }}
                    title="View & Edit Progress Notes"
                  />
                </Grid>
              </Grid>
            </InfoCard>
          </Grid>

          <Grid item>
            <InfoCard title="Admin notes">
              <Typography style={{ fontSize: 11, color: '#A6AEB7' }}>
                Here you can manualy change current assigned providers, or add
                ones
              </Typography>
              <EditableTextarea />
              <Box pt={2} display="flex" justifyContent="flex-end">
                <Button bgColor="#093b53" color="#ffff" px={1}>
                  Update notes
                </Button>
              </Box>
            </InfoCard>
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} lg={8} spacing={2}>
          <Grid item>
            <CalendarSection />
          </Grid>

          <Grid item>
            <AssignedProviders />
          </Grid>

          <Grid item container justify="space-between">
            <Grid component={Box} width="49%">
              <GraphStatistics
                headerTitle="Enrollments"
                link={{
                  title: 'View apointments',
                }}
                style={{ height: '100%' }}
              >
                <Box height={250}>
                  <ResponsivePie
                    ref={pie1}
                    data={payments}
                    totalValue="351"
                    totalTitle="Total appointments"
                  />
                </Box>
              </GraphStatistics>
            </Grid>

            <Grid component={Box} width="49%">
              <GraphStatistics
                headerTitle="Payments"
                link={{
                  title: 'View apointments',
                }}
                style={{ height: '100%' }}
              >
                <Box height={250}>
                  <ResponsivePie
                    ref={pie2}
                    data={payments}
                    totalValue="$965"
                    totalTitle="Total payments"
                  />
                </Box>
              </GraphStatistics>
            </Grid>
          </Grid>

          <Grid item container justify="space-between">
            <Grid component={Box} width="49%">
              <InfoCard
                title="Billing info"
                link={{
                  title: 'View & Update',
                }}
                LinkIcon={EditIcon}
                flex={1}
              >
                <DataItem
                  title="Billing method"
                  value="Insurance"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Insurance type"
                  value="Primary Insurance"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Copy amount"
                  value="$400"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Deductible amount"
                  value="$99"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Deductible outstanding"
                  value="$0"
                  direction="row"
                  py={1}
                />
              </InfoCard>
            </Grid>

            <Grid component={Box} width="49%">
              <InfoCard
                title="Client balance"
                flex={1}
                style={{ height: '100%' }}
              >
                <DataItem
                  title="Uninvoiced amount"
                  value="$0"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Client Payments"
                  value="$400"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Insurance Payments"
                  value="$500"
                  direction="row"
                  py={1}
                />
                <DataItem
                  title="Insurance Balance"
                  value="$65"
                  direction="row"
                  py={1}
                />
              </InfoCard>
            </Grid>
          </Grid>

          <Grid item>
            <InfoCard
              title="Forms & Documents"
              link={{
                title: 'Upload new',
              }}
              LinkIcon={EditIcon}
            >
              <FormDocumetns />
            </InfoCard>
          </Grid>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default ClientProfile;
