import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    marginTop: spacing(10),
    marginBottom: spacing(10),

    [breakpoints.down('xs')]: {
      marginTop: spacing(8),
      marginBottom: spacing(8),
    },
  },
  imageGridSm: {
    display: 'none',

    [breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'left',
      '& img': {
        width: 150,
      },
    },
  },
  imageGrid: {
    textAlign: 'right',
    '& img': {
      maxWidth: '100%',
    },

    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));
