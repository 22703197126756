import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  countLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: 14,
    color: '#143A56',
  },
  boltString: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: -0.35,
    color: '#143A56',
  },
  checkBoxContainer: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  checkBox: {
    color: '#57CEBA',
  },
  iconButton: {
    height: 32,
    width: 32,
    borderRadius: 10,
  },
  iconButtonOpen: {
    backgroundColor: '#143A56',
  },
  iconButtonClose: {
    backgroundColor: '#EAF0F4',
  },
  subTable: {
    width: '98%',
    margin: 15,
  },
}));
