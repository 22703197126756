const patientColor = '#40ccb9';
const providerColor = '#1f6c94';

export default [
  {
    country: 'Jan',
    'New patient enrollments': 180,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 62,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Feb',
    'New patient enrollments': 92,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 159,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Mar',
    'New patient enrollments': 65,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 90,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Apr',
    'New patient enrollments': 36,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 140,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'May',
    'New patient enrollments': 26,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 62,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Jun',
    'New patient enrollments': 120,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 167,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Jul',
    'New patient enrollments': 43,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 160,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Aug',
    'New patient enrollments': 52,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 105,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Sep',
    'New patient enrollments': 80,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 90,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Oct',
    'New patient enrollments': 50,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 62,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Nov',
    'New patient enrollments': 150,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 162,
    'New provider enrollmentsColor': providerColor,
  },
  {
    country: 'Dec',
    'New patient enrollments': 10,
    'New patient enrollmentsColor': patientColor,
    'New provider enrollments': 62,
    'New provider enrollmentsColor': providerColor,
  },
];
