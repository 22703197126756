import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const blog = actionTypesGenerator(['tags'], 'blog');

const initialState = {
  tags: [],
  loading: true,
  error: false,
};

export default reducerGenerator(blog.tags, initialState, (state, payload) => ({
  ...state,
  tags: payload.data,
}));

export const getBlogTags = () => ({
  type: blog.tags.requested,
  payload: {
    // fixme fix GET endpoint in empeek package and remove unnecessary pagination
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'id', order: 'ASC' },
  },
});
