import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints, typography }) => ({
  articleWrapper: {
    maxWidth: 1000,
    margin: spacing(14.75, 'auto', 12),
    color: palette.primary.main,

    [breakpoints.down('md')]: {
      margin: spacing(10, 'auto', 10),
    },
    [breakpoints.down('sm')]: {
      margin: spacing(6, 'auto', 6),
    },
  },
  articleTitle: {
    marginBottom: spacing(7),

    [breakpoints.down('md')]: {
      marginBottom: spacing(5),
    },
    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
      '& .MuiTypography-caption': {
        fontSize: typography.pxToRem(12),
      },
    },
    '@media (max-width: 370px)': {
      '& .MuiTypography-caption': {
        fontSize: typography.pxToRem(11),
      },
    },
  },
  articleTitleName: {
    textAlign: 'center',
    lineHeight: 1.25,
    marginBottom: spacing(1),
  },
  articleCoverImage: {
    borderRadius: 30,
    width: '100%',
    height: 500,
    objectFit: 'cover',
    boxShadow: `13px 20px 40px 0px ${palette.black}15`,

    [breakpoints.down('sm')]: {
      height: 450,
    },
    [breakpoints.down('xs')]: {
      height: 250,
    },
  },
  articleContentWrapper: {
    maxWidth: 800,
    margin: '0 auto',
  },
  articleContent: {
    paddingTop: spacing(7.75),
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 1.66,

    [breakpoints.down('sm')]: {
      paddingTop: spacing(4),
    },

    '& > p': {
      margin: spacing(0, 0, 4),
      fontSize: typography.pxToRem(18),

      [breakpoints.down('sm')]: {
        margin: spacing(0, 0, 2),
        fontSize: typography.pxToRem(16),
      },
    },
    '& > p:last-child': {
      marginBottom: spacing(0),
    },
    '& a': {
      color: palette.secondary.main,
    },
    '& > h1': {
      fontFamily: 'Poppins',
      fontSize: typography.pxToRem(36),
      lineHeight: 1.25,
      fontWeight: 400,
      margin: spacing(2, 0, 3.5),

      [breakpoints.down('md')]: {
        fontSize: typography.pxToRem(30),
      },
      [breakpoints.down('sm')]: {
        fontSize: typography.pxToRem(25),
      },
      [breakpoints.down('xs')]: {
        margin: spacing(1, 0, 2),
        fontWeight: 600,
        fontSize: typography.pxToRem(20),
      },
    },
    '& > h2': {
      margin: spacing(0, 0, 3.5),
      fontSize: typography.pxToRem(24),
      lineHeight: 1.25,

      [breakpoints.down('sm')]: {
        margin: spacing(1.4, 0, 3.5),
        fontSize: typography.pxToRem(18),
      },
    },
    '& strong': {
      fontFamily: 'Poppins',
      fontWeight: 700,
    },
    '& img': {
      borderRadius: 15,
      width: '100%',
      margin: spacing(4, 0),

      [breakpoints.down('sm')]: {
        margin: spacing(2, 0),
      },
    },
    '& > blockquote': {
      margin: spacing(0, -7),
      position: 'relative',
      fontFamily: 'Poppins',
      fontWeight: 300,
      fontSize: typography.pxToRem(30),
      lineHeight: 1.2,

      [breakpoints.down('md')]: {
        fontSize: typography.pxToRem(25),
        margin: spacing(0, 0, 0, 4),
      },
      [breakpoints.down('xs')]: {
        fontSize: typography.pxToRem(20),
      },
    },
    '& > blockquote:before': {
      content: '"\\201C"',
      fontFamily: 'PassionOne Regular',
      top: '-0.17em',
      left: '-0.52em',
      position: 'absolute',
      color: palette.secondary.main,
      fontSize: '3em',
    },
    '& > blockquote:after': {
      content: '"\\201C"',
      position: 'absolute',
      bottom: '0.52em',
      fontFamily: 'PassionOne Regular',
      transform: 'rotate(180deg)',
    },
    '& > pre': {
      margin: spacing(1, -7, 6),
      fontFamily: 'Poppins',
      fontWeight: 500,
      color: palette.text.primary,
      whiteSpace: 'pre-wrap',
      lineHeight: 1.2,

      [breakpoints.down('md')]: {
        margin: spacing(1, 0, 6, 3.75),
      },
      [breakpoints.down('xs')]: {
        margin: spacing(1, 0, 3, 3.75),
      },
    },
  },
  articleShare: {
    marginTop: spacing(5),

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  articleShareButton: {
    width: 'auto',
    cursor: 'pointer',
    borderRadius: 15,
    outline: 'none',
    padding: spacing(1.5, 2),
    background: palette.white,
    border: `2px solid ${palette.text.disabled}`,

    [breakpoints.down('sm')]: {
      padding: spacing(0.75, 1),
    },
  },
  articleShareFacebookIcon: {
    marginRight: spacing(0.7),
    color: palette.shareButton.facebook,
  },
  articleShareTwitterIcon: {
    marginRight: spacing(0.7),
    color: palette.shareButton.twitter,
  },
  articleShareLinkedInIcon: {
    marginRight: spacing(0.7),
    color: palette.shareButton.linkedIn,
  },
  articleSharePinterestIcon: {
    marginRight: spacing(0.7),
    color: palette.shareButton.pinterest,
  },
  articleShareCopyTooltip: {
    margin: spacing(0.625, 0, 1.75),
  },
  articleShareCopyIcon: {
    marginRight: spacing(0.7),
  },
  articleNavigation: {
    justifyContent: 'space-between',

    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  articleNavigationLink: {
    marginRight: spacing(0.9),
    color: palette.text.primary,
  },
  articleNavigationLinkWrapper: {
    marginBottom: spacing(1),
  },
  articleNavigationName: {
    cursor: 'pointer',
    marginTop: spacing(1),
    lineHeight: 1.25,
    fontWeight: 600,
  },
  articleDivider: {
    marginTop: spacing(6.25),
    marginBottom: spacing(6),

    [breakpoints.down('sm')]: {
      marginTop: spacing(5),
      marginBottom: spacing(5),
    },
  },
}));
