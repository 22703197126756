import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Typography,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './styles';

const CheckList = (props) => {
  const { title, options, value = [], onChange } = props;
  const [list, setList] = useState(value);
  const styles = useStyles();

  const ledToNumber = typeof options?.[0]?.id === 'number';

  const isChecked = (checkBoxValue) => list.includes(checkBoxValue);

  const handleChange = ({ target: { value: v } }) => {
    const newValue = ledToNumber ? Number(v) : v;

    if (!list.includes(newValue)) {
      const result = [...list, newValue];
      setList(result);
      onChange(result);
      return;
    }

    const result = list.filter((listValue) => listValue !== newValue);
    setList(result);
    onChange(result);
  };

  return (
    <Accordion square defaultExpanded classes={{ root: styles.listContainer }}>
      <AccordionSummary
        id="toc-accordion"
        aria-controls="toc-accordion"
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: styles.listSummary,
          content: styles.listSummaryContent,
          expandIcon: styles.listSummaryIcon,
        }}
      >
        <Typography variant="subtitle2">
          <Box fontWeight="700" component="span">
            {title}
          </Box>
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: styles.listDetails }}>
        <FormGroup value={value}>
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              className={styles.checkLabel}
              label={
                <Typography variant="subtitle1" component="span">
                  <Box fontWeight="400" component="span">
                    {option.name}
                  </Box>
                </Typography>
              }
              control={
                <Checkbox
                  size="small"
                  value={option.id}
                  checked={isChecked(option.id)}
                  onChange={handleChange}
                  classes={{
                    root: styles.checkBase,
                    checked: styles.checked,
                  }}
                />
              }
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

CheckList.propTypes = {
  title: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default CheckList;
