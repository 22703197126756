import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  inputRoot: {
    width: 60,
  },
  input: {
    fontSize: 23,
    fontWeight: 600,
    color: 'black',
    '& > input': {
      padding: '0px 0px 0px 25px',
    },
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
  },
});
