import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

import routes from 'common/constants/routes';
import { BlogDateReadTime, TOCButton } from 'common/components/simple';
import { getLatestArticle } from '../../ducks';
import useStyles from './styles';

const LatestArticle = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    dispatch(getLatestArticle());
  }, [dispatch]);

  const { article, loading } = useSelector((store) => store.blog.latestArticle);

  if (loading || !article) {
    return <div />;
  }

  return (
    <Container>
      <Grid container className={styles.latestArticleWrapper}>
        <Grid
          item
          xs={12}
          sm={6}
          container
          className={styles.latestArticleDescriptionGrid}
        >
          <Typography variant="h4" className={styles.latestArticleHint}>
            Latest from the blog
          </Typography>

          <Typography variant="h2" className={styles.latestArticleName}>
            {article.name}
          </Typography>

          <BlogDateReadTime
            size="medium"
            date={article.createdAt}
            readTime={article.minutesToRead}
          />

          <Hidden smUp>
            <img
              alt="article cover"
              src={article.coverImageUrl}
              className={styles.latestArticleCoverImage}
            />
          </Hidden>

          <Grid item className={styles.latestArticleButton} container>
            <TOCButton
              color="secondary"
              width={236}
              size="large"
              to={routes.blogArticle.url.replace(':id', String(article.id))}
            >
              <Box fontWeight="700" component="span">
                Read the full post
              </Box>
            </TOCButton>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Hidden xsDown>
            <img
              alt="article cover"
              src={article.coverImageUrl}
              className={styles.latestArticleCoverImage}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LatestArticle;
