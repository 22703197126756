import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from 'common/components/admin/IconButton';
import VisaLogo from 'assets/images/icons/visa';
import { convertTime, getTwoDigitsValue } from 'common/utils/helpers';
import useStyles from './styles';

const getExpiresCard = (date) => {
  const tempDate = new Date(date);
  const tempMonth = tempDate.getMonth() + 1;
  const viewMonth = getTwoDigitsValue(tempMonth);
  return `${viewMonth} / ${tempDate.getFullYear()}`;
};

const getLastNumbersCard = (cardId) => cardId.toString().slice(12);

const getPaymentDate = (date) => {
  const tempDate = new Date(date);
  const parsedMonth = getTwoDigitsValue(tempDate.getMonth() + 1);
  const parsedDay = getTwoDigitsValue(tempDate.getDate());
  const fullYear = tempDate.getFullYear();
  const tempHours = tempDate.getHours();
  const tempMinutes = tempDate.getMinutes();
  const parsedTime = convertTime({ hours: tempHours, minutes: tempMinutes });
  return `${parsedMonth}/${parsedDay}/${fullYear} ${parsedTime}`;
};

const getRows = (data) => {
  const date = getPaymentDate(data.paymentDate);
  return [
    { title: 'Payment ID', value: data.paymentId },
    { title: 'Statement descriptor', value: data.statementDescriptor },
    { title: 'Amount', value: `$${data.amount}` },
    { title: 'Fee', value: `$${data.fee}` },
    { title: 'Net', value: `$${data.net}` },
    { title: 'Date', value: date },
  ];
};

const CreditCardModal = (props) => {
  const { data, handleCloseModal } = props;
  const expires = getExpiresCard(data.cardExpires);
  const cardId = getLastNumbersCard(data.cardNumber);
  const rows = getRows(data);
  const classes = useStyles();
  return (
    <Grid className={classes.modalContainer}>
      <Grid
        className={classes.mainTitleContainer}
        container
        component={Box}
        px={3}
        justifyContent="space-between"
      >
        <Typography className={classes.mainTitle}>Payment details</Typography>
        <IconButton
          style={{ backgroundColor: '#fff' }}
          onClick={handleCloseModal}
          IconComponent={CloseIcon}
          iconProps={{
            style: {
              color: '#A6AEB7',
            },
          }}
        />
      </Grid>
      <Divider />
      <Grid container component={Box} px={3} py={3}>
        <Typography className={classes.subTitle}>
          Charge on {data.date} for {data.client}
        </Typography>
        {rows.map(({ title, value }) => {
          return (
            <Grid key={title} container component={Box} direction="row">
              <Typography className={classes.paidTitle}>{title}</Typography>
              <Typography className={classes.paidContent}>{value}</Typography>
            </Grid>
          );
        })}
      </Grid>
      <Divider variant="middle" />
      <Grid container component={Box} direction="column" px={3} py={3}>
        <Typography className={classes.subTitle}>Card Details</Typography>
        <Grid container className={classes.cardContainer}>
          <Grid className={classes.leftContainer} />
          <Grid className={classes.rightContainer} />
          <Grid className={classes.cardLogoContainer}>
            <SvgIcon>{VisaLogo}</SvgIcon>
          </Grid>
          <Grid className={classes.cardContentContainer}>
            <Grid className={classes.cardId}>
              <Typography className={classes.cardTitle}>Card ID</Typography>
              <Typography className={classes.cardContent}>
                {data.paymentId}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.cardTitle}>Card number</Typography>
              <Typography className={classes.cardNumber}>
                ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                {cardId}
              </Typography>
            </Grid>
            <Grid
              className={classes.cardContentRow}
              container
              component={Box}
              direction="row"
              py={4}
            >
              <Grid item>
                <Typography className={classes.cardTitle}>
                  Cardholder
                </Typography>
                <Typography className={classes.cardContent}>
                  {data.client}
                </Typography>
              </Grid>
              <Grid item className={classes.cardRowItem}>
                <Typography className={classes.cardTitle}>Expires</Typography>
                <Typography className={classes.cardContent}>
                  {expires}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.cardTitle}>CVC</Typography>
                <Typography
                  className={[classes.cardContent, classes.greyContent].join(
                    ' '
                  )}
                >
                  Not provided
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CreditCardModal.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.number,
    cardExpires: PropTypes.string,
    cardNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    client: PropTypes.string,
    date: PropTypes.string,
    fee: PropTypes.number,
    id: PropTypes.number,
    net: PropTypes.number,
    paymentDate: PropTypes.string,
    paymentId: PropTypes.string,
    statementDescriptor: PropTypes.string,
    type: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func,
};

export default CreditCardModal;
