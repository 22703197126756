import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  faqWrapper: {
    color: palette.primary.main,
    margin: spacing(16, 'auto', 0),

    [breakpoints.down('md')]: {
      margin: spacing(12, 'auto', 0),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(8, 'auto', 0),
    },
  },
  faqHeaderSubtitle: {
    color: palette.text.primary,
    marginTop: spacing(6),
    fontWeight: 600,
    marginBottom: spacing(1.5),
  },
  faqHeadertitle: {
    letterSpacing: 0,
  },
  faqTopicSection: {
    flexWrap: 'nowrap',
    marginTop: spacing(12),

    [breakpoints.down('md')]: {
      marginTop: spacing(10),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: spacing(8),
    },
  },
  faqTopicTitle: {
    color: `${palette.primary.main}50`,

    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  faqTopicTitleWrapper: {
    flexShrink: 0,
  },
  faqTopicAnchor: {
    display: 'inline-block',
    textDecoration: 'none',
    margin: spacing(0, 0.625, 1, 0.625),
  },
  faqTopicAnchorWrapper: {
    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  faqTopicChip: {
    cursor: 'pointer',
    color: palette.primary.main,
  },
  faqDivider: {
    margin: spacing(10, 0),

    [breakpoints.down('md')]: {
      margin: spacing(8, 0),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(5, 0),
    },
  },
  faqTopicWrapper: {},
  faqTopicHeader: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: spacing(6),
    },
  },
  imgFluide: {
    maxWidth: '100%',
    height: 'auto',
  },
}));
