import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const TOCSimpleDropdown = (props) => {
  const { label, value, options, onChange } = props;
  const styles = useStyles();

  const handleChange = (ev) => onChange(ev.target.value);

  return (
    <FormControl className={styles.dropdown}>
      <Typography
        variant="h5"
        className={styles.dropdownLabel}
        component="span"
      >
        {label}
      </Typography>

      <Select
        labelId="toc-simple-select-label"
        id="toc-simple-select"
        value={value}
        onChange={handleChange}
        className={styles.select}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.id}
              value={option.id}
              className={styles.selectMenuItem}
            >
              <Typography variant="h4" component="span">
                {option.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

TOCSimpleDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  onChange: PropTypes.func,
};

export default TOCSimpleDropdown;
