import React from 'react';

import { Header, Footer, SmartphoneAppAdvertisement } from 'common/components';
import FAQList from './components/FAQList';

const FAQ = () => {
  return (
    <>
      <Header height={{ xs: '300px', sm: '350px' }} />
      <FAQList />
      <SmartphoneAppAdvertisement />
      <Footer />
    </>
  );
};

export default FAQ;
