import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const CustomRadioGroup = ({
  options,
  label,
  direction = 'row',
  interval = 0,
}) => {
  const classes = useStyles();
  let directionProps = {};
  if (direction === 'row') {
    directionProps = { row: true };
  }
  if (direction === 'column') {
    directionProps = { column: true };
  }
  return (
    <Box>
      {label && (
        <Typography style={{ fontSize: '0.85rem', fontWeight: 600 }}>
          {label}
        </Typography>
      )}
      <RadioGroup {...directionProps}>
        {options.map(({ value, label: optionLabel }, idx) => (
          <>
            <FormControlLabel
              key={value}
              value={value}
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label={optionLabel}
            />
            {direction === 'column' && idx < options.length - 1 && (
              <Box py={interval} />
            )}
          </>
        ))}
      </RadioGroup>
    </Box>
  );
};

CustomRadioGroup.propTypes = {
  label: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  interval: PropTypes.number,
};

export default CustomRadioGroup;
