import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const CustomMenuItem = ({
  IconComponent = null,
  title,
  url,
  otherUrls = [],
  expanded,
  currentPath,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isActive =
    currentPath === url ||
    otherUrls.some((otherUrl) => otherUrl === currentPath);
  const titleClasses = [
    classes.title,
    isActive ? classes.active : '',
    !expanded ? classes.hidden : '',
  ].join(' ');
  const titleIconClasses = [
    classes.itemIcon,
    isActive ? classes.active : '',
  ].join(' ');
  const goToUrl = useCallback(() => {
    dispatch(push(url));
  }, [dispatch, url]);
  return (
    <MenuItem
      component={Box}
      className={classes.item}
      display="flex"
      alignItems="center"
      onClick={goToUrl}
    >
      <ListItemIcon className={titleIconClasses}>{IconComponent}</ListItemIcon>
      <Typography variant="subtitle2" className={titleClasses}>
        {title}
      </Typography>
    </MenuItem>
  );
};

CustomMenuItem.propTypes = {
  url: PropTypes.string,
  IconComponent: PropTypes.node,
  title: PropTypes.string,
  expanded: PropTypes.bool,
  currentPath: PropTypes.string,
  otherUrls: PropTypes.arrayOf(PropTypes.string),
};

export default CustomMenuItem;
