import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';

import FirstStep from 'assets/images/first_step-min.png';
import SecondStep from 'assets/images/second_step-min.png';
import ThirdStep from 'assets/images/third_step-min.png';

import useStyles from './styles';

const HowDoesItWorkSection = () => {
  const styles = useStyles();

  return (
    <Grid className={styles.container}>
      <Container>
        <Grid className={styles.wrapper}>
          <Grid item container direction="column" alignItems="center">
            <Typography
              variant="h5"
              color="textPrimary"
              className={styles.subtitle}
            >
              <Box fontWeight="700" component="span">
                How does it work?
              </Box>
            </Typography>

            <Typography variant="h2" align="center" className={styles.title}>
              We make it easy and comfortable to get professional counseling
            </Typography>

            <Grid item container direction="row">
              <Grid
                item
                xs={12}
                md={4}
                container
                className={styles.stepItemGrid}
              >
                <Hidden smDown>
                  <img
                    src={FirstStep}
                    alt="first step"
                    className={styles.firstStepImage}
                  />
                </Hidden>

                <Hidden mdUp>
                  <Grid
                    className={styles.circles}
                    classes={{
                      root: styles.firstCircle,
                    }}
                  >
                    <Typography variant="h4">01</Typography>
                  </Grid>
                </Hidden>

                <Typography variant="h4" className={styles.stepTitle}>
                  Select your state from the drop down
                </Typography>

                <Typography
                  align="center"
                  variant="body1"
                  className={styles.stepDescriptionBlock}
                >
                  It&apos;s quick and easy to search our licensed counselors and
                  find someone great to speak with.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                container
                className={styles.stepItemGrid}
              >
                <Typography variant="h4" className={styles.stepTitle}>
                  Search Counselors
                </Typography>

                <Typography
                  align="center"
                  variant="body1"
                  className={styles.stepDescriptionBlock}
                >
                  Use our intuitive filters and search features to narrow down
                  your selections.
                </Typography>

                <Hidden smDown>
                  <img
                    src={SecondStep}
                    alt="second step"
                    className={styles.secondStepImage}
                  />
                </Hidden>

                <Hidden mdUp>
                  <Grid
                    className={styles.circles}
                    classes={{
                      root: styles.secondCircle,
                    }}
                  >
                    <Typography variant="h4">02</Typography>
                  </Grid>
                </Hidden>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                container
                className={styles.stepItemGrid}
              >
                <Hidden smDown>
                  <img
                    src={ThirdStep}
                    alt="third step"
                    className={styles.thirdStepImage}
                  />
                </Hidden>

                <Hidden mdUp>
                  <Grid
                    className={styles.circles}
                    classes={{
                      root: styles.thirdCircle,
                    }}
                  >
                    <Typography variant="h4">03</Typography>
                  </Grid>
                </Hidden>

                <Typography variant="h4" className={styles.stepTitle}>
                  Make an appointment
                </Typography>

                <Typography
                  align="center"
                  variant="body1"
                  className={styles.stepDescriptionBlock}
                >
                  Once you&apos;ve found the counselor you&apos;d like to work
                  with, use our booking tools to make an appointment at a time
                  that suits you-in the comfort of your own home.
                </Typography>
              </Grid>
            </Grid>

            <Alert
              severity="info"
              className={styles.feedbackNoticeWrap}
              classes={{
                standardInfo: styles.feedbackNoticeBackground,
                message: styles.feedbackNoticeMessage,
              }}
              icon={
                <Icon
                  fontSize="large"
                  color="secondary"
                  className="far fa-lightbulb"
                />
              }
            >
              <Typography
                variant="h5"
                className={styles.feedbackNoticeMessageText}
              >
                <Box fontWeight="700" component="span">
                  Your feedback is important. Don&lsquo;t forget to rate the
                  counselor after having a session.
                </Box>
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default HowDoesItWorkSection;
