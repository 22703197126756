import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { profile } from '../index';

function* getCounselorSagaRequest() {
  yield requestSaga({
    actionType: profile.counselor,
    request: dataProvider.getOne,
    resource: resources.counselor,
  });
}

export default getCounselorSagaRequest;
