import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { availabilityOptions } from 'common/constants/staticOptions';
import { Range, RadioList, CheckList } from 'common/components/simple';
import { saveFilters } from '../../ducks';

import useStyles from './styles';

const FilterList = () => {
  const dispatch = useDispatch();

  const { options } = useSelector((store) => store.search.options);
  const { filters } = useSelector((store) => store.search.filters);

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [expandedWrapper, setExpandedWrapper] = useState(false);

  const handleChange = (name, value) => {
    dispatch(saveFilters({ [name]: value }));
  };

  const SecondaryFilters = () => {
    return (
      <>
        <CheckList
          title="Select a speciality"
          value={filters.specialities}
          options={options?.availableSpecialities || []}
          onChange={(value) => handleChange('specialities', value)}
        />

        <RadioList
          allowEmpty
          value={filters.insurance}
          title="Insurance accepted"
          options={options?.availableInsurances || []}
          onChange={(value) => handleChange('insurance', value)}
        />

        <CheckList
          title="Gender"
          value={filters.genders}
          options={options?.genders || []}
          onChange={(value) => handleChange('genders', value)}
        />

        <CheckList
          title="Language"
          value={filters.languages}
          options={options?.availableLanguages || []}
          onChange={(value) => handleChange('languages', value)}
        />

        <CheckList
          title="Ethnicity"
          value={filters.ethnicity}
          options={options?.availableEthnicity || []}
          onChange={(value) => handleChange('ethnicity', value)}
        />

        {options ? (
          <Range
            title="Age"
            value={filters.age}
            min={options?.maxAvailableAge}
            max={options?.minAvailableAge}
            step={1}
            valueType="number"
            onChange={(value) => handleChange('age', value)}
          />
        ) : null}
      </>
    );
  };

  const WrappedFilters = () => {
    const [expanded, setExpanded] = useState(expandedWrapper);
    const handleChangeExpanded = () => {
      setExpanded((v) => !v);
      setExpandedWrapper((v) => !v);
    };

    return (
      <Accordion
        square
        expanded={expanded}
        onChange={handleChangeExpanded}
        classes={{ root: styles.accordionContainer }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="wrappedFilters-content"
          id="wrappedFilters-header"
          classes={{
            root: styles.summaryContainer,
            expandIcon: styles.listSummaryIcon,
          }}
        >
          <Typography variant="subtitle2">
            <Box fontWeight="700">Filters</Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.detailsContainer }}>
          <SecondaryFilters />
        </AccordionDetails>
      </Accordion>
    );
  };

  const ResponsiveFilters = useCallback(() => {
    return <WrappedFilters />;
  }, []);

  return (
    <>
      <RadioList
        row
        value={filters.availability}
        title="Select an option"
        options={availabilityOptions || []}
        onChange={(value) => handleChange('availability', value)}
      />

      {matches ? <ResponsiveFilters /> : <SecondaryFilters />}
    </>
  );
};
export default FilterList;
