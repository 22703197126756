export default [
  {
    id: 'Insurance Payments',
    label: 'Insurance Payments',
    value: 48,
    color: 'rgba(175,205,80,255)',
  },
  {
    id: 'Self pay fees',
    label: 'Self pay fees',
    value: 10,
    color: 'rgba(64,206,186,255)',
  },
  {
    id: 'No Show Fees',
    label: 'No Show Fees',
    value: 33,
    color: 'rgba(52,155,204,255)',
  },
  {
    id: 'Copay',
    label: 'Copay',
    value: 6,
    color: 'rgba(13,99,142,255)',
  },
  {
    id: 'Deductible',
    label: 'Deductible',
    value: 6,
    color: 'rgba(9,59,84,255)',
  },
];
