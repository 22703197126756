import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  popoverContainer: {
    marginRight: 20,
    borderRadius: 10,
    boxShadow: '0px 5px 15px #00000027',
  },
  itemRow: {
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginBottom: '0.5rem',
    },
    '&:hover': {
      backgroundColor: '#F8FAFA',
      color: '#000',
      opacity: 0.6,
    },
  },
  itemRowTitle: {
    alignSelf: 'center',
    paddingBottom: 2,
    paddingLeft: 4,
    fontSize: 14,
    color: '#143A56',
    letterSpacing: -0.35,
  },
});
