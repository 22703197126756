import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import useStyles from './styles';

const iconStyles = {
  fontSize: 14,
  color: '#D2D6DB',
};

const DateInput = () => {
  const classes = useStyles();
  const defaultDate = new Date();
  const viewDate = (date) => {
    const renderDate = new Date(date);
    return `${renderDate.getMonth()}/${renderDate.getDate()}/${renderDate.getFullYear()}`;
  };
  const [startDateValue, setStartDate] = useState(viewDate(defaultDate));
  const [endDateValue, setEndDate] = useState(defaultDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Grid
      item
      container
      component={Box}
      flex={2}
      border={1}
      className={classes.dateInput}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Icon className="fas fa-calendar-alt" style={iconStyles} />
        <Grid item container component={Box} className={classes.dates}>
          <DatePicker
            className={classes.date}
            autoOk
            disableFuture
            disableToolbar
            orientation="landscape"
            value={startDateValue}
            onChange={handleStartDateChange}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input },
            }}
            format="MM/dd/yyyy"
          />
          <Box sx={{ mx: 2 }} flex={1}>
            {' '}
            &nbsp;-&nbsp;{' '}
          </Box>
          <DatePicker
            autoOk
            disableFuture
            disableToolbar
            className={classes.date}
            value={endDateValue}
            onChange={handleEndDateChange}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input },
            }}
            format="MM/dd/yyyy"
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

export default DateInput;
