export default {
  home: {
    title: 'Home',
    url: '/home',
  },
  search: {
    title: 'Search',
    url: '/search',
  },
  profile: {
    title: 'Profile',
    url: '/profile/:id',
  },
  about: {
    title: 'About',
    url: '/about',
  },
  faq: {
    title: 'FAQ',
    url: '/faq',
  },
  joinUs: {
    title: 'Join Our Group',
    url: '/join-us',
  },
  contact: {
    title: 'Contact',
    url: '/contact',
  },
  blog: {
    title: 'Blog',
    url: '/blog',
  },
  blogArticle: {
    title: 'Blog Article',
    url: '/article/:id',
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    url: '/privacy-policy',
  },
  termsConditions: {
    title: 'Terms & Conditions',
    url: '/terms-of-use',
  },
  admin: {
    dashboard: {
      title: 'Dashboard',
      url: '/dashboard',
    },
    manageClients: {
      title: 'Manage Clients',
      url: '/manageClients',
    },
    editClient: {
      title: 'Client',
      url: '/manageClients/:id/edit',
    },
    clientProfile: {
      title: 'Client',
      url: '/manageClients/:id',
    },
    manageProviders: {
      overview: {
        title: 'Providers Overview',
        url: '/manageProviders/overview',
      },
      profile: {
        title: 'Provider Profile',
        url: '/manageProviders/:id/profile',
      },
      inquiries: {
        title: 'Provider Inquiries',
        url: '/manageProviders/inquiries',
      },
      credentialing: {
        title: 'Credentialing',
        url: '/manageProviders/credentialing',
      },
    },
    appointments: {
      title: 'Appointments',
      url: '/appointments',
    },
    billing: {
      title: 'Billing & insurance',
      url: '/billing',
      recentTransactions: {
        title: 'Recent transactions',
        url: '/billing/recentTransactions',
      },
      insuranceTransactions: {
        title: 'Insurance transactions',
        url: '/billing/insuranceTransactions',
      },
      transactionDetails: {
        title: 'Insurance transactions',
        url: '/billing/transactionDetails/:id',
      },
      creditCardPayments: {
        title: 'Credit card payments',
        url: '/billing/creditCardPayments',
      },
      billingDocuments: {
        title: 'Billing documents',
        url: '/billing/billingDocuments',
      },
    },
    assessments: {
      title: 'Assessments',
      url: '/assessments',
      manageAssessments: {
        title: 'Manage assessments',
        url: '/assessments/manage',
      },
      assignedAssessments: {
        title: 'Assigned assessments',
        url: '/assessments/assigned',
      },
    },
    reports: {
      title: 'Reports',
      url: '/reports',
    },
    agency: {
      serviceCodes: {
        title: 'serviceCodes',
        url: '/agency/serviceCodes',
      },
    },
    accountActivity: {
      title: 'accountActivity',
      url: '/accountActivity',
    },
  },
};
