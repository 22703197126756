import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  divider: {
    margin: '16px 0px 24px 0px',
  },
  creditCardsContainer: {
    maxWidth: '20rem',
  },
  creditCardIcon: {
    width: 30,
  },
  creditCardNumber: {
    fontSize: '0.8rem',
    fontWeight: 900,
    letterSpacing: 0.8,
  },
  creditCardEdit: {
    padding: '1px 15px',
    fontSize: '0.8rem',
    color: palette.secondary.main,
    fontWeight: 900,
  },
  creditExpireDate: {
    fontSize: '0.8rem',
    color: '#bec4ca',
    fontWeight: 500,
    position: 'relative',
    bottom: 10,
  },
  subtitle: {
    fontSize: '0.86rem',
    fontWeight: 600,
  },
  rateInput: {
    borderRadius: 5,
    marginRight: 20,
    height: 27.4,
    '& input': {
      padding: 5,
      width: 90,
    },
  },
  sectionTitle: {
    paddingBottom: 6,
    fontSize: '1.2rem',
    fontWeight: 500,
  },
}));
