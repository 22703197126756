import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  resetButton: {
    textTransform: 'capitalize',
    backgroundColor: '#093b54',
    color: '#ffff',
    padding: 7,
    fontSize: 14,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#093b54',
      opacity: 0.6,
    },
  },
}));
