import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography, breakpoints }) => ({
  sectionWrapper: {
    paddingTop: spacing(10),
    paddingBottom: spacing(20),

    [breakpoints.down('sm')]: {
      paddingBottom: spacing(10),
    },

    [breakpoints.down('xs')]: {
      paddingBottom: spacing(2),
    },
  },
  missionGrid: {
    justifyContent: 'space-between',
  },
  descriptionTile: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  reviewsTile: {
    position: 'relative',
    backgroundColor: palette.primary.lighter,
    alignSelf: 'flex-start',
    padding: spacing(18, 7, 6),
    borderRadius: '0 60px',
    boxShadow: `0 6px 10px 5px ${palette.black}10`,

    [breakpoints.down('md')]: {
      padding: spacing(15, 7, 6),
    },
    [breakpoints.down('sm')]: {
      padding: spacing(8, 4, 4),
    },
  },
  quoteGrid: {
    position: 'relative',

    [breakpoints.down('sm')]: {
      marginTop: spacing(12.5),
      order: 1,
    },
  },
  reviewButton: {
    width: 43,
    height: 43,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.primary.main,
    color: palette.text.secondary,
    borderRadius: '100%',
    '& > .MuiIcon-root': {
      width: 'auto',
    },
  },
  personImageTop: {
    zIndex: 1,
    position: 'absolute',
    '&:nth-child(1)': {
      top: -210,
      left: '20%',
    },
    '&:nth-child(2)': {
      left: 0,
      top: 40,
    },
    '&:nth-child(3)': {
      top: -20,
      left: '35%',
    },

    '@media (min-width:1925px)': {
      '&:nth-child(1)': {
        top: -210,
        left: 'calc(50% - 570px)',
      },
      '&:nth-child(2)': {
        left: 'calc(50% - 976px)',
        top: 40,
      },
      '&:nth-child(3)': {
        top: -20,
        left: 'calc(50% - 240px)',
      },
    },
    '@media (max-width:1400px)': {
      '&:nth-child(2)': {
        top: -120,
        left: 0,
      },
      '&:nth-child(3)': {
        top: -70,
        left: '35%',
      },
    },
    [breakpoints.down('sm')]: {
      '&:nth-child(1)': {
        top: -210,
        left: '30%',
      },
      '&:nth-child(2)': {
        top: -120,
        left: 0,
      },
      '&:nth-child(3)': {
        top: -100,
        left: '75%',
      },
    },
    [breakpoints.down('xs')]: {
      '&:nth-child(1)': {
        width: 100,
        top: -210,
        left: '40%',
      },
      '&:nth-child(2)': {
        top: -120,
        left: 0,
      },
      '&:nth-child(3)': {
        top: -100,
        left: 'unset',
        right: 5,
      },
    },
  },
  personImageQuoteWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  personImageQuote: {
    zIndex: 1,
    position: 'absolute',
    '&:nth-child(1)': {
      top: 230,
      left: -100,
    },
    '&:nth-child(2)': {
      top: 360,
      right: -20,
    },
    '&:nth-child(3)': {
      bottom: -160,
      right: 0,
    },

    '@media (max-width:1400px)': {
      '&:nth-child(1)': {
        top: 'unset',
        bottom: -55,
        left: 200,
      },
      '&:nth-child(2)': {
        top: 220,
        right: -40,
      },
      '&:nth-child(3)': {
        width: 100,
        right: -50,
      },
    },
    [breakpoints.down('sm')]: {
      '&:nth-child(1)': {
        bottom: -70,
        left: '60%',
      },
      '&:nth-child(2)': {
        top: -20,
        right: '60%',
      },
      '&:nth-child(3)': {
        width: 80,
        bottom: 'unset',
        top: -30,
        right: '20%',
      },
    },
  },
  personImageQuoteBottom: {
    position: 'absolute',
    bottom: -50,
    left: -100,

    '@media (max-width:1400px)': {
      width: 120,
      bottom: -190,
      left: 0,
      zIndex: 1,
    },
    [breakpoints.down('sm')]: {
      width: 100,
      bottom: -50,
      left: '10%',
    },
  },
  rotateDashedLine: {
    transform: 'rotate(70deg)',
  },
  quote: {
    position: 'relative',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: 0,
    '&:before': {
      content: '"\\201C"',
      position: 'absolute',
      fontSize: typography.pxToRem(72),
      fontFamily: 'PassionOne Regular',
      top: -97,
      left: 0,
      color: palette.text.secondary,
    },

    [breakpoints.down('md')]: {
      '&:before': {
        fontSize: typography.pxToRem(64),
        top: -80,
      },
    },
    [breakpoints.down('sm')]: {
      '&:before': {
        fontSize: typography.pxToRem(52),
        top: -50,
      },
    },
    [breakpoints.down('xs')]: {
      '&:before': {
        fontSize: typography.pxToRem(36),
        top: -40,
      },
    },
  },
  quoteAuth: {
    marginTop: spacing(5.5),
    marginBottom: spacing(2.75),

    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
      marginBottom: spacing(2.75),
    },
  },
  descriptionText: {
    marginBottom: spacing(4),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
    },
  },
  descriptionList: {
    display: 'flex',
    flexWrap: 'no-wrap',
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  subtitle: {
    marginBottom: spacing(1),
  },
  listItem: {
    fontWeight: 700,
    lineHeight: 1.875,
  },

  figuresTitleWrapper: {
    marginTop: spacing(27),

    [breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: spacing(22),
    },
    [breakpoints.down('sm')]: {
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
  figuresContentWrapper: {
    marginTop: spacing(10),

    [breakpoints.down('sm')]: {
      marginTop: spacing(6),
    },
  },
  figureWrapper1: {
    marginRight: spacing(4),

    [breakpoints.down('xs')]: {
      textAlign: 'center',
      marginRight: 0,
      marginBottom: spacing(6),
    },
  },
  figureWrapper2: {
    marginRight: spacing(4),
    marginTop: spacing(13),

    [breakpoints.down('sm')]: {
      marginRight: 0,
    },
    [breakpoints.down('xs')]: {
      textAlign: 'center',
      marginTop: 0,
      marginBottom: spacing(6),
    },
  },
  figureWrapper3: {
    marginRight: spacing(5),
    marginLeft: spacing(1.5),

    [breakpoints.down('xs')]: {
      textAlign: 'center',
      marginRight: 0,
      marginLeft: 0,
      marginBottom: spacing(6),
    },
  },
  figureWrapper4: {
    marginTop: spacing(13),
    marginLeft: spacing(1.5),

    [breakpoints.down('xs')]: {
      textAlign: 'center',
      marginTop: 0,
      marginLeft: 0,
      marginBottom: spacing(6),
    },
  },
  figureLink1: {
    position: 'absolute',
    top: 30,
    right: 10,
    '& img': {
      transform: 'rotate(70deg)',
    },

    [breakpoints.down('md')]: {
      right: -20,
    },
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  figureLink2: {
    position: 'absolute',
    top: -65,
    right: 30,

    [breakpoints.down('md')]: {
      right: 0,
    },
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  figureLink3: {
    position: 'absolute',
    top: 30,
    right: -10,
    '& img': {
      transform: 'rotate(70deg)',
    },

    [breakpoints.down('md')]: {
      right: -40,
    },
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));
