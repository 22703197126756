import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AdminLayout from 'common/components/admin/Layout';
import Button from 'common/components/admin/Button';
import { AntTab, AntTabs } from './components/Tabs';
import UnbilledClaims from './components/UnbilledClaims';
import BilledClaims from './components/BilledClaims';
import PaidClaims from './components/PaidClaims';

const useStyles = makeStyles({
  topButton: {
    borderRadius: 20,
    boxShadow: '0px 5px 15px #143A5680',
    color: '#fff',
  },
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: '0.9rem',
    color: 'black',
  },
  actionsCol: {
    width: 122,
  },
});

const InsuranceTransactions = () => {
  const classes = useStyles();
  const actionButtonsRow = [
    <Button key={0} bgColor="#143A56" className={classes.topButton}>
      Create claims
    </Button>,
    <Button key={1} bgColor="#143A56" className={classes.topButton}>
      Create & submit claims
    </Button>,
  ];
  const singleActionButton = (
    <Button
      key={2}
      startIcon={<AddIcon />}
      bgColor="#40ceba"
      className={classes.topButton}
    >
      Apply payment
    </Button>
  );
  const renderedTable = [
    <UnbilledClaims key={0} />,
    <BilledClaims key={1} />,
    <PaidClaims key={2} />,
  ];
  const [currentTab, setCurrentTab] = useState(1);
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };
  return (
    <AdminLayout
      subTitle="Billing"
      title="Insurance transactions"
      actionButtons={
        currentTab
          ? [singleActionButton]
          : [...actionButtonsRow, singleActionButton]
      }
    >
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <AntTabs value={currentTab} onChange={handleChangeTab}>
          <AntTab label="Unbilled claims" />
          <AntTab label="Billed claims" />
          <AntTab label="Paid claims" />
        </AntTabs>
        {renderedTable[currentTab]}
      </Grid>
    </AdminLayout>
  );
};

export default InsuranceTransactions;
