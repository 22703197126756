import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    position: 'relative',
  },
  logoMenuContainer: {
    paddingTop: spacing(10),

    [breakpoints.down('md')]: {
      paddingTop: spacing(3),
    },
    [breakpoints.down('xs')]: {
      paddingTop: spacing(2),
    },
  },
  wrapper: {
    color: palette.primary.main,
  },
  logo: {
    width: 160,
    height: 78,
    cursor: 'pointer',
  },
  headerEmptySpace: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    width: '100%',
    backgroundColor: palette.waveSecondaryColor,
  },
}));
