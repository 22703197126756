import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  clientsCountLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
  },
  radio: {
    color: palette.secondary.main,
    paddingRight: 4,
    '& svg': {
      fontSize: '1rem',
    },
  },
  radioLabel: {
    fontSize: '0.83rem',
  },
  input: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '0.9rem',
    color: 'black',
    '&::placeholder': {
      fontWeight: 500,
      color: 'grey',
    },
  },
  resetButton: {
    textTransform: 'capitalize',
    backgroundColor: '#093b54',
    color: '#ffff',
    fontSize: '0.9rem',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#093b54',
      opacity: 0.6,
    },
  },
  textField: {
    '& > div': {
      borderRadius: 10,
    },
  },
}));
