import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import createEnum from 'common/utils/createEnum';
import useStyles from './styles';

const justifyEnum = createEnum({
  center: null,
  left: 'flex-start',
  right: 'flex-end',
});

const TOCLink = ({ to, children, align = 'left' }) => {
  const styles = useStyles();

  return (
    <Grid
      to={to}
      container
      component={Link}
      justify={justifyEnum[align]}
      alignItems="center"
      className={styles.linkRoot}
    >
      {children}
    </Grid>
  );
};

TOCLink.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.objectOf(PropTypes.any),
    }),
  ]),
};

export default TOCLink;
