import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  searchContainer: {
    marginTop: spacing(18),
    marginBottom: spacing(15),
    color: palette.primary.main,

    [breakpoints.down('sm')]: {
      marginTop: spacing(15),
      marginBottom: spacing(10),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(10),
      marginBottom: spacing(6),
    },
  },
  searchStateDropdownContainer: {
    marginBottom: spacing(11),
  },
  searchTitle: {
    marginBottom: spacing(3),
  },
  filters: {
    marginBottom: spacing(2.5),
  },
  counselorList: {
    marginLeft: spacing(3.75),

    [breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  searchResultSubtitle: {
    color: palette.text.primary,
    letterSpacing: 0,
    marginBottom: spacing(2),
  },
  searchStateDropdown: {
    borderRadius: 30,
    padding: spacing(2.75),
    background: palette.white,
    boxShadow: `0 6px 27px 0 ${palette.black}10`,
    margin: spacing(8, 0, 3),
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      margin: spacing(6, 0, 3),
      padding: spacing(2),
      borderRadius: 15,
    },
  },
  searchButton: {
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },
}));
