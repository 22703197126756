import { actionTypesGenerator } from 'common/utils';

export const widget = actionTypesGenerator(
  ['init', 'mount', 'unmount', 'open', 'close'],
  'widget'
);

export const initialState = {
  init: false,
  mounted: false,
  opened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case widget.init:
      return {
        ...state,
        init: true,
      };

    case widget.mount:
      return {
        ...state,
        mounted: true,
      };

    case widget.unmount:
      return {
        ...state,
        init: false,
        mounted: false,
      };

    case widget.open:
      return {
        ...state,
        opened: true,
      };

    case widget.close:
      return {
        ...state,
        opened: false,
      };

    default:
      return state;
  }
};

export const initSPWidget = () => ({
  type: widget.init,
});

export const mountSPWidget = () => ({
  type: widget.mount,
});

export const destroySPWidget = () => ({
  type: widget.unmount,
});

export const openSPWidget = () => ({
  type: widget.open,
});

export const closeSPWidget = () => ({
  type: widget.close,
});
