import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import useStyles from './styles';

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

Element.propTypes = {
  attributes: PropTypes.shape(),
  children: PropTypes.node,
  element: PropTypes.shape({
    type: PropTypes.string,
  }),
};

const EditableTextarea = () => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]);
  const classes = useStyles();

  const renderElement = useCallback(() => {
    return <Element />;
  }, []);

  return (
    <Slate
      renderElement={renderElement}
      editor={editor}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    >
      <Grid
        container
        direction="column"
        component={Box}
        border="1px solid #e0e2e5"
        borderRadius={5}
      >
        <Grid item container component={Box} p={1}>
          <Grid item>
            <ButtonBase className={classes.markButton}>
              <FormatBoldIcon className={classes.markIcon} />
            </ButtonBase>
            <ButtonBase className={classes.markButton}>
              <FormatItalicIcon className={classes.markIcon} />
            </ButtonBase>
            <ButtonBase className={classes.markButton}>
              <UndoIcon className={classes.markIcon} />
            </ButtonBase>
            <ButtonBase className={classes.markButton}>
              <RedoIcon className={classes.markIcon} />
            </ButtonBase>
            <ButtonBase className={classes.markButton}>
              <FormatListBulletedIcon className={classes.markIcon} />
            </ButtonBase>
            <ButtonBase className={classes.markButton}>
              <FormatListNumberedIcon className={classes.markIcon} />
            </ButtonBase>
          </Grid>
        </Grid>
        <Divider />
        <Grid item component={Box} p={1} minHeight={60}>
          <Editable />
        </Grid>
      </Grid>
    </Slate>
  );
};

export default EditableTextarea;
