import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  countLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: 14,
    color: '#143A56',
  },
}));
