import makeStyles from '@material-ui/styles/makeStyles';
import phones from 'assets/images/phones-min.png';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  section: {
    backgroundImage: `url(${phones})`,
    backgroundPosition: '10% 0',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    maxWidth: 1920,
    margin: '0 auto',
    marginTop: spacing(4),
    marginBottom: spacing(6),

    '@media (max-width:1400px)': {
      backgroundPosition: '0 0',
      backgroundSize: 'auto 100%',
    },
    [breakpoints.down('sm')]: {
      backgroundSize: 'auto 300px',
      backgroundPosition: '50% 0',
    },
    [breakpoints.down('xs')]: {
      backgroundSize: 'auto 200px',
    },
  },
  image: {
    height: 875,
    [breakpoints.down('md')]: {
      height: 650,
      marginBottom: spacing(4),
    },
    [breakpoints.down('sm')]: {
      height: 300,
    },
    [breakpoints.down('xs')]: {
      height: 200,
    },
  },
  container: {
    height: '100%',
  },
  wrapper: {
    color: palette.primary.main,
    height: '100%',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
  subTitle: {
    color: `${palette.primary.main}50`,
    fontWeight: 700,
    marginBottom: spacing(1.2),
  },
  description: {
    marginTop: spacing(2.5),
    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
      marginBottom: spacing(8),
      textAlign: 'center',
    },
  },
}));
