import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  latestArticleWrapper: {
    marginTop: spacing(18),
    color: palette.primary.main,

    [breakpoints.down('sm')]: {
      marginTop: spacing(10),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(5),
    },
  },
  latestArticleDescriptionGrid: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: spacing(3),

    [breakpoints.down('sm')]: {
      '& .MuiTypography-caption': {
        fontSize: 12,
      },
    },
    [breakpoints.down('xs')]: {
      maxWidth: 375,
      paddingRight: 0,
      margin: '0 auto',
    },
    '@media (max-width:370px)': {
      '& .MuiTypography-caption': {
        fontSize: 11,
      },
    },
  },
  latestArticleHint: {
    color: palette.text.primary,
    fontWeight: 600,
    marginBottom: spacing(3.5),

    [breakpoints.down('xs')]: {
      fontWeight: 700,
    },
  },
  latestArticleName: {
    lineHeight: 1.33,
  },
  latestArticleButton: {
    marginTop: spacing(4.75),

    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  latestArticleCoverImage: {
    height: 445,
    objectFit: 'cover',
    width: '100%',
    borderRadius: 30,

    [breakpoints.down('md')]: {
      height: 330,
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1),
      height: 245,
    },
  },
}));
