import React from 'react';

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path fill="#444" d="M11 7H5l3-4zM5 9h6l-3 4z" />
  </svg>
);
