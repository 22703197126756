import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';

const CalendarSection = () => {
  const renderItem = ({ title, value }) => (
    <Box display="flex" pl={3} alignItems="center">
      <Typography style={{ fontSize: '0.86rem', color: '#ffff' }}>
        {`${title}:`}
      </Typography>
      <Typography
        style={{
          fontSize: '0.86rem',
          color: '#ffff',
          fontWeight: 600,
          paddingLeft: 10,
        }}
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <Grid
      container
      component={Box}
      py={2}
      px={2}
      borderRadius={10}
      alignItems="center"
      style={{ backgroundColor: '#40ceba' }}
    >
      <Grid
        item
        component={Box}
        p={1}
        height={50}
        width={50}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: '#76dacc' }}
        borderRadius={15}
      >
        <EventIcon style={{ color: '#ffff' }} />
      </Grid>
      <Grid item container component={Box} flex={1}>
        {renderItem({ title: 'New appointment', value: 'June 24, 2020' })}
        {renderItem({ title: 'Provider', value: 'Dr. Paula Braun' })}
      </Grid>
    </Grid>
  );
};

export default CalendarSection;
