import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import InfoCard from 'common/components/admin/InfoCard';

import DataItem from '../DataItem';
import useStyles from './styles';

const ProfileOverview = ({ data, sections = [] }) => {
  const classes = useStyles();
  const { imageUrl, name, username, dateJoined } = data;
  return (
    <InfoCard
      title="Overview"
      link={{
        title: 'View & Update',
      }}
      LinkIcon={EditIcon}
    >
      <Grid container direction="column">
        <Grid item container component={Box} pb={2}>
          <Grid
            item
            component={Box}
            className={classes.avatar}
            style={{ backgroundImage: `url("${imageUrl}")` }}
          />
          <Grid
            item
            conteiner
            component={Box}
            flex={1}
            px={3}
            pb={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Typography>{name}</Typography>
            <DataItem title="Username" value={username} />
            <DataItem title="Active since" value={dateJoined} />
          </Grid>
        </Grid>

        {sections.map((section) => (
          <>
            <Divider />
            <Box py={2}>{section}</Box>
          </>
        ))}
        <Divider />
        <Grid item container component={Box} justify="space-between" pt={2}>
          <Grid
            item
            component={Typography}
            style={{
              color: '#bcc4ca',
              fontSize: 13,
              fontWeight: 600,
              paddingTop: 6,
            }}
          >
            Password reset required?
          </Grid>
          <Grid item>
            <MenuItem
              style={{
                fontSize: 13,
                color: '#ee5e5c',
                fontWeight: 600,
              }}
            >
              Reset password
            </MenuItem>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
};

ProfileOverview.propTypes = {
  data: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    dateJoined: PropTypes.string,
    sex: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(PropTypes.node),
};

export default ProfileOverview;
