export default ({ requested, success, failed }, initialState, onSuccess) => (
  state = initialState,
  action
) => {
  switch (action.type) {
    case requested:
      return { ...state, loading: true };
    case success:
      return onSuccess(
        { ...state, loading: false, error: null },
        action.payload
      );
    case failed:
      return {
        ...state,
        ...action.payload,
        error: action.message,
        loading: false,
      };

    default:
      return state;
  }
};
