export default (states, prefix = '') => {
  const prefixPart = prefix ? `${prefix}.` : '';

  return Object.keys(states)
    .filter((key) => !states[key])
    .reduce(
      (p, c) => ({
        ...p,
        [c]: `${prefixPart}${c}`,
      }),
      states
    );
};
