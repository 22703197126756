import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import monthNames from 'common/constants/monthNames';
import weekDayNames from 'common/constants/weekDayNames';
import AppointmentsTable from '../AppointmentsTable';
import StatusBanner from '../StatusBanner';
import useStyles from './styles';

const parsedDate = (date) => {
  const tempDate = new Date(date);
  return `${weekDayNames[tempDate.getDay()].full}, ${
    monthNames[tempDate.getMonth()]
  } ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
};

const appointmentsByStatus = (appointments) => {
  const prepared = {};
  for (let i = 0; i < appointments.length; i += 1) {
    const element = appointments[i].status;
    if (prepared[element.id]) {
      prepared[element.id].count += 1;
    } else {
      prepared[element.id] = { ...element, count: 1 };
    }
  }
  return prepared;
};

const AppointmentsSummary = ({ data }) => {
  const classes = useStyles();
  const AppointmentsDate = data[0];
  const [open, setOpen] = useState(false);
  const dateRow = parsedDate(AppointmentsDate);
  const appointmentsCount = data[1].length;
  const appointmentsStatuses = appointmentsByStatus(data[1]);
  const containerBackground = open ? classes.isOpen : classes.isClose;
  return (
    <>
      <Grid
        container
        direction="row"
        component={Box}
        justifyContent="space-between"
        alignItems="center"
        key={data[1].id}
        px={2}
        py={2}
        className={[classes.contentContainer, containerBackground].join(' ')}
      >
        <Grid
          item
          container
          component={Box}
          flex={1}
          className={classes.titleContainer}
        >
          <Grid item>
            <IconButton
              aria-label="expand row"
              size="small"
              className={classes.iconButton}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <Icon
                  className="fas fa-chevron-up"
                  style={{ fontSize: 14, color: '#EAF0F4' }}
                />
              ) : (
                <Icon
                  className="fas fa-chevron-down"
                  style={{ fontSize: 14, color: '#EAF0F4' }}
                />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            <Typography className={classes.dateString}>{dateRow}</Typography>
          </Grid>
          <Grid item>
            <Typography>{appointmentsCount} appointments</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          component={Box}
          justifyContent="flex-end"
          flex={1}
          className={classes.statusContainer}
        >
          {Object.keys(appointmentsStatuses)
            .slice(0, 4)
            .map((statusId) => {
              const { color, count, title } = appointmentsStatuses[statusId];
              return (
                <StatusBanner
                  key={statusId}
                  mainColor={color}
                  title={title.toLowerCase()}
                  count={count}
                />
              );
            })}
        </Grid>
      </Grid>
      {open && <AppointmentsTable data={data[1]} />}
    </>
  );
};

AppointmentsSummary.propTypes = {
  data: PropTypes.arrayOf([PropTypes.string, PropTypes.shape()]),
};

export default AppointmentsSummary;
