import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const MenuLink = ({ title, style }) => {
  return (
    <MenuItem style={{ paddingLeft: 4, paddingRight: 4 }}>
      <Typography
        style={{ fontSize: 10, color: '#40ceba', fontWeight: 600, ...style }}
      >
        {title}
      </Typography>
    </MenuItem>
  );
};

MenuLink.defaultProps = {
  style: {},
};

MenuLink.propTypes = {
  title: PropTypes.string,
  style: PropTypes.shape(),
};

export default MenuLink;
