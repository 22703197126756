import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  cardContainer: {
    minWidth: 235,
    maxWidth: 250,
    height: 320,
    marginTop: 30,
    border: '2px solid #E9EDF2',
    borderRadius: 15,
    alignContent: 'space-between',
  },
  topContainer: {
    paddingRight: 30,
    paddingLeft: 15,
  },
  switchLabel: {
    fontSize: 10,
    fontWeight: 600,
    color: '#A6AEB7',
    textTransform: 'uppercase',
  },
  clientsRow: {
    padding: 5,
    color: '#143A56',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: -0.35,
  },
  middleContainer: {
    padding: '25px 30px 0px 30px',
  },
  dateRow: {
    color: '#A6AEB7',
    fontSize: 10,
    fontWeight: 600,
  },
  titleRow: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: -0.45,
  },
  popoverContainer: {
    marginLeft: 20,
    marginTop: 10,
    maxHeight: 240,
  },
  searchTextField: {
    '& > div': {
      borderRadius: 10,
    },
  },
  searchInput: {
    minWidth: 110,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    fontSize: '0.9rem',
    color: 'black',
    shrink: true,
    '&::placeholder': {
      fontWeight: 500,
      color: 'grey',
    },
  },
  descriptionRow: {
    color: '#A6AEB7',
    fontSize: 14,
    letterSpacing: -0.35,
  },
  bottomContainer: {
    padding: '0px 30px',
  },
});
