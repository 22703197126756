export default [
  {
    id: 'Show',
    label: 'Show',
    value: 48,
    color: 'rgba(175,205,80,255)',
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
    value: 6,
    color: 'rgba(64,206,186,255)',
  },
  {
    id: 'Late Cancelled',
    label: 'Late Cancelled',
    value: 22,
    color: 'rgba(52,155,204,255)',
  },
  {
    id: 'No show',
    label: 'No show',
    value: 24,
    color: 'rgba(13,100,142,255)',
  },
];
