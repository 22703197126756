export const paymentsData = new Array(20).fill(0).map((_, id) => ({
  id,
  paymentId: 'ABC123-456-789-012',
  paymentDate: '06.07.2020 09:00:00',
  cardNumber: 1234123412341234,
  cardExpires: '08.08.2022',
  statementDescriptor: 'Paula Brown',
  client: id % 5 ? 'Jonathan Brown' : '',
  type: id % 5 ? 'Charge' : 'Payout',
  net: id % 5 ? 19.11 : 195.76,
  amount: id % 5 ? 20 : 195.76,
  fee: 0.89,
}));

export const clientsValues = [
  {
    value: 'client1',
    label: 'Client 1',
  },
  {
    value: 'client2',
    label: 'Client 2',
  },
];

export const typesValues = [
  {
    value: 'type1',
    label: 'Type1',
  },
  {
    value: 'type2',
    label: 'Type2',
  },
];
