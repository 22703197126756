export default [
  {
    id: 'Submitted',
    label: 'Submitted',
    value: 10,
    color: 'rgba(64,206,186,255)',
  },
  {
    id: 'Rejected',
    label: 'Rejected',
    value: 15,
    color: 'rgba(52,155,204,255)',
  },
  {
    id: 'Denied',
    label: 'Denied',
    value: 6,
    color: 'rgba(13,100,142,255)',
  },
  {
    id: 'Accepted',
    label: 'Accepted',
    value: 15,
    color: 'rgba(9,59,84,255)',
  },
  {
    id: 'Paid pending',
    label: 'Paid pending',
    value: 8,
    color: 'rgba(165,175,184,255)',
  },
  {
    id: 'Deductible',
    label: 'Deductible',
    value: 5,
    color: 'rgba(184,180,165,255)',
  },
  {
    id: 'Paid',
    label: 'Paid',
    value: 18,
    color: 'rgba(200,153,155,255)',
  },
  {
    id: 'Prepared',
    label: 'Prepared',
    value: 23,
    color: 'rgba(175,205,80,255)',
  },
];
