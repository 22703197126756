import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid, Icon, Typography } from '@material-ui/core';

import useStyles from './styles';

const BlogDateReadTime = ({ date, readTime, size = 'small' }) => {
  const styles = useStyles({ size });

  const format = (nonFormattedDate) => {
    const mDate = moment(nonFormattedDate);
    return `${mDate.format('D')}th ${mDate.format('MMMM')}`;
  };

  return (
    <Grid container alignItems="center" className={styles.wrapper}>
      <Typography variant="caption" className={styles.dateTime}>
        {format(date)}
      </Typography>

      <Grid className={styles.dot} />

      <Typography variant="caption" className={styles.dateTime}>
        <Icon
          className="fas fa-clock"
          classes={{ root: styles.dateInfoIcon }}
        />
        {readTime} minute read
      </Typography>
    </Grid>
  );
};

BlogDateReadTime.propTypes = {
  date: PropTypes.string,
  readTime: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default BlogDateReadTime;
