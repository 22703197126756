import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from 'common/components/admin/Button';
import useStyles from './styles';

const ResetButton = ({ onPress }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Button
        className={classes.resetButton}
        variant="outlined"
        onClick={onPress}
      >
        Reset
      </Button>
    </Grid>
  );
};

ResetButton.propTypes = {
  onPress: PropTypes.func,
};

export default ResetButton;
