import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import routes from 'common/constants/routes';
import { Circle, TOCButton, Wave } from 'common/components/simple';
import BlueCross from 'assets/images/blue_cross-min.png';
import Aetna from 'assets/images/aetna-min.png';
import UnitedHealthCare from 'assets/images/united-min.png';
import Magellan from 'assets/images/magellan-min.png';
import Cigna from 'assets/images/cigna-min.png';
import Intro from 'assets/video/Intro.mp4';

import useStyles from './styles';

const HelloSection = () => {
  const styles = useStyles();

  return (
    <Grid className={styles.container}>
      <Wave animated color="waveSecondaryColor" />

      <Grid className={styles.contentContainer}>
        <Container>
          <Grid container direction="column" className={styles.contentWrapper}>
            <Typography variant="subtitle1">
              <Box fontWeight="700" component="span">
                We accept a range of health insurance plans for counseling
                services
              </Box>
            </Typography>

            <Grid
              container
              justify="center"
              spacing={4}
              alignItems="center"
              className={styles.plansWrapper}
            >
              <Grid item className={styles.plansItem}>
                <img src={BlueCross} alt="Blue Cross" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={Aetna} alt="Aetna" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={UnitedHealthCare} alt="United Health Care" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={Magellan} alt="Magellan" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={Cigna} alt="Cigna" />
              </Grid>
            </Grid>

            <Divider />

            <Grid container className={styles.helloSectionMain}>
              <Grid
                item
                sm={12}
                md={6}
                container
                justify="center"
                alignItems="center"
                className={styles.helloSectionIntroVideoGrid}
              >
                <Box className={styles.helloSectionIntroVideoWrapper}>
                  <video
                    loop
                    muted
                    autoPlay
                    src={Intro}
                    playsInline
                    className={styles.helloSectionIntroVideo}
                  />
                </Box>
                <Box className={styles.helloSectionCircleWrapper}>
                  <Circle size={15.9} left="74%" top="-3%" color="tertiary" />
                  <Circle size={10} left="91%" top="17%" color="primary" />
                  <Circle size={14.5} left="14%" top="9%" color="tertiary" />
                  <Circle size={8.9} left="-4%" top="26%" color="tertiary" />
                  <Circle size={16.6} left="79%" top="46%" color="secondary" />
                  <Circle size={26.2} left="-7%" top="74%" color="secondary" />
                  <Circle size={7.6} left="96%" top="73%" color="primary" />
                  <Circle size={12.4} left="29%" top="104%" color="primary" />
                </Box>
              </Grid>

              <Grid item sm={12} md={6} lg={5} container direction="column">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={styles.subtitle}
                >
                  Are you ready?
                </Typography>

                <Typography variant="h2" className={styles.title}>
                  Say hello to a new you with Text and Online Counseling
                </Typography>

                <Typography variant="body1" className={styles.text}>
                  Text and Online Counseling in an innovative, online,
                  counseling practice
                </Typography>

                <Typography variant="body1" className={styles.text}>
                  Our professionally licensed TOCs (Text and Online Counseling)
                  use evidence based counseling techniques to treat a variety of
                  emotional, behavioral and mental health concerns.
                </Typography>

                <TOCButton
                  size="large"
                  color="secondary"
                  width={235}
                  to={routes.about.url}
                  className={styles.button}
                >
                  <Box fontWeight="700" component="span">
                    Learn more about us
                  </Box>
                </TOCButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Wave animated invert color="waveSecondaryColor" />
    </Grid>
  );
};

export default HelloSection;
