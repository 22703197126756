import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import useStyles from './styles';

const TOCAccordion = (props) => {
  const { title, children, fontWeight = 'inherit' } = props;
  const [expanded, toggleExpanding] = useState(false);
  const styles = useStyles();

  return (
    <Accordion
      square
      expanded={expanded}
      classes={{ root: styles.container }}
      onChange={() => toggleExpanding(!expanded)}
    >
      <AccordionSummary
        id="toc-accordion"
        aria-controls="toc-accordion"
        classes={{
          root: styles.summary,
          content: styles.summaryContent,
          expandIcon: styles.summaryIcon,
        }}
        expandIcon={
          expanded ? (
            <RemoveCircleOutlineIcon fontSize="large" />
          ) : (
            <AddCircleOutlineIcon fontSize="large" />
          )
        }
      >
        <Typography variant={expanded ? 'body2' : 'body1'} component="p">
          <Box component="span" fontWeight={expanded ? fontWeight : 'inherit'}>
            {title}
          </Box>
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: styles.details }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

TOCAccordion.propTypes = {
  title: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node,
};

export default TOCAccordion;
