import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  countLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
  },
  input: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '0.9rem',
    color: 'black',
    '&::placeholder': {
      fontWeight: 500,
      color: 'grey',
    },
  },
  resetButton: {
    textTransform: 'capitalize',
    backgroundColor: '#093b54',
    color: '#ffff',
    fontSize: '0.9rem',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#093b54',
      opacity: 0.6,
    },
  },
  textField: {
    '& > div': {
      borderRadius: 10,
    },
  },
  dateString: {
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: -0.4,
    color: '#A6AEB7',
  },
  coastString: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: -0.4,
    color: '#143A56',
  },
  nameString: {
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: -0.4,
    color: '#143A56',
  },
  payerString: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: -0.35,
    color: '#A6AEB7',
  },
  countDays: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#143A56',
  },
}));
