import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  dates: {
    paddingLeft: 10,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  date: {
    flex: 4,
  },
  dateInput: {
    flexDirection: 'row',
    flex: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid #D2D6DB`,
    borderRadius: 10,
    padding: 3,
    paddingLeft: 10,
    minWidth: 180,
    maxWidth: 260,
  },
  input: {
    flex: 1,
    fontSize: 14,
    color: 'black',
    '&::placeholder': {
      fontWeight: 500,
      color: 'grey',
    },
  },
}));
