import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import routes from 'common/constants/routes';
import { TOCButton, Wave } from 'common/components/simple';
import Person from 'assets/images/people/home/person0-min.png';
import Person1 from 'assets/images/people/home/person1-min.png';
import Person2 from 'assets/images/people/home/person2-min.png';
import Person3 from 'assets/images/people/home/person3-min.png';
import Person4 from 'assets/images/people/home/person4-min.png';
import Person5 from 'assets/images/people/home/person5-min.png';
import Person6 from 'assets/images/people/home/person6-min.png';

import useStyles from './styles';

const OurMissionSection = () => {
  const styles = useStyles();

  return (
    <>
      <Wave animated />

      <Box position="relative" bgcolor="primary.main">
        <img src={Person} alt="person" className={styles.personImage} />
        <img src={Person1} alt="person one" className={styles.personImage} />
        <img src={Person2} alt="person two" className={styles.personImage} />
        <img src={Person3} alt="person three" className={styles.personImage} />
        <img src={Person4} alt="person four" className={styles.personImage} />
        <img src={Person5} alt="person five" className={styles.personImage} />
        <img src={Person6} alt="person six" className={styles.personImage} />

        <Container>
          <Typography variant="h5" className={styles.subtitle}>
            <Box color="logoColors.primary" fontWeight="700" component="span">
              Our mission?
            </Box>
          </Typography>
          <Grid container className={styles.contentGrid}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                color="textSecondary"
                className={styles.title}
              >
                Our mission is to change the narrative and remove the stigma
                around mental health care.
              </Typography>
            </Grid>

            <Grid item xs={12} md={5} container>
              <Typography variant="body1" className={styles.text}>
                <Box color="lightText" component="span">
                  We utilize cutting-edge technology and deliver convenient,
                  affordable service to those looking to feel their best. We aim
                  to improve lives ... one telehealth session at a time.
                </Box>
              </Typography>

              <Typography variant="body1" className={styles.text}>
                <Box color="lightText" component="span">
                  Partnering with clinically sound and professionally licensed
                  mental health counselors, we deliver telehealth service with
                  high standards of ethics, dedication to privacy, and attention
                  to the consumer experience.
                </Box>
              </Typography>

              <TOCButton
                color="secondary"
                size="large"
                width={235}
                to={routes.about.url}
                className={styles.button}
              >
                Learn more about us
              </TOCButton>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Wave invert animated />
    </>
  );
};

export default OurMissionSection;
