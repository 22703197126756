import createEnum from 'common/utils/createEnum';

export default createEnum({
  searchOptions: 'counselor/filters',
  counselors: 'counselor/search',
  counselor: 'counselor/view',
  articles: 'article/view',
  topic: 'topic',
  contact: 'contact/send',
});
