import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from 'assets/images/icons/sort';
import ResetButton from '../ResetButton';
import SelectInput from '../../../../../../../common/components/admin/SelectInput';
import useStyles from './styles';
import {
  clientsOptionValues,
  payersOptionValues,
  unbilledData,
} from '../../insuranceData';

const columnNames = [
  { label: 'Include in batch', style: { width: '12%' } },
  { label: 'Appointments', style: { width: '11%' } },
  { label: 'Client', isSorted: true, style: { width: '15%' } },
  { label: 'Insurance payer', isSorted: true, style: { width: '28%' } },
  { label: 'Provider(s)', isSorted: true, style: { width: '15%' } },
  { label: 'Charged', isSorted: true, style: { width: '10%' } },
  { style: { width: '7%' } },
];

const Row = (props) => {
  const { row } = props;
  const { appointments } = row;
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const classes = useStyles();
  const iconButtonStyle = open
    ? classes.iconButtonOpen
    : classes.iconButtonClose;
  return (
    <>
      <TableRow key={row.id}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={check}
            onChange={() => setCheck(!check)}
            size="small"
            className={classes.checkBox}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>
            Include all ({row.includeInBatch})
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>
            {row.appointmentsCount}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.boltString}>{row.fullName}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.insurancePayer}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>{row.providers}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography className={classes.td}>
            Total ${row.totalCharged}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            className={[classes.iconButton, iconButtonStyle].join(' ')}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <Icon
                className="fas fa-chevron-up"
                style={{ fontSize: 14, color: '#EAF0F4' }}
              />
            ) : (
              <Icon
                className="fas fa-chevron-down"
                style={{ fontSize: 14, color: '#143A56' }}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={row.id}>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" className={classes.subTable}>
              <TableBody>
                {appointments.map((appointment) => (
                  <TableRow selected key={appointment.id}>
                    <TableCell
                      className={classes.checkBoxContainer}
                      scope="row"
                      style={{ width: '2%' }}
                    >
                      <Checkbox size="small" className={classes.checkBox} />
                    </TableCell>
                    <TableCell
                      className={classes.td}
                      align="left"
                      scope="row"
                      style={{ width: '11%' }}
                    >
                      {appointment.date}
                    </TableCell>
                    <TableCell className={classes.td} align="left" xs={9}>
                      {appointment.name}
                    </TableCell>
                    <TableCell align="left" style={{ width: '15%' }}>
                      <Typography className={classes.td}>
                        {appointment.provider}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.boltString}>
                        $ {appointment.charged}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const rows = unbilledData.map((item) => {
  const { id, fullName, insurancePayer, appointments } = item;
  const getUniqueProvidersCount = (appointmentsArray) => {
    const providers = {};
    appointmentsArray.forEach((appointment) => {
      providers[appointment.provider] = appointment.provider;
    });
    const uniqueProviders = Object.keys(providers);
    return uniqueProviders.length;
  };
  const totalCharged = appointments.reduce(
    (acc, appointment) => acc + appointment.charged,
    0
  );
  const uniqueProvidersCount = getUniqueProvidersCount(appointments);

  return {
    id,
    appointments,
    isChecked: true,
    includeInBatch: appointments.length,
    appointmentsCount: appointments.length,
    fullName,
    insurancePayer,
    providers: uniqueProvidersCount,
    totalCharged,
  };
});

const UnbilledClaims = () => {
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        container
        display="flex"
        flexDirection="row"
        justify="space-between"
        alignItems="center"
        component={Box}
        py={2}
        px={2}
      >
        <Grid
          item
          container
          component={Box}
          flex={1}
          className={classes.countLabel}
        >
          <Typography component="span">Showing</Typography>
          <Typography style={{ fontWeight: 700 }}>&nbsp;30&nbsp;</Typography>
          <Typography>unbilled claims</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          component={Box}
          xs={7}
          flex={3}
          justifyContent="flex-end"
        >
          <Grid item component={Box}>
            <SelectInput
              placeholder="All insurance payers"
              valueOptions={payersOptionValues}
            />
          </Grid>
          <Grid item component={Box}>
            <SelectInput
              placeholder="All clients"
              valueOptions={clientsOptionValues}
            />
          </Grid>
          <ResetButton />
        </Grid>
      </Grid>
      <Grid container direction="column" component={Box}>
        <TableContainer className={classes.table}>
          <Table size="small" style={{ tableLayout: 'fixed' }}>
            <TableHead style={{ backgroundColor: '#F5F8FA' }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox size="small" className={classes.checkBox} />
                </TableCell>
                {columnNames.map((col) => {
                  return (
                    <TableCell
                      key={col.label}
                      style={col.style}
                      className={classes.thGrid}
                    >
                      <Grid
                        component={Box}
                        display="flex"
                        container
                        alignItems="center"
                      >
                        <Tooltip title={col.label} arrow>
                          <Typography className={classes.th}>
                            {col.label}
                          </Typography>
                        </Tooltip>
                        {col.isSorted && (
                          <SvgIcon
                            component={Box}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {SortIcon}
                          </SvgIcon>
                        )}
                      </Grid>
                    </TableCell>
                  );
                })}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    appointments: PropTypes.arrayOf(
      PropTypes.shape({
        charged: PropTypes.number,
        date: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        provider: PropTypes.string,
      })
    ),
    appointmentsCount: PropTypes.number,
    fullName: PropTypes.string,
    id: PropTypes.number,
    includeInBatch: PropTypes.number,
    insurancePayer: PropTypes.string,
    isChecked: PropTypes.bool,
    providers: PropTypes.number,
    totalCharged: PropTypes.number,
  }),
};

export default UnbilledClaims;
