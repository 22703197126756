import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Container from '@material-ui/core/Container';

import { Circle, Wave } from 'common/components/simple';

import AboutSmile from 'assets/images/about_smile-min.png';
import AboutHeadphones from 'assets/images/about_headphones-min.png';

import BlueCross from 'assets/images/blue_cross-min.png';
import Aetna from 'assets/images/aetna-min.png';
import UnitedHealthCare from 'assets/images/united-min.png';
import Magellan from 'assets/images/magellan-min.png';
import Cigna from 'assets/images/cigna-min.png';

import useStyles from './styles';

const InsurancesSection = () => {
  const styles = useStyles();
  return (
    <>
      <Wave animated color="waveSecondaryColor" />

      <Box bgcolor="waveSecondaryColor" position="relative">
        <Container>
          <Box className={styles.boxFirstPerson}>
            <img src={AboutSmile} alt="smiled woman on about page" />
            <Circle size={12} left="70.1%" top="0" color="tertiary" />
            <Circle size={8} left="89%" top="56%" />
            <Circle size={10} left="24%" top="15%" color="tertiary" />
            <Circle size={13} left="82%" top="31%" color="secondary" />
            <Circle size={6} left="92%" top="12%" />
            <Circle size={8} left="42%" top="69%" />
          </Box>

          <Grid container direction="column" alignItems="center">
            <Grid item xs={7} className={styles.titleGrid}>
              <Typography variant="h2" align="center" className={styles.title}>
                Our services are available under a range of insurance plans
              </Typography>
            </Grid>

            <Grid item xs={12} md={7}>
              <Box mt={4}>
                <Typography variant="body1" align="center">
                  As a commitment to those who wish to use health insurance
                  coverage, we are in-network providers with many commercial
                  health insurance plans. We are continuously extending our
                  relationship with additional insurance plans to allow us to
                  accept a wide and diverse range of clients.
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              justify="center"
              spacing={4}
              alignItems="center"
              className={styles.plansWrapper}
            >
              <Grid item className={styles.plansItem}>
                <img src={BlueCross} alt="Blue Cross" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={Aetna} alt="Aetna" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={UnitedHealthCare} alt="United Health Care" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={Magellan} alt="Magellan" />
              </Grid>
              <Grid item className={styles.plansItem}>
                <img src={Cigna} alt="Cigna" />
              </Grid>
            </Grid>

            <Alert
              severity="info"
              className={styles.feedbackNoticeWrap}
              classes={{
                standardInfo: styles.feedbackNoticeBackground,
                message: styles.feedbackNoticeMessage,
              }}
              icon={
                <Icon
                  fontSize="large"
                  color="secondary"
                  className="far fa-lightbulb"
                />
              }
            >
              <Box>
                We accept many health insurance plans. However, if we are not
                participating in your health plan and you would like us to be,
                please send an email to
              </Box>

              <a href="mailto:insurances@textandonlinecounseling.com">
                insurances@&shy;textand&shy;online&shy;coun&shy;seling.com
              </a>
            </Alert>
          </Grid>

          <Box className={styles.boxSecondPersonWrapper}>
            <Box className={styles.boxSecondPerson}>
              <img src={AboutHeadphones} alt="woman with headphones" />
              <Circle size={12} left="68%" top="-3%" color="tertiary" />
              <Circle size={10} left="21%" top="17%" color="tertiary" />
              <Circle size={6} left="6%" top="26%" color="tertiary" />
              <Circle size={16} left="7%" top="64%" color="secondary" />
              <Circle size={7} left="40%" top="90%" />
            </Box>
          </Box>
        </Container>
      </Box>

      <Wave animated invert color="waveSecondaryColor" />
    </>
  );
};

export default InsurancesSection;
