import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const blog = actionTypesGenerator(['list'], 'blog');

const initialState = {
  list: [],
  total: 0,
  loading: true,
  error: false,
};

export default reducerGenerator(blog.list, initialState, (state, payload) => ({
  ...state,
  list: payload.data,
  total: payload.total,
}));

export const getPosts = (page = 1, topics) => ({
  type: blog.list.requested,
  payload: {
    pagination: { page, perPage: 9 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: {
      TopicIds: topics ? [topics] : [],
    },
  },
});
