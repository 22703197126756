import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  formControl: {
    backgroundColor: '#fff',
    minWidth: 120,
    maxWidth: 300,
    borderColor: '#D2D6DB',
    borderRadius: 10,
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
      color: '#143A56',
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
  },
  placeholder: {
    color: '#143A56',
  },
});
