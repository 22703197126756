import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { options } from '../reducers/filterOptions';

function* getOptionsSagaRequest() {
  yield requestSaga({
    actionType: options.get,
    request: dataProvider.getList,
    resource: resources.searchOptions,
  });
}

export default getOptionsSagaRequest;
