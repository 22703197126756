import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import { useTheme } from '@material-ui/core/styles';
import { getPosts, getBlogTags } from 'ui/containers/Blog/ducks';
import { TOCBlogCard } from 'common/components';
import { TOCPagination } from 'common/components/simple';
import useStyles from './styles';

const BlogList = () => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const styles = useStyles();
  const urlQuery = qs.parse(search, { ignoreQueryPrefix: true });
  const [page, setPage] = useState(+urlQuery.page || 1);
  const [topic, setTopic] = useState(+urlQuery.topic || null);

  const updateUrl = (nPage, nTopic) => {
    // it filters params of query string by value that exists
    const queries = [
      ['page', nPage],
      ['topic', nTopic],
    ].filter(([, value]) => !!value);
    const queryString = qs.stringify(Object.fromEntries(queries), {
      addQueryPrefix: true,
    });

    dispatch(push(`${pathname}${queryString}`));
  };

  const handlePaginationPage = (newPage) => {
    setPage(newPage);
    updateUrl(newPage, topic);
  };

  const handleClickChip = (id) => {
    const calculatedValue = topic === id ? null : id;
    setTopic(calculatedValue);
    updateUrl(page, calculatedValue);
  };

  const getChipStyle = (id) => ({
    color: id === topic ? palette.white : 'inherit',
    background: id === topic ? palette.secondary.main : '',
  });

  useEffect(() => {
    dispatch(getBlogTags());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPosts(page, topic));
  }, [dispatch, page, topic]);

  const { list, total } = useSelector((store) => store.blog.posts);
  const { tags } = useSelector((store) => store.blog.tags);

  const pagesCount = Math.ceil(total / 9);

  return (
    <Grid className={styles.blogContainer}>
      <Container>
        <Grid container direction="column" className={styles.blogWrapper}>
          <Grid
            item
            container
            justify="space-between"
            className={styles.filterTopicSection}
            spacing={4}
          >
            <Grid item className={styles.filterTitleGrid}>
              <Typography variant="body2" className={styles.filterTopicTitle}>
                Filter by topic:
              </Typography>
            </Grid>

            <Grid item className={styles.filterTopicChipsGrid}>
              {tags.map(({ id, name }) => (
                <Chip
                  key={id}
                  size="medium"
                  style={getChipStyle(id)}
                  className={styles.filterTopicChip}
                  label={<Typography variant="subtitle1">{name}</Typography>}
                  onClick={() => handleClickChip(id)}
                />
              ))}
            </Grid>
          </Grid>

          <Divider className={styles.filterDivider} />

          <Grid
            item
            container
            justify="flex-start"
            className={styles.postsGrid}
          >
            {list.map((article) => (
              <Grid
                item
                key={article.id}
                xs={12}
                md={4}
                container
                className={styles.postGrid}
              >
                <TOCBlogCard post={article} />
              </Grid>
            ))}
          </Grid>

          <Grid className={styles.blogPaginationContainer}>
            {total > 0 ? (
              <TOCPagination
                current={page}
                count={pagesCount}
                onChange={handlePaginationPage}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default BlogList;
