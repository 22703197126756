import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    '& svg': {
      color: '#5CD0B9',
      fontSize: '1rem',
    },
  },
  label: {
    fontSize: '0.85rem',
  },
});
