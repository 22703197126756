import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  avatar: {
    borderRadius: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  searchInputRoot: {
    borderRadius: 10,
  },
  searchInput: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}));
