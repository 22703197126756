import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

import useStyles from './styles';

const FormInput = ({
  label,
  disabled,
  required,
  options = [],
  helperText,
  placeholder,
  style = {},
  inputClassName = '',
  defaultValue,
}) => {
  const classes = useStyles();
  const input = (
    <OutlinedInput
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      classes={{
        root: [
          classes.inputRoot,
          label ? classes.inputRootMargin : '',
          inputClassName,
        ].join(' '),
        disabled: classes.inputDisabled,
        input: classes.input,
      }}
    />
  );
  return (
    <FormControl style={{ width: '100%', ...style }}>
      <Box position="relative">
        <InputLabel className={classes.inputLabel} shrink>
          {label}
        </InputLabel>
        {required && (
          <Typography className={classes.requiredMark}>*</Typography>
        )}
      </Box>
      {options.length > 0 ? (
        <Select
          className={classes.select}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          input={input}
          defaultValue={defaultValue}
        >
          {options.map(({ value, label: optLabel }) => (
            <MenuItem key={value} className={classes.menuItem} value={value}>
              {optLabel}
            </MenuItem>
          ))}
        </Select>
      ) : (
        input
      )}
      <FormHelperText className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.shape(),
  inputClassName: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default FormInput;
