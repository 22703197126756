import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useStyles from './styles';

const CustomTabs = ({ pages }) => {
  const [value, setValue] = React.useState(Object.keys(pages)[0]);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CurrentPage = pages[value].page;

  return (
    <Paper style={{ borderRadius: 15 }}>
      <Tabs
        value={value}
        indicatorColor="secondary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {Object.keys(pages).map((prop) => (
          <Tab
            key={pages[prop].title}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
            value={prop}
            label={pages[prop].title}
          />
        ))}
      </Tabs>
      <Box py={2} px={3}>
        {CurrentPage && <CurrentPage />}
      </Box>
    </Paper>
  );
};

CustomTabs.propTypes = {
  pages: PropTypes.objectOf(PropTypes.node),
};

export default CustomTabs;
