import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from 'common/components/admin/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import SortIcon from 'assets/images/icons/sort';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircle from '@material-ui/icons/CheckCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { convertTime } from 'common/utils/helpers';
import Banner from 'common/components/admin/Banner';
import ActionsPopover from '../ActionsPopover';
import StatusPopover from '../StatusPopover';
import useStyles from './styles';

const NotesMedicalIcon = ({ style }) => (
  <Icon
    className="fas fa-notes-medical"
    style={{ color: '#fff', fontSize: 18, ...style }}
  />
);

const columnNames = [
  { label: 'Time', isSorted: true, style: { width: '6%' } },
  { label: 'Duration', style: { width: '9%' } },
  { label: 'Client name', isSorted: true, style: { width: '15%' } },
  { label: 'Provider name', isSorted: true, style: { width: '15%' } },
  { label: 'Status', isSorted: true, style: { width: '15%' } },
  { label: 'Billing type', isSorted: true, style: { width: '10%' } },
  { label: 'Client owes', isSorted: true, style: { width: '10%' } },
  { label: 'Actions', style: { width: '15%' } },
];

const Row = (props) => {
  const { row } = props;
  const classes = useStyles();
  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        <Typography className={classes.boldTextRow}>{row.time}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography className={classes.td}>{row.duration} mins</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography className={classes.boldTextRow}>{row.client}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography className={classes.boldTextRow}>{row.provider}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <StatusPopover status={row.status} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography className={classes.td}>{row.billingType}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography className={classes.td}>
          ${row.clientOwes}
          <Banner title="PAID" bgColor="#E2F3E9" />
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Grid container component={Box} justifyContent="space-between">
          <Grid className={classes.iconsRow}>
            {row.status.title === 'Attended' && (
              <IconButton
                IconComponent={NotesMedicalIcon}
                iconProps={{
                  style: {
                    color: '#143A56',
                  },
                }}
              />
            )}
            {row.status.title === 'Upcoming' && (
              <>
                <IconButton
                  IconComponent={CheckCircle}
                  iconProps={{
                    style: {
                      color: '#143A56',
                    },
                  }}
                />
                <IconButton
                  IconComponent={NotInterestedIcon}
                  iconProps={{
                    style: {
                      color: '#143A56',
                    },
                  }}
                />
              </>
            )}
            <IconButton
              IconComponent={DeleteIcon}
              iconProps={{
                style: {
                  color: '#E55852',
                },
              }}
            />
          </Grid>
          <ActionsPopover />
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const AppointmentsTable = ({ data }) => {
  const classes = useStyles();
  const rows = data.map((item) => {
    const { date } = item;
    const tempDate = new Date(date);

    const hours = tempDate.getHours();
    const minutes = tempDate.getMinutes();
    const parsedTime = convertTime({ hours, minutes });
    return {
      ...item,
      time: parsedTime,
    };
  });
  return (
    <Grid container direction="column" component={Box}>
      <TableContainer className={classes.table}>
        <Table size="small" style={{ tableLayout: 'fixed' }}>
          <TableHead style={{ backgroundColor: '#F5F8FA' }}>
            <TableRow>
              {columnNames.map((col) => {
                return (
                  <TableCell
                    key={col.label}
                    style={col.style}
                    className={classes.thGrid}
                  >
                    <Grid
                      component={Box}
                      display="flex"
                      container
                      alignItems="center"
                    >
                      <Tooltip title={col.label} arrow>
                        <Typography className={classes.th}>
                          {col.label}
                        </Typography>
                      </Tooltip>
                      {col.isSorted && (
                        <SvgIcon
                          component={Box}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {SortIcon}
                        </SvgIcon>
                      )}
                    </Grid>
                  </TableCell>
                );
              })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

NotesMedicalIcon.propTypes = {
  style: PropTypes.shape(),
};

Row.propTypes = {
  row: PropTypes.shape({
    billingType: PropTypes.string,
    client: PropTypes.string,
    clientOwes: PropTypes.number,
    date: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.number,
    provider: PropTypes.string,
    status: PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    time: PropTypes.string,
  }),
};

AppointmentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default AppointmentsTable;
