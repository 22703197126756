import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { blog } from '../reducers/posts';

function* getArticlesSagaRequest() {
  yield requestSaga({
    actionType: blog.list,
    request: dataProvider.getList,
    resource: resources.articles,
  });
}

export default getArticlesSagaRequest;
