import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, typography, spacing, breakpoints }) => ({
  counselorContainer: {
    borderRadius: 30,
    marginBottom: spacing(3.75),
    boxShadow: `0 6px 27px 0 ${palette.black}20`,
    backgroundColor: palette.white,
  },
  avatarSection: {
    padding: spacing(3.125),
    borderRight: `1px solid ${palette.black}25`,
    minWidth: 200,

    [breakpoints.down('xs')]: {
      minWidth: 300,
      borderRight: 'unset',
      marginRight: 'auto',
      marginLeft: 'auto',
      textAlign: 'center',
    },
  },
  counselorTitle: {
    textDecoration: 'none',
  },
  counselorAvatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 150,
    height: 150,
  },
  counselorVideoLink: {
    textDecoration: 'none',
    '&:link, &:active, &:visited, &:focus': {
      color: 'inherit',
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(2),
      justifyContent: 'center',
    },
  },
  counselorVideoIcon: {
    background: palette.primary.main,
    color: palette.white,
    fontSize: typography.pxToRem(11),
    marginRight: spacing(1),
    width: 25,
    height: 25,
    padding: 0,
    '&:hover': {
      background: palette.primary.light,
    },
  },
  infoSection: {
    padding: spacing(3.125),
    color: palette.primary.main,

    [breakpoints.down('xs')]: {
      padding: spacing(0, 2, 3.125),
    },
  },
  infoSectionName: {
    marginBottom: spacing(3),

    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  infoSectionLicence: {
    flexWrap: 'nowrap',
    marginTop: spacing(0.3),

    [breakpoints.down('xs')]: {
      justifyContent: 'center',
      textAlign: 'left',
    },
  },
  priceDegree: {
    fontSize: typography.pxToRem(13),
    marginTop: spacing(0.5),
    display: 'flex',
    alignItems: 'flex-start',
    '& > .MuiSvgIcon-root': {
      fontSize: typography.pxToRem(16),
      margin: spacing(0.2, 0, 0, 0.4),
    },
    '&:before': {
      content: '"."',
    },
  },
  tooltipRoot: {
    backgroundColor: palette.primary.main,
    color: palette.semiWhite,
    maxWidth: 150,
    fontSize: typography.pxToRem(8),
    border: `1px solid ${palette.text.primary}`,
    borderRadius: 10,
    padding: spacing(1.5, 1.8),
    textAlign: 'center',
  },
  tooltipDescription: {
    fontSize: typography.pxToRem(10),
  },
  licenseCityRow: {
    marginTop: spacing(0.25),
    marginBottom: spacing(3),
  },
  licenseCity: {
    justifyContent: 'flex-end',
    alignItems: 'center',

    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  infoSectionLicenceIcon: {
    color: palette.secondary.main,
    width: '1.3rem',
    marginRight: spacing(0.5),
  },
  infoSectionDescription: {
    marginBottom: spacing(3),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  infoSectionSpecializes: {
    marginBottom: spacing(1),
  },
  infoSectionSpecialityChip: {
    marginBottom: spacing(0.625),
    marginRight: spacing(0.625),
    fontSize: '0.75rem',
    fontWeight: 600,
    color: `${palette.primary.main}`,
  },
  infoSectionAvailabilityChip: {
    background: palette.secondary.lighter,
    borderRadius: 3,
    height: 20,
    marginRight: spacing(0.625),
  },
  infoSectionButton: {
    marginBottom: spacing(2),
  },
  profileButtons: {
    fontWeight: 700,
    fontSize: '0.75rem',
  },
}));
