import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const PageTitle = ({ title, subTitle, actionButtons }) => {
  return (
    <Grid
      component={Box}
      item
      container
      justify="space-between"
      alignItems="center"
      width="100%"
    >
      <Grid item component={Box} flex={1}>
        <Typography
          variant="h6"
          style={{
            color: '#143A56',
          }}
        >
          {subTitle}
        </Typography>

        <Typography
          variant="h3"
          style={{
            color: '#093b54',
          }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid
        item
        container
        spacing={2}
        component={Box}
        flex={1}
        justify="flex-end"
      >
        {actionButtons.map((btn, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={idx}>
            {btn}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

PageTitle.defaultProps = {
  actionButtons: [],
};

PageTitle.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
};

export default PageTitle;
