import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import SearchIcon from '@material-ui/icons/Search';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from 'common/components/admin/IconButton';

import monthNames from 'common/constants/monthNames';
import { clients } from '../../assessmentsData';
import useStyles from './styles';

const getViewDate = (date) => {
  const tempDate = new Date(date);
  return `${
    monthNames[tempDate.getMonth() + 1]
  } ${tempDate.getDate()} ${tempDate.getFullYear()}`;
};

const PersonAddIcon = ({ style }) => (
  <Icon
    className="fas fa-user-plus"
    style={{ color: '#fff', fontSize: 14, width: 18, ...style }}
  />
);

const iconSize = { width: 32, height: 32 };

const AssessmentCard = ({ data }) => {
  const {
    isActive,
    isAppending,
    createdDate,
    title,
    description,
    isEditable,
  } = data;
  const renderDate = getViewDate(createdDate);
  const classes = useStyles();
  const [active, setActive] = useState(isActive);
  const [searchValue, setSearchValue] = useState('');
  const clientList = clients.filter((client) => {
    return (
      !searchValue ||
      client.userName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleChange = () => setActive(!active);
  const iconStyleMode = isAppending
    ? { paddingRight: 6, backgroundColor: '#57CEBA' }
    : { paddingRight: 6, backgroundColor: '#EAF0F4' };
  const iconColorProps = isAppending
    ? { style: { color: '#fff' } }
    : { style: { color: '#A6AEB7' } };

  return (
    <Grid container component={Box} py={3} className={classes.cardContainer}>
      <Grid item container>
        <Grid
          container
          component={Box}
          className={classes.topContainer}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item container xs={7} direction="row" alignItems="center">
            <Switch checked={active} onChange={handleChange} />
            <Typography className={classes.switchLabel}>
              {active ? 'active' : 'inactive'}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={!isAppending}
              onClick={handleClick}
              style={iconStyleMode}
              IconComponent={PersonAddIcon}
              iconProps={iconColorProps}
            />
          </Grid>
        </Grid>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.popoverContainer}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Grid container component={Box} direction="column" py={2} px={1}>
            <Grid item>
              <TextField
                className={classes.searchTextField}
                placeholder="Search clients"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { input: classes.searchInput },
                }}
              />
            </Grid>
            <Grid item container direction="column">
              {clientList.map((client) => (
                <Typography key={client.id} className={classes.clientsRow}>
                  {client.userName}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Popover>
        <Grid className={classes.middleContainer}>
          <Typography className={classes.dateRow}>
            Created: {renderDate}
          </Typography>
          <Box component="span" className={classes.titleRowContainer}>
            <Typography display="inline" className={classes.titleRow}>
              {title}
            </Typography>
            <Typography display="inline"> Assesment</Typography>
          </Box>
          <Typography className={classes.descriptionRow}>
            {description.length < 80
              ? description
              : `${description.slice(0, 80)}...`}
          </Typography>
        </Grid>
      </Grid>
      {isEditable && (
        <Grid item container>
          <Grid
            container
            component={Box}
            direction="row"
            justifyContent="space-between"
            className={classes.bottomContainer}
          >
            <Grid
              item
              container
              component={Box}
              xs={5}
              direction="row"
              justifyContent="space-around"
            >
              <IconButton style={iconSize} IconComponent={VisibilityIcon} />
              <IconButton style={iconSize} IconComponent={EditIcon} />
            </Grid>
            <Grid item>
              <IconButton
                IconComponent={DeleteIcon}
                style={iconSize}
                iconProps={{
                  style: {
                    color: '#ed7271',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

PersonAddIcon.propTypes = {
  style: PropTypes.shape(),
};

AssessmentCard.propTypes = {
  data: PropTypes.shape({
    isActive: PropTypes.bool,
    isAppending: PropTypes.bool,
    createdDate: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
};

export default AssessmentCard;
