const assessmentDescription = [
  'This is the description of the assessment.',
  'Esse reprehenderit occaecat esse commodo aliqua amet anim excepteur qui enim…',
  'Cillum laboris et aute labore do esse ut esse.',
];

const clientMock = ['Jonathan Wright', 'Paula Brown', 'Peter West'];

export const assessmentsCard = new Array(9).fill(0).map((_, id) => ({
  id,
  createdDate: '06.07.2020',
  title: 'Mental & Emotional Health',
  description: assessmentDescription[id % 3],
  isActive: id % 3,
  isEditable: id === 5,
  isAppending: id !== 1 && id !== 4,
}));

export const clients = new Array(10).fill(0).map((_, id) => ({
  id,
  userName: clientMock[id % 3],
}));

export const assessmentSortValues = [
  {
    value: 'date',
    label: 'Create date',
  },
  {
    value: 'active',
    label: 'Active',
  },
];
