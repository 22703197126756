import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButtonsGroup from 'common/components/admin/IconButtonsGroup';
import IconButton from 'common/components/admin/IconButton';
import VisaIcon from 'assets/images/icons/visa.png';
import MasterCardIcon from 'assets/images/icons/mastercard.svg';

import useStyles from './styles';

const items = [
  {
    type: 'visa',
    number: '**** **** **** 2525',
    icon: VisaIcon,
    expireDate: '12/12',
  },
  {
    type: 'masterCard',
    number: '**** **** **** 2525',
    icon: MasterCardIcon,
    expireDate: '12/12',
  },
];

const CreditCards = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.creditCardsContainer}>
      {items.map(({ number, icon, expireDate }) => (
        <Grid
          key={number}
          item
          container
          direction="row"
          component={Box}
          py={1}
        >
          <Grid item component={Box} px={1}>
            <img src={icon} className={classes.creditCardIcon} alt="card" />
          </Grid>
          <Grid item component={Box} container direction="column" flex={1}>
            <Grid item container direction="row">
              <Grid item>
                <Typography className={classes.creditCardNumber}>
                  {number}
                </Typography>
              </Grid>
              <Grid item>
                <MenuItem className={classes.creditCardEdit}>Edit</MenuItem>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.creditExpireDate}>
                expires {expireDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButtonsGroup>
              <IconButton
                IconComponent={DeleteIcon}
                iconProps={{
                  style: {
                    color: '#ed7271',
                  },
                }}
              />

              <IconButton
                IconComponent={AddIcon}
                style={{ backgroundColor: 'black' }}
                iconProps={{
                  style: {
                    color: '#ffff',
                  },
                }}
              />
            </IconButtonsGroup>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CreditCards;
