import requestSaga from 'common/utils/sagaGenerator';
import dataProvider from 'store/dataProvider';
import resources from 'common/constants/resources';
import { blog } from '../reducers/latestArticle';

function* getLatestArticleSagaRequest() {
  yield requestSaga({
    actionType: blog.article,
    request: dataProvider.getList,
    resource: resources.articles,
  });
}

export default getLatestArticleSagaRequest;
