import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import useStyles from './styles';

const CustomCheckbox = ({ label }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{ label: classes.label }}
      control={<Checkbox className={classes.root} />}
      label={label}
    />
  );
};

CustomCheckbox.propTypes = {
  label: PropTypes.string,
};

export default CustomCheckbox;
