import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { DataProviderContext } from 'react-admin';

import dataProvider from 'store/dataProvider';
import createStore, { history } from './createStore';
import theme from './theme';

import Routes from './ui/routes';

const store = createStore();

export default () => (
  <Provider store={store}>
    <DataProviderContext.Provider value={dataProvider}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </ThemeProvider>
    </DataProviderContext.Provider>
  </Provider>
);
