import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const article = actionTypesGenerator(['fetch'], 'article');

const initialState = {
  article: null,
  loading: true,
  error: false,
};

export default reducerGenerator(
  article.fetch,
  initialState,
  (state, payload) => ({
    ...state,
    article: payload.data,
  })
);

export const getArticle = (id) => ({
  type: article.fetch.requested,
  payload: { id },
});
