import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  avatar: {
    height: 150,
    width: 150,
    borderRadius: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
  middleSectionLabel: {
    fontSize: 11,
  },
  middleSectionMenuItem: {
    fontSize: 9,
    fontWeight: 600,
    color: '#40ceba',
    paddingLeft: 2,
    paddingRight: 2,
  },
}));
