import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import StatusRow from '../StatusRow';
import { statusTitle } from '../../appointmentsData';

import useStyles from './styles';

const StatusPopover = ({ status }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(status);
  const handleChoose = (id) => {
    setCurrentStatus(statusTitle[id - 1]);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <StatusRow
        title={currentStatus.title}
        mainColor={currentStatus.color}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popoverContainer}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container component={Box} direction="column" py={1} px={1}>
          <Grid item container direction="column">
            {statusTitle.map((item) => (
              <Grid
                key={item.id}
                container
                alignItems="center"
                className={classes.itemRow}
                onClick={() => handleChoose(item.id)}
              >
                <Icon
                  className="fas fa-circle"
                  style={{ fontSize: 10, color: item.color }}
                />
                <Typography key={item.id} className={classes.itemRowTitle}>
                  {item.title}
                </Typography>
                {item.id === currentStatus.id && (
                  <Icon
                    className="fas fa-check"
                    style={{ fontSize: 10, color: item.color }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

StatusPopover.propTypes = {
  status: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default StatusPopover;
