import PropTypes from 'prop-types';

export const counselorType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  photoUrl: PropTypes.string,
  introVideoUrl: PropTypes.string,
  pricingModel: PropTypes.string,
  subTitle: PropTypes.string,
  city: PropTypes.string,
  gender: PropTypes.string,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      modifiedAt: PropTypes.string,
    })
  ),
  description: PropTypes.string,
  experience: PropTypes.number,
  rate: PropTypes.number,
  ethnicityId: PropTypes.number,
  ethnicity: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    modifiedAt: PropTypes.string,
  }),
  age: PropTypes.number,
  status: PropTypes.string,
  availability: PropTypes.arrayOf(PropTypes.string),
  specialities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  insurances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  titles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      modifiedAt: PropTypes.string,
    })
  ),
});
