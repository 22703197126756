import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { Circle, Wave } from 'common/components/simple';
import FounderPhoto from 'assets/images/founder_photo-min.png';

import useStyles from './styles';

const Founder = () => {
  const styles = useStyles();

  return (
    <>
      <Box className={styles.sectionWrapper}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Box position="relative" className={styles.imageWrapper}>
                <img src={FounderPhoto} alt="founder avatar" />

                <Circle size={12} right="36%" top="-5%" color="tertiary" />
                <Circle size={8} right="13%" top="12%" />
                <Circle size={10} right="86%" top="17%" color="tertiary" />
                <Circle size={6} right="102%" top="27%" color="tertiary" />
                <Circle size={13} right="23%" top="41%" color="secondary" />
                <Circle size={16} right="99%" top="67%" color="secondary" />
                <Circle size={6} right="17%" top="78%" />
                <Circle size={8} right="66%" top="97%" />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box className={styles.contentWrapper}>
                <Typography variant="body2" color="textPrimary">
                  Meet our founder
                </Typography>

                <Box mt={1} mb={4}>
                  <Typography variant="h2">
                    Jessica Blanding, MS, LPC.
                  </Typography>
                </Box>

                <Box className={styles.text}>
                  <Typography variant="body1">
                    Jessica is an enthusiastic advocate for accessibility and
                    acceptability in mental health counseling. While all of her
                    professional experience has been in the helping professions,
                    she has been working as an independent practitioner and
                    entrepreneur since 2004. Jessica is trained and experienced
                    in solution focused modalities and cognitive behavioral
                    strategies. Her clients report her greatest gift is the
                    ability to truly listen and understand.
                  </Typography>
                </Box>

                <Typography variant="body1">
                  In response to the change in the delivery of healthcare,
                  Jessica founded TOC, an organization committed to fair pay for
                  counselors and streamlined accessibility for those seeking
                  care. What Jessica finds most rewarding are the opportunities
                  to be a witness to positive change in the world at large, one
                  person at a time. Jessica’s work is a constant reminder of the
                  power of love, respect, and gratitude.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Wave animated invert color="waveSecondaryColor" />
    </>
  );
};

export default Founder;
