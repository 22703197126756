import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import ReactPlayer from 'react-player';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

import { counselorType } from 'common/constants/propTypes';
import { joinNameTitles, pluralize } from 'common/utils';
import DoneIcon from '@material-ui/icons/Done';

import useStyles from './styles';

const IntroSection = ({ counselor }) => {
  const { state = {} } = useLocation();
  const styles = useStyles();
  const titles = joinNameTitles(counselor.titles);
  const yearExp = (counselor.experience / 12).toFixed(0);
  const monthExp = counselor.experience % 12;

  useEffect(() => {
    if (!state.autoscroll) return;
    setTimeout(() => {
      document
        .querySelector('#coverVideo')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
  }, [state.autoscroll]);

  return (
    <Grid container direction="column" className={styles.infoSectionContainer}>
      <Grid container>
        <Grid
          item
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={styles.avatarSection}
        >
          <Avatar
            alt="Counselor profile avatar"
            src={counselor.photoUrl}
            className={styles.infoSectionAvatar}
          />
        </Grid>

        <Grid
          item
          xs
          container
          direction="column"
          className={styles.infoSection}
        >
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid item container justify="space-between">
                {counselor.availability.length ? (
                  <Chip
                    size="medium"
                    className={styles.acceptingChip}
                    label={
                      <Typography variant="subtitle2">
                        <Box fontWeight="700" component="span">
                          Accepting clients
                        </Box>
                      </Typography>
                    }
                  />
                ) : null}
              </Grid>

              <Grid item container justify="space-between">
                <Grid
                  item
                  variant="h3"
                  component={Typography}
                  className={styles.infoSectionProfileName}
                >
                  {counselor.firstName} {counselor.lastName}
                  {titles}
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item container className={styles.licenseRow}>
                  <Icon
                    color="secondary"
                    fontSize="inherit"
                    className="fas fa-user-shield"
                    classes={{ root: styles.infoSectionLicenceIcon }}
                  />

                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      {counselor.subTitle}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container className={styles.experienceRow}>
                <Grid item container alignItems="center" wrap="nowrap">
                  <Icon
                    color="secondary"
                    fontSize="inherit"
                    className="fas fa-business-time"
                    classes={{ root: styles.infoSectionExperienceIcon }}
                  />

                  <Typography variant="subtitle1">
                    <Box fontWeight="700" component="span">
                      {pluralize(yearExp, 'year')} and&nbsp;
                      {pluralize(monthExp, 'month')} experience
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                component={Box}
                mb={{ xs: 3 }}
              >
                <Icon
                  color="secondary"
                  fontSize="inherit"
                  className="fas fa-map-marker-alt"
                />

                <Typography variant="body1">
                  {counselor.states[0].code}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="column">
            <Typography variant="subtitle1">
              <Box fontWeight="700" component="span">
                Accepted Insurances
              </Box>
            </Typography>

            <Grid item container direction="row">
              {counselor.insurances.map(({ id, name }) => (
                <Grid
                  key={id}
                  item
                  container
                  alignItems="center"
                  className={styles.insurance}
                >
                  <DoneIcon color="primary" fontSize="inherit" />
                  <Typography variant="h5" className={styles.insuranceText}>
                    <Box fontWeight="400" component="span">
                      {name}
                    </Box>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {counselor.introVideoUrl ? (
        <Grid id="coverVideo" className={styles.introVideoContainer}>
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            url={counselor.introVideoUrl}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

IntroSection.propTypes = {
  counselor: counselorType,
};

export default IntroSection;
