import { createMuiTheme } from '@material-ui/core/styles';
import palette from 'theme/palette';

const theme = createMuiTheme();

export default {
  allVariants: {
    color: palette.primary.main,
    fontFamily: 'Poppins',
  },
  h1: {
    fontWeight: 300,
    fontSize: '3.75rem', // 60

    [theme.breakpoints.down('md')]: {
      fontSize: '3.125rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.1875rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  h2: {
    fontWeight: 400,
    fontSize: '2.25rem', // 36
    letterSpacing: 0,

    [theme.breakpoints.down('md')]: {
      fontSize: '1.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5625rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.875rem', // 30

    [theme.breakpoints.down('md')]: {
      fontSize: '1.5625rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem',
    },
  },
  h4: {
    fontWeight: 700, // can change through context 600
    fontSize: '1.5rem', // 24
    letterSpacing: '0.03em',

    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  h5: {
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '1.125rem', // 18
    letterSpacing: '0.03em',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: '0.875rem', // 14
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: '0.024em',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: 1.67,
    letterSpacing: 0,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  body2: {
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: '0.026em',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  button: {},
  caption: {
    fontWeight: 500,
    fontSize: '0.75rem',
    letterSpacing: '0.05em',
  },
  overline: {
    fontWeight: 400,
    fontSize: '1rem',
  },
};
