import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  sectionWrapper: {
    paddingTop: spacing(10),
    paddingBottom: spacing(8),
    backgroundColor: palette.waveSecondaryColor,

    [breakpoints.down('xs')]: {
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },
  },
  imageWrapper: {
    marginLeft: spacing(-10),
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },

    '@media (max-width:1400px)': {
      marginLeft: 0,
    },
    [breakpoints.down('md')]: {
      top: 20,
      width: 300,
      margin: spacing(0, 'auto'),
      '& img': {
        position: 'relative',
        left: 5,
      },
    },
  },
  contentWrapper: {
    marginLeft: spacing(5),
    marginRight: spacing(6.625),

    '@media (max-width:1400px)': {
      marginLeft: spacing(0),
      marginRight: 0,
    },
    [breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  text: {
    marginBottom: spacing(4),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
    },
  },
}));
