import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  // parent block of TOCBlogCard must be flex to get max height
  blogContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderRadius: 15,
    cursor: 'pointer',
    boxShadow: `0 2px 25px -10px ${palette.semiBlack}`,

    [breakpoints.down('sm')]: {
      maxWidth: 500,
      width: '100%',
      margin: spacing(0, 'auto'),
    },
    [breakpoints.down('xs')]: {
      maxWidth: 375,
    },
  },
  blogTextContainer: {
    display: 'flex',
    flexGrow: 1,
    padding: spacing(3.5, 4, 4),

    [breakpoints.down('md')]: {
      padding: spacing(3),
      '& .MuiTypography-caption': {
        fontSize: '11px',
      },
    },
    [breakpoints.down('sm')]: {
      padding: spacing(2.5),
    },
  },
  blogContentContainer: {
    display: 'flex',
  },
  blogTitleWrapper: {
    // 36px is grayed date&read time info
  },
  blogTitle: {
    lineHeight: 1.25,
    letterSpacing: 0,
    fontWeight: 600,
  },
  blogMedia: {
    height: 245,
    borderRadius: 15,
  },
}));
