import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  accordionContainer: {
    borderRadius: 15,
    padding: spacing(1, 1),
    border: `1px solid ${palette.text.primary}30`,
    marginBottom: spacing(2.5),
    boxShadow: 'none',
    transition: 'padding 200ms',
    '&.Mui-expanded': {
      marginTop: 0,
      padding: spacing(1, 1),
      marginBottom: spacing(2.5),
      borderRadius: 15,
    },
    '&:before': {
      display: 'none',
    },
  },
  listSummaryIcon: {
    color: palette.primary.main,
  },
  summaryContainer: {
    '&.Mui-expanded': {
      minHeight: spacing(6),
    },
    '& > .MuiAccordionSummary-content': {
      margin: spacing(1.5, 0),
    },
  },
  detailsContainer: {
    flexDirection: 'column',
  },
}));
