import Image from 'assets/images/client-women.png';

export default {
  imageUrl: Image,
  name: 'Paula Brown',
  username: 'PaulaBrown',
  dateJoined: new Date().toLocaleDateString(),
  sex: 'Male',
  phone: '(123) 456-7890',
  email: 'jonatahnwrite@gmail.com',
  address: '123 Street Name, Philadelphia',
};
