import makeStyles from '@material-ui/styles/makeStyles';

const sizeToFontSize = {
  small: 12,
  medium: 14,
  large: 16,
};

export default makeStyles(({ palette, spacing, typography }) => ({
  wrapper: {
    marginBottom: spacing(0.5),
  },
  dateTime: {
    fontSize: ({ size }) => typography.pxToRem(sizeToFontSize[size]),
    lineHeight: 1.2,
    textTransform: 'uppercase',
    color: palette.text.primary,
  },
  dot: {
    '&:before': {
      content: '""',
      width: 4,
      height: 4,
      display: 'block',
      borderRadius: '100%',
      margin: spacing(1.5, 1),
      backgroundColor: palette.semiBlack,
    },
  },
  dateInfoIcon: {
    fontSize: ({ size }) => typography.pxToRem(sizeToFontSize[size]),
    lineHeight: 1.2,
    overflow: 'visible',
    color: palette.text.primary,
    paddingRight: spacing(0.5),
  },
}));
