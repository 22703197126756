import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const blog = actionTypesGenerator(['article'], 'blog');

const initialState = {
  article: null,
  loading: true,
  error: false,
};

export default reducerGenerator(
  blog.article,
  initialState,
  (state, payload) => ({
    ...state,
    article: payload.data[0],
  })
);

export const getLatestArticle = () => ({
  type: blog.article.requested,
  payload: {
    pagination: { page: 1, perPage: 1 },
    sort: { field: 'createdAt', order: 'DESC' },
  },
});
