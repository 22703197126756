export const loadScript = (
  src,
  options,
  onLoad = () => {},
  onError = () => {}
) => {
  const { type, async, defer } = options;
  const script = document.createElement('script');

  script.src = src;
  script.type = type || 'text/javascript';
  script.async = async || true;
  script.defer = defer;

  document.body.appendChild(script);

  script.addEventListener('load', onLoad);

  script.addEventListener('error', () => {
    onError();
    throw new Error(`${src} failed to load.`);
  });
};

export const formatCoinsFromPrice = (price) => {
  const decimal = Math.floor(price);
  const coins = Math.round((price - decimal) * 100);
  const zeroBeforeCoins = coins > 0 && coins < 10 ? `0${coins}` : coins;
  return String(zeroBeforeCoins).length < 2
    ? `${zeroBeforeCoins}0`
    : zeroBeforeCoins;
};

export const joinNameTitles = (titles = []) =>
  titles.reduce((acc, { name }) => `${acc}, ${name}`, '');

export const sortByAlphabet = (list, paramName = 'name') =>
  list.sort((first, second) => {
    const firstItem = first[paramName].toLowerCase();
    const secondItem = second[paramName].toLowerCase();

    return firstItem.localeCompare(secondItem);
  });

export const genderMapper = (genders) =>
  sortByAlphabet(
    genders.map((gender) => ({
      id: gender,
      name: gender,
    }))
  );

export const openLink = (link) => window.open(link, '_target');

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${+count > 1 ? suffix : ''}`;
