import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import routes from 'common/constants/routes';
import { JoinUsLink } from 'common/constants/common';
import { TOCAccordion } from 'common/components';
import { TOCButton } from 'common/components/simple';

const CounselorFAQSection = () => (
  <>
    <Container>
      <Box my={{ xs: 5, sm: 8, md: 10 }}>
        <Typography align="center" variant="h2" color="primary">
          Read out counselors FAQ&apos;s
        </Typography>
      </Box>

      <Box mb={{ xs: 10, sm: 15 }}>
        <Grid container justify="space-between">
          <Box width={{ xs: '100%', md: '48.5%' }}>
            <TOCAccordion
              title="What are the qualifications for TOCs?"
              fontWeight="700"
            >
              <Typography variant="body1" component="div">
                <ul>
                  <li>
                    Master’s degree or higher in psychology, counseling, social
                    work, or related field
                  </li>
                  <li>
                    Clinical licensure (LPC, LMFT, LCSW) in state(s) of practice
                  </li>
                  <li>
                    A minimum of 3 years of relevant, clinical mental health
                    counseling experience
                  </li>
                  <li>Current NPI and CAQH registration</li>
                  <li>
                    Strong working knowledge of DSM criteria and counseling
                    treatment modalities
                  </li>
                  <li>Excellent time management skills</li>
                  <li>
                    Excellent interpersonal, communication, and technological
                    skills
                  </li>
                </ul>
              </Typography>
            </TOCAccordion>

            <TOCAccordion title="Why should I work with TOC?" fontWeight="700">
              <Typography variant="body1" component="div">
                Text and Online Counseling is an innovative mental health group
                committed to its mission of changing the narrative and removing
                the stigma around mental health care. We provide a platform for
                mental health counselors to use their time in the most efficient
                manner by eliminating the strains of marketing and medical
                billing. If you&apos;re looking to work remotely and
                independently while having the support of a larger practice,
                then you may find TOC to be a great fit for your professional
                goals.
              </Typography>
            </TOCAccordion>

            <TOCAccordion title="Is it really free to join?" fontWeight="700">
              <Typography variant="body1" component="div">
                Yes. We charge no hidden or monthly fees. Our transparent
                administrative fees are calculated at a small percentage of each
                claim submitted.
              </Typography>
            </TOCAccordion>

            <TOCAccordion
              title="Can I work for other groups or continue with my private practice?"
              fontWeight="700"
            >
              <Typography variant="body1" component="div">
                Yes. As independent contractors, TOC counselors can contract or
                hold employment with other mental health providers or see
                clients in their own private practice. However, it&apos;s a
                breach of contract to provide services to a client initiated
                through TOC in another mental health group or practice.
              </Typography>
            </TOCAccordion>
          </Box>

          <Box width={{ xs: '100%', md: '48.5%' }}>
            <TOCAccordion
              title="What conditions and diagnoses do TOCs typically treat?"
              fontWeight="700"
            >
              <Typography variant="body1" component="div">
                Many of our clients are seeking treatment for anxiety,
                depression, past trauma or abuse, and relationship or adjustment
                challenges. However, we don&apos;t provide crisis intervention.
                Clients in crisis are encouraged to call 911 or seek help from
                their nearest crisis center.
              </Typography>
            </TOCAccordion>

            <TOCAccordion
              title="Do I need to have my own malpractice insurance?"
              fontWeight="700"
            >
              <Typography variant="body1" component="div">
                Yes. As an independent contractor, counselors must hold and
                maintain general liability and malpractice insurance.
              </Typography>
            </TOCAccordion>

            <TOCAccordion
              title="What's the earning potential?"
              fontWeight="700"
            >
              <Typography variant="body1" component="div">
                Many traditional counseling practices pay counselors only about
                half of the reimbursement received for the session. Our
                counselors keep most of the reimbursement amount and therefore
                earn, on average, more than counselors in traditional counseling
                practices. In addition, counselors who are accustomed with
                private practice find they&apos;re able to earn more money
                through TOC as they don&apos;t have to spend time away from
                clients trying to solve denied claims and billing issues.
              </Typography>
            </TOCAccordion>

            <TOCAccordion title="Am I guaranteed referrals?" fontWeight="700">
              <Typography variant="body1" component="div">
                While the number of referrals isn&apos;t guaranteed, we find
                that counselors who consistently manage their schedule and
                availability, and keep updated profiles including pictures,
                videos, professional statements, and specialties, receive more
                referrals than those who don&apos;t. In addition, counselors
                with good ratings and feedback are more attractive to
                prospective clients than other counselors are.
              </Typography>
            </TOCAccordion>
          </Box>
        </Grid>

        <Box mt={{ xs: 4, sm: 6, md: 10 }}>
          <Typography color="primary" align="center" variant="body2">
            <Box fontWeight="700" component="span">
              Learn more or get started today
            </Box>
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            mt={5}
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems="center"
          >
            <Box mr={{ xs: 0, sm: 4 }} mb={{ xs: 3, sm: 0 }}>
              <TOCButton
                width={137}
                height={79}
                size="large"
                color="secondary"
                to={JoinUsLink}
              >
                <Box fontWeight="700" component="span">
                  Join now
                </Box>
              </TOCButton>
            </Box>

            <TOCButton
              width={152}
              height={79}
              size="large"
              to={routes.contact.url}
            >
              <Box fontWeight="700" component="span">
                Contact us
              </Box>
            </TOCButton>
          </Box>
        </Box>
      </Box>
    </Container>
  </>
);

export default CounselorFAQSection;
