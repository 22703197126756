import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, typography, breakpoints }) => ({
  container: {
    marginTop: spacing(7),
    marginBottom: spacing(7),

    [breakpoints.down('xs')]: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
    },
  },
  textWrapper: {
    [breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        fontSize: typography.pxToRem(16),
      },
    },
  },
  inputWrapper: {
    width: '48%',

    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  emailLink: {
    color: palette.secondary.main,
  },
  textField: {
    height: 60,
    marginTop: 4,
    width: '100%',
    outline: 'none',
    borderRadius: 10,
    padding: 0,
    paddingLeft: spacing(2),
    fontSize: typography.pxToRem(18),
    border: '1px solid #d2d6db',
    boxSizing: 'border-box',
  },
  textArea: {
    height: 135,
    marginTop: 4,
    width: '100%',
    resize: 'none',
    outline: 'none',
    borderRadius: 10,
    padding: spacing(2),
    fontFamily: 'Poppins Regular',
    fontSize: typography.pxToRem(18),
    border: '1px solid #d2d6db',
    boxSizing: 'border-box',
  },
}));
