import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  overlay: {
    '& > .MuiBackdrop-root': {
      // without 'important' property opacity param overrides by library via style attribute
      opacity: '0.5 !important',
      backgroundColor: palette.primary.main,
    },
  },
  sideMenuWrap: {
    width: 430,
    padding: spacing(6.25, 10.5, 0),
    backgroundColor: palette.primary.main,

    [breakpoints.down('md')]: {
      width: 350,
      padding: spacing(6.25, 10.5, 0),
    },
    [breakpoints.down('xs')]: {
      width: '100%',
      boxSizing: 'border-box',
      padding: spacing(2),
    },
  },
  menuWrapper: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  contentWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    marginBottom: spacing(2),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(1),
    },
  },
  menuButtonsSection: {
    marginBottom: spacing(2),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(1),
    },
  },
  sideMenuLink: {
    textDecoration: 'none',
    marginTop: 0,
    marginBottom: spacing(2.5),
    '& > .MuiTypography-root': {
      transition: 'color .3s ease-in-out',
    },

    '&:hover': {
      '& > .MuiTypography-root': {
        color: palette.secondary.main,
      },
    },

    [breakpoints.down('xs')]: {
      marginBottom: spacing(0.5),
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      textAlign: 'center',
    },
  },
  sideMenuActiveLink: {
    textDecoration: 'none',
    '& > .MuiTypography-root': {
      color: palette.secondary.main,
    },
  },
  socialLinksWrapper: {
    marginTop: spacing(10),

    [breakpoints.down('md')]: {
      marginTop: spacing(8),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(6),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(4),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
