import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    border: '1px dashed #d3d6db',
    borderRadius: 10,
  },
  title: {
    fontSize: 14,
    color: '#b6bdc4',
    fontWeight: 600,
    padding: '16px 0px',
  },
  fileIcon: {
    height: 50,
  },
  footer: {
    fontSize: 12,
    color: '#45677b',
    fontWeight: 600,
    padding: '16px 0px',
  },
});
