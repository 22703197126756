import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  contentContainer: {
    border: '1px solid #EAF0F4',
  },
  iconButton: {
    height: 32,
    width: 32,
    borderRadius: 10,
    backgroundColor: '#57CEBA',
  },
  isOpen: {
    backgroundColor: '#f4f8fb',
  },
  isClose: {
    backgroundColor: '#fff',
  },
  dateString: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: -0.4,
    color: '#143A56',
  },
  titleContainer: {
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: '1.2rem',
    },
  },
  statusContainer: {
    '& > *:not(:last-child)': {
      marginRight: '0.5rem',
    },
  },
});
