import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const CustomButton = ({
  children,
  bgColor,
  color,
  className,
  px,
  py,
  style: otherStyles,
  ...rest
}) => {
  const styles = {
    textTransform: 'none',
    border: 'none',
    borderRadius: 10,
    fontSize: '0.84rem',
    fontWeight: 600,
  };
  if (px) {
    styles.paddingRight = px * 8;
    styles.paddingLeft = px * 8;
  }
  if (py) {
    styles.paddingTop = py * 8;
    styles.paddingBottom = py * 8;
  }
  if (bgColor) {
    styles.backgroundColor = bgColor;
  }
  if (color) {
    styles.color = color;
  }
  return (
    <Button
      variant="outlined"
      style={{ ...styles, ...otherStyles }}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  style: {},
  className: '',
};

CustomButton.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  px: PropTypes.string,
  py: PropTypes.string,
  style: PropTypes.shape(),
  className: PropTypes.string,
};

export default CustomButton;
