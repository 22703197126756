import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  modalContainer: {
    width: '33rem',
    backgroundColor: '#fff',
    borderRadius: 15,
    paddingTop: 30,
  },
  mainTitleContainer: {
    paddingBottom: 16,
  },
  mainTitle: {
    fontSize: 18,
    fontWeight: 800,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 16,
  },
  paidTitle: {
    flex: 2,
    color: '#A6AEB7',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: -0.35,
  },
  paidContent: {
    flex: 3,
    color: '#143A56',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: -0.4,
  },
  cardContainer: {
    borderRadius: 15,
    backgroundColor: '#163449',
    display: 'flex',
    position: 'relative',
    marginTop: 15,
  },
  leftContainer: {
    backgroundColor: '#163449',
    width: 'calc( 60% - 40px)',
    height: '100%',
  },
  rightContainer: {
    width: 'calc( 40% + 40px)',
    height: '100%',
    backgroundColor: '#1c3950',
    borderLeft: '5rem solid #163449',
    borderBottom: '17.5rem solid transparent',
    borderRadius: '0px 15px 15px 0px',
    boxSizing: 'border-box',
  },
  cardContentContainer: {
    position: 'absolute',
    padding: 40,
  },
  cardLogoContainer: {
    position: 'absolute',
    top: 20,
    right: 40,
    '& .MuiSvgIcon-root': {
      width: 58,
      fontSize: 58,
    },
  },
  cardContentRow: {
    flex: 1,
    justifyContent: 'space-between',
  },
  cardId: {
    paddingBottom: 15,
  },
  cardTitle: {
    color: '#899CAA',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: -0.35,
  },
  cardContent: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: -0.4,
  },
  cardNumber: {
    color: '#FFFFFF',
    fontSize: 24,
    fontWeight: 400,
    letterSpacing: -0.6,
  },
  cardRowItem: {
    paddingLeft: 36,
    paddingRight: 36,
  },
  greyContent: {
    color: '#899CAA',
  },
}));
