import React from 'react';

import { Header, Footer } from 'common/components';
import HeaderSection from './components/HeaderSection';
import ContactForm from './components/ContactForm';

const ContactPage = () => (
  <>
    <Header height={{ xs: '380px', sm: '450px', md: '500px', lg: '600px' }} />
    <HeaderSection />
    <ContactForm />
    <Footer />
  </>
);

export default ContactPage;
