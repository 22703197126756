export const statusTitle = [
  { id: 1, title: 'Attended', color: '#70C491' },
  { id: 2, title: 'Cancelled', color: '#9338ED' },
  { id: 3, title: 'Late cancel', color: '#EFC981' },
  { id: 4, title: 'No show', color: '#E55852' },
  { id: 5, title: 'Upcoming', color: '#4998CE' },
];

export const clientsValues = [
  {
    value: 'client1',
    label: 'Client 1',
  },
  {
    value: 'client2',
    label: 'Client 2',
  },
];

export const providersValues = [
  {
    value: 'provider1',
    label: 'Provider 1',
  },
  {
    value: 'provider2',
    label: 'Provider 2',
  },
];

export const statusValues = statusTitle.map(({ title, color }, index) => ({
  value: index,
  label: title,
  color,
}));

const mockDate = [
  '02.08.2021',
  '03.08.2021',
  '10.02.2021',
  '08.08.2021',
  '11.08.2021',
  '04.08.2021',
  '08.02.2021',
  '01.08.2021',
];
const billingTypes = ['self pay', 'no show fee', 'insurance'];

export const appointments = new Array(100).fill(0).map((_, id) => ({
  id,
  date: mockDate[id % 7],
  duration: 50,
  client: 'Jane Jones',
  provider: 'Jonathan Brown',
  status: statusTitle[id % 5],
  billingType: billingTypes[id % 3],
  clientOwes: 45,
}));

export const actions = [
  {
    id: 1,
    icon: 'fas fa-share-alt-square',
    title: 'Send session link',
  },
  {
    id: 2,
    icon: 'fas fa-video',
    title: 'Start video session',
  },
  {
    id: 3,
    icon: 'fas fa-envelope',
    title: 'Email receipt',
  },
  {
    id: 4,
    icon: 'fas fa-print',
    title: 'Print receipt',
  },
  {
    id: 5,
    icon: 'fas fa-sticky-note',
    title: 'View/add progress note',
  },
  {
    id: 6,
    icon: 'fas fa-envelope',
    title: 'Email superbill',
  },
  {
    id: 7,
    icon: 'fas fa-print',
    title: 'Print superbill',
  },
];
