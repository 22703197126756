import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuList from '@material-ui/core/MenuList';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MenuItem from './MenuItem';

import useStyles from './styles';

const SubMenu = ({
  IconComponent = null,
  title,
  menuItems = [],
  expanded,
  currentPath,
}) => {
  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  const onOpenClick = () => {
    setOpened((prevState) => !prevState);
  };

  const isActive = menuItems.some((item) => item.url === currentPath);

  useEffect(() => {
    if (isActive) {
      setOpened(true);
    }
  }, [isActive]);

  const titleClasses = [
    classes.title,
    isActive ? classes.active : '',
    !expanded ? classes.hidden : '',
  ].join(' ');

  return (
    <Grid
      container
      direction="column"
      className={[classes.item, opened ? classes.subMenuCollapsed : ''].join(
        ' '
      )}
    >
      <Grid item component={Box} flex={1} style={{ width: '100%' }}>
        <MaterialMenuItem onClick={onOpenClick}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item container component={Box} flex={1} display="flex">
              <Grid item className={classes.itemIcon} component={ListItemIcon}>
                {IconComponent}
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" className={titleClasses}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" component={Box} style={{ height: 0 }}>
              {opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
          </Grid>
        </MaterialMenuItem>
      </Grid>
      {opened && expanded && (
        <Grid item component={Box} flex={1}>
          <MenuList>
            {menuItems.map(({ key, title: itemTitle, url }) => {
              return (
                <MenuItem
                  key={key}
                  title={itemTitle}
                  url={url}
                  expanded={expanded}
                  currentPath={currentPath}
                />
              );
            })}
          </MenuList>
        </Grid>
      )}
    </Grid>
  );
};

SubMenu.propTypes = {
  IconComponent: PropTypes.node,
  title: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
  expanded: PropTypes.bool,
  currentPath: PropTypes.string,
};

export default SubMenu;
