import { reducerGenerator, actionTypesGenerator } from 'common/utils';

export const profile = actionTypesGenerator(['counselor'], 'search');

const initialState = {
  counselor: null,
  loading: true,
  error: false,
};

export default reducerGenerator(
  profile.counselor,
  initialState,
  (state, payload) => ({
    ...state,
    counselor: payload.data,
  })
);

export const getCounselor = (id) => ({
  type: profile.counselor.requested,
  payload: { id },
});
