import React, { useState } from 'react';
import AdminLayout from 'common/components/admin/Layout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from 'common/components/admin/Button';
import AddIcon from '@material-ui/icons/Add';
import ButtonTripleSwitch from 'common/components/admin/ButtonTripleSwitch';
import DatePickerExtended from 'common/components/admin/DatePickerExtended';
import SelectInput from 'common/components/admin/SelectInput';
import AppointmentsTable from './components/AppointmentsTable';
import AppointmentsSummary from './components/AppointmentsSummary';
import useStyles from './styles';
import {
  appointments,
  clientsValues,
  providersValues,
  statusValues,
} from './appointmentsData';

const preparedData = (data) => {
  const prepared = {};
  for (let i = 0; i < data.length; i += 1) {
    const element = data[i];
    if (prepared[element.date]) {
      prepared[element.date] = [...prepared[element.date], element];
    } else {
      prepared[element.date] = [element];
    }
  }
  const convertedData = Object.entries(prepared);
  return convertedData;
};

const Appointments = () => {
  const classes = useStyles();
  const actionButton = [
    <Button
      key={0}
      startIcon={<AddIcon />}
      bgColor="#40ceba"
      className={classes.topButton}
    >
      Create appointment
    </Button>,
  ];
  const [calendarMode, setCalendarMode] = useState('day');
  const switchCalendarMode = (value) => setCalendarMode(value);
  const appointmentsList = preparedData(appointments);
  return (
    <AdminLayout title="Appointments" actionButtons={actionButton}>
      <Grid container direction="column" className={classes.tableContainerGrid}>
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          component={Box}
          py={2}
          px={3}
        >
          <Grid container component={Box} flex={1}>
            <DatePickerExtended mode={calendarMode} />
          </Grid>
          <Grid container component={Box} flex={1} justifyContent="flex-end">
            <ButtonTripleSwitch switchItem={switchCalendarMode} />
          </Grid>
        </Grid>
        <Divider />

        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          component={Box}
          py={3}
          px={2}
        >
          <Grid
            item
            container
            component={Box}
            flex={1}
            className={classes.countLabel}
          >
            <Typography component="span">Showing</Typography>
            <Typography style={{ fontWeight: 700 }}>&nbsp;100&nbsp;</Typography>
            <Typography>appointments</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            py={2}
            px={3}
            alignItems="center"
            component={Box}
            flex={2}
            justifyContent="flex-end"
          >
            <Grid item component={Box}>
              <SelectInput
                placeholder="All clients"
                valueOptions={clientsValues}
              />
            </Grid>
            <Grid item component={Box}>
              <SelectInput
                placeholder="All providers"
                valueOptions={providersValues}
              />
            </Grid>
            <Grid item component={Box}>
              <SelectInput placeholder="Status" valueOptions={statusValues} />
            </Grid>
            <Grid item component={Box}>
              <Button className={classes.resetButton} variant="outlined">
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          display="flex"
          direction="row"
          justify="space-between"
          alignItems="center"
          component={Box}
        >
          {calendarMode === 'day' ? (
            <AppointmentsTable data={appointmentsList[0][1]} />
          ) : (
            appointmentsList.map((appointmentsByDay) => (
              <AppointmentsSummary
                key={appointmentsByDay[0]}
                data={appointmentsByDay}
              />
            ))
          )}
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Appointments;
