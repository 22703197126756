import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PeopleIcon from '@material-ui/icons/People';
import ContactsIcon from '@material-ui/icons/Contacts';
import AdminLayout from 'common/components/admin/Layout';
import TotalStatistics from 'common/components/admin/Statistics/TotalStatistics';
import GraphStatistics from 'common/components/admin/Statistics/GraphStatistics';
import ResponsivePie from 'common/components/admin/Statistics/graphs/ResponsivePie';
import ResponsiveBar from 'common/components/admin/Statistics/graphs/ResponsiveBar';
import routes from 'common/constants/routes';

import attendanceData from './graphData/attendance';
import revenueData from './graphData/revenue';
import claims from './graphData/claims';
import enrollments from './graphData/enrollments';

const Dashboard = () => {
  const pie1 = useRef();
  const pie2 = useRef();
  const pie3 = useRef();
  const bar = useRef();
  const handleMenuExpand = () => {
    pie1.current?.refresh();
    pie2.current?.refresh();
    pie3.current?.refresh();
    bar.current?.refresh();
  };
  return (
    <AdminLayout
      title="Dashboard"
      onWindowResize={handleMenuExpand}
      onMenuExpand={handleMenuExpand}
    >
      <Grid container>
        <Grid item container component={Box} xs={12} lg={4} py={2} pr={2}>
          <Grid item xs={12}>
            <TotalStatistics
              iconBgColor="#d1f3ee"
              icon={<PeopleIcon style={{ color: '#40ceba' }} />}
              title="Total Clients"
              value={450}
              link={{
                title: 'Manage Clients',
                url: routes.admin.manageClients.url,
              }}
            />
          </Grid>

          <Grid item component={Box} xs={12} pt={2}>
            <TotalStatistics
              iconBgColor="#d1e0e9"
              icon={<ContactsIcon style={{ color: '#0d648e' }} />}
              title="Total Providers"
              value={450}
              link={{
                title: 'Manage Providers',
              }}
            />
          </Grid>
        </Grid>

        <Grid item container component={Box} xs={12} lg={8} py={2} pl={1}>
          <GraphStatistics headerTitle="Enrollments">
            <Box height={203} style={{ width: '100%', padding: '17px 0px' }}>
              <ResponsiveBar
                ref={bar}
                keys={['New patient enrollments', 'New provider enrollments']}
                data={enrollments}
              />
            </Box>
          </GraphStatistics>
        </Grid>
      </Grid>

      <Grid
        item
        spacing={3}
        container
        direction="row"
        component={Box}
        py={2}
        flex={1}
      >
        <Grid item xs={12} lg={4} component={Box} flex={1}>
          <GraphStatistics
            headerTitle="Attendance"
            link={{
              title: 'View appointments',
            }}
          >
            <Box height={300}>
              <ResponsivePie
                ref={pie1}
                data={attendanceData}
                totalValue="670"
                totalTitle="Total appointments"
              />
            </Box>
          </GraphStatistics>
        </Grid>
        <Grid item xs={12} lg={4} component={Box} flex={1}>
          <GraphStatistics
            headerTitle="Revenue"
            link={{
              title: 'View reports',
            }}
          >
            <Box height={300}>
              <ResponsivePie
                ref={pie2}
                data={revenueData}
                totalValue="$14865"
                totalTitle="Total revenue"
              />
            </Box>
          </GraphStatistics>
        </Grid>
        <Grid item xs={12} lg={4} component={Box} flex={1}>
          <GraphStatistics
            headerTitle="Claims"
            link={{
              title: 'Manage claims',
            }}
          >
            <Box height={300}>
              <ResponsivePie ref={pie3} data={claims} innerRadius={0} />
            </Box>
          </GraphStatistics>
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Dashboard;
