import { actionTypesGenerator } from 'common/utils';
import { options } from './filterOptions';

export const filters = actionTypesGenerator(['save', 'reset'], 'filters');

const initialFilterState = {
  filters: {
    page: 1,
    state: 1,
    availability: 0,
    specialities: [],
    insurance: 0,
    genders: [],
    languages: [],
    ethnicity: [],
    age: [10, 90],
  },
};

export default (state = initialFilterState, { type, payload }) => {
  switch (type) {
    case filters.save.requested:
      return {
        filters: {
          ...state.filters,
          ...payload,
        },
      };

    case filters.reset.requested:
      return {
        filters: initialFilterState.filters,
      };

    // catching success action type of next-door reducer is necessary
    // for correct update of min and max fees
    case options.get.success: {
      const {
        minAvailableRate: minFee,
        maxAvailableRate: maxFee,
      } = payload.data;

      return {
        filters: {
          ...state.filters,
          fees: [minFee, maxFee],
        },
      };
    }

    default:
      return state;
  }
};

export const saveFilters = (payload) => ({
  type: filters.save.requested,
  payload,
});

export const resetFilters = () => ({
  type: filters.reset.requested,
});
