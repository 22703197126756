import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  topButton: {
    borderRadius: 20,
    boxShadow: '0px 5px 15px #143A5680',
    color: '#fff',
  },
  topButtonIcon: {
    '& .MuiIcon-root': {
      fontSize: 14,
      width: 18,
    },
  },
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  resetButton: {
    textTransform: 'capitalize',
    backgroundColor: '#093b54',
    color: '#ffff',
    padding: 7,
    fontSize: 14,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#093b54',
      opacity: 0.6,
    },
  },
  countLabel: {
    '& > *': {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
  },
  table: {
    backgroundColor: '#ffff',
  },
  thGrid: {
    backgroundColor: '#f4f8fb',
  },
  th: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: '#8B94AC',
    paddingRight: '0.2rem',
    maxWidth: 'calc(100% - 28px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: 400,
    fontSize: 14,
    color: '#143A56',
  },
  queryIcon: {
    fontSize: 10,
    color: '#143A56',
  },
  actionButton: {
    color: '#143A56',
    padding: 10,
    paddingTop: 6,
    paddingBottom: 7,
  },
  lightGrey: {
    color: '#A6AEB7',
  },
  boldFont: {
    fontWeight: 600,
    fontSize: 14,
    color: '#143A56',
  },
  modal: {
    display: 'flex',
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
