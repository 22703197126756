import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import MenuList from '@material-ui/core/MenuList';

import menuStructure from 'common/constants/adminMenuStructure';
import MenuItem from './components/MenuItem';
import SubMenu from './components/SubMenu';

const Menu = ({ expanded }) => {
  const match = useRouteMatch();
  const { path: currentPath } = match;
  return (
    <MenuList style={{ width: '100%', outline: 'none' }}>
      {menuStructure.map((menuItem) => {
        return menuItem.nested ? (
          <SubMenu
            IconComponent={menuItem.icon || null}
            title={menuItem.title}
            currentPath={currentPath}
            menuItems={menuItem.nested}
            expanded={expanded}
          />
        ) : (
          <MenuItem
            key={menuItem.key}
            IconComponent={menuItem.icon || null}
            currentPath={currentPath}
            title={menuItem.title}
            url={menuItem.url}
            otherUrls={menuItem.otherUrls}
            expanded={expanded}
          />
        );
      })}
    </MenuList>
  );
};

Menu.propTypes = {
  expanded: PropTypes.bool,
};

export default Menu;
