import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderRadius: 10,
  },
  topButton: {
    borderRadius: 20,
    boxShadow: '0px 5px 15px #143A5680',
    color: '#fff',
  },
  input: {
    minWidth: 150,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '0.9rem',
    color: 'black',
    shrink: true,
    '&::placeholder': {
      fontWeight: 500,
      color: 'grey',
    },
  },
  textField: {
    '& > div': {
      borderRadius: 10,
    },
  },
  contentContainer: {
    flexWrap: 'wrap',
    paddingLeft: 24,
    '& > *:not(:last-child)': {
      marginRight: '1.8rem',
    },
  },
});
