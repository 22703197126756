import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  aboutSoftwareContainer: {
    color: palette.primary.main,
    backgroundColor: palette.waveSecondaryColor,

    [breakpoints.down('sm')]: {
      paddingTop: spacing(6),
    },
  },
  aboutSoftwarePreviewSet: {
    position: 'relative',

    [breakpoints.down('sm')]: {
      height: 350,
    },
  },
  softwareClientsPreview: {
    position: 'absolute',
    top: -80,
    left: -135,
    zIndex: 2,

    '@media (max-width:1500px)': {
      width: 380,
      left: 0,
    },
    [breakpoints.down('md')]: {
      width: 350,
    },
    [breakpoints.down('sm')]: {
      top: -130,
      width: 250,
      left: '40%',
      transform: 'translateX(-50%)',
    },
  },
  softwareEarningsPreview: {
    position: 'absolute',
    top: 55,
    left: 170,
    zIndex: 3,

    '@media (max-width:1500px)': {
      width: 380,
      left: '40%',
    },
    [breakpoints.down('md')]: {
      width: 350,
      left: '35%',
    },
    [breakpoints.down('sm')]: {
      top: -40,
      width: 250,
      left: '61%',
      transform: 'translateX(-50%)',
    },
  },
  softwareCalendarPreview: {
    position: 'absolute',
    top: 280,
    left: -75,
    zIndex: 1,

    '@media (max-width:1500px)': {
      width: 290,
      left: '10%',
    },
    [breakpoints.down('md')]: {
      width: 260,
    },
    [breakpoints.down('sm')]: {
      top: 150,
      width: 150,
      left: '34%',
      transform: 'translateX(-50%)',
    },
  },
  aboutSoftwareDescription: {
    paddingLeft: '5%',

    [breakpoints.down('sm')]: {
      paddingLeft: 0,
      textAlign: 'center',
    },
  },
  aboutSoftwareDescriptionHint: {
    color: palette.text.primary,
    letterSpacing: 0,
    marginBottom: spacing(0.4),
  },
  aboutSoftwareDescriptionTitle: {
    lineHeight: 1.33,
  },
}));
