import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const MultiInput = ({ label }) => {
  const classes = useStyles();
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);

  const onChangeInput = (e, prevRef, nextRef) => {
    const {
      target: { value },
    } = e;
    if (value.length > 0) {
      const [firstSymbol] = value;
      e.target.value = firstSymbol;
      if (nextRef) {
        nextRef.current.focus();
      }
    } else if (value.length === 0) {
      if (prevRef) {
        prevRef.current.focus();
      }
    }
  };

  const onKeyDown = (e, currentRef, prevRef) => {
    if (e.code === 'Backspace' && !currentRef.current.value) {
      prevRef.current.focus();
    }
  };

  return (
    <Box>
      <InputLabel className={classes.inputLabel} shrink>
        {label}
      </InputLabel>
      <Grid container>
        <Grid item component={Box} pr={1}>
          <TextField
            inputRef={input1}
            className={classes.inputRoot}
            onChange={(e) => onChangeInput(e, null, input2)}
            InputProps={{
              className: classes.input,
            }}
            id="outlined-basic"
            variant="outlined"
          />
        </Grid>

        <Grid item component={Box} px={1}>
          <TextField
            inputRef={input2}
            className={classes.inputRoot}
            InputProps={{
              className: classes.input,
            }}
            onChange={(e) => onChangeInput(e, input1, input3)}
            onKeyDown={(e) => onKeyDown(e, input2, input1)}
            id="outlined-basic"
            variant="outlined"
          />
        </Grid>

        <Grid item component={Box} px={1}>
          <TextField
            inputRef={input3}
            className={classes.inputRoot}
            InputProps={{
              className: classes.input,
            }}
            onChange={(e) => onChangeInput(e, input2, input4)}
            onKeyDown={(e) => onKeyDown(e, input3, input2)}
            id="outlined-basic"
            variant="outlined"
          />
        </Grid>

        <Grid item component={Box} pl={1}>
          <TextField
            inputRef={input4}
            className={classes.inputRoot}
            InputProps={{
              className: classes.input,
            }}
            onChange={(e) => onChangeInput(e, input3)}
            onKeyDown={(e) => onKeyDown(e, input4, input3)}
            id="outlined-basic"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

MultiInput.propTypes = {
  label: PropTypes.string,
};

export default MultiInput;
