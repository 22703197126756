import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from 'common/components/admin/Button';

import useStyles from './styles';

const TableHeader = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [radio, setRadio] = useState('all');
  return (
    <Grid
      item
      container
      display="flex"
      flexDirection="row"
      justify="space-between"
      alignItems="center"
      component={Box}
      py={2}
      px={2}
    >
      <Grid
        item
        container
        component={Box}
        flex={1}
        className={classes.clientsCountLabel}
      >
        <Typography component="span">Showing</Typography>
        <Typography style={{ fontWeight: 700 }}>&nbsp;4&nbsp;</Typography>
        <Typography>Clients</Typography>
      </Grid>
      <Grid
        item
        container
        spacing={1}
        component={Box}
        xs={6}
        flex={2}
        justifyContent="flex-end"
      >
        <Grid item component={Box} flex={3}>
          <RadioGroup
            row
            value={radio}
            onChange={(e) => setRadio(e.target.value)}
          >
            <FormControlLabel
              value="all"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="All"
            />
            <FormControlLabel
              value="active"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="Active"
            />
            <FormControlLabel
              value="inactive"
              classes={{ label: classes.radioLabel }}
              control={
                <Radio
                  size="small"
                  className={classes.radio}
                  color="secondary"
                />
              }
              label="Inactive"
            />
          </RadioGroup>
        </Grid>
        <Grid item component={Box} flex={2}>
          <TextField
            placeholder="Search providers"
            variant="outlined"
            value={search}
            className={classes.textField}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.input },
            }}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.resetButton}
            variant="outlined"
            onClick={() => setSearch('')}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableHeader;
