import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  rootSection: {
    position: 'relative',
    marginTop: spacing(15),
    marginBottom: spacing(20),

    [breakpoints.down('md')]: {
      marginTop: spacing(10),
      marginBottom: spacing(6),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },
  prosSectionTile: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.secondary.lighter,
    padding: spacing(4.5),
    borderRadius: '0 60px',
    boxShadow: `0 6px 10px 5px ${palette.black}10`,
  },
  mainTitleGrid: {
    flexDirection: 'column',

    [breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  tilesGrid: {
    justifyContent: 'space-between',
    '& > .MuiGrid-item': {
      paddingBottom: 70,
      maxWidth: 'calc(50% - 25px)',
    },

    [breakpoints.down('md')]: {
      '& > .MuiGrid-item': {
        maxWidth: 'calc(50% - 20px)',
        paddingBottom: 42,
      },
    },
    [breakpoints.down('sm')]: {
      '& > .MuiGrid-item': {
        maxWidth: '100%',
        paddingBottom: 32,
      },
    },
  },
  tileText: {
    lineHeight: 1.6,
  },
  boxPersonWrapper: {
    position: 'absolute',
    left: -87,
    bottom: -100,
    '& img': {
      maxWidth: '100%',
    },

    '@media (min-width:1925px)': {
      left: 'calc(50% - 965px)',
    },
    [breakpoints.down('md')]: {
      display: 'none',
      maxWidth: 300,
      left: -70,
      bottom: -290,
    },
    [breakpoints.down('sm')]: {
      maxWidth: 200,
      left: -50,
      bottom: -180,
    },
    [breakpoints.down('xs')]: {
      maxWidth: 150,
      left: -40,
      bottom: -130,
    },
  },
}));
