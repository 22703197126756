import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import OverviewLayout from 'common/components/admin/profiles/ProfileOverview';
import DataItem from 'common/components/admin/profiles/DataItem';

import useStyles from './styles';
import data from './data';

const ClientProfile = () => {
  const classes = useStyles();
  return (
    <OverviewLayout
      data={data}
      sections={[
        <Grid key={0} container direction="column">
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            component={Box}
            py={1}
          >
            <Grid
              item
              component={Typography}
              className={classes.middleSectionLabel}
              variant="subtitle2"
              color="textPrimary"
            >
              Active Profile
            </Grid>
            <Grid item component={Switch} />
          </Grid>

          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            component={Box}
            py={1}
          >
            <Grid
              item
              component={Typography}
              className={classes.middleSectionLabel}
              variant="subtitle2"
              color="textPrimary"
            >
              Accepting new clients
            </Grid>
            <Grid item component={Switch} />
          </Grid>

          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            component={Box}
            py={1}
          >
            <Grid
              item
              component={Typography}
              className={classes.middleSectionLabel}
              variant="subtitle2"
              color="textPrimary"
            >
              Self pay rate per session
            </Grid>
            <Grid
              item
              component={Typography}
              style={{ fontSize: 14, paddingRight: 11 }}
            >
              $200
            </Grid>
          </Grid>
        </Grid>,

        <Grid key={1} container direction="column">
          <Grid
            item
            component={Typography}
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            Professional Information
          </Grid>
          <Grid
            item
            component={DataItem}
            title="License Type"
            value="License Professional Comuncelor"
          />
          <Grid container justify="space-between" component={Box} py={1}>
            <Grid
              item
              component={DataItem}
              title="Individual NPI"
              value="1234567890"
            />
            <Grid item component={DataItem} title="CAQH ID" value="123456" />
          </Grid>
        </Grid>,

        <Grid key={2} container direction="column">
          <Grid
            item
            component={Typography}
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            Personal Information
          </Grid>

          <Grid item container justify="space-between" component={Box} py={1}>
            <Grid item component={Box} flex={1}>
              <DataItem title="Promary Practice state" value="Florida (FL)" />
            </Grid>
            <Grid item component={Box} flex={1}>
              <DataItem
                title="Additional practice state"
                value="Georgia (GA)"
              />
            </Grid>
          </Grid>

          <Grid item component={Box} py={1}>
            <DataItem
              title="Mailling address"
              value="123 Street Name, Florida"
            />
          </Grid>

          <Grid item component={Box} py={1}>
            <DataItem title="Primary Email" value="paula.braun@gmail.com" />
          </Grid>

          <Grid item component={Box} py={1}>
            <DataItem
              title="Phone"
              value={
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      component="span"
                      style={{
                        fontSize: '0.825rem',
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      Home:&nbsp;
                    </Typography>
                    <Typography
                      component="span"
                      style={{ fontSize: '0.825rem', color: 'black' }}
                    >
                      (123) 142-234-34
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      component="span"
                      style={{
                        fontSize: '0.825rem',
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      Cell:&nbsp;
                    </Typography>
                    <Typography
                      component="span"
                      style={{ fontSize: '0.825rem', color: 'black' }}
                    >
                      (123) 142-234-34
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>,
        <Grid key={2} container direction="column" component={Box} py={1}>
          <Grid item component={Box} pb={1}>
            <Typography
              style={{ fontSize: '1.1rem', color: 'black', fontWeight: 600 }}
            >
              Sanctions
            </Typography>

            <Typography style={{ fontSize: '0.825rem', color: '#bcc4c9' }}>
              Nothing here yet
            </Typography>
          </Grid>
        </Grid>,
      ]}
    />
  );
};

export default ClientProfile;
