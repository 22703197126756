import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import ToggleButton from './ToggleButton';

const ToggleButtonGroup = ({ data }) => {
  const [selected, setSelected] = useState(data[0]?.value);
  return (
    <ButtonGroup disableElevation variant="contained">
      {data.map((item) => (
        <ToggleButton
          key={item.value}
          {...item}
          isSelected={item.value === selected}
          onClick={() => setSelected(item.value)}
        />
      ))}
    </ButtonGroup>
  );
};

ToggleButtonGroup.defaultProps = {
  data: [
    {
      value: 'year',
      label: 'Year',
    },
    {
      value: 'month',
      label: 'Month',
    },
  ],
};

ToggleButtonGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default ToggleButtonGroup;
