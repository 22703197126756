import React from 'react';
import PropTypes from 'prop-types';
import ReactWave from 'react-wavify';

import palette from 'theme/palette';

import StaticWave from './StaticWave';
import useStyles from './styles';

const Wave = (props) => {
  const { animated, invert, color = 'primary', height, bgcolor } = props;
  const styles = useStyles({ animated, invert, height });
  const paletteColor = palette[color]?.main || palette[color];
  const backgroundColor = bgcolor
    ? palette[bgcolor]
    : palette.waveDefaultBackgroundColor;

  return animated ? (
    <ReactWave
      fill={paletteColor}
      className={styles.dynamic}
      options={{
        points: 3,
        speed: 0.15,
        amplitude: 40,
        height: 20,
      }}
      style={{ backgroundColor }}
    />
  ) : (
    // todo pay attention on viewBox
    <svg viewBox="0 0 1440 280" className={styles.static}>
      <StaticWave fill={paletteColor} />
    </svg>
  );
};

Wave.propTypes = {
  invert: PropTypes.bool,
  color: PropTypes.string,
  bgcolor: PropTypes.string,
  animated: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default Wave;
