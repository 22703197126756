import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
  CustomSelect,
  ToggleButtonGroup,
} from 'common/components/admin/Statistics/GraphStatistics';

import useStyles from './styles';

const TotalRevenue = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      component={Box}
      py={2}
      className={classes.root}
    >
      <Grid item component={Box} py={1}>
        <BarChartIcon />
      </Grid>

      <Grid item component={Box} py={1}>
        <Typography
          style={{
            fontSize: 16,
            color: '#a9b3ba',
            fontWeight: 600,
          }}
        >
          Total revenue earned
        </Typography>
      </Grid>

      <Grid item component={Box} py={1}>
        <Typography
          component="span"
          style={{
            fontSize: 20,
            fontWeight: 600,
            position: 'relative',
            bottom: 6,
          }}
        >
          $
        </Typography>
        <Typography
          component="span"
          style={{
            fontSize: 30,
            fontWeight: 600,
          }}
        >
          2000.00
        </Typography>
      </Grid>

      <Grid
        item
        container
        justify="center"
        alignItems="center"
        component={Box}
        py={1}
      >
        <ToggleButtonGroup />
        <Box px={0.5} />
        <CustomSelect />
      </Grid>

      <Grid item component={Box} py={1}>
        <Divider />
      </Grid>

      <Grid
        item
        container
        justify="space-between"
        component={Box}
        py={1}
        px={2}
      >
        <Grid item>
          <Typography className={classes.footerTitle}>
            Next payout date
          </Typography>
          <Typography className={classes.footerValue}>July 1, 2020</Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.footerTitle}>
            Expected payout amount
          </Typography>
          <Typography className={classes.footerValue}>$143.00</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalRevenue;
