import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    marginTop: spacing(10),
    marginBottom: spacing(10),

    [breakpoints.down('xs')]: {
      marginTop: spacing(8),
      marginBottom: spacing(8),
    },
  },
  heroGrid: {
    marginBottom: spacing(10),

    [breakpoints.down('xs')]: {
      marginBottom: spacing(8),
    },
  },
  imageGrid: {
    textAlign: 'right',
    '& img': {
      maxWidth: '100%',
    },
  },
  secondaryGrid: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subheaderMain: {
    marginBottom: spacing(1.4),
  },
  subheaderSecondary: {
    marginBottom: spacing(0.4),

    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  textSecondary: {
    marginBottom: spacing(4),

    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
    },
  },
  headerSecondary: {
    lineHeight: 1.33,
  },
}));
