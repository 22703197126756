import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import palette from 'theme/palette';

const Circle = memo((props) => {
  const { size, top, left, bottom, right, color = 'primary' } = props;
  const paletteColor = palette.logoColors[color];

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('sm'));
  const width = window.innerWidth;

  const responsiveSize = matchesMD ? size / 2 : size;
  const getLeft = () => {
    if (typeof left === 'string') return left?.slice(0, -1) > 87 ? '87%' : left;
    return left > width * 0.87 ? width * 0.87 : left;
  };
  const getRight = () => {
    if (typeof right === 'string')
      return right?.slice(0, -1) < 13 ? '13%' : right;
    return right < width * 0.13 ? width * 0.13 : right;
  };

  const responsiveLeft = matchesMD ? getLeft() : left;
  const responsiveRight = matchesMD ? getRight() : right;

  // method for generating random coordinate point
  const gen = () => Math.random() * 100 - 50;
  const genX = () =>
    matchesMD ? Math.random() * 20 - 10 : Math.random() * 100 - 50;

  // saving first generated point for x / y axes
  const startX = genX();
  const startY = gen();

  // the animation starts/ends in the same point
  const animate = {
    x: [
      startX,
      genX(),
      genX(),
      genX(),
      genX(),
      genX(),
      genX(),
      genX(),
      genX(),
      startX,
    ],
    y: [startY, gen(), gen(), gen(), gen(), gen(), gen(), gen(), gen(), startY],
  };

  const transition = {
    duration: 50,
    ease: 'easeInOut',
    repeat: Infinity,
    repeatType: 'reverse',
  };

  return (
    <motion.svg
      width={responsiveSize}
      height={responsiveSize}
      animate={animate}
      transition={transition}
      style={{
        position: 'absolute',
        zIndex: 5,
        top,
        left: responsiveLeft,
        bottom,
        right: responsiveRight,
      }}
    >
      <circle
        cx={responsiveSize / 2}
        cy={responsiveSize / 2}
        r={responsiveSize / 2}
        fill={paletteColor}
      />
    </motion.svg>
  );
});

Circle.propTypes = {
  size: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

export default Circle;
