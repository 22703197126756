import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  topButton: {
    borderRadius: 20,
    boxShadow: '0px 5px 15px #143A5680',
    color: '#fff',
  },
  tableContainerGrid: {
    backgroundColor: '#ffff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  resetButton: {
    textTransform: 'capitalize',
    backgroundColor: '#093b54',
    color: '#ffff',
    padding: 7,
    fontSize: 14,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#093b54',
      opacity: 0.6,
    },
  },
});
