export const data = [
  {
    columnName: 'Client reports',
    reports: [
      {
        title: 'Appointment Status',
        subTitle: 'Unpaid appointments & unwritten notes',
      },
      {
        title: 'Outstanding Balances',
        subTitle: 'See all clients carrying a balance',
      },
      {
        title: 'Attendance',
        subTitle: 'Track client attendance patterns',
      },
      {
        title: 'Invoices',
        subTitle: 'Check the status of all invoices',
      },
      {
        title: 'Check the status of all invoices',
        subTitle: 'Identify clients with past due invoices',
      },
    ],
  },
  {
    columnName: 'Claims reports',
    reports: [
      {
        title: 'Unpaid Insurance Appointments',
        subTitle: 'Find appointments with unpaid claims',
      },
      {
        title: 'Claim Aging',
        subTitle: 'Track unpaid amounts by insurance payer',
      },
      {
        title: 'Claims Filed',
        subTitle: 'Review all electronic claims filed',
      },
      {
        title: 'Insurance Processing Fees',
        subTitle: 'See claim filing and coverage report fees',
      },
    ],
  },
  {
    columnName: 'Income reports',
    reports: [
      {
        title: 'Overall Income by Month',
        subTitle: 'Track income month-by-month',
      },
      {
        title: 'Income Charged by Provider',
        subTitle: 'Review billing history by provider',
      },
      {
        title: 'Income Received by Provider',
        subTitle: 'Run payroll and track income by provider',
      },
      {
        title: 'Payouts',
        subTitle: 'Check the status of payouts',
      },
    ],
  },
];
