import { call, put, takeEvery } from 'redux-saga/effects';

export default function* sagaGenerator({ actionType, request, resource }) {
  function* fetch(action) {
    try {
      const payload = yield call(request, resource, action.payload);
      yield put({ type: actionType.success, payload });
    } catch ({ message }) {
      yield put({ type: actionType.failed, message });
    }
  }

  yield takeEvery(actionType.requested, fetch);
}
