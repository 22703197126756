import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  inputLabel: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  inputRoot: {
    marginTop: 20,
    maxWidth: 220,
    borderRadius: 0,
  },
  label: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  monthInput: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  yearInput: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  input: {
    padding: 10,
    fontSize: 14,
    color: 'black',
  },
  inputDisabled: {
    backgroundColor: '#f8f9fd',
    color: 'grey',
  },
  requiredMark: {
    position: 'absolute',
    left: '96%',
    bottom: -22,
    color: 'red',
  },
  select: {
    width: '100%',
  },
  menuItem: {
    fontSize: 14,
  },
});
