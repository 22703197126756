import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    borderRadius: 15,
    padding: spacing(3.75),
    border: `1px solid ${palette.text.primary}30`,
    backgroundColor: palette.white,

    [breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      border: 'unset',
      padding: spacing(3.75, 0),
      textAlign: 'center',
    },
  },
}));
